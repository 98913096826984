define('retro-game-store/pods/inventory/items/new/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-filtering', 'ember-validations', 'ember-awesome-macros/array/filter-by', 'ember-awesome-macros/array/find-by', 'ember-awesome-macros/conditional', 'ember-awesome-macros/raw'], function (exports, _ember, _retroGameStoreMixinsControllerFiltering, _emberValidations, _emberAwesomeMacrosArrayFilterBy, _emberAwesomeMacrosArrayFindBy, _emberAwesomeMacrosConditional, _emberAwesomeMacrosRaw) {
    var reads = _ember['default'].computed.reads;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var isBlank = _ember['default'].isBlank;
    var RSVP = _ember['default'].RSVP;
    var Promise = _ember['default'].RSVP.Promise;
    var debounce = _ember['default'].run.debounce;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        validations: {
            'model.quantity': {
                presence: true,
                numericality: {
                    onlyInteger: true,
                    greaterThanOrEqualTo: 1
                }
            },
            'model.buyPrice': {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            },
            'model.sellPrice': {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            }
        },
        scanner: service(),
        api: service(),
        saleController: controller('sales.sale'),
        sale: reads('saleController.model'),
        saleItems: reads('saleController.saleItems'),
        pricingData: null,
        pricingDataFilterBy: (0, _emberAwesomeMacrosArrayFilterBy['default'])('pricingData', (0, _emberAwesomeMacrosRaw['default'])('language.id'), 'languagesFilter.id'),
        pricingDataFiltered: (0, _emberAwesomeMacrosConditional['default'])('languagesFilter', 'pricingDataFilterBy', 'pricingData'),
        selectedProductSku: (0, _emberAwesomeMacrosArrayFindBy['default'])('product.productSkus', (0, _emberAwesomeMacrosRaw['default'])('id'), 'selectedCondition.id'),

        performSearch: function performSearch(term, resolve, reject) {
            return this.store.query('product', {
                keywords: term,
                sort: [{ name: 'Name', direction: 'ASC' }],
                largePage: true
            }).then(function (result) {
                return resolve(result);
            }, reject);
        },

        cancel: function cancel() {
            this.transitionToRoute('inventory.items');
        },

        getPricingData: function getPricingData() {
            var _this = this;

            RSVP.hash({
                pricingData: get(this, 'store').query('pricing-data', { productId: get(this, 'product.id') }),
                languages: get(this, 'store').query('product-sku-language', { productId: get(this, 'product.id') })
            }).then(function (response) {
                var pricingData = get(response, 'pricingData');
                var languages = get(response, 'languages');
                _this.selectCondition(get(pricingData, 'firstObject'));
                var languagesFilter = languages.findBy('abbreviation', 'EN');
                setProperties(_this, {
                    pricingData: pricingData,
                    languages: languages,
                    languagesFilter: languagesFilter
                });
            });
        },

        selectProduct: function selectProduct(product) {
            setProperties(this, {
                product: product,
                'model.productId': product ? get(product, 'id') : null,
                selectedCondition: null,
                pricingData: null
            });
            if (product) {
                this.getPricingData();
            }
        },

        selectCondition: function selectCondition(condition) {
            var _this2 = this;

            setProperties(this, {
                'model.buyPrice': get(condition, 'buyPrice'),
                'model.sellPrice': get(condition, 'sellPrice')
            });
            set(this, 'selectedCondition', condition);
            get(this, 'api').request('GET', '/products/pricing-data/' + get(this, 'selectedProductSku.id')).then(function (pricingData) {
                set(_this2, 'selectedPricingData', pricingData);
            });
        },

        actions: {
            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },

            cancel: function cancel() {
                this.cancel();
            },

            searchForProducts: function searchForProducts(term) {
                var _this3 = this;

                if (isBlank(term)) {
                    return [];
                }

                return new Promise(function (resolve, reject) {
                    debounce(_this3, 'performSearch', term, resolve, reject, 500);
                });
            },

            loadPricing: function loadPricing(type) {
                set(this, 'pricing', type);
            },

            selectedConditionChanged: function selectedConditionChanged(condition) {
                this.selectCondition(condition);
            },

            selectProduct: function selectProduct(product) {
                this.selectProduct(product);
            },

            save: function save(callback) {
                var _this4 = this;

                set(this, 'model.productSkuId', get(this, 'selectedCondition.id'));
                var promise = get(this, 'model').save().then(function () {
                    _this4.cancel();
                });
                callback(promise);
            }
        }
    });
});