define('retro-game-store/pods/store-label-option/model', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var get = _ember['default'].get;
    var isEmpty = _ember['default'].isEmpty;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        category: belongsTo('product-category'),
        categoryId: attr('string'),
        conditions: attr(),
        productType: belongsTo('product-type'),
        productTypeId: attr('number'),
        storeId: attr('number'),

        productTypeName: alias('productType.name'),

        fullCategory: computed('category.name', 'productType.name', function () {
            if (!isEmpty(get(this, 'category.name'))) {
                return get(this, 'productType.name') + ' / ' + get(this, 'category.name');
            } else {
                return '' + get(this, 'productType.name');
            }
        })
    });
});