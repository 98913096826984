define('retro-game-store/pods/invoice-item/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            productSku: {
                embedded: 'always'
            },
            storeInfo: {
                key: 'store',
                embedded: 'always'
            }
        },
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            payload.storeInfo = payload.store;
            delete payload.store;
            return this._super(store, primaryModelClass, payload, id, requestType);
        },
        serialize: function serialize(snapshot) {
            var payload = {
                storeId: snapshot.attr('storeId'),
                invoiceId: snapshot.attr('invoiceId'),
                itemCode: snapshot.attr('itemCode'),
                quantity: snapshot.attr('quantity'),
                buyPrice: snapshot.attr('buyPrice'),
                sellPrice: snapshot.attr('sellPrice')
            };

            // If this is a new invoice-item then there won't be an id, otherwise
            // we have to include it in the payload.
            if (snapshot.id) {
                payload.id = snapshot.id;
            }

            if (snapshot.belongsTo('productSku')) {
                payload.productSkuId = snapshot.belongsTo('productSku').id;
            } else if (snapshot.attr('productName')) {
                payload.productName = snapshot.attr('productName');
                delete payload.productSkuId;
            }

            // If a categoryId is present don't send categoryName because we
            // don't want a new custom category to be created.
            if (snapshot.attr('categoryId')) {
                payload.categoryId = snapshot.attr('categoryId');
            } else if (snapshot.attr('categoryName')) {
                payload.category = {
                    name: snapshot.attr('categoryName')
                };
                delete payload.categoryId;
            }

            if (snapshot.attr('upc')) {
                payload.upc = snapshot.attr('upc');
            }

            return payload;
        }
    });
});