define('retro-game-store/pods/components/confirm-action-btn/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['action-container'],
        classNameBindings: ['confirm', 'isDisabled'],
        tagName: 'span',
        updating: false,
        confirm: false,

        isDisabled: _ember['default'].computed('disabled', function () {
            if (this.get('disabled')) {
                return true;
            } else {
                return null;
            }
        }),

        buttonSize: (function () {
            if (this.get('size') === 'small') {
                return 'btn-xs';
            }
        }).property('size'),

        icon: (function () {
            if (this.get('confirm')) {
                return 'glyphicon-question-sign';
            } else {
                return 'glyphicon-remove';
            }
        }).property('confirm'),

        destroyPopover: function destroyPopover() {
            this.get('popover').popover('destroy');
            this.set('popover', null);
        },

        cancel: function cancel() {
            this.destroyPopover();
            this.set('confirm', false);
            this.destroyOutsideClickListener();
        },

        listenForClicksOutside: function listenForClicksOutside() {
            var _this = this;

            var listener = $(document).on('click', function (event) {
                var container = $(_this.get('element')),
                    describedById = $(_this.get('element')).find('a').attr('aria-describedby'),
                    popup = $('#' + describedById);
                if (!popup.is(event.target) && !container.is(event.target) && container.has(event.target).length === 0 && popup.has(event.target).length === 0 && _this.get('popover')) {
                    // if the target of the click isn't the container...... nor a descendant of the container
                    _this.cancel();
                }
            });
            this.set('listener', listener);
        },

        destroyOutsideClickListener: function destroyOutsideClickListener() {
            $(document).off('click', this.get('listener'));
        },

        actions: {
            confirm: function confirm() {
                var element, placement;
                if (!this.get('disabled')) {
                    if (!this.get('confirm')) {
                        element = this.get('element');
                        placement = this.get('placement');

                        this.set('popover', $(element).find('a').popover({
                            container: 'body', // popover will be rendered inside body to prevent clipping by parent elements
                            placement: placement || 'top auto',
                            html: true,
                            content: $(element).find('.action-confirmation').html()
                        }));

                        this.get('popover').popover('show');
                        this.listenForClicksOutside();
                        this.toggleProperty('confirm');
                    } else {
                        this.cancel();
                    }
                }
            },

            confirmAction: function confirmAction() {
                this.set('updating', false);
                this.cancel();
                this.sendAction('confirmAction', this.get('model'));
            },

            cancel: function cancel() {
                this.cancel();
            }
        }
    });
});