define('retro-game-store/pods/sales/sale/trade-in-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'retro-game-store/mixins/wizard-modal', 'retro-game-store/mixins/scanner-event-listener', 'ember-validations', 'accounting/to-fixed', 'ember-awesome-macros/array/find-by', 'ember-awesome-macros/array/filter-by', 'ember-awesome-macros/conditional', 'ember-awesome-macros/raw'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _retroGameStoreMixinsWizardModal, _retroGameStoreMixinsScannerEventListener, _emberValidations, _accountingToFixed, _emberAwesomeMacrosArrayFindBy, _emberAwesomeMacrosArrayFilterBy, _emberAwesomeMacrosConditional, _emberAwesomeMacrosRaw) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var bool = _Ember$computed.bool;
    var mapBy = _Ember$computed.mapBy;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var next = _ember['default'].run.next;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], _retroGameStoreMixinsWizardModal['default'], _retroGameStoreMixinsScannerEventListener['default'], {
        scanner: service(),
        api: service(),
        saleController: controller('sales.sale'),
        sale: reads('saleController.model'),
        saleItems: reads('saleController.saleItems'),
        product: null,
        productTotalSaleCount: computed('product.totalSaleCount', function () {
            return get(this, 'product.totalSaleCount') || 0;
        }),

        selectedProductSku: (0, _emberAwesomeMacrosArrayFindBy['default'])('product.productSkus', (0, _emberAwesomeMacrosRaw['default'])('id'), 'selectedCondition.id'),
        pricingDataFilterBy: (0, _emberAwesomeMacrosArrayFilterBy['default'])('pricingData', (0, _emberAwesomeMacrosRaw['default'])('language.id'), 'languagesFilter.id'),
        pricingDataFiltered: (0, _emberAwesomeMacrosConditional['default'])('languagesFilter', 'pricingDataFilterBy', 'pricingData'),

        //Step array is required for wizard modal
        steps: ['find', 'add'],
        validations: {},
        stepValidations: {
            add: {
                'willSellFor': {
                    presence: true,
                    numericality: {
                        greaterThan: 0
                    }
                }
            }
        },
        step: 'find',
        serverQueryParams: ['keywords', 'categoryIds', 'productTypeIds', 'includeSales'],
        includeSales: true,
        selectedCategories: A(),
        categoryIds: mapBy('selectedCategories', 'id'),
        selectedProductTypes: A(),
        productTypeIds: mapBy('selectedProductTypes', 'id'),
        keyboardListActive: true,

        tradeForPrice: 0,
        cashForPrice: 0,
        tradeInputHasFocus: false,
        cashInputHasFocus: false,
        cashDiscountMultiplier: computed('product.cashDiscount', function () {
            return 1 - this.get('product.cashDiscount');
        }),

        averageCalcTradeValueFromCash: computed('selectedPricingData.averageTradeCashPrice', 'cashDiscountMultiplier', function () {
            var tradePrice = get(this, 'selectedPricingData.averageTradeCashPrice');
            var multiplier = get(this, 'cashDiscountMultiplier');
            if (multiplier === 0) {
                return tradePrice;
            } else {
                return tradePrice / multiplier;
            }
        }),

        finalPriceCredit: reads('tradeForPrice'),
        finalPriceCash: reads('cashForPrice'),

        tradeMargins: computed('product.tradePercentage', 'selectedPricingData.competitorBuyPrice', 'willSellFor', function () {
            if (get(this, 'product')) {
                var cashDiscount = get(this, 'product.cashDiscount');
                // Note that tradePercentage is coming from the product model;
                // if there is a platform-specific tradePercentage value then
                // product.tradePercentage will return that, otherwise it
                // will match the global value set in admin->affiliate->options
                var tradePercentage = get(this, 'product.tradePercentage');
                var tradeMarginPercent = tradePercentage / 100;
                var willSellFor = get(this, 'willSellFor');
                var competitorBuyPrice = get(this, 'selectedPricingData.competitorBuyPrice');
                var defaultTradeMargin = tradeMarginPercent * willSellFor;
                return {
                    standard: defaultTradeMargin,
                    plusTen: defaultTradeMargin * 1.10,
                    minusTen: defaultTradeMargin * 0.90,
                    competitorCash: competitorBuyPrice * 1.10,
                    competitorTrade: competitorBuyPrice * (1 + cashDiscount) * 1.10
                };
            }
        }),

        pricing: 'loose',

        willSellFor: alias('selectedPricingData.sellingForPrice'),

        priceChartsEnabled: bool('selectedPricingData.buyPrice'),

        closeModal: function closeModal() {
            this.setProperties({
                model: null,
                pricing: 'loose',
                pricingData: null,
                product: null,
                keywords: null,
                platformFilter: null,
                category1Ids: _ember['default'].A(),
                inventoryNotes: null,
                tradeForPrice: 0,
                cashForPrice: 0,
                selectedCategory: null,
                selectedCategories: A()
            });
            this.resetModal();
            this.ignoreScanner();
            get(this, 'saleController').listenForScanner();
            this.send('closeModal');
        },

        resetModal: function resetModal() {
            set(this, 'step', get(this, 'steps')[0]);
        },

        addTradeItem: function addTradeItem() {
            var _this = this;

            var data = {
                id: get(this, 'sale.id'),
                productSkuId: get(this, 'selectedCondition.id'),
                conditionId: get(this, 'selectedCondition.condition.id'),
                type: 'trade',
                sellPrice: get(this, 'willSellFor'),
                tradePrice: (0, _accountingToFixed['default'])(get(this, 'finalPriceCredit'), 2),
                cashPrice: (0, _accountingToFixed['default'])(get(this, 'finalPriceCash'), 2),
                inventoryNotes: get(this, 'inventoryNotes'),
                quantity: 1
            };

            return get(this, 'api').request('POST', '/tradeSaleItems', data).then(function (saleItem) {
                //since this record was created by the back end, we now push it into the store and add it to the current list of sale items
                var normalizedSaleItem = _this.store.normalize('saleItem', saleItem);
                var saleItemAdded = _this.store.push(normalizedSaleItem);
                get(_this, 'saleItems').addObject(saleItemAdded);
                _this.send('refreshSale');
            });
        },

        selectProduct: function selectProduct(item) {
            var _this2 = this;

            set(this, 'product', item);

            RSVP.hash({
                pricingData: get(this, 'store').query('pricing-data', { productId: get(item, 'id') }),
                languages: get(this, 'store').query('product-sku-language', { productId: get(item, 'id') })
            }).then(function (response) {
                var pricingData = get(response, 'pricingData');
                // Select the second item --> "Complete in Box"
                _this2.selectCondition(pricingData.objectAt(1));
                var languages = get(response, 'languages');
                var languagesFilter = languages.findBy('abbreviation', 'EN');
                setProperties(_this2, {
                    pricingData: pricingData,
                    languages: languages,
                    languagesFilter: languagesFilter
                });

                _this2.stepForward();
            });
        },

        selectCondition: function selectCondition(condition) {
            var _this3 = this;

            set(this, 'selectedCondition', condition);
            get(this, 'api').request('GET', '/products/pricing-data/' + get(this, 'selectedProductSku.id')).then(function (pricingData) {
                set(_this3, 'selectedPricingData', pricingData);
            });
        },

        resetFirstStep: function resetFirstStep() {
            if (get(this, 'model')) {
                get(this, 'model').clear();
            }
            set(this, 'keywords', null);
        },

        backToFirstStep: function backToFirstStep(addItemCompleted) {
            if (addItemCompleted) {
                this.resetFirstStep();
            }
            this.stepBackward();
            get(this, 'selectedCondition').rollbackAttributes();
            setProperties(this, {
                pricingData: null,
                product: null,
                inventoryNotes: null,
                tradeForPrice: null,
                cashForPrice: null,
                selectedCondition: null,
                willSellForMasked: null
            });
            next(function () {
                _ember['default'].$('.modal-body input').first().focus();
            });
        },

        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },

            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },

            cancel: function cancel() {
                this.closeModal();
            },

            loadPricing: function loadPricing(type) {
                set(this, 'pricing', type);
            },

            selectProduct: function selectProduct(item) {
                this.selectProduct(item);
            },

            addToSale: function addToSale(callback) {
                var promise = this.addTradeItem();
                this.backToFirstStep(true);
                callback(promise);
            },

            scanSuccess: function scanSuccess(number) {
                var _this4 = this;

                get(this, 'api').request('GET', '/products/upc/' + number).then(function (product) {
                    var productAdded = get(_this4, 'store').push(get(_this4, 'store').normalize('product', product));
                    if (productAdded) {
                        _this4.selectProduct(productAdded);
                    }
                });
            },

            backToFirstStep: function backToFirstStep() {
                this.backToFirstStep();
            },

            platformSelectStateChanged: function platformSelectStateChanged() {
                this.toggleProperty('keyboardListActive');
            },

            setPrice: function setPrice(type, tradeForPrice, cashForPrice) {
                setProperties(this, {
                    tradeForPrice: tradeForPrice,
                    cashForPrice: cashForPrice
                });
            },

            inputBlur: function inputBlur(type, price) {
                var discount = 1 - get(this, 'product.cashDiscount');
                var priceInt = price ? parseFloat(price.replace("$", "")) : 0;
                if (type === 'trade') {
                    if (discount === 0) {
                        set(this, 'cashForPrice', 0);
                    } else {
                        set(this, 'cashForPrice', get(this, 'tradeForPrice') * discount);
                    }
                } else {
                    if (discount === 0) {
                        setProperties(this, {
                            tradeForPrice: priceInt,
                            cashForPrice: 0
                        });
                    } else {
                        if (!priceInt) {
                            set(this, 'tradeForPrice', priceInt);
                        } else {
                            set(this, 'tradeForPrice', get(this, 'cashForPrice') / discount);
                        }
                    }
                }
            },

            selectedConditionChanged: function selectedConditionChanged(condition) {
                this.selectCondition(condition);
            }
        }
    });
});