define('retro-game-store/pods/components/sale-totals/component', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        shipping: true,
        tax: true,
        discount: true,
        origTaxPercent: null,
        noTax: false,

        actions: {
            editShipping: function editShipping(sale) {
                this.sendAction('editShipping', sale);
            },
            editTax: function editTax(sale) {
                this.sendAction('editTax', sale);
            },
            editDiscount: function editDiscount(sale) {
                this.sendAction('editDiscount', sale);
            },
            toggleNoTax: function toggleNoTax(sale) {
                set(this, 'noTax', !get(this, 'noTax'));
                //store original tax percent
                if (get(this, 'noTax')) {
                    set(this, 'origTaxPercent', get(sale, 'taxPercent'));
                }
                set(sale, 'taxPercent', get(this, 'noTax') ? 0 : get(this, 'origTaxPercent'));
                sale.save();
            }
        }
    });
});