define('retro-game-store/pods/sale/serializer', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
    exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            customer: {
                serialize: false,
                deserialize: 'records'
            },
            payments: {
                embedded: 'always'
            },
            processedBy: {
                serialize: false,
                deserialize: 'records'
            },
            returnTransaction: {
                serialize: false,
                deserialize: 'records'
            },
            storeInfo: {
                embedded: 'always'
            }
        },
        normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
            payload.storeInfo = payload.store;
            delete payload.store;
            var payloadWithRoot = {};
            payloadWithRoot[primaryModelClass.modelName] = payload;
            return this._super(store, primaryModelClass, payloadWithRoot, id, requestType);
        },
        normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
            payload.storeInfo = payload.store;
            delete payload.store;
            var payloadWithRoot = {};
            payloadWithRoot[primaryModelClass.modelName] = payload;
            return this._super(store, primaryModelClass, payloadWithRoot, id, requestType);
        },
        normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
            payload.storeInfo = payload.store;
            delete payload.store;
            var payloadWithRoot = {};
            payloadWithRoot[primaryModelClass.modelName] = payload;
            return this._super(store, primaryModelClass, payloadWithRoot, id, requestType);
        },
        normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
            if (payload === null) {
                return { data: null };
            }

            var payloadWithRoot = {},
                isCollection = payload.items.length > 0,
                key = isCollection ? _emberInflector['default'].inflector.pluralize(primaryModelClass.modelName) : primaryModelClass.modelName;

            payloadWithRoot[key] = payload.items;
            payloadWithRoot[key] = payloadWithRoot[key].map(function (item) {
                item.storeInfo = item.store;
                delete item.store;
                return item;
            });
            payloadWithRoot['meta'] = payload.meta;
            return this._super(store, primaryModelClass, payloadWithRoot, id, requestType);
        },
        serializeIntoHash: function serializeIntoHash(json, typeClass, snapshot, options) {
            if (!options) {
                options = { includeId: true };
            } else {
                options.includeId = true;
            }

            var serialized = this.serialize(snapshot, options),
                prop;

            for (prop in serialized) {
                if (serialized.hasOwnProperty(prop)) {
                    json[prop] = serialized[prop];
                }
            }
        }
    });
});