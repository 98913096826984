define('retro-game-store/pods/components/autocomplete-with-create/component', ['exports', 'ember', 'ember-power-select/utils/group-utils'], function (exports, _ember, _emberPowerSelectUtilsGroupUtils) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        matcher: _emberPowerSelectUtilsGroupUtils.defaultMatcher,

        // Lifecycle hooks
        init: function init() {
            this._super.apply(this, arguments);
            _ember['default'].assert('{{autocomplete-with-create}} requires an `oncreate` function', this.get('oncreate') && typeof this.get('oncreate') === 'function');
        },

        // CPs
        optionsArray: computed('options.[]', function () {
            if (!this.get('options')) {
                return [];
            }
            return _ember['default'].A(this.get('options')).toArray();
        }),

        // Actions
        actions: {
            searchAndSuggest: function searchAndSuggest(term) {
                var _this = this;

                var newOptions = this.get('optionsArray');

                if (term.length === 0) {
                    return newOptions;
                }

                if (this.get('search')) {
                    return _ember['default'].RSVP.resolve(this.get('search')(term)).then(function (results) {
                        if (results.toArray) {
                            results = results.toArray();
                        }
                        results.unshift(_this.buildSuggestionForTerm(term));
                        return results;
                    });
                }

                newOptions = this.filter(_ember['default'].A(newOptions), term);
                newOptions.unshift(this.buildSuggestionForTerm(term));
                return newOptions;
            },

            selectOrCreate: function selectOrCreate(selection) {
                var suggestion = undefined;
                if (this.get('multiple')) {
                    suggestion = selection.filter(function (option) {
                        return option.__isSuggestion__;
                    })[0];
                } else if (selection && selection.__isSuggestion__) {
                    suggestion = selection;
                }

                if (suggestion) {
                    this.get('oncreate')(suggestion.__value__);
                } else {
                    this.get('onchange')(selection);
                }
            }
        },

        // Methods
        filter: function filter(options, searchText) {
            var _this2 = this;

            var matcher = undefined;
            if (this.get('searchField')) {
                matcher = function (option, text) {
                    return _this2.matcher(get(option, _this2.get('searchField')), text);
                };
            } else {
                matcher = function (option, text) {
                    return _this2.matcher(option, text);
                };
            }
            return (0, _emberPowerSelectUtilsGroupUtils.filterOptions)(options || [], searchText, matcher);
        },

        buildSuggestionForTerm: function buildSuggestionForTerm(term) {
            return {
                __isSuggestion__: true,
                __value__: term,
                text: this.buildSuggestionLabel(term)
            };
        },

        buildSuggestionLabel: function buildSuggestionLabel(term) {
            var buildSuggestion = this.get('buildSuggestion');
            if (buildSuggestion) {
                return buildSuggestion(term);
            }
            return 'Add "' + term + '"...';
        }
    });
});