define('retro-game-store/mixins/query-params', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var isArray = _ember['default'].isArray;
    var Mixin = _ember['default'].Mixin;
    exports['default'] = Mixin.create({
        getParamsObject: function getParamsObject(parameters, context) {
            var params = {};
            context = context || this;

            if (parameters && isArray(parameters)) {
                parameters.forEach(function (paramName) {
                    var param = get(context, paramName);
                    if (param instanceof Date) {
                        params[paramName] = param.toISOString();
                    } else {
                        params[paramName] = param;
                    }
                });
            }
            if (get(context, 'sortBy')) {
                params.sortby = get(context, 'sortBy');
            }
            if (get(context, 'inlineCount')) {
                params.inlinecount = get(context, 'inlineCount');
            }
            return params;
        },
        removeEmptyQueryParams: function removeEmptyQueryParams(queryParams) {
            for (var i in queryParams) {
                if (queryParams[i] === null || queryParams[i] === undefined || queryParams[i] === '' || isArray(queryParams[i]) && queryParams[i].length === 0) {
                    delete queryParams[i];
                }
            }
            return queryParams;
        }
    });
});