define('retro-game-store/pods/sales/sale/stash-modal/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        saleController: _ember['default'].inject.controller('sales/sale'),
        closeModal: function closeModal() {
            var saleController = this.get('saleController');
            this.get('model').rollbackAttributes();
            saleController.set('saveForLater', true);
            _ember['default'].run.later(function () {
                saleController.set('saveForLater', false);
            }, 3000);
            this.send('closeModal');
        },
        actions: {
            cancel: function cancel() {
                this.closeModal();
            },
            save: function save(callback) {
                var _this = this;

                var promise = this.get('model').save().then(function () {
                    _this.closeModal();
                })['catch'](function (err) {
                    console.log(err);
                });
                callback(promise);
            }
        }
    });
});