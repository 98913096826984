define('retro-game-store/pods/shipment-item/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        shipmentId: _emberData['default'].attr('string'),
        transactionItemId: _emberData['default'].attr('string'),
        quantity: _emberData['default'].attr('number'),

        //relationships
        transactionItem: _emberData['default'].belongsTo('saleItem'),
        shipment: _emberData['default'].belongsTo('shipment')
    });
});