define('retro-game-store/pods/invoices/invoice/items/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination'], function (exports, _ember, _retroGameStoreMixinsRoutePagination) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsRoutePagination['default'], {
        categories: service(),

        model: function model(params) {
            var controller = this.controllerFor('invoices.invoice.items');
            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            queryParams.invoiceId = get(this.modelFor('invoices.invoice'), 'id');
            return this.pagingQuery('invoice-item', queryParams, 'invoices.invoice.items');
        },

        openInvoiceItemModal: function openInvoiceItemModal(record) {
            var isNew = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

            var location = 'invoices/invoice/items/invoice-item-modal';
            var controller = this.controllerFor(location);
            var productTypes = get(this, 'categories.raw').filter(function (item) {
                return [0, 1].contains(parseInt(get(item, 'id'), 10));
            });

            setProperties(controller, {
                productTypes: productTypes,
                modalFocusInput: isNew
            });

            if (get(record, 'productSku.product.category')) {
                setProperties(record, {
                    category: get(record, 'productSku.product.category'),
                    categoryId: get(record, 'productSku.product.category.id')
                });
            }

            if (get(record, 'productSku.product')) {
                set(record, 'productName', get(record, 'productSku.product.name'));
                setProperties(controller, {
                    productName: get(record, 'productSku.product.name'),
                    productUpc: get(record, 'productSku.product.upc')
                });
            }

            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });

            this.send('openModal', location, record, location);
        },

        actions: {
            addInvoiceItem: function addInvoiceItem() {
                var invoiceId = get(this.modelFor('invoices/invoice'), 'id');
                var record = this.store.createRecord('invoice-item', { invoiceId: invoiceId });
                this.openInvoiceItemModal(record, true);
            },

            editInvoiceItem: function editInvoiceItem(record) {
                this.openInvoiceItemModal(record);
            }
        }
    });
});