define('retro-game-store/pods/debug/controller', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		terminal: _ember['default'].inject.service(),
		api: _ember['default'].inject.service(),
		reportCommandStatus: function reportCommandStatus(message, spinner) {
			var currentText = $('#OutputField').val();
			$('#OutputField').val(currentText + message.Message + '\n\n');
			this.set(spinner, false);
		},
		actions: {
			clearOutput: function clearOutput() {
				$("#OutputField").val("");
			},

			checkConnectivity: function checkConnectivity() {
				this.set('testingConnections', true);
				window.desktop.BrokerAsyncCommand('CheckConnectivity', {});
			},

			openCashDrawer: function openCashDrawer() {
				this.set('openingCash', true);
				window.desktop.BrokerAsyncCommand('OpenCashDrawer', {});
			},

			printTestInventoryLabels: function printTestInventoryLabels() {
				this.set('printingInventory', true);
				var inventoryLabels = { ListWrapper: [{ StockNumber: "0001-000000123456", Price: 59.19, Condition: "New", Name: "Professor Layton and the Azran Legacy", Category: "Nintendo 3DS" }, { StockNumber: "0001-000000123457", Price: 17.99, Condition: "New", Name: "Elebits", Category: "Wii" }, { StockNumber: "0001-000000123458", Price: 30.99, Condition: "PRE-OWNED", Name: "E.T the Extra-Terrestrial", Category: "Atari 2600" }, { StockNumber: "0001-000000123459", Price: 15.99, Condition: "PRE-OWNED", Name: "Dr. Mario", Category: "GameBoy" }] };

				window.desktop.BrokerAsyncCommand('PrintInventoryLabels', inventoryLabels);
			},

			printTestInventoryBoxLabels: function printTestInventoryBoxLabels() {
				this.set('printingInventoryBox', true);
				var inventoryLabels = { ListWrapper: [{ StockNumber: "0001-000000123456", Price: 59.19, Condition: "New", Name: "Professor Layton and the Azran Legacy", Category: "Nintendo 3DS", IsBoxLabel: true }, { StockNumber: "0001-000000123457", Price: 17.99, Condition: "New", Name: "Elebits", Category: "Wii", IsBoxLabel: true }, { StockNumber: "0001-000000123458", Price: 30.99, Condition: "PRE-OWNED", Name: "E.T the Extra-Terrestrial", Category: "Atari 2600", IsBoxLabel: true }, { StockNumber: "0001-000000123459", Price: 15.99, Condition: "PRE-OWNED", Name: "Dr. Mario", Category: "GameBoy", IsBoxLabel: true }] };

				window.desktop.BrokerAsyncCommand('PrintInventoryLabels', inventoryLabels);
			},

			printTestShippingLabels: function printTestShippingLabels() {
				this.set('printingShipping', true);
				window.desktop.BrokerAsyncCommand('PrintShippingLabels', { ListWrapper: ['testlabel'] });
			},

			switchToInventoryLabels: function switchToInventoryLabels() {
				this.set('calibratingInventory', true);
				window.desktop.BrokerAsyncCommand('SwitchToInventory', {});
			},

			switchToShippingLabels: function switchToShippingLabels() {
				this.set('calibratingShipping', true);
				window.desktop.BrokerAsyncCommand('SwitchToShipping', {});
			},

			printTestReceipt: function printTestReceipt() {
				//this.set('printingReceipt', true);
				var receipt = {
					OrderNumber: 121599,
					CashierName: "Penelope Clearwater",
					Total: 39.23,
					Subtotal: 34.48,
					Tax: 1.55,
					Shipping: 3.2,
					Discount: 5.4,
					CustomerName: "Angelina Johnson",
					CustomerNumber: 4025,
					CustomerBalance: 35,
					Shop: {
						Name: "Jay Street Video Games",
						Location: "Crossgates Mall",
						Address1: "250 Crossgates Mall Rd.",
						Address2: "Albany, NY, 12110",
						Phone: "(518) 123-4567",
						Website: "www.jaystreet.something.com",
						Id: "1007"
					},
					Items: [{ Description: "New Super Mario Bros. Wii", Price: 9.99, Quantity: 1 }, { Description: "Nintendo 64 Controller", Price: 13.5, Quantity: 3 }, { Description: "PS2 Memory Card 250MB", Price: 5, Quantity: 1, Discount: 3.5 }, { Description: "Okamiden", Price: 14.48, Quantity: 1, IsShipment: true }, { Description: "Grand Theft Auto 4", Price: -15.99, Quantity: 1, IsTradein: true }, { Description: "Gift Card", Price: 10 }],
					Payments: [{ Description: "Credit ************1234", Amount: 39.23 }]
				};

				window.desktop.BrokerAsyncCommand('PrintReceipt', receipt);
			},

			printTestAuthorizationReceipt: function printTestAuthorizationReceipt() {
				this.set('printingAuthReceipt', true);
				var authorizationReceipt = {
					OrderNumber: 121599, CashierName: "Hannah Abbott", Total: 39.23,
					CardType: "MasterCard", CardNumber: "************1234", StripeId: "0987", CustomerName: "Angelina Johnson",
					Shop: {
						Name: "Jay Street Video Games",
						Location: "Crossgates Mall",
						Address1: "250 Crossgates Mall Rd.",
						Address2: "Albany, NY, 12110",
						Phone: "(518) 123-4567",
						Website: "www.jaystreet.something.com",
						Id: "1007"
					}
				};

				window.desktop.BrokerAsyncCommand('PrintAuthorizationReceipt', authorizationReceipt);
			}
		},
		init: function init() {
			// Here we are receiving events from the terminal - such as this credit card read
			this.get('terminal').on('CreditCardRead:Success', this, function (creditCard) {
				var currentText = $("#OutputField").val();

				var newText = "Successful Credit Card Read: -----------\n" + "Account Holder: " + creditCard.FirstName + " " + creditCard.MiddleInitial + " " + creditCard.LastName + "\n" + "Account Number: " + creditCard.CardNumber + "\n" + "Expiration: " + creditCard.Expiration + "\n" + "Service Code: " + creditCard.ServiceCode + "\n\n";

				$("#OutputField").val(currentText + newText);
			});

			this.get('terminal').on('ConnectivityStatus', this, function () {
				var currentText = $('#OutputField').val();

				var newText = "Receipt Printer: Status " + this.get('terminal').get('receiptPrinter').get('status') + "\n" + "\t Message: " + this.get('terminal').get('receiptPrinter').get('message') + "\n";
				newText += "Label Printer: Status " + this.get('terminal').get('labelPrinter').get('status') + "\n" + "\t Message: " + this.get('terminal').get('labelPrinter').get('message') + "\n";
				newText += "Card Reader: Status " + this.get('terminal').get('cardReader').get('status') + "\n" + "\t Message: " + this.get('terminal').get('cardReader').get('message') + "\n";
				newText += "Cash Drawer: Status " + this.get('terminal').get('cashDrawer').get('status') + "\n" + "\t Message: " + this.get('terminal').get('cashDrawer').get('message') + "\n";

				if (this.get('testingConnections')) {
					$('#OutputField').val(currentText + newText + '\n');
				}
				this.set('testingConnections', false);
			});

			this.get('terminal').on('error', this, function (message) {
				this.reportCommandStatus(message);
			});
			this.get('terminal').on('CashDrawer:Success', this, function (message) {
				this.reportCommandStatus(message, 'openingCash');
			});
			this.get('terminal').on('CashDrawer:Failure', this, function (message) {
				this.reportCommandStatus(message, 'openingCash');
			});
			this.get('terminal').on('PrintReceipt:Success', this, function (message) {
				this.reportCommandStatus(message, 'printingReceipt');
			});
			this.get('terminal').on('PrintReceipt:Failure', this, function (message) {
				this.reportCommandStatus(message, 'printingReceipt');
			});
			this.get('terminal').on('PrintReceipt:Auth:Success', this, function (message) {
				this.reportCommandStatus(message, 'printingAuthReceipt');
			});
			this.get('terminal').on('PrintReceipt:Auth:Failure', this, function (message) {
				this.reportCommandStatus(message, 'printingAuthReceipt');
			});
			this.get('terminal').on('PrintLabel:Inventory:Success', this, function (message) {
				this.reportCommandStatus(message, 'printingInventory');this.set('printingInventoryBox');
			});
			this.get('terminal').on('PrintLabel:Inventory:Failure', this, function (message) {
				this.reportCommandStatus(message, 'printingInventory');this.set('printingInventoryBox', false);
			});
			this.get('terminal').on('PrintLabel:Shipping:Success', this, function (message) {
				this.reportCommandStatus(message, 'printingShipping');
			});
			this.get('terminal').on('PrintLabel:Shipping:Failure', this, function (message) {
				this.reportCommandStatus(message, 'printingShipping');
			});
			this.get('terminal').on('SetMode:Inventory:Success', this, function (message) {
				this.reportCommandStatus(message, 'calibratingInventory');
			});
			this.get('terminal').on('SetMode:Inventory:Failure', this, function (message) {
				this.reportCommandStatus(message, 'calibratingInventory');
			});
			this.get('terminal').on('SetMode:Shipping:Success', this, function (message) {
				this.reportCommandStatus(message, 'calibratingShipping');
			});
			this.get('terminal').on('SetMode:Shipping:Failure', this, function (message) {
				this.reportCommandStatus(message, 'calibratingShipping');
			});
			this.get('terminal').on('CreditCardRead:Failure', this, function (message) {
				this.reportCommandStatus(message);
			});

			this._super.apply(this, arguments);
		}
	});
});
/**
 * @DEPRECATED
 *
 * This page is no longer in use (see `application/settings-modal` instead).
 * However, it will remain in the application for legacy concerns.
 */