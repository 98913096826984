define('retro-game-store/pods/customers/customer/notifications/index/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _emberValidations['default'], {
        limit: 20,
        serverQueryParams: ['customerId'],
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(this.get('serverQueryParams'));
                callback(promise);
            },
            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            }
        }
    });
});