define("retro-game-store/pods/dashboard/list-partial/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 20
            },
            "end": {
              "line": 15,
              "column": 20
            }
          },
          "moduleName": "retro-game-store/pods/dashboard/list-partial/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "tableView.name", ["loc", [null, [14, 24], [14, 42]]]]],
        locals: ["tableView"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "retro-game-store/pods/dashboard/list-partial/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "element-detail-box");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row table-filter-data");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-6");
        var el6 = dom.createTextNode("\n                    Currently showing results for ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-xs-6 hidden-print");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["content", "resultsShownDate", ["loc", [null, [6, 50], [6, 70]]]], ["block", "select-component", [], ["options", ["subexpr", "@mut", [["get", "tableViews", ["loc", [null, [10, 32], [10, 42]]]]], [], []], "selected", ["subexpr", "@mut", [["get", "viewSelected", ["loc", [null, [11, 33], [11, 45]]]]], [], []], "searchEnabled", false, "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "viewSelected", ["loc", [null, [13, 46], [13, 58]]]]], [], ["loc", [null, [13, 41], [13, 59]]]]], [], ["loc", [null, [13, 33], [13, 60]]]]], 0, null, ["loc", [null, [9, 20], [15, 41]]]], ["inline", "report-table", [], ["columns", ["subexpr", "@mut", [["get", "columns", ["loc", [null, [19, 24], [19, 31]]]]], [], []], "models", ["subexpr", "@mut", [["get", "model", ["loc", [null, [20, 23], [20, 28]]]]], [], []], "totals", ["subexpr", "@mut", [["get", "totals", ["loc", [null, [21, 23], [21, 29]]]]], [], []], "canLoadMore", ["subexpr", "@mut", [["get", "hasMore", ["loc", [null, [22, 28], [22, 35]]]]], [], []], "fetch-records", ["subexpr", "action", ["loadMore"], [], ["loc", [null, [23, 30], [23, 49]]]], "row-clicked", ["subexpr", "action", ["rowClicked"], [], ["loc", [null, [24, 28], [24, 49]]]], "tableActions", ["subexpr", "hash", [], ["linkClick", ["subexpr", "action", ["linkClick"], [], ["loc", [null, [26, 30], [26, 50]]]]], ["loc", [null, [25, 29], [27, 17]]]]], ["loc", [null, [18, 12], [28, 14]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});