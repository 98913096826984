define('retro-game-store/pods/customers/customer/details/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        creditCard: _ember['default'].computed.alias('model.creditCard'),
        validations: {
            'model.firstName': {
                presence: true
            },
            'model.lastName': {
                presence: true
            },
            'model.email': {
                presence: true
            },
            'model.mobile': {
                presence: true
            },
            'model.license': {
                length: {
                    maximum: 32,
                    allowBlank: true
                }
            },
            'model.creditBalance': {
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            }
        },
        actions: {
            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            }
        }

    });
});