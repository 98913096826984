define('retro-game-store/pods/dashboard/stores/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var mapBy = _Ember$computed.mapBy;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], {
        i18n: service(),

        dashboardController: controller('dashboard'),
        platformController: controller('dashboard.platforms'),
        completedSalesController: controller('sales.completed'),
        storeSelected: reads('dashboardController.storeSelected'),
        categoryFilters: reads('dashboardController.categoryFilters'),
        productTypeFilters: reads('dashboardController.productTypeFilters'),
        startDate: reads('dashboardController.startDate'),
        endDate: reads('dashboardController.endDate'),
        resultsShownDate: reads('dashboardController.resultsShownDate'),
        _typeKey: 'store-total',

        storeId: computed('storeSelected', function () {
            if (get(this, 'storeSelected')) {
                return get(this, 'storeSelected.id');
            }
        }),

        viewSelected: {
            id: 'overview',
            name: 'Overview'
        },

        groupBy: 'store',
        limit: 10,
        platformIds: mapBy('categoryFilters', 'id'),
        productTypeIds: mapBy('productTypeFilters', 'id'),
        serverQueryParams: ['startDate', 'endDate', 'platformIds', 'productTypeIds', 'storeId', 'groupBy'],

        tableViews: computed(function () {
            var i18n = get(this, 'i18n');
            return A([{
                id: 'overview',
                name: i18n.t("dashboard.views.overview")
            }, {
                id: 'activity',
                name: i18n.t("dashboard.views.activity")
            }, {
                id: 'payments',
                name: i18n.t("dashboard.views.paymentTypes")
            }, {
                id: 'taxAndShipping',
                name: i18n.t("dashboard.views.taxAndShipping")
            }]);
        }),

        overview: computed(function () {
            var i18n = get(this, 'i18n');
            return A([{
                label: i18n.t("dashboard.store"),
                valuePath: 'storeInfo.name',
                width: '20%'
            }, {
                label: i18n.t("dashboard.sales"),
                valuePath: 'salesOverview',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.cogs"),
                valuePath: 'costOfGoods',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.returns"),
                valuePath: 'returnsOverview',
                cellComponent: 'absolute-table-money-cell',
                align: 'right'
            }, {
                label: i18n.t("dashboard.grossProfit"),
                valuePath: 'grossProfit',
                cellComponent: 'color-table-money-cell',
                align: 'right'
            }, {
                label: i18n.t("dashboard.salesHistory"),
                cellComponent: 'report-table/link-to',
                align: 'right',
                width: '20%'
            }]);
        }),
        activity: computed(function () {
            var i18n = get(this, 'i18n');
            return A([{
                label: i18n.t("dashboard.store"),
                valuePath: 'storeInfo.name',
                width: '20%'
            }, {
                label: i18n.t("dashboard.unitsSold"),
                valuePath: 'unitsSold',
                align: 'right'
            }, {
                label: i18n.t("dashboard.invoices"),
                valuePath: 'invoices',
                cellComponent: 'absolute-table-money-cell',
                align: 'right'
            }, {
                label: i18n.t("dashboard.cashTrades"),
                valuePath: 'tradesCashOverview',
                cellComponent: 'absolute-table-money-cell',
                align: 'right'
            }, {
                label: i18n.t("dashboard.creditTrades"),
                valuePath: 'tradesCreditOverview',
                cellComponent: 'absolute-table-money-cell',
                align: 'right'
            }, {
                label: i18n.t("dashboard.salesHistory"),
                cellComponent: 'report-table/link-to',
                align: 'right',
                width: '20%'
            }]);
        }),
        payments: computed(function () {
            var i18n = get(this, 'i18n');
            return A([{
                label: i18n.t("dashboard.store"),
                valuePath: 'storeInfo.name',
                width: '20%'
            }, {
                label: i18n.t("dashboard.netCash"),
                valuePath: 'netCash',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.netCreditCard"),
                valuePath: 'netCreditCard',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.netCustomerCredit"),
                valuePath: 'netCustomerCredit',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.netExternal"),
                valuePath: 'netExternal',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.salesHistory"),
                cellComponent: 'report-table/link-to',
                align: 'right',
                width: '20%'
            }]);
        }),
        taxAndShipping: computed(function () {
            var i18n = get(this, 'i18n');
            return A([{
                label: i18n.t("dashboard.store"),
                valuePath: 'storeInfo.name',
                width: '20%'
            }, {
                label: i18n.t("dashboard.salesTax"),
                valuePath: 'salesTax',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.shippingReceived"),
                valuePath: 'shippingReceived',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t("dashboard.shippingPaid"),
                valuePath: 'shippingPaid',
                cellComponent: 'absolute-table-money-cell',
                align: 'right'
            }, {
                label: i18n.t("dashboard.salesHistory"),
                cellComponent: 'report-table/link-to',
                align: 'right',
                width: '20%'
            }]);
        }),
        columns: computed('viewSelected', function () {
            return get(this, get(this, 'viewSelected').id);
        }),

        actions: {
            loadMore: function loadMore() {
                return this.loadMore(get(this, 'serverQueryParams'));
            },

            filter: function filter() {
                var _this = this;

                set(this, 'dashboardController.isSpinning', true);
                var promise = this.filter(get(this, 'serverQueryParams'));
                promise.then(function (data) {
                    set(data, 'meta.id', -1);
                    if (_this.store.hasRecordForId('store-total', -1)) {
                        _this.store.peekRecord('store-total', -1).setProperties(get(data, 'meta'));
                    } else {
                        set(_this, 'totals', _this.store.createRecord('store-total', get(data, 'meta')));
                    }

                    set(_this, 'dashboardController.isSpinning', false);
                });
            },

            fetchRecords: function fetchRecords() {
                var queryParams = this.getParamsObject(get(this, 'serverQueryParams'));
                queryParams.offset = get(this, 'offset');
                queryParams.limit = get(this, 'limit');
                queryParams = this.removeEmptyQueryParams(queryParams);
                return get(this, 'store').query('store-total', queryParams);
            },

            rowClicked: function rowClicked(model) {
                if (model) {
                    var id = get(model, 'id');
                    set(this, 'platformController.viewSelected', get(this, 'viewSelected'));
                    set(this, 'dashboardController.storeSelected', this.store.peekRecord('store', id));
                    get(this, 'dashboardController.categoryFilters').clear();
                    this.transitionToRoute('dashboard.platforms');
                }
            },

            linkClick: function linkClick() {
                var _getProperties = this.getProperties('startDate', 'endDate', 'completedSalesController', 'storeSelected');

                var startDate = _getProperties.startDate;
                var endDate = _getProperties.endDate;
                var completedSalesController = _getProperties.completedSalesController;
                var storeSelected = _getProperties.storeSelected;

                setProperties(completedSalesController, {
                    storeFilter: storeSelected,
                    startDate: startDate,
                    endDate: endDate
                });
                this.transitionToRoute('sales.completed');
            }
        }
    });
});