define('retro-game-store/pods/profile/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        validations: {
            'model.firstName': {
                presence: true
            },
            'model.lastName': {
                presence: true
            },
            'model.email': {
                email: true
            },
            'model.password': {
                confirmation: {
                    message: ' must match Password'
                }
            }
        },
        actions: {
            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            }
        }
    });
});