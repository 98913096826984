define('retro-game-store/instance-initializers/validation-i18n', ['exports', 'ember', 'ember-validations/messages'], function (exports, _ember, _emberValidationsMessages) {
    exports.initialize = initialize;

    function initialize(instance) {
        _ember['default'].I18n = instance.lookup('service:i18n');

        _emberValidationsMessages['default'].render = function (attribute, context) {
            return this.replaceRegex(_ember['default'].I18n.t('errors.' + attribute, context), context);
        };

        _emberValidationsMessages['default'].replaceRegex = function (text, context) {
            var regex = new RegExp('{{(.*?)}}'),
                attributeName = '',
                result = text;

            while (regex.test(result)) {
                attributeName = regex.exec(result)[1];
                result = result.replace(regex, context[attributeName]);
            }

            return result;
        };
    }

    exports['default'] = {
        name: 'validation-i18n',
        initialize: initialize
    };
});