define('retro-game-store/validators/local/phone', ['exports', 'ember', 'ember-validations/validators/local/format', 'ember-validations/messages'], function (exports, _ember, _emberValidationsValidatorsLocalFormat, _emberValidationsMessages) {
    exports['default'] = _emberValidationsValidatorsLocalFormat['default'].extend({
        init: function init() {
            var pattern = /^\d{3}\-\d{3}\-\d{4}$/;
            if (this.options === true) {
                this.options = {};
            }
            this.options['with'] = pattern;

            _ember['default'].set(this, 'options.blankMessage', _emberValidationsMessages['default'].render('blank', this.options));

            // this call is necessary, don't forget it!
            this._super();
        },
        call: function call() {
            if (_ember['default'].isEmpty(_ember['default'].get(this.model, this.property))) {
                if (this.options.allowBlank === undefined) {
                    this.errors.pushObject(this.options.blankMessage);
                }
            } else if (this.options['with'] && !this.options['with'].test(_ember['default'].get(this.model, this.property))) {
                this.errors.pushObject(this.options.message);
            } else if (this.options.without && this.options.without.test(_ember['default'].get(this.model, this.property))) {
                this.errors.pushObject(this.options.message);
            }
        }
    });
});