define('retro-game-store/pods/store/ability', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
    exports['default'] = _emberCan.Ability.extend({
        list: _ember['default'].computed('session.user.roles.[]', function () {
            var roles = this.get('session.user.roles');
            if (_ember['default'].isArray(roles) && roles.contains('AffiliateAdministrator')) {
                return true;
            } else {
                return false;
            }
        }),
        create: _ember['default'].computed('session.user.affiliate.storeCount', 'session.user.affiliate.maxStoreLimit', function () {
            var storeCount = this.get('session.user.affiliate.storeCount');
            var maxAffiliateStoreCount = this.get('session.user.affiliate.maxStoreLimit');
            if (maxAffiliateStoreCount) {
                return storeCount < maxAffiliateStoreCount;
            } else {
                return true;
            }
        })
    });
});