define('retro-game-store/helpers/list-contains', ['exports', 'ember'], function (exports, _ember) {
    exports.listContains = listContains;

    function listContains(params /*, hash*/) {
        var list = params[0],
            item = params[1];
        if (list.contains(item)) {
            return true;
        } else {
            return false;
        }
        return params;
    }

    exports['default'] = _ember['default'].Helper.helper(listContains);
});