define('retro-game-store/pods/customers/customer/trades/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'retro-game-store/constants/event-types'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _retroGameStoreConstantsEventTypes) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var Controller = _ember['default'].Controller;
    var service = _ember['default'].inject.service;
    var observer = _ember['default'].observer;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], {
        i18n: service(),

        sortby: 'name',
        sortdirection: 'asc',
        serverQueryParams: ['customerId', 'storeId', 'eventTypes', 'status', 'sortby', 'sortdirection'],
        storeId: null,
        status: 'closed',
        storeSelected: null,
        pagingTarget: 'model.models',

        eventTypes: computed(function () {
            return [get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRADE, 'type'), get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_BUY, 'type')];
        }),

        storeSelectedDidChange: observer('storeSelected', function () {
            var storeId = get(this, 'storeSelected.id');
            if (storeId) {
                set(this, 'storeId', storeId);
            } else {
                set(this, 'storeId', null);
            }
            this.send('filter', get(this, 'serverQueryParams'));
        }),

        menuOptions: computed(function () {
            return A([{ action: 'viewSale', label: get(this, 'i18n').t('actions.viewSale') }]);
        }),

        columns: computed(function () {
            var i18n = get(this, 'i18n');
            return A([{
                label: i18n.t('customers.edit.trades.title'),
                valuePath: 'name',
                subValuePath: 'fullCategory',
                cellComponent: 'data-table/cell/item-title',
                width: '20%'
            }, {
                label: i18n.t('customers.edit.trades.location'),
                valuePath: 'storeInfo.name'
            }, {
                label: i18n.t('customers.edit.trades.cash'),
                valuePath: 'cashPrice',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t('customers.edit.trades.credit'),
                valuePath: 'tradePrice',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t('customers.edit.trades.retail'),
                valuePath: 'itemPrice',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t('customers.edit.trades.net'),
                valuePath: 'net',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: '',
                cellComponent: 'data-table/cell/link-to-menu',
                align: 'right',
                width: '20%',
                menuOptions: get(this, 'menuOptions')
            }]);
        }),

        totals: computed('model.meta', function () {
            var model = get(this, 'model.meta');
            var totals = {
                net: 0,
                itemPrice: 0,
                cashPrice: 0,
                tradePrice: 0
            };
            totals.net = get(model, 'sellPrice') + get(model, 'cashPrice') + get(model, 'tradePrice');
            totals.itemPrice = get(model, 'sellPrice');
            totals.cashPrice = get(model, 'cashPrice');
            totals.tradePrice = get(model, 'tradePrice');
            return totals;
        }),

        actions: {
            viewSale: function viewSale(row) {
                if (row) {
                    this.transitionToRoute('sales.sale', get(row, 'transactionId'));
                }
            },
            loadMore: function loadMore() {
                return this.loadMore(get(this, 'serverQueryParams'));
            },
            filter: function filter() {
                var promise = this.filterWithMeta(get(this, 'serverQueryParams'));
                return promise;
            }
        }
    });
});