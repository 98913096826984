define('retro-game-store/pods/components/empty-link/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'a',
        attributeBindings: ['href', 'role'],
        href: '',
        role: 'presentation',

        onClick: _ember['default'].on('click', function (event) {
            event.preventDefault();
        })
    });
});