define('retro-game-store/pods/stores/store/employees/route', ['exports', 'ember', 'ember-data-route', 'retro-game-store/mixins/route-pagination'], function (exports, _ember, _emberDataRoute, _retroGameStoreMixinsRoutePagination) {
    var get = _ember['default'].get;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    exports['default'] = Route.extend(_emberDataRoute['default'], _retroGameStoreMixinsRoutePagination['default'], {
        afterModel: function afterModel() {
            var _this = this;

            return this.pagingQuery('store-employee', { top: 50 }, 'stores.store').then(function (employees) {
                set(_this, 'employees', employees.toArray());
            });
        },
        setupController: function setupController(controller, model) {
            set(controller, 'employees', get(this, 'employees'));
            this._super(controller, model);
        },
        resetController: function resetController(controller) {
            get(controller, 'model').rollbackAttributes();
        }
    });
});