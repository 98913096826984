define('retro-game-store/pods/inventory/transfer/route', ['exports', 'ember', 'retro-game-store/mixins/scanner-detection'], function (exports, _ember, _retroGameStoreMixinsScannerDetection) {
    var A = _ember['default'].A;
    var get = _ember['default'].get;
    var Route = _ember['default'].Route;
    exports['default'] = Route.extend(_retroGameStoreMixinsScannerDetection['default'], {
        setupController: function setupController(controller, model) {
            var _this = this;

            this._super.apply(this, arguments);

            model = A();

            this.store.query('store', { skip: 0 }).then(function (results) {
                var stores = results.rejectBy('id', get(_this, 'session.user.storeInfo.id'));
                controller.set('stores', stores);
            });
            controller.listenForScanner();
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.hardReset();
                controller.ignoreScanner();
            }
        },
        renderModal: function renderModal(name, controller) {
            this.render(name, {
                into: 'application',
                outlet: 'modal',
                controller: controller || null //if controller is null, it will use default controller
            });
        }
    });
});