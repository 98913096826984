define('retro-game-store/pods/sales/sale/return-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-filtering', 'ember-validations', 'retro-game-store/constants/event-types'], function (exports, _ember, _retroGameStoreMixinsControllerFiltering, _emberValidations, _retroGameStoreConstantsEventTypes) {
    var set = _ember['default'].set;
    var get = _ember['default'].get;
    var setProperties = _ember['default'].setProperties;
    var observer = _ember['default'].observer;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    var reads = _ember['default'].computed.reads;
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        scanner: service(),
        api: service(),

        saleController: controller('sales.sale'),
        sale: reads('saleController.model'),
        saleItems: reads('saleController.saleItems'),
        inputDisabled: false,
        buttonDisabled: true,
        saleEvent: _retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE,
        miscSaleEvent: _retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE,

        saleIdDidChange: observer('saleId', function () {
            var saleId = get(this, 'saleId');
            if (saleId) {
                set(this, 'buttonDisabled', false);
            } else {
                set(this, 'buttonDisabled', true);
            }
        }),
        closeModal: function closeModal() {
            get(this, 'saleController').listenForScanner();
            setProperties(this, {
                saleId: null,
                model: null,
                inputDisabled: false,
                buttonDisabled: true
            });
            this.get('api').clearErrors();
            this.send('closeModal');
        },
        autoSearchSale: function autoSearchSale(saleId) {
            var _this = this;

            set(this, 'saleId', saleId);
            return this.store.query('saleItem', { id: saleId }).then(function (saleItems) {
                set(_this, 'model', saleItems);
                get(_this, 'model.content').forEach(function (item) {
                    //reset the counter to 0 on search
                    set(item, 'record.quantity', 1);
                });
            });
        },

        actions: {
            findSaleItems: function findSaleItems(callback) {
                var _this2 = this;

                var promise = this.store.query('saleItem', { id: get(this, 'saleId') }).then(function (saleItems) {
                    set(_this2, 'model', saleItems);
                    get(_this2, 'model.content').forEach(function (item) {
                        //reset the counter to 0 on search
                        set(item, 'record.quantity', 1);
                    });
                });
                callback(promise);
            },
            cancel: function cancel() {
                this.closeModal();
            },
            addReturn: function addReturn(item) {
                var _this3 = this;

                get(this, 'api').request('POST', '/returnSaleItems', {
                    returnId: get(item, 'id'),
                    id: get(this, 'sale.id'),
                    quantity: get(item, 'quantity')
                }).then(function (returnItem) {
                    // Prevent searching for further sales
                    setProperties(_this3, {
                        inputDisabled: true,
                        buttonDisabled: true
                    });
                    // Remove the model from this list so they can't add it again
                    if (get(returnItem, 'quantityReturnable') === 0) {
                        get(_this3, 'model').removeObject(get(_this3, 'model').findBy('id', get(item, 'id')));
                    }
                    //since this record was created by the back end, we now push it into the store and add it to the current list of sale items
                    var returnItemAdded = _this3.store.push(_this3.store.normalize('saleItem', returnItem));
                    get(_this3, 'saleItems').addObject(returnItemAdded);
                    item.reload();
                    set(item, 'quantity', 1);
                    _this3.send('refreshSale');
                });
            },
            autoSearchSale: function autoSearchSale(saleId) {
                this.autoSearchSale(saleId);
            }
        }
    });
});