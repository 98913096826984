define('retro-game-store/pods/stores/store/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _emberValidations['default'], {
        limit: 50,
        pagingTarget: 'employees',
        serverQueryParams: [],
        validations: {
            'model.name': {
                presence: true
            },
            'model.address': {
                presence: true
            },
            'model.city': {
                presence: true
            },
            'model.state': {
                presence: true
            },
            'model.zip': {
                presence: true
            },
            'model.phoneNumber': {
                phone: true
            },
            'model.defaultTax': {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            },
            'model.defaultShipping': {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            }
        },
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore();
                callback(promise);
            },
            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            }
        }
    });
});