define('retro-game-store/pods/components/form-validator/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'form', //if this is changed, the loading button wont function properly due to form submit on enter key
        showValidationFields: false,
        actions: {
            formSubmit: function formSubmit(callback, params) {
                var self = this;
                this.get('targetObject').validate().then(function () {
                    self.sendAction('submit', callback, params);
                })['catch'](function () {
                    self.set('showValidationFields', true);

                    var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                        reject();
                        // Were doing this so that if they click save again, we show all possible validation fields.
                        // This prevents the case of clicking save, clicking a button that shows extra fields,
                        // then clicking save again and not seeing all validations
                        self.set('showValidationFields', false);
                    });
                    if (callback) {
                        callback(promise);
                    }
                });
            }
        }
    });
});