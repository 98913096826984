define('retro-game-store/initializers/api-service-adapter', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('service:api', 'router', 'router:main');
    application.inject('service:api', 'adapter', 'adapter:application');
  }

  exports['default'] = {
    name: 'api-service-adapter',
    initialize: initialize
  };
});