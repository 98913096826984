define('retro-game-store/pods/application/route', ['exports', 'ember', 'retro-game-store/config/environment', 'ember-simple-auth/mixins/application-route-mixin', 'retro-game-store/mixins/route-pagination', 'ember-can'], function (exports, _ember, _retroGameStoreConfigEnvironment, _emberSimpleAuthMixinsApplicationRouteMixin, _retroGameStoreMixinsRoutePagination, _emberCan) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    var RSVP = _ember['default'].RSVP;
    var set = _ember['default'].set;
    exports['default'] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], _retroGameStoreMixinsRoutePagination['default'], _emberCan.CanMixin, {
        api: service(),
        categories: service(),
        qz: service(),
        accountSettingsActive: null,
        shouldPollForLabels: true,
        beforeModel: function beforeModel() {
            var _this = this;

            get(this, 'qz').startup();
            if (get(this, 'session.isAuthenticated')) {
                var getUser = get(this, 'session').getCurrentUser().then(function (me) {
                    var pathname = window.location.pathname;
                    if (pathname.charAt(0) === '/') {
                        pathname = pathname.substr(1);
                    }
                    if (get(me, 'affiliate.urlFragment') !== pathname.split('/')[0]) {
                        _this.transitionTo(_retroGameStoreConfigEnvironment['default']['ember-simple-auth'].routeIfAlreadyAuthenticated, get(me, 'affiliate.urlFragment'));
                    }
                    _this.pollLabels();
                });
                var getCategories = get(this, 'categories').loadModels();
                return RSVP.hash({ getUser: getUser, getCategories: getCategories });
            }
            return this._super.apply(this, arguments);
        },
        sessionAuthenticated: function sessionAuthenticated() {
            var _this2 = this;

            this.intermediateTransitionTo('affiliate.loading');
            get(this, 'session').getCurrentUser().then(function () {
                var attemptedTransition = get(_this2, 'session.attemptedTransition');
                if (attemptedTransition) {
                    attemptedTransition.retry();
                    set(_this2, 'session.attemptedTransition', null);
                } else {
                    _this2.transitionTo(_retroGameStoreConfigEnvironment['default']['ember-simple-auth'].routeIfAlreadyAuthenticated, _retroGameStoreConfigEnvironment['default']['affiliate']);
                }
                _this2.pollLabels();
                get(_this2, 'categories').loadModels();
            });
        },
        sessionInvalidated: function sessionInvalidated() {
            this.transitionTo('login');
            _ember['default'].$('body').removeClass('authenticated');
            if (!_ember['default'].testing) {
                if (_retroGameStoreConfigEnvironment['default']['affiliate']) {
                    window.location.replace('/' + _retroGameStoreConfigEnvironment['default']['affiliate'] + '/login');
                } else {
                    window.location.replace('/unauthorized');
                }
            }
        },
        pollLabels: function pollLabels() {
            if (get(this, 'shouldPollForLabels') && !get(this, 'pollLaterActive') && get(this, 'session.data.storeInfo')) {
                _ember['default'].$.ajax(_retroGameStoreConfigEnvironment['default'].RESTAPI + '/api/labelPolling', {
                    beforeSend: function beforeSend(xhr) {
                        if (get(this, 'session.isAuthenticated')) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + get(this, 'session.data.authenticated.access_token'));
                        }
                    },
                    success: function success(response) {
                        var _this3 = this;

                        set(this.controllerFor('application'), 'labelCount', response);
                        set(this, 'pollLaterActive', true);
                        _ember['default'].run.later(function () {
                            set(_this3, 'pollLaterActive', false);
                            _this3.pollLabels();
                        }, 60000);
                    },
                    context: this,
                    type: 'GET',
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json'
                });
            }
        },
        renderModal: function renderModal(name, model, controller) {
            this.render(name, {
                into: 'application',
                outlet: 'modal',
                model: model,
                controller: controller || null //if controller is null, it will use default controller
            });
        },
        actions: {
            error: function error(errorInfo) {
                if (errorInfo.errors && errorInfo.errors.length > 0) {
                    var error = errorInfo.errors.get('firstObject');
                    if (error.status === '403') {
                        return this.replaceWith('fourOhThree', _retroGameStoreConfigEnvironment['default']['affiliate']);
                    } else if (error.status === '401') {
                        document.location.href = '/login';
                    } else if (error.status === '404') {
                        return this.replaceWith('fourOhFour', '404');
                    } else {
                        console.log('Error: ' + error.detail);
                    }
                }
            },
            openReportBugModal: function openReportBugModal() {
                var location = 'application/report-bug-modal';
                this.renderModal(location, null, location);
            },
            openSettingsModal: function openSettingsModal() {
                var location = 'application/settings-modal';
                var controller = this.controllerFor(location);
                this.renderModal(location, null, location);
                set(controller, 'isLoadingPrinters', true);
                get(this, 'qz').findPrinters().then(function (printers) {
                    set(controller, 'printers', printers);
                    set(controller, 'isLoadingPrinters', false);
                })['catch'](function () {
                    set(controller, 'isLoadingPrinters', false);
                });
            },
            openStoreModal: function openStoreModal() {
                var _this4 = this;

                var location = 'application/change-store-modal';
                this.pagingQuery('store', {
                    top: 20,
                    employeeId: get(this, 'session.user.employeeId')
                }, location).then(function (models) {
                    _this4.renderModal(location, models, location);
                });
            },
            openLoginModal: function openLoginModal() {
                var _this5 = this;

                var location = 'application/change-user-modal';
                this.pagingQuery('employee', {
                    top: 20,
                    storeId: get(this, 'session.user.storeInfo.id')
                }, location).then(function (models) {
                    _this5.renderModal(location, models, location);
                });
            },
            openPrintLabelModal: function openPrintLabelModal() {
                var location = 'application/print-labels-modal';
                var controller = this.controllerFor(location);
                set(this, 'shouldPollForLabels', false);
                this.store.findRecord('store', get(this, 'session.user.storeInfo.id')).then(function (store) {
                    set(controller, 'store', store);
                });
                set(controller, 'totalLabels', get(this.controllerFor('application'), 'labelCount'));
                this.renderModal(location, [], location);
            },
            openModal: function openModal() {
                this.renderModal.apply(this, arguments);
            },
            closeModal: function closeModal() {
                return this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            },
            willTransition: function willTransition() {
                this.send('closeModal');
            },
            killSession: function killSession() {
                //Clear session data that we store.
                set(this, 'session.data.user', null);
                set(this, 'session.data.affiliate', null);
                set(this, 'session.data.storeInfo', null);
                get(this, 'session').invalidate();
            },
            continuePolling: function continuePolling() {
                set(this, 'shouldPollForLabels', true);
                this.pollLabels();
            }
        },
        init: function init() {
            get(this, 'api').on('error', this, function (error) {
                this.send('error', error);
            });

            this._super.apply(this, arguments);
        }
    });
});