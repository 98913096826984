define('retro-game-store/pods/api/service', ['exports', 'ember', 'retro-game-store/config/environment', 'retro-game-store/mixins/query-params'], function (exports, _ember, _retroGameStoreConfigEnvironment, _retroGameStoreMixinsQueryParams) {

    //This is a service for non ember data related api calls
    exports['default'] = _ember['default'].Service.extend(_retroGameStoreMixinsQueryParams['default'], _ember['default'].Evented, {
        session: _ember['default'].inject.service(),
        clearErrors: function clearErrors() {
            this.set('errors', null);
        },
        request: function request(type, url, data, isDownload) {
            var _this = this;

            data = this.removeEmptyQueryParams(data);
            if (type !== 'GET') {
                data = JSON.stringify(data);
            }
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var config = {
                    beforeSend: function beforeSend(xhr) {
                        if (this.get('session').isAuthenticated) {
                            xhr.setRequestHeader("Authorization", "Bearer " + this.get('session').get('data').authenticated.access_token);
                        }
                    },
                    success: function success(response) {
                        this.set('errors', null);
                        resolve(response);
                    },
                    error: function error(reason) {
                        var response = reason.responseJSON || null,
                            errors = {};

                        if (!response) {
                            reject(null);
                            return;
                        }

                        errors.messages = [];

                        function addError(item) {
                            if (item === '') {
                                item = "Error.";
                            }
                            errors.messages.push(item);
                        }

                        if (response.errors !== undefined) {
                            var jsonErrors = response.errors;
                            for (var key in jsonErrors) {
                                jsonErrors[key].forEach(addError);
                            }
                        }

                        this.set('errors', errors);

                        reject(this.get('errors'));
                    },
                    data: data,
                    context: _this,
                    type: type,
                    contentType: 'application/json; charset=utf-8'
                };

                // if the response is a data stream that should be downloaded as a file,
                // send true as the isDownload arg value so the dataType is not set to json.
                // If the dataType is set to json but the result is not json compliant the
                // async error handler will fire even if the HTTP response is 200
                if (isDownload !== true) {
                    config.dataType = 'json';
                }

                _ember['default'].$.ajax(_retroGameStoreConfigEnvironment['default'].RESTAPI + '/api' + url, config);
            });
        },
        init: function init() {
            var _this2 = this;

            //clear the errors whenver we change a page
            if (this.get('router')) {
                this.get('router').on('didTransition', function () {
                    _this2.clearErrors();
                });
            }
        }
    });
});