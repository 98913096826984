define('retro-game-store/pods/store/model', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        address: attr('string'),
        address2: attr('string'),
        affiliateId: attr('number'),
        city: attr('string'),
        dateDeleted: attr('date'),
        defaultShipping: attr('number'),
        defaultTax: attr('percent'),
        logo: attr(),
        name: attr('string'),
        phoneNumber: attr('string'),
        returnPolicy: attr('string'),
        state: attr('string'),
        zip: attr('string'),

        //Relationships
        categoryOptions: hasMany('product-category'),
        employees: hasMany('storeEmployee'),
        labelOptions: hasMany('label-option'),

        //Computeds
        categoryOptionsMap: computed('categoryOptions.[]', function () {
            var map = {};
            get(this, 'categoryOptions').forEach(function (item) {
                map[get(item, 'id')] = item;
            });
            return map;
        }),
        receiptLogo: alias('logo'),
        receiptLogoImage: alias('receiptLogo.image')
    });
});