define('retro-game-store/pods/employees/employee/route', ['exports', 'ember', 'retro-game-store/mixins/title-bar', 'retro-game-store/mixins/route-pagination', 'ember-data-route'], function (exports, _ember, _retroGameStoreMixinsTitleBar, _retroGameStoreMixinsRoutePagination, _emberDataRoute) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsTitleBar['default'], _retroGameStoreMixinsRoutePagination['default'], _emberDataRoute['default'], {
        model: function model(params) {
            return this.store.findRecord('employee', params.employee_id);
        },
        afterModel: function afterModel() {
            var _this = this;

            return this.pagingQuery('employee-store', { top: 50 }, 'employees.employee').then(function (stores) {
                _this.set('stores', stores.toArray());
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('stores', this.get('stores'));
            this._super(controller, model);
        }
    });
});