define('retro-game-store/pods/sales/sale/pay-customer-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-filtering', 'ember-validations', 'accounting/unformat', 'accounting/to-fixed'], function (exports, _ember, _retroGameStoreMixinsControllerFiltering, _emberValidations, _accountingUnformat, _accountingToFixed) {
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        scanner: _ember['default'].inject.service(),
        api: _ember['default'].inject.service(),
        qz: _ember['default'].inject.service(),
        saleController: _ember['default'].inject.controller('sales.sale'),
        sale: _ember['default'].computed.reads('saleController.model'),
        closeModal: function closeModal() {
            this.get('saleController').listenForScanner();
            this.set('activePayment', null);
            this.set('error', null);
            this.send('closeModal');
        },
        validations: {},
        processPaymentDisabled: true,
        cashValidations: {
            'activePayment.amountApplied': {
                presence: true,
                numericality: { greaterThan: 0 }
            }
        },
        creditValidations: {
            'activePayment.amountApplied': {
                presence: true,
                numericality: { greaterThan: 0 }
            }
        },
        externalValidations: {
            'activePayment.amountApplied': {
                presence: true,
                numericality: { greaterThan: 0 }
            }
        },
        storeCreditValidations: {
            'activePayment.amountApplied': {
                presence: true,
                numericality: { greaterThan: 0 }
            }
        },
        activePayment: null,
        customerCreditDisabled: _ember['default'].computed('model.customer', 'model.payments.[]', 'model.isReturn', 'model.tradeType', function () {
            var isTradeIn = !this.get('model.isReturn'),
                tradeType = this.get('model.tradeType') ? this.get('model.tradeType').toLowerCase() : null,
                cashPaymentsExist = this.get('model.payments').isAny('paymentType', "Cash"),
                customerCreditPaymentsExist = this.get('model.payments').isAny('paymentType', "StoreCredit");
            if (!this.get('model.customer.id') || isTradeIn && cashPaymentsExist || customerCreditPaymentsExist || isTradeIn && tradeType === 'cash') {
                return true;
            } else {
                return null;
            }
        }),
        isTradeIn: _ember['default'].computed.not('model.isReturn'),
        cashDisabled: _ember['default'].computed('model.payments.[]', 'model.isReturn', 'model.tradeType', function () {
            var isTradeIn = !this.get('model.isReturn'),
                tradeType = this.get('model.tradeType') ? this.get('model.tradeType').toLowerCase() : null,
                cashPaymentsExist = this.get('model.payments').isAny('paymentType', "Cash"),
                customerCreditPaymentsExist = this.get('model.payments').isAny('paymentType', "StoreCredit");

            if (cashPaymentsExist || isTradeIn && customerCreditPaymentsExist || isTradeIn && tradeType === 'credit') {
                return true;
            } else {
                return null;
            }
        }),
        customerCreditToApply: _ember['default'].computed('model.customer.creditBalance', 'model.totalAmount', function () {
            var creditBalance = this.get('model.customer.creditBalance');
            return (0, _accountingToFixed['default'])(Math.min(this.get('model.totalAmount'), creditBalance), 2);
        }),
        customerCreditRemaining: _ember['default'].computed('model.customer.creditBalance', 'activePayment.amountApplied', 'activePayment.paymentType', function () {
            if (this.get('activePayment.paymentType') === 'storeCredit') {
                return (0, _accountingToFixed['default'])(this.get('model.customer.creditBalance') - this.get('activePayment.amountApplied'), 2);
            } else {
                return 0;
            }
        }),
        //When the active payment changes, we need to rebuild the validations so that we can validate each field
        activePaymentTypeChanged: _ember['default'].observer('activePayment.paymentType', function () {
            var type = this.get('activePayment.paymentType') ? this.get('activePayment.paymentType').toLowerCase() : '';
            this.set('validations', this.get(type + 'Validations'));
            this.rebuildValidations();
        }),
        // Calculate the payment needed by subtracting the total amount from all of the payments added
        paymentNeeded: _ember['default'].computed('model.totalAmount', 'model.payments.@each.amountApplied', 'activePayment.amountApplied', function () {
            var paymentsAdded = 0;
            this.get('model.payments').forEach(function (payment) {
                var amountApplied = payment.toJSON().amountApplied;
                paymentsAdded += amountApplied;
            });
            return this.get('model.totalAmount') + (0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(paymentsAdded, 2));
        }),
        cardReturn: _ember['default'].computed('returnableCreditCards.[]', 'returnableExternalPayments.[]', function () {
            return this.get('returnableCreditCards').get('length') > 0 || this.get('returnableExternalPayments').get('length') > 0;
        }),
        returnableCreditCards: _ember['default'].computed.filterBy('model.returnTransaction.payments', 'paymentType', 'Credit'),
        returnableExternalPayments: _ember['default'].computed.filterBy('model.returnTransaction.payments', 'paymentType', 'External'),
        returnPaymentsUsed: _ember['default'].computed('model.payments.@each.returnPayment', function () {
            return this.get('model.payments').mapBy('returnPayment').mapBy('id');
        }),
        canProcessPayment: _ember['default'].observer('customerCreditRemaining', 'paymentNeeded', function () {
            //If customer credit remaining is greater than 0,
            //and payment needed is 0 (we have payments totaling up to what we needed)
            //then we can continue on and process the payment
            if (this.get('paymentNeeded') === 0) {
                this.set('processPaymentDisabled', false);
            } else {
                this.set('processPaymentDisabled', true);
            }
        }),
        rebuildValidations: function rebuildValidations() {
            this.validators = _ember['default'].A();
            this.buildValidators();
            this.validators.forEach(function (validator) {
                if (!validator.hasObserverFor('errors.[]')) {
                    validator.addObserver('errors.[]', this, function (sender) {
                        var errors = _ember['default'].A();
                        this.validators.forEach(function (validator) {
                            if (validator.property === sender.property) {
                                errors.addObjects(validator.errors);
                            }
                        }, this);
                        _ember['default'].set(this, 'errors.' + sender.property, errors);
                    });
                }
            }, this);
        },
        actions: {
            cancel: function cancel() {
                this.get('sale').set('payments', _ember['default'].A());
                this.closeModal();
            },
            addPayment: function addPayment(type, returnPayment) {
                //If we clicked on store credit and its disabled - return
                if (type === 'StoreCredit' && this.get('customerCreditDisabled')) {
                    return;
                }
                //If we clicked on a payment thats already being used - return
                if (returnPayment && this.get('returnPaymentsUsed').contains(returnPayment.get('id'))) {
                    return;
                }

                if (type === 'Cash' && this.get('cashDisabled')) {
                    return;
                }

                var paymentNeeded = (0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(this.get('paymentNeeded'), 2));
                if (paymentNeeded === 0) {
                    return;
                }

                var payment = this.store.createRecord('sale-payment', {
                    paymentType: type,
                    amountApplied: -paymentNeeded,
                    returnPayment: returnPayment
                });

                if (returnPayment && returnPayment.get('remainingReturnAmount')) {
                    payment.setProperties({
                        accountNumber: returnPayment.get('accountNumber'),
                        remainingReturnAmount: returnPayment.get('remainingReturnAmount'),
                        amountApplied: Math.min(-this.get('paymentNeeded'), returnPayment.get('remainingReturnAmount')),
                        cardType: returnPayment.get('cardType'),
                        nameOnCard: returnPayment.get('nameOnCard'),
                        cardExpirationDate: returnPayment.get('cardExpirationDate'),
                        chargeId: returnPayment.get('chargeId')
                    });
                }
                this.get('model').get('payments').addObject(payment);
                this.set('activePayment', payment);
            },
            removePayment: function removePayment(payment) {
                this.get('model').get('payments').removeObject(payment);
                if (payment === this.get('activePayment')) {
                    this.set('activePayment', null);
                }
            },
            showPayment: function showPayment(payment) {
                this.set('activePayment', payment);
            },
            pay: function pay(callback) {
                var _this = this;

                var payments = this.get('model').get('payments').map(function (payment) {
                    return payment.toJSON();
                });
                var willDrawerOpen = false;
                payments.forEach(function (payment) {
                    var amountApplied = payment.amountApplied;
                    var paymentType = payment.paymentType;

                    if (amountApplied === 0) {
                        payments.removeObject(payment);
                    } else {
                        amountApplied = -1 * amountApplied;
                    }

                    if (paymentType.toLowerCase() === 'cash') {
                        willDrawerOpen = true;
                    }
                });

                var promise = this.get('api').request('POST', '/processSale', {
                    id: this.get('sale').get('id'),
                    payments: payments
                }).then(function () {
                    _this.get('model').set('payments', _ember['default'].A());
                    _this.get('sale').reload().then(function () {
                        if (willDrawerOpen) {
                            _this.get('qz').openCashDrawer();
                        }
                        _this.send('printReceipt', _this.get('sale'), _this.get('saleController').get('saleItems'));
                        _this.closeModal();
                    });
                });
                callback(promise);
            }
        }
    });
});