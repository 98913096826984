define('retro-game-store/pods/components/report-table/link-to/component', ['exports', 'ember'], function (exports, _ember) {
   var get = _ember['default'].get;
   var tryInvoke = _ember['default'].tryInvoke;
   exports['default'] = _ember['default'].Component.extend({

      actions: {
         linkClick: function linkClick() {
            tryInvoke(get(this, 'tableActions'), 'linkClick');
         }
      }
   });
});