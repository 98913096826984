define('retro-game-store/pods/shipping/sale/shipments/new/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        saleController: _ember['default'].inject.controller('shipping.sale'),
        sale: _ember['default'].computed.reads('saleController.model'),
        shipmentsController: _ember['default'].inject.controller('shipping.sale.shipments'),
        shipments: _ember['default'].computed.reads('shipmentsController.model'),
        api: _ember['default'].inject.service(),
        itemsToShip: [],
        itemsSelected: _ember['default'].computed('itemsToShip.[]', function () {
            return !_ember['default'].isEmpty(this.get('itemsToShip'));
        }),
        validations: {
            'pounds': {
                presence: true,
                numericality: {
                    onlyInteger: true,
                    allowBlank: true
                }
            },
            'ounces': {
                presence: true,
                numericality: {
                    onlyInteger: true,
                    allowBlank: true
                }
            },
            'length': {
                presence: true,
                numericality: {
                    allowBlank: true
                }
            },
            'width': {
                presence: true,
                numericality: {
                    allowBlank: true
                }
            },
            'height': {
                presence: true,
                numericality: {
                    allowBlank: true
                }
            }

        },
        resetProperties: function resetProperties() {
            this.setProperties({
                length: null,
                width: null,
                height: null,
                pounds: null,
                ounces: null,
                rates: null,
                shipItems: null,
                itemsToShip: []
            });
        },
        actions: {
            getRates: function getRates(callback) {
                var _this = this;

                var promise = this.get('api').request('POST', '/shipmentRates', {
                    length: this.get('length'),
                    width: this.get('width'),
                    height: this.get('height'),
                    weightPounds: this.get('pounds'),
                    weightOunces: this.get('ounces'),
                    customerId: this.get('sale').get('customer').get('id')
                }).then(function (rates) {
                    _this.set('rates', rates);
                });
                callback(promise);
            },
            purchaseRate: function purchaseRate(callback, rate) {
                var _this2 = this;

                var items = this.get('itemsToShip').map(function (item) {
                    return _this2.store.createRecord('shipment-item', {
                        transactionItemId: item.get('id'),
                        quantity: item.get('quantity')
                    });
                });
                this.get('model').set('shipmentItems', items);
                // This is a normal object, so we need to use .id instead of .get('id')
                this.get('model').set('easyPostId', rate.id);
                var promise = this.get('model').save().then(function (shipment) {
                    _this2.get('shipments').addObject(shipment);
                    _this2.transitionToRoute('shipping.sale.shipments.shipment', shipment.get('id'));
                });
                callback(promise);
            }
        }
    });
});