define('retro-game-store/pods/terminal/service', ['exports', 'ember', 'retro-game-store/pods/terminal/status', 'accounting/to-fixed', 'moment', 'retro-game-store/constants/label-print-type'], function (exports, _ember, _retroGameStorePodsTerminalStatus, _accountingToFixed, _moment, _retroGameStoreConstantsLabelPrintType) {
    var computed = _ember['default'].computed;
    var Evented = _ember['default'].Evented;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var Service = _ember['default'].Service;
    exports['default'] = Service.extend(Evented, {
        api: service(),
        listening: true,
        active: computed('terminalFunctionExists', function () {
            return this.terminalFunctionExists();
        }),
        receiptPrinter: _retroGameStorePodsTerminalStatus['default'].create(),
        labelPrinter: _retroGameStorePodsTerminalStatus['default'].create(),
        cardReader: _retroGameStorePodsTerminalStatus['default'].create(),
        cashDrawer: _retroGameStorePodsTerminalStatus['default'].create(),
        terminalFunctionExists: function terminalFunctionExists() {
            if (!isEmpty(window.desktop) && window.desktop['BrokerAsyncCommand']) {
                return true;
            } else {
                return false;
            }
        },

        printInventoryLabels: function printInventoryLabels(items) {
            var labels = [];
            if (get(this, 'active')) {
                items.forEach(function (item) {
                    if (item.labelType === get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_MEDIA_ONLY, 'type')) {
                        labels.push({
                            Name: item.name,
                            Condition: item.condition.name,
                            Category: item.category.name,
                            StockNumber: item.stockNumber,
                            Price: item.sellPrice,
                            IsBoxLabel: false
                        });
                    }
                    if (item.labelType === get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_BOX_ONLY, 'type')) {
                        labels.push({
                            Name: item.name,
                            Condition: item.condition.name,
                            Category: item.category.name,
                            StockNumber: item.stockNumber,
                            Price: item.sellPrice,
                            IsBoxLabel: true
                        });
                    }
                    if (item.labelType === get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_ALL, 'type')) {
                        labels.push({
                            Name: item.name,
                            Condition: item.condition.name,
                            Category: item.category.name,
                            StockNumber: item.stockNumber,
                            Price: item.sellPrice,
                            IsBoxLabel: false
                        });
                        labels.push({
                            Name: item.name,
                            Condition: item.condition.name,
                            Category: item.category.name,
                            StockNumber: item.stockNumber,
                            Price: item.sellPrice,
                            IsBoxLabel: true
                        });
                    }
                });
                window.desktop.BrokerAsyncCommand('PrintInventoryLabels', { ListWrapper: labels });
            }
        },

        switchToInventoryLabels: function switchToInventoryLabels() {
            if (get(this, 'active')) {
                window.desktop.BrokerAsyncCommand('SwitchToInventory', {});
            }
        },
        switchToShippingLabels: function switchToShippingLabels() {
            if (get(this, 'active')) {
                window.desktop.BrokerAsyncCommand('SwitchToShipping', {});
            }
        },
        openCashDrawer: function openCashDrawer() {
            if (get(this, 'active')) {
                window.desktop.BrokerAsyncCommand('OpenCashDrawer', {});
            }
        },
        printShippingLabel: function printShippingLabel(shipmentId) {
            if (get(this, 'active')) {
                get(this, 'api').request('GET', '/easy-post/get-label-image/' + shipmentId).then(function (image) {
                    window.desktop.BrokerAsyncCommand('PrintShippingLabels', {
                        ListWrapper: [image]
                    });
                });
            }
        },
        printAuthorizationReceipt: function printAuthorizationReceipt(affiliateName, sale) {
            if (get(this, 'active')) {
                var creditPayments = get(sale, 'payments').filterBy('paymentType', "Credit");
                creditPayments.forEach(function (creditPayment) {
                    var authorizationReceipt = {
                        OrderNumber: get(sale, 'id'),
                        CashierName: get(sale, 'processedBy.name'),
                        Total: get(creditPayment, 'amountApplied'),
                        CardNumber: "************" + get(creditPayment, 'accountNumber'),
                        CustomerName: get(creditPayment, 'nameOnCard'),
                        CardType: get(creditPayment, 'cardType'),
                        Shop: {
                            Name: affiliateName,
                            Location: get(sale, 'storeInfo.name'),
                            Address1: get(sale, 'storeInfo.address'),
                            Address2: get(sale, 'storeInfo.address2'),
                            City: get(sale, 'storeInfo.city'),
                            State: get(sale, 'storeInfo.state'),
                            Zip: get(sale, 'storeInfo.zip'),
                            Phone: get(sale, 'storeInfo.phoneNumber'),
                            Id: get(sale, 'storeInfo.id')
                        }
                    };
                    window.desktop.BrokerAsyncCommand('PrintAuthorizationReceipt', authorizationReceipt);
                });
            }
        },
        printReceipt: function printReceipt(affiliateName, sale, items) {
            if (get(this, 'active')) {
                (function () {
                    var saleItems = [],
                        payments = [],
                        changeTotal = 0;
                    items.forEach(function (item) {
                        saleItems.push({
                            Description: get(item, 'description'),
                            Price: get(item, 'subtotal'),
                            Quantity: get(item, 'quantity'),
                            Discount: get(item, 'discount'),
                            DiscountPercent: get(item, 'discountPercent'),
                            IsShipment: get(item, 'willShip'),
                            IsTradeIn: get(item, 'eventType') === 'Trade' || get(item, 'eventType') === 'Buy',
                            ReturnNumber: get(item, 'returnItemId') ? get(item, 'returnItemId') : '0'
                        });
                    });
                    get(sale, 'payments').forEach(function (payment) {
                        var accountStr = get(payment, 'accountNumber') == null ? "" : " **" + get(payment, 'accountNumber');
                        payments.push({
                            Description: get(payment, 'paymentType') + accountStr,
                            AmountApplied: get(payment, 'amountApplied'),
                            AmountTendered: get(payment, 'amountTendered')
                        });
                        changeTotal += get(payment, 'changeOwed') ? get(payment, 'changeOwed') : 0;
                    });
                    var receipt = {
                        OrderNumber: get(sale, 'id'),
                        OrderDate: (0, _moment['default'])(get(sale, 'date')).format('MMM DD Y HH:mm:ss'),
                        CashierName: get(sale, 'processedBy.name'),
                        Total: get(sale, 'totalAmount'),
                        Subtotal: get(sale, 'subtotal'),
                        Tax: get(sale, 'taxAmount'),
                        Shipping: get(sale, 'shippingAmount'),
                        Discount: get(sale, 'discount'),
                        DiscountPercent: get(sale, 'discountPercent'),
                        ReturnPolicy: get(sale, 'storeInfo.returnPolicy'),
                        Change: (0, _accountingToFixed['default'])(changeTotal, 2),
                        Shop: {
                            Name: affiliateName,
                            Location: get(sale, 'storeInfo.name'),
                            Address1: get(sale, 'storeInfo.address'),
                            Address2: get(sale, 'storeInfo.address2'),
                            City: get(sale, 'storeInfo.city'),
                            State: get(sale, 'storeInfo.state'),
                            Zip: get(sale, 'storeInfo.zip'),
                            Phone: get(sale, 'storeInfo.phoneNumber'),
                            Id: get(sale, 'storeInfo.id')
                        },
                        Items: saleItems,
                        Payments: payments
                    };
                    if (get(sale, 'customer.id')) {
                        receipt.CustomerName = get(sale, 'customer.name');
                        receipt.CustomerNumber = get(sale, 'customer.id');
                        receipt.CustomerBalance = get(sale, 'customer.creditBalance');
                    }
                    window.desktop.BrokerAsyncCommand('PrintReceipt', receipt);
                })();
            }
        },
        checkConnectivity: function checkConnectivity() {
            if (this.terminalFunctionExists('BrokerAsyncCommand')) {
                window.desktop.BrokerAsyncCommand('CheckConnectivity');
            }
        },
        listeningChanged: _ember['default'].observer('listening', function () {
            if (get(this, 'listening')) {
                this.listen();
            } else {
                this.ignore();
            }
        }),
        listen: function listen() {
            var _this = this;

            _ember['default'].$(document).bind('OpenCashDrawerCommandSuccess', function (event, data) {
                _this.trigger('CashDrawer:Success', data);
            });
            _ember['default'].$(document).bind('OpenCashDrawerCommandFailure', function (event, data) {
                _this.trigger('CashDrawer:Failure', data);
            });
            _ember['default'].$(document).bind('PrintReceiptCommandSuccess', function (event, data) {
                _this.trigger('PrintReceipt:Success', data);
            });
            _ember['default'].$(document).bind('PrintReceiptCommandFailure', function (event, data) {
                _this.trigger('PrintReceipt:Failure', data);
            });
            _ember['default'].$(document).bind('PrintAuthReceiptCommandSuccess', function (event, data) {
                _this.trigger('PrintReceipt:Auth:Success', data);
            });
            _ember['default'].$(document).bind('PrintAuthReceiptCommandFailure', function (event, data) {
                _this.trigger('PrintReceipt:Auth:Failure', data);
            });
            _ember['default'].$(document).bind('PrintInventoryLabelCommandSuccess', function (event, data) {
                _this.trigger('PrintLabel:Inventory:Success', data);
            });
            _ember['default'].$(document).bind('PrintInventoryLabelCommandFailure', function (event, data) {
                _this.trigger('PrintLabel:Inventory:Failure', data);
            });
            _ember['default'].$(document).bind('PrintShippingLabelCommandSuccess', function (event, data) {
                _this.trigger('PrintLabel:Shipping:Success', data);
            });
            _ember['default'].$(document).bind('PrintShippingLabelCommandFailure', function (event, data) {
                _this.trigger('PrintLabel:Shipping:Failure', data);
            });
            _ember['default'].$(document).bind('SetModeInventoryCommandSuccess', function (event, data) {
                _this.trigger('SetMode:Inventory:Success', data);
            });
            _ember['default'].$(document).bind('SetModeInventoryCommandFailure', function (event, data) {
                _this.trigger('SetMode:Inventory:Failure', data);
            });
            _ember['default'].$(document).bind('SetModeShippingCommandSuccess', function (event, data) {
                _this.trigger('SetMode:Shipping:Success', data);
            });
            _ember['default'].$(document).bind('SetModeShippingCommandFailure', function (event, data) {
                _this.trigger('SetMode:Shipping:Failure', data);
            });
            _ember['default'].$(document).bind('CreditCardReadSuccess', function (event, data) {
                _this.trigger('CreditCardRead:Success', data);
            });
            _ember['default'].$(document).bind('CreditCardReadFailure', function (event, data) {
                _this.trigger('CreditCardRead:Failure', data);
            });
            _ember['default'].$(document).bind('UnexpectedTerminalError', function (event, data) {
                get(_this, 'api').request('POST', '/error/clientlog', {
                    message: data.Message
                });
                _this.trigger('Error', data);
            });
            _ember['default'].$(document).bind('ConnectivityStatus', function (event, data) {
                get(_this, 'receiptPrinter').setProperties({
                    active: !data.EpsonReceipt.ErrorType,
                    message: data.EpsonReceipt.Message
                });
                get(_this, 'labelPrinter').setProperties({
                    // active: !data.ZebraLabel.ErrorType,
                    active: true,
                    message: data.ZebraLabel.Message
                });
                get(_this, 'cardReader').setProperties({
                    active: !data.MagTekReader.ErrorType,
                    message: data.MagTekReader.Message
                });
                get(_this, 'cashDrawer').setProperties({
                    active: !data.VasarioDrawer.ErrorType,
                    message: data.VasarioDrawer.Message
                });
                _this.trigger('ConnectivityStatus');
            });
        },
        ignore: function ignore() {
            _ember['default'].$(document).unbind('OpenCashDrawerCommandSuccess');
            _ember['default'].$(document).unbind('OpenCashDrawerCommandFailure');
            _ember['default'].$(document).unbind('PrintReceiptCommandSuccess');
            _ember['default'].$(document).unbind('PrintReceiptCommandFailure');
            _ember['default'].$(document).unbind('PrintAuthReceiptCommandSuccess');
            _ember['default'].$(document).unbind('PrintAuthReceiptCommandFailure');
            _ember['default'].$(document).unbind('PrintInventoryLabelCommandSuccess');
            _ember['default'].$(document).unbind('PrintInventoryLabelCommandFailure');
            _ember['default'].$(document).unbind('PrintShippingLabelCommandSuccess');
            _ember['default'].$(document).unbind('PrintShippingLabelCommandFailure');
            _ember['default'].$(document).unbind('SetModeInventoryCommandSuccess');
            _ember['default'].$(document).unbind('SetModeInventoryCommandFailure');
            _ember['default'].$(document).unbind('SetModeShippingCommandSuccess');
            _ember['default'].$(document).unbind('SetModeShippingCommandFailure');
            _ember['default'].$(document).unbind('OpenCashDrawerCommandSuccess');
            _ember['default'].$(document).unbind('CreditCardReadSuccess');
            _ember['default'].$(document).unbind('CreditCardReadFailure');
            _ember['default'].$(document).unbind('ConnectivityStatus');
            _ember['default'].$(document).unbind('UnexpectedTerminalError');
        }
    });
});
/**
 * @DEPRECATED
 *
 * This service is no longer in use (see `qz` and `print` services instead).
 * However, it will remain in the application for legacy concerns.
 */