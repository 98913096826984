define("retro-game-store/helpers/combine-string", ["exports", "ember"], function (exports, _ember) {
    exports.combineString = combineString;

    function combineString(params /*, hash*/) {
        var string = "";
        if (!_ember["default"].isArray(params)) {
            return string;
        } else {
            params.forEach(function (param) {
                string += param;
            });
            return string;
        }
    }

    exports["default"] = _ember["default"].Helper.helper(combineString);
});