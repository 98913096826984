define('retro-game-store/macros/conditional', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = conditional;
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;

    function conditional(condition, expr1, expr2) {
        return computed(condition, function () {
            return get(this, condition) ? expr1 : expr2;
        });
    }
});