define('retro-game-store/pods/inventory-summary/model', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-inflector'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberInflector) {

    var inflector = _emberInflector['default'].inflector;
    inflector.irregular('inventorySummary', 'inventorySummary');

    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    exports['default'] = _emberDataModel['default'].extend({
        name: (0, _emberDataAttr['default'])(),
        productType: (0, _emberDataAttr['default'])(),

        cost: (0, _emberDataAttr['default'])('number'),
        count: (0, _emberDataAttr['default'])('number'),
        price: (0, _emberDataAttr['default'])('number'),

        //Computeds
        fullCategory: computed('productType.name', 'name', function () {
            return get(this, 'productType.name') + ' / ' + get(this, 'name');
        })
    });
});