define('retro-game-store/pods/invoices/invoice/items/invoice-item-modal/controller', ['exports', 'ember', 'ember-validations', 'retro-game-store/macros', 'ember-awesome-macros/not', 'ember-awesome-macros/array/find-by', 'ember-awesome-macros/raw', 'ember-awesome-macros/or'], function (exports, _ember, _emberValidations, _retroGameStoreMacros, _emberAwesomeMacrosNot, _emberAwesomeMacrosArrayFindBy, _emberAwesomeMacrosRaw, _emberAwesomeMacrosOr) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var bool = _Ember$computed.bool;
    var notEmpty = _Ember$computed.notEmpty;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    var isEmpty = _ember['default'].isEmpty;
    var next = _ember['default'].run.next;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    var conditional = _retroGameStoreMacros['default'].conditional;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        api: service(),
        categories: service(),

        title: 'Invoice Item',
        selectedCategories: A(),
        storeInfo: reads('model.storeInfo'),
        productSku: reads('model.productSku'),
        productCategory: reads('model.category'),
        productName: reads('model.name'),
        productNameInputId: 'productNameInput',
        upcInputId: 'upcInput',
        productUpc: reads('product.upc'),
        productSkuNew: (0, _emberAwesomeMacrosArrayFindBy['default'])('productSkus', (0, _emberAwesomeMacrosRaw['default'])('name'), (0, _emberAwesomeMacrosRaw['default'])('New')),
        productCategorySelected: notEmpty('model.categoryId'),
        invoiceController: controller('invoices/invoice'),
        invoiceItemsController: controller('invoices/invoice/items'),
        vendor: reads('invoiceController.model.vendor'),
        upcLookupActive: false,
        itemNumberLookupActive: false,
        platformDisabled: true,
        buyPriceNotEmpty: bool('model.buyPrice'),
        buyPriceMask: conditional('buyPriceNotEmpty', 'currency', '*'),
        sellPriceNotEmpty: bool('model.sellPrice'),
        sellPriceMask: conditional('sellPriceNotEmpty', 'currency', '*'),
        modelIsNew: alias('model.isNew'),
        itemNumberDisabled: (0, _emberAwesomeMacrosNot['default'])('modelIsNew'),
        productSkuExists: bool('model.productSku.id'),
        upcDisabled: (0, _emberAwesomeMacrosOr['default'])('productSkuExists', 'itemNumberLookupActive'),
        productNameDisabled: (0, _emberAwesomeMacrosOr['default'])('productSkuExists', 'upcLookupActive'),

        validations: {
            'model.itemCode': {
                presence: true
            },
            'model.productName': {
                presence: true
            },
            'model.categoryName': {
                presence: {
                    unless: 'productCategorySelected'
                }
            },
            'model.quantity': {
                presence: true,
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0,
                    messages: {
                        numericality: " must be a number"
                    }
                }
            },
            'model.buyPrice': {
                presence: true
            },
            'model.sellPrice': {
                presence: true
            },
            'model.storeId': {
                presence: true
            }
        },

        availableProductTypes: computed('productTypes', 'productSku.id', 'model.productName', function () {
            if (!get(this, 'productSku.id') && get(this, 'model.productName')) {
                // if there's an existing categoryId and it is in 'Video Games' then null it
                if (get(this, 'selectedCategory.id') && get(this, 'selectedCategory.productTypeId') === 1) {
                    this.categoryRemoved();
                }
                return get(this, 'productTypes').filter(function (item) {
                    return get(item, 'id') === 0;
                });
            } else {
                return get(this, 'productTypes');
            }
        }),

        productTypeAndCategory: computed('model.category', function () {
            if (!get(this, 'model.category')) {
                return;
            }

            var category = get(this, 'model.category');
            var productType = this.store.peekRecord('product-type', get(category, 'productTypeId'));

            if (productType && category) {
                return get(productType, 'name') + ' / ' + get(category, 'name');
            } else {
                return '';
            }
        }),

        closeModal: function closeModal() {
            setProperties(this, {
                'model.categoryId': null,
                'model.categoryName': null,
                itemCode: null,
                performLookup: true,
                selectedCategories: A(),
                productName: null,
                productUpc: null,
                upcLookupActive: false,
                itemNumberLookupActive: false,
                platformDisabled: true
            });
            this.send('closeModal');
        },

        lookupItemNumber: function lookupItemNumber(value) {
            var _this = this;

            if (get(this, 'itemCode') === value) {
                return;
            }

            setProperties(this, {
                itemCode: value,
                performLookup: false,
                itemNumberLookupActive: true
            });

            this.store.query('vendor-item', { itemCode: value, vendorId: get(this, 'vendor.id') }).then(function (vendorItems) {
                set(_this, 'itemNumberLookupActive', false);

                if (vendorItems.get('length')) {
                    next(function () {
                        var product = get(vendorItems, 'firstObject');

                        var productType = _this.store.peekRecord('product-type', get(product, 'productTypeId'));

                        set(_this, 'productSkus', get(product, 'productSkus'));

                        setProperties(_this, {
                            productType: productType,
                            'productUpc': get(product, 'upc'),
                            'model.productId': get(product, 'id'),
                            'model.productSku': get(_this, 'productSkuNew'),
                            'model.productName': get(product, 'name'),
                            'model.category': get(product, 'category'),
                            'model.categoryId': get(product, 'categoryId')
                        });
                    });
                } else {
                    setProperties(_this, {
                        productUpc: null,
                        productType: null,
                        selectedCategories: A(),
                        'model.productId': null,
                        'model.productSku': null,
                        'model.productSkuId': null,
                        'model.productName': null,
                        'model.category': null,
                        'model.categoryId': null,
                        'model.categoryName': null
                    });

                    next(function () {
                        _ember['default'].$('#' + get(_this, 'upcInputId')).focus();
                    });
                }
            });
        },

        lookupUPC: function lookupUPC(value) {
            var _this2 = this;

            set(this, 'upcLookupActive', true);
            get(this, 'api').request('GET', '/products/upc/' + value).then(function (product) {
                set(_this2, 'upcLookupActive', false);
                if (product) {
                    var normalized = _this2.store.normalize('product-child', product);
                    var productAdded = _this2.store.push(normalized);
                    var productType = _this2.store.peekRecord('product-type', get(product, 'productTypeId'));

                    // Have to set this first before setProperties below so 'productSkuNew'
                    // is computed for model.productSku
                    set(_this2, 'productSkus', get(productAdded, 'productSkus'));

                    setProperties(_this2, {
                        productType: productType,
                        'model.productId': get(productAdded, 'id'),
                        'model.productSku': get(_this2, 'productSkuNew'),
                        'model.productName': get(productAdded, 'name'),
                        'model.category': get(productAdded, 'category'),
                        'model.categoryId': get(productAdded, 'categoryId')
                    });
                } else {
                    if (!isEmpty(get(_this2, 'model'))) {
                        next(function () {
                            _ember['default'].$('#' + get(_this2, 'productNameInputId')).focus();
                        });
                    }
                }
            });
        },

        actions: {
            cancel: function cancel() {
                this.closeModal();
            },

            lookupItemNumber: function lookupItemNumber(value) {
                if (value) {
                    this.lookupItemNumber(value);
                }
            },

            lookupUPC: function lookupUPC(value) {
                if (value) {
                    this.lookupUPC(value);
                } else {
                    set(this, 'model.product', null);
                }
            },

            categoryAdded: function categoryAdded() {
                var item = arguments[1];
                setProperties(get(this, 'model'), {
                    categoryId: get(item, 'id'),
                    categoryName: get(item, 'name')
                });
            },

            categoryRemoved: function categoryRemoved() {
                setProperties(get(this, 'model'), {
                    categoryId: null,
                    categoryName: null
                });
            },

            selectStore: function selectStore(store) {
                if (store) {
                    setProperties(this, {
                        'model.storeId': get(store, 'id'),
                        'model.storeInfo': store
                    });
                } else {
                    setProperties(this, {
                        'model.storeId': null,
                        'model.storeInfo': null
                    });
                }
            },

            save: function save(callback) {
                var _this3 = this;

                if (get(this, 'modelIsNew') && get(this, 'productUpc')) {
                    set(this, 'model.upc', get(this, 'productUpc'));
                }

                var promise = get(this, 'model').save().then(function () {
                    get(_this3, 'invoiceItemsController.model').addObject(get(_this3, 'model'));

                    // Refresh product types in case custom category was created
                    set(_this3, 'productTypes', get(_this3, 'categories').loadModels());

                    _this3.closeModal();
                });

                callback(promise);
            }
        }
    });
});