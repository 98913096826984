define('retro-game-store/constants/sale-filter-types', ['exports'], function (exports) {
    var SALE_FILTER_TYPE_CATEGORY = { id: 'byCategory', label: 'By Category', type: 'name' };
    exports.SALE_FILTER_TYPE_CATEGORY = SALE_FILTER_TYPE_CATEGORY;
    var SALE_FILTER_TYPE_STORE = { id: 'byStore', label: 'By Store', type: 'name' };
    exports.SALE_FILTER_TYPE_STORE = SALE_FILTER_TYPE_STORE;
    var SALE_FILTER_TYPE_NET = { id: 'net', label: 'Net', type: 'allCost' };
    exports.SALE_FILTER_TYPE_NET = SALE_FILTER_TYPE_NET;
    var SALE_FILTER_TYPE_SELL_PRICE = { id: 'sellPrice', label: 'Sell Price', type: 'saleCost' };
    exports.SALE_FILTER_TYPE_SELL_PRICE = SALE_FILTER_TYPE_SELL_PRICE;
    var SALE_FILTER_TYPE_COST = { id: 'cost', label: 'Cost', type: 'saleCost' };
    exports.SALE_FILTER_TYPE_COST = SALE_FILTER_TYPE_COST;
    var SALE_FILTER_TYPE_DISCOUNT = { id: 'discount', label: 'Discount', type: 'saleCost' };
    exports.SALE_FILTER_TYPE_DISCOUNT = SALE_FILTER_TYPE_DISCOUNT;
    var SALE_FILTER_TYPE_CASH = { id: 'cash', label: 'Cash', type: 'tradeCost' };
    exports.SALE_FILTER_TYPE_CASH = SALE_FILTER_TYPE_CASH;
    var SALE_FILTER_TYPE_CREDIT = { id: 'credit', label: 'Credit', type: 'tradeCost' };
    exports.SALE_FILTER_TYPE_CREDIT = SALE_FILTER_TYPE_CREDIT;
    var SALE_FILTER_TYPE_RETAIL = { id: 'retail', label: 'Retail', type: 'tradeCost' };

    exports.SALE_FILTER_TYPE_RETAIL = SALE_FILTER_TYPE_RETAIL;
    exports['default'] = [SALE_FILTER_TYPE_CATEGORY, SALE_FILTER_TYPE_STORE, SALE_FILTER_TYPE_NET, SALE_FILTER_TYPE_SELL_PRICE, SALE_FILTER_TYPE_COST, SALE_FILTER_TYPE_DISCOUNT, SALE_FILTER_TYPE_CASH, SALE_FILTER_TYPE_CREDIT, SALE_FILTER_TYPE_RETAIL];
});