define('retro-game-store/initializers/abilities', ['exports'], function (exports) {
    exports.initialize = initialize;

    function initialize(application) {
        application.inject('ability', 'session', 'service:session');
    }

    exports['default'] = {
        name: 'abilities',
        initialize: initialize
    };
});