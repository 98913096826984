define('retro-game-store/helpers/to-string', ['exports', 'ember'], function (exports, _ember) {
    exports.toString = toString;

    function toString(params /*, hash*/) {
        if (!_ember['default'].isEmpty(params[0])) {
            return params[0].toString();
        } else {
            return '';
        }
    }

    exports['default'] = _ember['default'].Helper.helper(toString);
});