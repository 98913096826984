define('retro-game-store/pods/affiliates/affiliate/general/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        creditCard: _ember['default'].computed.alias('model.creditCard'),
        validations: {
            'model.name': {
                presence: true
            },
            'model.contactFirstName': {
                presence: true
            },
            'model.contactLastName': {
                presence: true
            },
            'model.contactEmail': {
                email: true
            },
            'model.phoneNumber': {
                presence: true
            },
            'creditCard.expirationDate': {
                presence: true
            },
            'creditCard.nameOnCard': {
                presence: true
            }
        },
        actions: {
            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            }
        }
    });
});