define("retro-game-store/pods/components/customer-notification-row/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "retro-game-store/pods/components/customer-notification-row/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-xs-4 text-xs-left");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-xs-4");
        var el2 = dom.createElement("span");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-xs-2");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-xs-2 text-xs-right");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createMorphAt(element0, 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [6]), 1, 1);
        return morphs;
      },
      statements: [["inline", "product-badge", [], ["name", ["subexpr", "@mut", [["get", "model.product.name", ["loc", [null, [4, 33], [4, 51]]]]], [], []], "category", ["subexpr", "@mut", [["get", "fullCategory", ["loc", [null, [4, 61], [4, 73]]]]], [], []]], ["loc", [null, [4, 12], [4, 75]]]], ["attribute", "title", ["concat", [["get", "model.storeInfo.name", ["loc", [null, [8, 37], [8, 57]]]]]]], ["content", "model.storeInfo.name", ["loc", [null, [8, 61], [8, 85]]]], ["inline", "moment-from-now", [["get", "model.createdDateMinNow", ["loc", [null, [9, 40], [9, 63]]]]], [], ["loc", [null, [9, 22], [9, 65]]]], ["inline", "confirm-action-btn", [], ["confirmAction", "remove", "type", "delete", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [11, 68], [11, 73]]]]], [], []], "class", "remove", "placement", "auto left", "title", ["subexpr", "t", ["actions.remove"], [], ["loc", [null, [11, 117], [11, 137]]]]], ["loc", [null, [11, 4], [11, 139]]]]],
      locals: [],
      templates: []
    };
  })());
});