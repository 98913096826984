define('retro-game-store/helpers/truncate', ['exports', 'ember-cli-string-helpers/helpers/truncate'], function (exports, _emberCliStringHelpersHelpersTruncate) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersTruncate['default'];
    }
  });
  Object.defineProperty(exports, 'truncate', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersTruncate.truncate;
    }
  });
});