define('retro-game-store/mixins/unauthenticated-route', ['exports', 'ember', 'retro-game-store/config/environment'], function (exports, _ember, _retroGameStoreConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({
        beforeModel: function beforeModel(transition) {
            if (this.get('session').get('isAuthenticated')) {
                transition.abort();
                _ember['default'].assert('The route configured as Configuration.routeIfAlreadyAuthenticated cannot implement the UnauthenticatedRouteMixin mixin as that leads to an infinite transitioning loop!', this.get('routeName') !== _retroGameStoreConfigEnvironment['default']['ember-simple-auth'].routeIfAlreadyAuthenticated);
                this.transitionTo(_retroGameStoreConfigEnvironment['default']['ember-simple-auth'].routeIfAlreadyAuthenticated, _retroGameStoreConfigEnvironment['default']['affiliate']);
            } else {
                return this._super.apply(this, arguments);
            }
        }
    });
});