define('retro-game-store/pods/components/inventory-conditions/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        options: [{
            id: 'new',
            name: 'New'
        }, {
            id: 'completeInBox',
            name: 'Complete In Box'
        }, {
            id: 'loose',
            name: 'Media Only'
        }],
        selections: [],
        valuesChanged: _ember['default'].observer('selections.length', function () {
            var array = [];
            if (this.get('selections')) {
                this.get('selections').forEach(function (selection) {
                    array.push(selection.id);
                });
            }
            this.set('values', array);
        })
    });
});