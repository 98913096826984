define('retro-game-store/pods/pricing-data/model', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;

    var inflector = _emberInflector['default'].inflector;
    inflector.irregular('data', 'data');

    exports['default'] = Model.extend({
        buyPrice: attr('number'),
        competitorBuyPrice: attr('number'),
        competitorSellPrice: attr('number'),
        maxPrice: attr('number'),
        minPrice: attr('number'),
        sellPrice: attr('number'),
        sellingForPrice: attr('number'),
        name: attr('string'),
        isFoil: attr('boolean'),
        pricingDataAvailable: attr('boolean'),
        quantity: attr('number'),
        researchUrl: attr('string'),
        sourceId: attr('number'),

        productType: belongsTo('product-type'),
        language: belongsTo('product-sku-language'),
        condition: belongsTo('condition')
    });
});