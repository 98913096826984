define('retro-game-store/pods/customer-notification/serializer', ['exports', 'retro-game-store/pods/application/serializer'], function (exports, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend({
        keyForRelationship: function keyForRelationship(key) {
            if (key === 'storeInfo') {
                return 'storeId';
            } else {
                return key + 'Id';
            }
        }
    });
});