define('retro-game-store/mixins/authenticated-route', ['exports', 'ember', 'retro-game-store/config/environment'], function (exports, _ember, _retroGameStoreConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({
        session: _ember['default'].inject.service('session'),
        beforeModel: function beforeModel(transition) {
            if (!this.get('session.isAuthenticated')) {
                transition.abort();
                this.get('session').set('attemptedTransition', transition);
                _ember['default'].assert('The route configured as Configuration.authenticationRoute cannot implement the AuthenticatedRouteMixin mixin as that leads to an infinite transitioning loop!', this.get('routeName') !== _retroGameStoreConfigEnvironment['default']['ember-simple-auth'].authenticationRoute);
                this.transitionTo(_retroGameStoreConfigEnvironment['default']['ember-simple-auth'].authenticationRoute, _retroGameStoreConfigEnvironment['default']['affiliate']);
            } else {
                return this._super.apply(this, arguments);
            }
        }
    });
});