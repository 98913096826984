define('retro-game-store/pods/application/change-store-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination'], function (exports, _ember, _retroGameStoreMixinsControllerPagination) {
    var Controller = _ember['default'].Controller;
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], {
        api: service(),
        session: service(),

        serverQueryParams: ['employeeId'],
        employeeId: computed('session', function () {
            return this.get('session').get('user').get('employeeId');
        }),
        limit: 20,

        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            cancel: function cancel() {
                this.send('closeModal');
            },
            changeStore: function changeStore(store) {
                var _this = this;

                this.get('api').request('POST', '/currentStore', store.toJSON({ includeId: true })).then(function () {
                    _this.get('session').get('user').set('storeInfo', store);
                    //Get the internal session and modify local storage
                    _this.get('session').get('session').set('storeInfo', store.toJSON({ includeId: true }));
                    _this.send('closeModal');
                });
            }
        }
    });
});