define('retro-game-store/pods/store/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        isNewSerializerAPI: true,
        attrs: {
            employees: {
                serialize: 'records',
                deserialize: 'records'
            },
            categoryOptions: {
                serialize: 'records',
                deserialize: 'records'
            }
        }
    });
});