define('retro-game-store/helpers/multiply-helper', ['exports', 'ember'], function (exports, _ember) {
    exports.multiplyHelper = multiplyHelper;

    function multiplyHelper(params /*, hash*/) {
        if (!_ember['default'].isArray(params)) {
            return params;
        } else {
            var product = 1;
            params.forEach(function (param) {
                product *= param;
            });
            return product;
        }
    }

    exports['default'] = _ember['default'].Helper.helper(multiplyHelper);
});