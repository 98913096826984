define('retro-game-store/pods/customers/customer/notifications/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _emberValidations['default'], {
        // limit: 20,
        // serverQueryParams: [],
        // actions: {
        //     fetchMore (callback) {
        //         let promise = this.loadMore();
        //         callback(promise);
        //     },
        //     save (callback) {
        //         let promise = this.get('model').save();
        //         callback(promise);
        //     }
        // }
    });
});