define('retro-game-store/pods/components/category-dropdown/component', ['exports', 'ember', 'ember-awesome-macros/bool', 'ember-i18n', 'ember-data', 'ember-awesome-macros/is-empty', 'ember-awesome-macros/sum', 'ember-awesome-macros/array/concat'], function (exports, _ember, _emberAwesomeMacrosBool, _emberI18n, _emberData, _emberAwesomeMacrosIsEmpty, _emberAwesomeMacrosSum, _emberAwesomeMacrosArrayConcat) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var set = _ember['default'].set;
    var tryInvoke = _ember['default'].tryInvoke;

    var ENTER_KEYCODE = 13;

    exports['default'] = _ember['default'].Component.extend({
        api: service(),
        classNames: ['category-dropdown'],
        classNameBindings: ['hasSelection:items-selected'],
        hasSelection: (0, _emberAwesomeMacrosBool['default'])('selectedItems.length'),
        model: alias('categories'),
        canSelectMultiple: true,
        canAddParent: true,
        allowClear: true,
        renderInPlace: true,
        basicDropdown: null,
        value: null,
        selectedItemComponent: 'category-dropdown/selected-item',
        deleteComponent: 'category-dropdown/delete-items',
        triggerComponent: 'category-dropdown/trigger',
        selectedItems: A(),
        selectedItemTypes: A(),
        isLoading: (0, _emberAwesomeMacrosIsEmpty['default'])('model'),

        placeholder: (0, _emberI18n.translationMacro)('categoryDropdown.selectCategories'),
        placeholderText: computed('categories', 'placeholder', 'arrayTotal', function () {
            if (!get(this, 'arrayTotal') && get(this, 'placeholder')) {
                return get(this, 'placeholder');
            } else {
                return '';
            }
        }),

        // get all of the categories whose productType parent is not selected
        orphanSelectedItems: computed('selectedItemTypes.[]', 'selectedItems.[]', function () {
            var orphans = get(this, 'selectedItems');
            get(this, 'selectedItemTypes').forEach(function (itemType) {
                orphans = orphans.rejectBy('productTypeId', parseInt(get(itemType, 'id'), 10));
            });
            return orphans;
        }),

        // get all selected productTypes and orphans (see above)
        // the results here will be what is displayed in the selected items bank
        selectedParentsAndOrphans: (0, _emberAwesomeMacrosArrayConcat['default'])('selectedItemTypes', 'orphanSelectedItems'),

        // get all affected categories to display the number of categories in the main
        // input ie. if Video Games is selected and has 73 items, the input will read
        // "73 categories" with "x" to clear the selection even though we really only have
        // one parent item selected
        /* jshint ignore:start */
        allSelectedItems: computed('selectedItemTypes.[]', 'selectedItems.[]', function () {
            var _this = this;

            var all = A();
            get(this, 'selectedItemTypes').forEach(function (productType) {
                //Get the category from the original categories object, since the one passed in might not be a full model
                var selectedProductType = get(_this, 'categories').find(function (selectedType) {
                    //We don't care about type here
                    return get(selectedType, 'id') == get(productType, 'id');
                });
                all.pushObjects(get(selectedProductType, 'productCategories'));
            });
            all.pushObjects(get(this, 'selectedItems'));
            return all;
        }),
        /* jshint ignore:end */

        arrayTotal: (0, _emberAwesomeMacrosSum['default'])('selectedItemTypes.length', 'selectedItems.length'),

        // returns the provided categories but where each selectedItem contains a
        // selected param so we can toggle the selected style
        /* jshint ignore:start */
        gridCategories: computed('model.[]', 'selectedItemsTypes.[]', 'selectedItems.[]', function () {
            var _this2 = this;

            var hash = [];
            get(this, 'categories').forEach(function (productType) {
                hash.addObject(productType);
                var productTypeId = get(productType, 'id');

                // set all productCategory selected to false
                get(productType, 'productCategories').forEach(function (productCategory) {
                    set(productCategory, 'selected', false);
                });

                // if productType is selected, set all child productCategories selected to true
                var selectedProductType = get(_this2, 'selectedItemTypes').find(function (selectedType) {
                    //We don't care about type here
                    return get(selectedType, 'id') == productTypeId;
                });
                if (selectedProductType) {
                    // turn on all productCategories under selectedProductType
                    get(productType, 'productCategories').forEach(function (productCategory) {
                        set(productCategory, 'selected', true);
                    });
                }

                var selectedItems = get(_this2, 'selectedItems');
                selectedItems.forEach(function (item) {
                    //For each selected item, find the passed in item in the original categories object and set that value to be selected
                    var selectedItem = get(productType, 'productCategories').findBy('id', get(item, 'id'));
                    if (selectedItem) {
                        set(selectedItem, 'selected', true);
                    }
                });
            });
            return hash;
        }),
        /* jshint ignore:end */

        addItem: function addItem(item) {
            if (!get(this, 'canSelectMultiple')) {
                set(this, 'selectedItems', A());
            }

            get(this, 'selectedItems').addObject(item);

            // if all categories within a parent are selected, then select the parent
            // if the newly selected item causes all of the parent item's children to
            // be selected, we want to add the parent to selectedItemTypes (as if we clicked
            // "All" for that parent) and remove all of it's child categories from selectedItems
            var productType = get(this, 'categories').findBy('id', get(item, 'productTypeId'));
            var categoriesLength = get(productType, 'productCategories.length');
            var selectedItems = get(this, 'selectedItems').filterBy('productTypeId', get(productType, 'id'));
            var selectedCategoriesLength = get(selectedItems, 'length');
            if (categoriesLength === selectedCategoriesLength) {
                this.addParentItem(productType);
            } else {
                tryInvoke(this.attrs, 'itemAdded', [get(this, 'selectedItems'), item]);
            }
        },

        addParentItem: function addParentItem(item) {
            get(this, 'selectedItemTypes').addObject(item);

            set(this, 'selectedItems', get(this, 'selectedItems').rejectBy('productTypeId', get(item, 'id')));

            tryInvoke(this.attrs, 'itemAdded', [get(this, 'selectedItems'), item]);
            tryInvoke(this.attrs, 'itemTypeAdded', [get(this, 'selectedItemTypes'), item]);
        },

        removeItem: function removeItem(item) {
            var productType = get(this, 'selectedItemTypes').findBy('id', get(item, 'productTypeId'));

            get(this, 'selectedItems').removeObject(item);

            if (productType) {
                this.removeParentItem(productType);
            } else {
                tryInvoke(this.attrs, 'itemRemoved', [get(this, 'selectedItems'), item]);
            }
        },

        removeParentItem: function removeParentItem(item) {
            get(this, 'selectedItemTypes').removeObject(item);
            set(this, 'selectedItems', get(this, 'selectedItems').rejectBy('productTypeId', get(item, 'id')));

            set(this, 'parentAdded', false);

            tryInvoke(this.attrs, 'itemRemoved', [get(this, 'selectedItems'), item]);
            tryInvoke(this.attrs, 'itemTypeRemoved', [get(this, 'selectedItemTypes'), item]);
        },

        reset: function reset() {
            set(this, 'selectedItems', A());
            set(this, 'selectedItemTypes', A());
            tryInvoke(this.attrs, 'itemRemoved', [get(this, 'selectedItems')]);
            tryInvoke(this.attrs, 'itemTypeRemoved', [get(this, 'selectedItemTypes')]);
        },

        hasFocused: function hasFocused() {
            var textField = this.$('input');
            if (document.activeElement !== textField[0]) {
                textField.focus();
            }
            get(this, 'basicDropdown').actions.open();
        },

        //Due to a bug in ember data, the product types need to be loaded outside of ember data. To make this easier,
        //if you don't pass in any categories we do that for you. We also now warn that categories can't be an instance of a record array
        //which is the type that comes from embers store.
        didInsertElement: function didInsertElement() {
            var categories = get(this, 'categories');
            _ember['default'].assert('The categories parameter value provided to the category-dropdown component cannot be an instance of DS.RecordArray.', !(categories instanceof _emberData['default'].RecordArray));
        },
        actions: {
            onKeyDown: function onKeyDown(select, event) {
                if (event.keyCode === ENTER_KEYCODE && get(this, 'filteredItems.length') === 1) {
                    this.addItem(get(this, 'filteredItems.firstObject'));
                }
            },
            onClick: function onClick() {
                get(this, 'basicDropdown').actions.toggle();
            },
            resetState: function resetState() {
                set(this, 'value', null);
            },
            filtered: function filtered(filteredItems) {
                set(this, 'filteredItems', filteredItems);
            },
            addItem: function addItem(category) {
                this.addItem(category);
                this.hasFocused();
            },
            removeItem: function removeItem(item) {
                this.removeItem(item);
            },
            addParentItem: function addParentItem(parentCategory) {
                this.addParentItem(parentCategory);
                this.hasFocused();
            },
            removeParentItem: function removeParentItem(parentCategory) {
                this.removeParentItem(parentCategory);
            },
            removeSelectedItem: function removeSelectedItem(item) {
                if (isNaN(parseInt(get(item, 'productTypeId'), 10))) {
                    this.removeParentItem(item);
                } else {
                    this.removeItem(item);
                }
            },
            clear: function clear() {
                this.reset();
            }
        }
    });
});