define('retro-game-store/pods/sales/items-sold/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'retro-game-store/mixins/save-blob', 'retro-game-store/constants/event-types', 'retro-game-store/constants/sale-filter-types', 'moment', 'ember-awesome-macros/array/sort'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _retroGameStoreMixinsSaveBlob, _retroGameStoreConstantsEventTypes, _retroGameStoreConstantsSaleFilterTypes, _moment, _emberAwesomeMacrosArraySort) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var Controller = _ember['default'].Controller;
    var _Ember$computed = _ember['default'].computed;
    var reads = _Ember$computed.reads;
    var mapBy = _Ember$computed.mapBy;
    var filterBy = _Ember$computed.filterBy;
    var service = _ember['default'].inject.service;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _retroGameStoreMixinsSaveBlob['default'], {
        i18n: service(),
        api: service(),

        limit: 20,
        sortby: 'name',
        sortdirection: 'asc',
        exportIsSpinning: false,
        serverQueryParams: ['storeId', 'categoryId', 'date', 'eventTypes', 'saleStatus', 'sortby', 'sortdirection'],
        pagingTarget: 'model.models',

        filters: A(),
        saleFilterTypes: _retroGameStoreConstantsSaleFilterTypes['default'],
        categoryFilters: filterBy('filters', 'type', 'category'),
        sortedModels: (0, _emberAwesomeMacrosArraySort['default'])('groupedModels', 'costFilterSort'),

        nameFilterTypeSelected: null,
        costFilterTypeSelected: null,

        categoryId: mapBy('categoryFilters', 'id'),
        storeId: null,
        date: reads('dateShownQueryFormatted'),
        saleStatus: 1,
        dateShown: (0, _moment['default'])(),

        costFilterSort: computed('costFilterTypeSelected', function () {
            return [get(this, 'costFilterTypeSelected.id') + ':desc'];
        }),

        nameFilterTypes: computed('saleFilterTypes.[]', function () {
            var filterTypes = get(this, 'saleFilterTypes');
            var nameFilterList = A();
            filterTypes.forEach(function (filter) {
                if (get(filter, 'type') === 'name') {
                    nameFilterList.pushObject(filter);
                }
            });
            return nameFilterList;
        }),

        costFilterTypes: computed('saleFilterTypes.[]', function () {
            var filterTypes = get(this, 'saleFilterTypes');
            var costFilterList = A();
            filterTypes.forEach(function (filter) {
                var fType = get(filter, 'type');
                if (fType === 'allCost' || fType === 'saleCost') {
                    costFilterList.pushObject(filter);
                }
            });
            return costFilterList;
        }),

        eventTypes: computed(function () {
            return [get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE, 'type'), get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type')];
        }),

        dateShownQueryFormatted: computed('dateShown', function () {
            var date = get(this, 'dateShown');
            return date.format('YYYY-MM-DD');
        }),

        dateShownFormatted: computed('dateShown', function () {
            var date = get(this, 'dateShown');
            return date.format('MM/DD/YYYY');
        }),

        groupedModels: computed('model.models.[]', 'nameFilterTypeSelected', function () {
            var result = A();
            var models = get(this, 'model.models') ? get(this, 'model.models') : A();
            var filterType = get(this, 'nameFilterTypeSelected.id');
            var isCategory = filterType === 'byCategory';
            var filterItemType = isCategory ? 'category' : 'store';

            models.forEach(function (item) {
                var isMisc = get(item, 'eventType') === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type');
                var type = isCategory ? get(item, 'category.content') : get(item, 'storeInfo.content');
                var hasId = type ? result.findBy('id', get(type, 'id')) : false;

                if (!hasId && !isMisc) {
                    result.pushObject(_ember['default'].Object.create({
                        id: get(type, 'id'),
                        type: filterItemType,
                        name: get(type, 'name'),
                        productType: isCategory ? get(item, 'productType.name') : null,
                        net: 0,
                        itemPrice: 0,
                        costOfGoods: 0,
                        discount: 0
                    }));

                    var itemType = result.findBy('name', get(type, 'name'));
                    var totalNet = get(itemType, 'net');
                    var totalItemPrice = get(itemType, 'itemPrice');
                    var totalCost = get(itemType, 'costOfGoods');
                    var totalDiscount = get(itemType, 'discount');
                    totalNet += get(item, 'net');
                    totalItemPrice += get(item, 'itemPrice');
                    totalCost += get(item, 'costOfGoods');
                    totalDiscount += get(item, 'discount');

                    setProperties(itemType, {
                        net: totalNet,
                        itemPrice: totalItemPrice,
                        costOfGoods: totalCost,
                        discount: totalDiscount
                    });
                }
            });

            return result;
        }),

        menuOptions: computed(function () {
            return A([{ action: 'viewSale', label: get(this, 'i18n').t('actions.viewSale') }, { action: 'returnItem', label: get(this, 'i18n').t('actions.returnItem') }]);
        }),

        columns: computed(function () {
            var i18n = get(this, 'i18n');
            return A([{
                label: i18n.t("pos.itemReport.title"),
                valuePath: 'name',
                subValuePath: 'fullCategory',
                cellComponent: 'data-table/cell/item-title',
                width: '30%'
            }, {
                label: i18n.t('pos.itemReport.location'),
                valuePath: 'storeInfo.name'
            }, {
                label: i18n.t('pos.itemReport.cost'),
                valuePath: 'costOfGoods',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t('pos.itemReport.sellPrice'),
                valuePath: 'itemPrice',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t('pos.itemReport.discount'),
                valuePath: 'discount',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: i18n.t('pos.itemReport.net'),
                valuePath: 'net',
                cellComponent: 'data-table/cell/money',
                align: 'right'
            }, {
                label: '',
                cellComponent: 'data-table/cell/link-to-menu',
                align: 'right',
                menuOptions: get(this, 'menuOptions')
            }]);
        }),

        totals: computed('model.meta', function () {
            var model = get(this, 'model.meta');
            var totals = {
                net: 0,
                itemPrice: 0,
                costOfGoods: 0,
                discount: 0
            };
            totals.net = get(model, 'basePrice') - get(model, 'costOfGoods') + get(model, 'discount');
            totals.itemPrice = get(model, 'basePrice');
            totals.discount = get(model, 'discount');
            totals.costOfGoods = get(model, 'costOfGoods');
            return totals;
        }),

        actions: {
            exportCsv: function exportCsv() {
                var _this = this;

                set(this, 'exportIsSpinning', true);

                var params = this.getParamsObject(get(this, 'serverQueryParams'));

                return this.get('api').request('GET', '/saleItemSummary/csv', this.removeEmptyQueryParams(params), true).then(function (result) {
                    _this.saveBlobAs(result, 'saleItemSummary.csv');
                })['finally'](function () {
                    set(_this, 'exportIsSpinning', false);
                });
            },

            //report table
            loadMore: function loadMore() {
                var _this2 = this;

                return this.loadMore(get(this, 'serverQueryParams')).then(function (results) {
                    get(_this2, 'model.models').pushObjects(results.toArray());
                    return results;
                });
            },
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(this.get('serverQueryParams'));
                callback(promise);
            },
            viewSale: function viewSale(row) {
                if (row) {
                    this.transitionToRoute('sales.sale', get(row, 'transactionId'));
                }
            },
            returnItem: function returnItem(row) {
                if (row) {
                    this.transitionToRoute('sales.new', { queryParams: {
                            openWithReturn: true,
                            returnItemId: get(row, 'id')
                        } });
                }
            },

            //filter
            filter: function filter() {
                return this.filterWithMeta(get(this, 'serverQueryParams'));
            },
            addFilter: function addFilter(model) {
                if (model) {
                    var filters = get(this, 'filters');
                    var filterName = get(this, 'nameFilterTypeSelected.id');
                    var isCategory = filterName === 'byCategory';
                    var fid = get(model, 'id');
                    var fname = get(model, 'name');
                    var ftype = isCategory ? 'category' : 'store';
                    //check if the filtered id already exists so we don't add dupes
                    if (!filters.filterBy('type', ftype).findBy('id', get(model, 'id'))) {
                        //create a new filter for filter type and push to filters
                        var data = { id: fid, name: fname, type: ftype };
                        filters.pushObject(data);
                        //if the store filter is selected, set the selected storeID
                        if (!isCategory) {
                            set(this, 'storeId', fid);
                        }
                        return this.send('filter', get(this, 'serverQueryParams'));
                    }
                }
            },
            removeFilter: function removeFilter(model) {
                if (model) {
                    var filterName = get(this, 'nameFilterTypeSelected.id');
                    var filters = get(this, 'filters');
                    var isCategory = filterName === 'byCategory';

                    filters.removeObject(model);
                    if (!isCategory) {
                        set(this, 'storeId', null);
                    }
                    return this.send('filter', get(this, 'serverQueryParams'));
                }
            },

            //pagination
            nextDate: function nextDate() {
                var date = get(this, 'dateShown');
                date.add(1, 'day');
                set(this, 'dateShown', date.clone());
                this.send('filter', get(this, 'serverQueryParams'));
            },
            previousDate: function previousDate() {
                var date = get(this, 'dateShown');
                date.subtract(1, 'day');
                set(this, 'dateShown', date.clone());
                this.send('filter', get(this, 'serverQueryParams'));
            }
        }
    });
});