define('retro-game-store/pods/stores/store/labels/label-option-modal/controller', ['exports', 'ember'], function (exports, _ember) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var empty = _ember['default'].computed.empty;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Controller.extend({
        storeLabelsController: controller('stores/store/labels'),
        categories: service(),

        showCategoryDropdown: empty('model.productTypeId'),
        categoryFilters: A(),
        productTypeFilters: A(),
        fullCategory: computed('model.productType.name', 'model.category.name', function () {
            if (isEmpty(get(this, 'model.productType.name'))) {
                return null;
            } else {
                var s = get(this, 'model.productType.name');
                if (!isEmpty(get(this, 'model.category.name'))) {
                    s += ' / ' + get(this, 'model.category.name');
                }
                return s;
            }
        }),
        categoryAdded: function categoryAdded(item) {
            var productType = get(this, 'store').peekRecord('product-type', get(item, 'productTypeId'));
            var category = get(this, 'store').peekRecord('product-category', get(item, 'id'));
            if (productType && category) {
                // check if category already exists in labelOptions; if so, swap the model
                var existingLabelOption = get(this, 'labelOptions').findBy('categoryId', get(category, 'id'));

                if (existingLabelOption) {
                    get(this, 'model').destroyRecord();
                    set(this, 'model', existingLabelOption);
                } else {
                    setProperties(get(this, 'model'), {
                        productTypeId: get(productType, 'id'),
                        productType: productType,
                        categoryId: get(category, 'id'),
                        category: category
                    });

                    this.updateConditions(parseInt(get(productType, 'id'), 10));
                }
            }
        },
        productTypeAdded: function productTypeAdded(item) {
            var productType = get(this, 'store').peekRecord('product-type', get(item, 'id'));
            setProperties(get(this, 'model'), {
                productTypeId: get(productType, 'id'),
                productType: productType,
                categoryId: null,
                category: null
            });

            this.updateConditions(get(item, 'id'));
        },
        updateConditions: function updateConditions(productTypeId) {
            var productType = get(this, 'productTypes').findBy('id', productTypeId);
            var labelOptionConditions = A();
            get(productType, 'conditions').forEach(function (condition) {
                labelOptionConditions.addObject({
                    optionId: null,
                    condition: condition,
                    printBoxLabel: false,
                    printMediaLabel: false
                });
            });
            set(this, 'model.conditions', labelOptionConditions);
        },
        reset: function reset() {
            get(this, 'model').deleteRecord();
            set(this, 'originalModel', get(this, 'model'));
            setProperties(this, {
                model: this.store.createRecord('store-label-option', { storeId: get(this, 'model.storeId') }),
                categoryFilters: A(),
                productTypeFilters: A()
            });
        },
        rollbackModel: function rollbackModel() {
            if (!get(this, 'model.isNew')) {
                get(this, 'model').rollbackAttributes();
                set(this, 'model.conditions', get(this, 'originalConditions'));
            } else if (get(this, 'originalModel')) {
                get(this, 'originalModel').rollbackAttributes();
                set(this, 'model', get(this, 'originalModel'));
            }
        },
        closeModal: function closeModal() {
            this.rollbackModel();
            setProperties(this, {
                categoryFilters: A(),
                productTypeFilters: A(),
                originalModel: null,
                originalConditions: null
            });
            this.send('closeModal');
        },
        saveAndClose: function saveAndClose() {
            get(this, 'storeLabelsController.labelOptions').addObject(get(this, 'model'));
            setProperties(this, {
                categoryFilters: A(),
                productTypeFilters: A(),
                originalModel: null,
                originalConditions: null
            });
            this.send('closeModal');
        },
        actions: {
            itemAdded: function itemAdded(items, item) {
                this.categoryAdded(item);
            },
            itemTypeAdded: function itemTypeAdded(items, item) {
                this.productTypeAdded(item);
            },
            itemRemoved: function itemRemoved() {
                set(this, 'model.category', null);
            },
            itemTypeRemoved: function itemTypeRemoved() {
                set(this, 'model.productType', null);
            },
            reset: function reset() {
                this.reset();
            },
            saveAndClose: function saveAndClose() {
                this.saveAndClose();
            },
            cancel: function cancel() {
                this.closeModal();
            }
        }
    });
});