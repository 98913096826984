define('retro-game-store/pods/components/report-table/component', ['exports', 'ember', 'ember-light-table'], function (exports, _ember, _emberLightTable) {
    var isEmpty = _ember['default'].isEmpty;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Component.extend({
        dir: 'asc',
        sort: null,
        table: null,
        isLoading: false,
        canLoadMore: true,
        columns: null,
        totals: null,
        columnsDidChange: _ember['default'].observer('columns.[]', function () {
            get(this, 'table').setColumns(get(this, 'columns'));
        }),

        onModelsChange: _ember['default'].observer('models', function () {
            get(this, 'table').setRows(get(this, 'models'));
            set(this, 'prevSelectedRowIndex', -1);
        }),

        init: function init() {
            this._super.apply(this, arguments);
            set(this, 'table', new _emberLightTable['default'](this.columns, this.models));
        },

        fetchRecords: function fetchRecords() {
            var _this = this;

            set(this, 'isLoading', true);
            this.attrs['fetch-records']().then(function (records) {
                if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                    var results = records.toArray();
                    _this.table.addRows(results);
                    setProperties(_this, {
                        isLoading: false,
                        canLoadMore: !isEmpty(results)
                    });
                }
            });
        },

        actions: {
            onScrolledToBottom: function onScrolledToBottom() {
                if (get(this, 'canLoadMore')) {
                    this.fetchRecords();
                }
            },
            onRowClick: function onRowClick(row) {
                this.attrs['row-clicked'](row.get('content'));
            }
        }
    });
});