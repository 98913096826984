define('retro-game-store/pods/components/abs-currency-input/component', ['exports', 'ember', 'ember-inputmask/components/input-mask'], function (exports, _ember, _emberInputmaskComponentsInputMask) {

    /**
     * `{{currency}}` component
     *  Displays an input that masks currency
     */

    exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
        value: null,
        mask: 'currency',
        setUnmaskedValue: _ember['default'].observer('value', function () {
            if (this.$().inputmask('unmaskedvalue')) {
                var absValue = Math.abs(this.$().inputmask('unmaskedvalue'));
                var value = this.get('value');
                var absValueString = value.replace('-', '');
                this.set('value', absValueString);
                this.set('unmaskedValue', absValue);
            } else {
                this.set('unmaskedValue', 0);
            }
        })
    });
});