define('retro-game-store/helpers/payment-type', ['exports', 'ember'], function (exports, _ember) {
    exports.paymentType = paymentType;

    function paymentType(params /*, hash*/) {
        var type = params[0].toLowerCase();
        if (type === 'cash') {
            return 'Cash';
        } else if (type === 'credit') {
            return 'Credit Card';
        } else if (type === 'storecredit') {
            return 'Customer Credit';
        } else if (type === 'giftcard') {
            return 'Gift Card';
        } else if (type === 'external') {
            return 'External';
        }
        return params;
    }

    exports['default'] = _ember['default'].Helper.helper(paymentType);
});