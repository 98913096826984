define('retro-game-store/pods/components/keyboard-list-item/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'li',
        classNameBindings: ['isActive'],
        ariaRole: 'option',
        classNames: ['keyboard-navigation-item'],
        attributeBindings: ['tabindex', 'aria-current'],
        'aria-current': (function () {
            return this.get('isActive') + '';
        }).property('isActive'),
        isActive: _ember['default'].computed('activeItem', 'item', function () {
            var _this = this;

            var active = this.get('activeItem') === this.get('item');
            if (active) {
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    _this.get('element').focus();
                });
            }
            return active;
        }),
        keyDown: function keyDown(event) {
            if (this.get('isActive') && event.keyCode === 13) {
                event.stopPropagation();
                this.sendAction('enterTarget');
            }
        }
    });
});