define('retro-game-store/pods/components/file-input/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var on = _ember['default'].on;
    var get = _ember['default'].get;
    var isArray = _ember['default'].isArray;
    var tryInvoke = _ember['default'].tryInvoke;
    exports['default'] = Component.extend({
        tagName: 'input',
        classNames: ['file-input'],
        attributeBindings: ['type', 'accept'],
        type: 'file',
        accept: null,

        onInputChange: on('change', function (event) {
            var files = get(event, 'currentTarget.files');
            var file = isArray(files) ? files[0] : null;
            tryInvoke(this.attrs, 'onChange', [file]);
        })
    });
});