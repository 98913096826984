define('retro-game-store/pods/affiliates/index/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        api: _ember['default'].inject.service(),
        limit: 10,
        serverQueryParams: ['status', 'keywords'],
        statusFilterOptions: ['Active', 'Inactive', 'All'],
        status: 'Active',
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(this.get('serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filter(this.get('serverQueryParams'));
                callback(promise);
            },
            sendEmail: function sendEmail(callback, affiliateId) {
                var promise = this.get('api').request('POST', '/affiliates/send-welcome-email', affiliateId);
                callback(promise);
            }
        }
    });
});