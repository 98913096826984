define('retro-game-store/pods/user/model', ['exports', 'ember-data', 'ember-awesome-macros/tag'], function (exports, _emberData, _emberAwesomeMacrosTag) {
    var _templateObject = _taggedTemplateLiteral(['', ' ', ''], ['', ' ', '']);

    function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        affiliate: belongsTo('affiliate'),
        affiliateId: attr('number'),
        email: attr('string'),
        emailConfirmed: attr('boolean'),
        employeeId: attr('number'),
        phoneNumber: attr('string'),
        firstName: attr('string'),
        lastName: attr('string'),
        password: attr('string'),
        passwordConfirmation: attr('string'),
        roles: attr(),
        storeInfo: belongsTo('store'),
        userName: attr('string'),
        name: (0, _emberAwesomeMacrosTag['default'])(_templateObject, 'firstName', 'lastName')
    });
});