define('retro-game-store/pods/affiliates/new/route', ['exports', 'ember', 'retro-game-store/mixins/title-bar', 'ember-data-route'], function (exports, _ember, _retroGameStoreMixinsTitleBar, _emberDataRoute) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsTitleBar['default'], _emberDataRoute['default'], {
        model: function model() {
            var affiliate = this.store.createRecord('affiliate', {
                creditCardApiType: 0
            });
            affiliate.set('creditCard', this.store.createFragment('creditCard'));
            return affiliate;
        }
    });
});