define('retro-game-store/pods/stores/store/details/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        validations: {
            'model.name': {
                presence: true
            },
            'model.address': {
                presence: true
            },
            'model.city': {
                presence: true
            },
            'model.state': {
                presence: true
            },
            'model.zip': {
                presence: true
            },
            'model.phoneNumber': {
                phone: true
            },
            'model.defaultTax': {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            },
            'model.defaultShipping': {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            }
        },
        actions: {
            save: function save(callback) {
                var promise = get(this, 'model').save();
                callback(promise);
            }
        }
    });
});