define('retro-game-store/pods/inventory/transfer/controller', ['exports', 'ember', 'retro-game-store/mixins/scanner-event-listener'], function (exports, _ember, _retroGameStoreMixinsScannerEventListener) {
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var gt = _Ember$computed.gt;
    var empty = _Ember$computed.empty;
    var mapBy = _Ember$computed.mapBy;
    var sum = _Ember$computed.sum;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Controller.extend(_retroGameStoreMixinsScannerEventListener['default'], {
        fromStoreId: alias('session.user.storeInfo.id'),
        toStore: null,
        toStoreId: alias('toStore.id'),
        isDisabled: computed('toStoreId', 'inventoryItems.@each', function () {
            return !(get(this, 'toStoreId') && get(this, 'inventoryItems.length'));
        }),
        isSpinning: false,
        inventoryItems: _ember['default'].A(),
        transferQuantities: mapBy('inventoryItems', 'transferQuantity'),
        itemsCount: sum('transferQuantities'),
        itemsExist: gt('itemsCount', 0),
        itemsSelected: computed('itemsCount', function () {
            return get(this, 'itemsCount') === 1 ? 'inventory.transfer.itemsSelectedOne' : 'inventory.transfer.itemsSelectedMore';
        }),
        modalTitle: 'Enter SKU manually',
        manualButtonDisabled: empty('manualSku'),
        manualButtonIsSpinning: false,
        manualSku: null,

        itemExists: function itemExists(sku) {
            return get(this, 'inventoryItems').findBy('stockNumber', sku);
        },

        softReset: function softReset() {
            setProperties(this, {
                showTransferSuccessMessage: false,
                showTransferErrorMessage: false,
                showZeroQuantityError: false,
                showMaxQuantityError: false,
                showItemDoesNotExistError: false
            });
        },

        hardReset: function hardReset() {
            this.softReset();
            setProperties(this, {
                toStore: null,
                manualSku: null
            });
            get(this, 'inventoryItems').clear();
        },

        addInventoryItem: function addInventoryItem(sku) {
            var _this = this;

            this.softReset();

            this.store.query('inventory', { keywords: sku, storeId: get(this, 'fromStoreId'), isBarcode: true }).then(function (result) {
                var item = result.objectAt(0);

                if (item) {
                    var existingItem = _this.itemExists(get(item, 'stockNumber'));
                    if (existingItem) {
                        if (get(existingItem, 'maxTransferQuantityMet')) {
                            set(_this, 'showMaxQuantityError', true);
                        } else {
                            var transferQuantity = get(existingItem, 'transferQuantity');
                            set(existingItem, 'transferQuantity', transferQuantity + 1);
                        }
                    } else if (get(item, 'quantity') === 0) {
                        setProperties(_this, {
                            showZeroQuantityError: true,
                            manualButtonIsSpinning: false,
                            manualSku: null
                        });
                    } else {
                        set(item, 'transferQuantity', 1);
                        get(_this, 'inventoryItems').addObject(item);
                    }
                } else {
                    set(_this, 'showItemDoesNotExistError', true);
                }

                setProperties(_this, {
                    manualButtonIsSpinning: false,
                    manualSku: null
                });
            });
        },

        deleteAll: function deleteAll() {
            get(this, 'inventoryItems').clear();
        },

        transfer: function transfer() {
            var _this2 = this;

            this.softReset();

            set(this, 'isSpinning', true);

            var transferItems = get(this, 'inventoryItems').map(function (item) {
                return { inventoryItemId: get(item, 'id'), quantity: get(item, 'transferQuantity') };
            });

            var fromStoreId = get(this, 'fromStoreId');
            var toStoreId = get(this, 'toStoreId');

            get(this, 'api').request('POST', '/transfer', { fromStoreId: fromStoreId, toStoreId: toStoreId, transferItems: transferItems }).then(function () {
                _this2.deleteAll();
                setProperties(_this2, {
                    isSpinning: false,
                    showTransferSuccessMessage: true
                });
            })['catch'](function () {
                setProperties(_this2, {
                    isSpinning: false,
                    showTransferErrorMessage: true
                });
            });
        },

        actions: {
            scanSuccess: function scanSuccess(sku) {
                this.addInventoryItem(sku);
            },

            handleManualSku: function handleManualSku() {
                set(this, 'manualButtonIsSpinning', true);
                this.addInventoryItem(get(this, 'manualSku'));
            },

            'delete': function _delete(inventoryItem) {
                get(this, 'inventoryItems').removeObject(inventoryItem);
            },

            transfer: function transfer() {
                this.transfer();
            }
        }
    });
});