define('retro-game-store/pods/inventory/summary/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        api: service(),
        storesController: controller('inventory.summary.stores'),
        categoriesController: controller('inventory.summary.categories'),
        categoryFilters: A(),
        productTypeFilters: A(),
        graphType: 'count',
        graphTypes: ['count', 'price', 'cost'],
        totals: computed('summaryGraphData.[]', function () {
            var totals = {
                count: 0,
                price: 0,
                cost: 0
            };
            get(this, 'summaryGraphData').forEach(function (item) {
                totals.count = totals.count + item.count;
                totals.price = totals.price + item.price;
                totals.cost = totals.cost + item.cost;
            });
            return totals;
        }),
        modifyGraph: function modifyGraph() {
            var _this = this;

            var promise = get(this, 'api').request('GET', '/inventorySummary/chart', {
                categoryIds: get(this, 'categoryFilters').mapBy('id'),
                productTypeIds: get(this, 'productTypeFilters').mapBy('id'),
                storeId: get(this, 'storeFilter') ? get(this, 'storeFilter.id') : null
            });
            promise.then(function (data) {
                set(_this, 'summaryGraphData', data);
            });
        },
        storesActive: alias('storesController.isActive'),
        actions: {
            changeGraph: function changeGraph() {
                set(this, 'summaryGraphData', get(this, 'summaryGraphData1'));
            },
            filter: function filter(callback) {
                this.modifyGraph();
                if (get(this, 'storesActive')) {
                    get(this, 'storesController').send('filter');
                } else {
                    get(this, 'categoriesController').send('filter');
                }
                callback();
            }
        }
    });
});