define('retro-game-store/pods/qz/service', ['exports', 'ember', 'retro-game-store/config/environment', 'retro-game-store/utils/print-commands'], function (exports, _ember, _retroGameStoreConfigEnvironment, _retroGameStoreUtilsPrintCommands) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    var isEmpty = _ember['default'].isEmpty;
    var Service = _ember['default'].Service;
    var RSVP = _ember['default'].RSVP;
    var service = _ember['default'].inject.service;
    var bool = _ember['default'].computed.bool;
    var _Ember$run = _ember['default'].run;
    var later = _Ember$run.later;
    var cancel = _Ember$run.cancel;
    exports['default'] = Service.extend({
        session: service(),

        connectRetryCount: 2,
        connectRetryDelay: 1,
        reconnectPollInterval: 20000,
        reconnectTimer: null,
        connectionPromise: null,
        receiptPrinterIsConnected: false,
        labelPrinterIsConnected: false,
        isConfigured: false,
        isConnected: false,

        isConnecting: bool('connectionPromise'),

        startup: function startup() {
            var _this = this;

            return this.connect().then(function () {
                return _this.listenForPrinterStatusEvents();
            });
        },

        configure: function configure() {
            var _this2 = this;

            if (get(this, 'isConfigured')) {
                return;
            }

            qz.api.setPromiseType(function (resolver) {
                return new RSVP.Promise(resolver);
            });

            qz.api.setSha256Type(function (message) {
                return sha256(message);
            });

            //cert signing config
            qz.security.setSignatureAlgorithm('SHA1');

            qz.security.setCertificatePromise(function (resolve, reject) {
                _ember['default'].$.ajax('/qz/qz-certificate.txt', {
                    type: 'GET',
                    contentType: 'text/plain; charset=utf-8',
                    dataType: 'text',
                    cache: false,
                    success: function success(response) {
                        return resolve(response);
                    },
                    error: function error(reason) {
                        return reject(reason);
                    }
                });
            });

            qz.security.setSignaturePromise(function (request) {
                return function (resolve, reject) {
                    _ember['default'].$.ajax(_retroGameStoreConfigEnvironment['default'].RESTAPI + '/api/qz-tray/sign', {
                        type: 'POST',
                        contentType: 'application/json; charset=utf-8',
                        dataType: 'json',
                        cache: false,
                        data: JSON.stringify(request),
                        success: function success(response) {
                            return resolve(response);
                        },
                        error: function error(reason) {
                            return reject(reason);
                        }
                    });
                };
            });

            qz.printers.setPrinterCallbacks(function (event) {
                return _this2.updatePrinterStatuses(event);
            });
            qz.websocket.setClosedCallbacks(function () {
                return _this2.handleConnectionClosed();
            });
            qz.websocket.setErrorCallbacks(function () {
                return _this2.handleConnectionClosed();
            });
            set(this, 'isConfigured', true);
        },

        connect: function connect() {
            var _this3 = this;

            this.configure();

            if (qz.websocket.isActive()) {
                return RSVP.resolve();
            }

            //a connection attempt is already in progress, so return its promise instead
            if (get(this, 'connectionPromise')) {
                return get(this, 'connectionPromise');
            }

            try {
                //clear connected printer statuses in case this is a reconnect
                this.resetConnectionStatuses();

                var connPromise = new RSVP.Promise(function (resolve, reject) {
                    var promise = qz.websocket.connect({
                        retries: get(_this3, 'connectRetryCount'),
                        delay: get(_this3, 'connectRetryDelay')
                    });

                    promise.then(function () {
                        console.debug('qz tray connected!');
                        cancel(get(_this3, 'reconnectTimer'));
                        setProperties(_this3, {
                            isConnected: true,
                            connectionPromise: null,
                            reconnectPollInterval: null
                        });
                        return resolve();
                    });

                    promise['catch'](function (err) {
                        console.debug('qz tray connect error', err);
                        setProperties(_this3, {
                            isConnected: false,
                            connectionPromise: null
                        });
                        cancel(get(_this3, 'reconnectTimer'));
                        _this3.resetConnectionStatuses();
                        _this3.pollForReconnect();
                        return reject(err);
                    });
                });

                set(this, 'connectionPromise', connPromise);
                return connPromise;
            } catch (err) {
                console.debug('qz tray connect error', err);
                set(this, 'connectionPromise', null);
                return RSVP.reject(err);
            }
        },

        handleConnectionClosed: function handleConnectionClosed() {
            console.debug('qz tray connection lost');
            cancel(get(this, 'reconnectTimer'));
            this.resetConnectionStatuses();
            this.pollForReconnect();
        },

        resetConnectionStatuses: function resetConnectionStatuses() {
            setProperties(this, {
                isConnected: false,
                receiptPrinterIsConnected: false,
                labelPrinterIsConnected: false
            });
        },

        pollForReconnect: function pollForReconnect() {
            if (!get(this, 'isConnected') && !get(this, 'isConnecting')) {
                console.debug('qz tray attempting reconnection...');
                this.startup();
                var timer = later(this, 'pollForReconnect', get(this, 'reconnectPollInterval'));
                set(this, 'reconnectTimer', timer);
            }
        },

        findPrinters: function findPrinters() {
            var _this4 = this;

            return new RSVP.Promise(function (resolve, reject) {
                return _this4.connect().then(function () {
                    var promise = qz.printers.find();

                    promise.then(function (data) {
                        return _this4.listenForPrinterStatusEvents().then(function () {
                            return resolve(data);
                        })['catch'](function (err) {
                            return reject(err);
                        });
                    });

                    promise['catch'](function (err) {
                        console.debug('qz tray find printers error', err);
                        return reject(err);
                    });
                })['catch'](function (err) {
                    return reject(err);
                });
            });
        },

        listenForPrinterStatusEvents: function listenForPrinterStatusEvents() {
            return this.connect().then(function () {
                return qz.printers.stopListening();
            }).then(function () {
                return console.debug('qz tray stopped listening for printer events');
            }).then(function () {
                return qz.printers.startListening();
            }).then(function () {
                return console.debug('qz tray started listening to printer events...');
            }).then(function () {
                return qz.printers.getStatus();
            }).then(function () {
                return console.debug('qz tray get printer statuses success');
            })['catch'](function (err) {
                console.debug('qz tray listen for printer events error', err);
                return RSVP.reject(err);
            });
        },

        updatePrinterStatuses: function updatePrinterStatuses(event) {
            var receiptPrinterName = get(this, 'session.data.receiptPrinterId');
            var labelPrinterName = get(this, 'session.data.labelPrinterId');
            //the event's printer name seems to replace non-word characters with underscores in some environments
            var cleanedReceiptPrinterName = receiptPrinterName && receiptPrinterName.replace(/\W/g, '_');
            var cleanedLabelPrinterName = labelPrinterName && labelPrinterName.replace(/\W/g, '_');

            if (event.type === 'ACTION') {
                var isConnected = event.statusText !== 'OFFLINE' && event.statusText !== 'NOT_AVAILABLE';
                if (event.printerName === receiptPrinterName || event.printerName === cleanedReceiptPrinterName) {
                    set(this, 'receiptPrinterIsConnected', isConnected);
                }

                if (event.printerName === labelPrinterName || event.printerName === cleanedLabelPrinterName) {
                    set(this, 'labelPrinterIsConnected', isConnected);
                }
            }

            //if saved printers were cleared, they are not connected
            if (!receiptPrinterName) {
                set(this, 'receiptPrinterIsConnected', false);
            }

            if (!labelPrinterName) {
                set(this, 'labelPrinterIsConnected', false);
            }
        },

        print: function print(printerId, data, configOverrides) {
            return this.connect().then(function () {
                return qz.printers.find(printerId);
            }).then(function (printer) {
                return qz.configs.create(printer, configOverrides);
            }).then(function (config) {
                return qz.print(config, data);
            }).then(function () {
                return console.debug('print job sent to "' + printerId + '"');
            })['catch'](function (err) {
                console.debug('qz tray print error for "' + printerId + '"', err, data);
                return RSVP.reject(err);
            });
        },

        printToReceiptPrinter: function printToReceiptPrinter(data) {
            var configOverrides = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

            var printerId = get(this, 'session.data.receiptPrinterId');

            if (isEmpty(printerId)) {
                return RSVP.reject();
            }

            return this.print(printerId, data, configOverrides);
        },

        printToLabelPrinter: function printToLabelPrinter(data) {
            var configOverrides = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

            var printerId = get(this, 'session.data.labelPrinterId');

            if (isEmpty(printerId)) {
                return RSVP.reject();
            }

            return this.print(printerId, data, configOverrides);
        },

        openCashDrawer: function openCashDrawer() {
            var _generateOpenCashDrawerCommands = (0, _retroGameStoreUtilsPrintCommands.generateOpenCashDrawerCommands)();

            var _generateOpenCashDrawerCommands2 = _slicedToArray(_generateOpenCashDrawerCommands, 2);

            var commands = _generateOpenCashDrawerCommands2[0];
            var configOverrides = _generateOpenCashDrawerCommands2[1];

            return this.printToReceiptPrinter(commands, configOverrides);
        }
    });
});