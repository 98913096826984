define('retro-game-store/pods/components/link-to-popover/component', ['exports', 'ember', 'retro-game-store/mixins/popover-handler'], function (exports, _ember, _retroGameStoreMixinsPopoverHandler) {
    exports['default'] = _ember['default'].Component.extend(_retroGameStoreMixinsPopoverHandler['default'], {
        classNames: ['save-confirmation-button', 'confirmation-container'],
        placement: 'top',
        click: function click(event) {
            event.preventDefault();

            var element = this.get('element');
            var placement = this.get('placement');

            this.set('popover', $(element).popover({
                container: 'body', // popover will be rendered inside body to prevent clipping by parent elements
                placement: placement || 'top auto',
                html: true,
                content: $(element).find('.popover').html()
            }));

            this.get('popover').popover('show');
            this.listenForClicksOutside();
        }
    });
});