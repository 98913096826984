define('retro-game-store/pods/product-sku-child/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        conditionId: attr('number'),
        isFoil: attr('boolean'),
        languageId: attr('number'),
        languageStr: attr('string'),
        name: attr('string'),
        sourceId: attr('number'),

        //Relationships
        condition: belongsTo('condition'),
        inventoryItems: hasMany('inventory'),
        language: belongsTo('product-sku-language')
    });
});