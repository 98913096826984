define('retro-game-store/pods/pricing/index/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    exports['default'] = Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        i18n: service(),
        categories: service(),

        model: function model(params) {
            var controller = this.controllerFor('pricing.index');
            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQuery('product-price', queryParams, 'pricing.index');
        },

        setupController: function setupController(controller) {
            set(controller, 'productTypes', get(this, 'categories.raw'));

            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });

            this._super.apply(this, arguments);
        }
    });
});