define('retro-game-store/mixins/title-bar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        renderTemplate: function renderTemplate() {
            this.render(this.routeName + '.title-bar', { // the template to render
                into: 'application', // the template to render into
                outlet: 'title-bar', // the name of the outlet in that template
                controller: this.controller // the controller to use for the template
            });
            this._super();
        }
    });
});