define('retro-game-store/pods/sale/ability', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
    exports['default'] = _emberCan.Ability.extend({
        access: _ember['default'].computed('session.user.storeInfo', function () {
            var store = null;
            if (this.get('session').get('user')) {
                store = this.get('session').get('user').get('storeInfo').content;
            }
            return !_ember['default'].isEmpty(store);
        })
    });
});