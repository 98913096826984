define('retro-game-store/pods/components/category-dropdown-single/delete-item/component', ['exports', 'ember'], function (exports, _ember) {
    var tryInvoke = _ember['default'].tryInvoke;
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            clear: function clear(event) {
                event.stopPropagation();
                tryInvoke(this.attrs, 'clear');
                return false;
            }
        }
    });
});