define('retro-game-store/pods/inventory/history/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations) {
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var get = _ember['default'].get;
    var Promise = _ember['default'].RSVP.Promise;
    var debounce = _ember['default'].run.debounce;
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        _typeKey: 'inventory-history',
        inventoryHistories: alias('model'),
        serverQueryParams: ['productId', 'storeId', 'sortby', 'sortdirection'],
        sortby: 'date',
        sortdirection: 'desc',
        storeId: computed('storeSelected', function () {
            if (!get(this, 'storeSelected')) {
                return get(this, 'session.user.storeInfo.id');
            } else {
                return get(this, 'storeSelected.id') || null;
            }
        }),

        productId: computed('productSelected', function () {
            if (get(this, 'productSelected')) {
                return get(this, 'productSelected.id');
            } else {
                return null;
            }
        }),

        performSearch: function performSearch(term, resolve, reject) {
            return this.store.query('product', {
                keywords: term
            }).then(function (result) {
                return resolve(result);
            }, reject);
        },

        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(this.get('serverQueryParams'));
                callback(promise);
            },

            filter: function filter(callback) {
                var promise = this.filter(this.get('serverQueryParams'));
                callback(promise);
            },

            searchForProducts: function searchForProducts(term) {
                var _this = this;

                return new Promise(function (resolve, reject) {
                    debounce(_this, 'performSearch', term, resolve, reject, 500);
                });
            }
        }
    });
});