define('retro-game-store/mixins/save-blob', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        saveBlobAs: function saveBlobAs(fileContents, fileName) {
            var mimeType = arguments.length <= 2 || arguments[2] === undefined ? 'text/plain;charset=utf-8' : arguments[2];

            // @see Blob.js in ember-cli-build
            var blob = new Blob([fileContents], {
                type: mimeType
            });

            // @see FileSaver in ember-cli-build
            saveAs(blob, fileName);
        }
    });
});