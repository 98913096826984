define("retro-game-store/pods/components/sale-totals/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 20
              },
              "end": {
                "line": 31,
                "column": 20
              }
            },
            "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "text-brand-primary edit-discount");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element6);
            morphs[1] = dom.createMorphAt(element6, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["editDiscount", ["get", "sale", ["loc", [null, [30, 51], [30, 55]]]]], [], ["loc", [null, [30, 27], [30, 57]]]], ["inline", "format-money", [["get", "sale.discountTotal", ["loc", [null, [30, 114], [30, 132]]]]], [], ["loc", [null, [30, 99], [30, 134]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 20
              },
              "end": {
                "line": 33,
                "column": 20
              }
            },
            "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "format-money", [["get", "sale.discountTotal", ["loc", [null, [32, 39], [32, 57]]]]], [], ["loc", [null, [32, 24], [32, 59]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 8
            },
            "end": {
              "line": 36,
              "column": 8
            }
          },
          "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pull-left");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pull-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element7, [1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["sales.details.discount"], [], ["loc", [null, [26, 26], [26, 56]]]], ["block", "if", [["subexpr", "and", [["get", "editDiscount", ["loc", [null, [29, 31], [29, 43]]]], ["subexpr", "and", [["subexpr", "not", [["get", "sale.isClosed", ["loc", [null, [29, 54], [29, 67]]]]], [], ["loc", [null, [29, 49], [29, 68]]]], ["subexpr", "not", [["get", "sale.isReturn", ["loc", [null, [29, 74], [29, 87]]]]], [], ["loc", [null, [29, 69], [29, 88]]]]], [], ["loc", [null, [29, 44], [29, 89]]]]], [], ["loc", [null, [29, 26], [29, 90]]]]], [], 0, 1, ["loc", [null, [29, 20], [33, 27]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 20
              },
              "end": {
                "line": 45,
                "column": 20
              }
            },
            "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "text-brand-primary");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element4);
            morphs[1] = dom.createMorphAt(element4, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["editShipping", ["get", "sale", ["loc", [null, [44, 51], [44, 55]]]]], [], ["loc", [null, [44, 27], [44, 57]]]], ["inline", "format-money", [["get", "sale.shippingAmount", ["loc", [null, [44, 100], [44, 119]]]]], [], ["loc", [null, [44, 85], [44, 121]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 20
              },
              "end": {
                "line": 47,
                "column": 20
              }
            },
            "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "format-money", [["get", "sale.shippingAmount", ["loc", [null, [46, 39], [46, 58]]]]], [], ["loc", [null, [46, 24], [46, 60]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 8
            },
            "end": {
              "line": 50,
              "column": 8
            }
          },
          "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pull-left");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pull-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["sales.details.shipping"], [], ["loc", [null, [40, 20], [40, 50]]]], ["block", "if", [["subexpr", "and", [["get", "editShipping", ["loc", [null, [43, 31], [43, 43]]]], ["subexpr", "not", [["get", "sale.isClosed", ["loc", [null, [43, 49], [43, 62]]]]], [], ["loc", [null, [43, 44], [43, 63]]]]], [], ["loc", [null, [43, 26], [43, 64]]]]], [], 0, 1, ["loc", [null, [43, 20], [47, 27]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 20
              },
              "end": {
                "line": 60,
                "column": 20
              }
            },
            "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "pl-10");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element1, 1, 1);
            morphs[1] = dom.createMorphAt(element1, 3, 3);
            return morphs;
          },
          statements: [["inline", "input", [], ["type", "checkbox", "name", "noTax", "checked", ["subexpr", "@mut", [["get", "noTax", ["loc", [null, [57, 73], [57, 78]]]]], [], []], "change", ["subexpr", "action", ["toggleNoTax", ["get", "sale", ["loc", [null, [57, 108], [57, 112]]]]], [], ["loc", [null, [57, 86], [57, 113]]]]], ["loc", [null, [57, 28], [57, 115]]]], ["inline", "t", ["sales.details.noTax"], [], ["loc", [null, [58, 28], [58, 55]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 20
              },
              "end": {
                "line": 65,
                "column": 20
              }
            },
            "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "text-brand-primary");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["editTax", ["get", "sale", ["loc", [null, [64, 46], [64, 50]]]]], [], ["loc", [null, [64, 27], [64, 52]]]], ["inline", "format-money", [["get", "sale.taxAmount", ["loc", [null, [64, 95], [64, 109]]]]], [], ["loc", [null, [64, 80], [64, 111]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 20
              },
              "end": {
                "line": 67,
                "column": 20
              }
            },
            "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "format-money", [["get", "sale.taxAmount", ["loc", [null, [66, 39], [66, 53]]]]], [], ["loc", [null, [66, 24], [66, 55]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 8
            },
            "end": {
              "line": 70,
              "column": 8
            }
          },
          "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pull-left col-md-6 p-0");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("%)\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pull-right");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createMorphAt(element3, 3, 3);
          morphs[2] = dom.createMorphAt(element3, 5, 5);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["sales.details.tax"], [], ["loc", [null, [54, 20], [54, 45]]]], ["content", "sale.taxPercent", ["loc", [null, [54, 47], [54, 66]]]], ["block", "if", [["subexpr", "and", [["get", "editTax", ["loc", [null, [55, 31], [55, 38]]]], ["subexpr", "and", [["subexpr", "not", [["get", "sale.isReturn", ["loc", [null, [55, 49], [55, 62]]]]], [], ["loc", [null, [55, 44], [55, 63]]]], ["subexpr", "not", [["get", "sale.isClosed", ["loc", [null, [55, 69], [55, 82]]]]], [], ["loc", [null, [55, 64], [55, 83]]]]], [], ["loc", [null, [55, 39], [55, 84]]]]], [], ["loc", [null, [55, 26], [55, 85]]]]], [], 0, null, ["loc", [null, [55, 20], [60, 27]]]], ["block", "if", [["subexpr", "and", [["get", "editTax", ["loc", [null, [63, 31], [63, 38]]]], ["subexpr", "not", [["get", "noTax", ["loc", [null, [63, 44], [63, 49]]]]], [], ["loc", [null, [63, 39], [63, 50]]]], ["subexpr", "and", [["subexpr", "not", [["get", "sale.isReturn", ["loc", [null, [63, 61], [63, 74]]]]], [], ["loc", [null, [63, 56], [63, 75]]]], ["subexpr", "not", [["get", "sale.isClosed", ["loc", [null, [63, 81], [63, 94]]]]], [], ["loc", [null, [63, 76], [63, 95]]]]], [], ["loc", [null, [63, 51], [63, 96]]]]], [], ["loc", [null, [63, 26], [63, 97]]]]], [], 1, 2, ["loc", [null, [63, 20], [67, 27]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 0
          }
        },
        "moduleName": "retro-game-store/pods/components/sale-totals/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "sale-list");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "totals-list");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "class", "total");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-left");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-right");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0, 1]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element8, [3]);
        var element11 = dom.childAt(element8, [5]);
        var element12 = dom.childAt(element8, [7]);
        var element13 = dom.childAt(fragment, [2, 1]);
        var element14 = dom.childAt(element13, [5]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element10, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element11, [3]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element12, [3]), 0, 0);
        morphs[8] = dom.createMorphAt(element13, 1, 1);
        morphs[9] = dom.createMorphAt(element13, 2, 2);
        morphs[10] = dom.createMorphAt(element13, 3, 3);
        morphs[11] = dom.createMorphAt(dom.childAt(element14, [1]), 0, 0);
        morphs[12] = dom.createMorphAt(dom.childAt(element14, [3]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["sales.details.purchases"], [], ["loc", [null, [4, 35], [4, 66]]]], ["inline", "format-money", [["get", "sale.purchaseTotal", ["loc", [null, [5, 51], [5, 69]]]]], [], ["loc", [null, [5, 36], [5, 71]]]], ["inline", "t", ["sales.details.returns"], [], ["loc", [null, [8, 35], [8, 64]]]], ["inline", "format-money", [["get", "sale.returnTotal", ["loc", [null, [9, 51], [9, 67]]]]], [], ["loc", [null, [9, 36], [9, 69]]]], ["inline", "t", ["sales.details.trades"], [], ["loc", [null, [12, 35], [12, 63]]]], ["inline", "format-money", [["get", "sale.tradeTotal", ["loc", [null, [13, 51], [13, 66]]]]], [], ["loc", [null, [13, 36], [13, 68]]]], ["inline", "t", ["sales.details.retailValue"], [], ["loc", [null, [16, 35], [16, 68]]]], ["inline", "format-money", [["get", "sale.sellTotal", ["loc", [null, [17, 51], [17, 65]]]]], [], ["loc", [null, [17, 36], [17, 67]]]], ["block", "if", [["get", "discount", ["loc", [null, [23, 14], [23, 22]]]]], [], 0, null, ["loc", [null, [23, 8], [36, 15]]]], ["block", "if", [["get", "shipping", ["loc", [null, [37, 14], [37, 22]]]]], [], 1, null, ["loc", [null, [37, 8], [50, 15]]]], ["block", "if", [["get", "tax", ["loc", [null, [51, 14], [51, 17]]]]], [], 2, null, ["loc", [null, [51, 8], [70, 15]]]], ["inline", "t", ["sales.details.total"], [], ["loc", [null, [72, 35], [72, 62]]]], ["inline", "format-money", [["get", "sale.totalAmount", ["loc", [null, [73, 51], [73, 67]]]]], [], ["loc", [null, [73, 36], [73, 69]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});