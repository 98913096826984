define('retro-game-store/mixins/scroll-reset', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        activate: function activate() {
            this._super();
            this.resetScroll();
        },
        resetScroll: function resetScroll() {
            window.scrollTo(0, 0);
        }
    });
});