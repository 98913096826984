define('retro-game-store/pods/terminal/status', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Object.extend({
        status: computed('active', function () {
            if (get(this, 'active') !== null) {
                return get(this, 'active') ? 'on' : 'off';
            }
        }),
        active: null,
        message: null
    });
});