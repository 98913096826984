define('retro-game-store/pods/affiliate/model', ['exports', 'ember', 'ember-data', 'model-fragments', 'ember-awesome-macros/bool', 'ember-awesome-macros/conditional', 'ember-awesome-macros/raw'], function (exports, _ember, _emberData, _modelFragments, _emberAwesomeMacrosBool, _emberAwesomeMacrosConditional, _emberAwesomeMacrosRaw) {
    var computed = _ember['default'].computed;
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    var fragment = _modelFragments['default'].fragment;
    exports['default'] = Model.extend({
        name: attr('string'),
        urlFragment: attr('string'),
        authNetAPIKey: attr('string'),
        authNetLogin: attr('string'),
        creditCardApiType: attr('string'),
        contactFirstName: attr('string'),
        contactLastName: attr('string'),
        contactEmail: attr('string'),
        phoneNumber: attr('string'),
        creditCard: fragment('creditCard'),
        stripeAPIKey: attr('string'),
        easyPostAPIKey: attr('string'),
        maxStoreLimit: attr('number'),
        storeCount: attr('number'),
        subscriptionPlanId: attr('string'),
        subscriptionIsRenewing: attr('boolean'),
        subscriptionEndDate: attr('date'),
        useExternalPayments: attr('boolean'),
        cashDiscount: attr('percent', { defaultValue: 0 }),
        tradePercentage: attr('percent', { defaultValue: 0 }),

        //Relationships
        platformDiscounts: hasMany('affiliate/platform-discount'),

        //Computeds
        stripeActive: (0, _emberAwesomeMacrosBool['default'])('stripeAPIKey'),
        easyPostActive: (0, _emberAwesomeMacrosBool['default'])('easyPostAPIKey'),
        isSubscriptionActive: computed('subscriptionEndDate', function () {
            var now = new Date();
            if (this.get('subscriptionEndDate').toDate().getTime() <= now.getTime()) {
                return false;
            } else {
                return true;
            }
        }),
        subscriptionState: (0, _emberAwesomeMacrosConditional['default'])('isSubscriptionActive', (0, _emberAwesomeMacrosRaw['default'])('Active'), (0, _emberAwesomeMacrosRaw['default'])('Inactive')),
        url: computed('urlFragment', function () {
            return window.location.host + '/' + this.get('urlFragment');
        })
    });
});