define('retro-game-store/pods/session/service', ['exports', 'ember', 'ember-simple-auth/services/session'], function (exports, _ember, _emberSimpleAuthServicesSession) {
    exports['default'] = _emberSimpleAuthServicesSession['default'].extend({
        dataStore: _ember['default'].inject.service('store'),
        getCurrentUser: function getCurrentUser() {
            var _this = this;

            return this.get('dataStore').findRecord('user', 'me', { reload: true }).then(function (user) {
                _this.set('user', user);
                // This persists the user across storage
                _this.get('session').set('user', user.toJSON({ includeId: true }));
                if (user.get('storeInfo').content) {
                    _this.get('session').set('storeInfo', user.get('storeInfo').content.toJSON({ includeId: true }));
                } else {
                    _this.get('session').set('storeInfo', null);
                }
                return user;
            });
        },
        //This listens for any change in User ID of the local storage/cookie data, and updates the current user accordingly
        //We don't have to set the user here because we pass in an ID of me, which overwrites the currrent user
        sessionUserChanged: _ember['default'].observer('session.data.user.id', function () {
            if (this.get('session').get('user')) {
                var user = this.get('session').get('user');
                user.id = 'me';
                this.get('dataStore').pushPayload('user', { user: user });
            }
        }),
        //This listens for any change in Store ID of the local storage/cookie data, and updates the current store accordingly
        sessionStoreChanged: _ember['default'].observer('session.data.storeInfo.id', function () {
            if (this.get('user')) {
                var store = this.get('session').get('storeInfo');
                this.get('dataStore').pushPayload('store', { store: store });
                this.get('user').set('storeInfo', this.get('dataStore').peekRecord('store', store.id));
            }
        })

    });
});
// init() {
//     this._super(...arguments);
//     this.get('store').on('sessionDataUpdated', () => {
//         let user = this.get('session').get('user');
//         user.id = 'me';
//         this.get('dataStore').pushPayload('user', { user: user});
//
//         let store = this.get('session').get('storeInfo');
//         this.get('dataStore').pushPayload('store', { store: store});
//         this.get('user').set('storeInfo', this.get('dataStore').peekRecord('store', store.id));
//     });
// },