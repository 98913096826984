define('retro-game-store/pods/stores/store/labels/route', ['exports', 'ember', 'ember-data-route'], function (exports, _ember, _emberDataRoute) {
    var get = _ember['default'].get;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    exports['default'] = Route.extend(_emberDataRoute['default'], {
        afterModel: function afterModel(model) {
            var _this = this;

            this._super.apply(this, arguments);
            return this.store.query('store-label-option', { storeId: get(model, 'id') }).then(function (results) {
                set(_this, 'labelOptions', results.toArray()); // convert to array because we may be adding new items to array later; you can't add new objects to a RecordArray returned from a query
            })['catch'](function (err) {
                console.log(err.message);
            });
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            set(controller, 'labelOptions', get(this, 'labelOptions'));
        },
        resetController: function resetController(controller) {
            get(controller, 'model').rollbackAttributes();
            this._super.apply(this, arguments);
        }
    });
});