define("retro-game-store/pods/components/category-dropdown/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 8
                },
                "end": {
                  "line": 18,
                  "column": 8
                }
              },
              "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-control");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "loading active");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
              return morphs;
            },
            statements: [["inline", "t", ["loading.label"], [], ["loc", [null, [16, 53], [16, 74]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 12
                  },
                  "end": {
                    "line": 40,
                    "column": 12
                  }
                },
                "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "component", [["get", "deleteComponent", ["loc", [null, [35, 20], [35, 35]]]]], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [36, 26], [36, 31]]]]], [], []], "selectedItems", ["subexpr", "@mut", [["get", "allSelectedItems", ["loc", [null, [37, 34], [37, 50]]]]], [], []], "clear", ["subexpr", "action", ["clear"], [], ["loc", [null, [38, 26], [38, 42]]]]], ["loc", [null, [34, 16], [39, 18]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 8
                },
                "end": {
                  "line": 42,
                  "column": 8
                }
              },
              "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "ember-power-select-status-icon");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              return morphs;
            },
            statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [20, 22], [20, 27]]]]], [], []], "type", "text", "autocomplete", "off", "formnovalidate", "formnovalidate", "class", "category-dropdown-input", "placeholder", ["subexpr", "@mut", [["get", "placeholderText", ["loc", [null, [25, 28], [25, 43]]]]], [], []]], ["loc", [null, [19, 12], [26, 14]]]], ["inline", "component", [["get", "selectedItemComponent", ["loc", [null, [28, 16], [28, 37]]]]], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [29, 22], [29, 27]]]]], [], []], "selectedItems", ["subexpr", "@mut", [["get", "allSelectedItems", ["loc", [null, [30, 30], [30, 46]]]]], [], []], "clear", ["subexpr", "action", ["clear"], [], ["loc", [null, [31, 22], [31, 38]]]]], ["loc", [null, [27, 12], [32, 14]]]], ["block", "if", [["get", "allowClear", ["loc", [null, [33, 18], [33, 28]]]]], [], 0, null, ["loc", [null, [33, 12], [40, 19]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 4
              },
              "end": {
                "line": 43,
                "column": 4
              }
            },
            "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isLoading", ["loc", [null, [14, 14], [14, 23]]]]], [], 0, 1, ["loc", [null, [14, 8], [42, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 32
                  },
                  "end": {
                    "line": 56,
                    "column": 32
                  }
                },
                "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "category-dropdown-list-selected-items-item");
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ×\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element1);
                morphs[1] = dom.createMorphAt(element1, 1, 1);
                morphs[2] = dom.createMorphAt(element1, 3, 3);
                return morphs;
              },
              statements: [["element", "action", ["removeSelectedItem", ["get", "category", ["loc", [null, [52, 121], [52, 129]]]]], [], ["loc", [null, [52, 91], [52, 131]]]], ["content", "category.name", ["loc", [null, [53, 40], [53, 57]]]], ["inline", "category-dropdown/tooltip", [], ["text", ["subexpr", "@mut", [["get", "category.name", ["loc", [null, [54, 73], [54, 86]]]]], [], []]], ["loc", [null, [54, 40], [54, 88]]]]],
              locals: ["category"],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 16
                },
                "end": {
                  "line": 60,
                  "column": 16
                }
              },
              "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-12");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3, "class", "category-dropdown-list-selected-items");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "selectedParentsAndOrphans", ["loc", [null, [51, 40], [51, 65]]]]], [], 0, null, ["loc", [null, [51, 32], [56, 41]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 70,
                        "column": 52
                      },
                      "end": {
                        "line": 72,
                        "column": 52
                      }
                    },
                    "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "parentCategory.name", ["loc", [null, [71, 56], [71, 79]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 69,
                      "column": 48
                    },
                    "end": {
                      "line": 73,
                      "column": 48
                    }
                  },
                  "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "tablist.tab", [["get", "parentCategory.id", ["loc", [null, [70, 67], [70, 84]]]]], ["class", "list-group-item", "on-select", ["subexpr", "action", [["subexpr", "mut", [["get", "selection", ["loc", [null, [70, 132], [70, 141]]]]], [], ["loc", [null, [70, 127], [70, 142]]]]], [], ["loc", [null, [70, 119], [70, 143]]]], "tagName", "li"], 0, null, ["loc", [null, [70, 52], [72, 68]]]]],
                locals: ["parentCategory"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 67,
                    "column": 40
                  },
                  "end": {
                    "line": 75,
                    "column": 40
                  }
                },
                "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "role", "presentation");
                dom.setAttribute(el1, "class", "list-group elements-list");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "model", ["loc", [null, [69, 56], [69, 61]]]]], [], 0, null, ["loc", [null, [69, 48], [73, 57]]]]],
              locals: ["tablist"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 85,
                        "column": 44
                      },
                      "end": {
                        "line": 96,
                        "column": 44
                      }
                    },
                    "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "category.name", ["loc", [null, [94, 48], [94, 65]]]], ["inline", "category-dropdown/tooltip", [], ["text", ["subexpr", "@mut", [["get", "category.name", ["loc", [null, [95, 81], [95, 94]]]]], [], []]], ["loc", [null, [95, 48], [95, 96]]]]],
                  locals: ["category"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 84,
                      "column": 40
                    },
                    "end": {
                      "line": 97,
                      "column": 40
                    }
                  },
                  "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "category-dropdown/pane", [], ["parentCategory", ["subexpr", "@mut", [["get", "parentCategory", ["loc", [null, [86, 63], [86, 77]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [87, 54], [87, 59]]]]], [], []], "selection", ["subexpr", "@mut", [["get", "selection", ["loc", [null, [88, 58], [88, 67]]]]], [], []], "canAddParent", ["subexpr", "@mut", [["get", "canAddParent", ["loc", [null, [89, 61], [89, 73]]]]], [], []], "addParentItem", ["subexpr", "action", ["addParentItem"], [], ["loc", [null, [90, 62], [90, 86]]]], "addItem", ["subexpr", "action", ["addItem"], [], ["loc", [null, [91, 56], [91, 74]]]], "removeItem", ["subexpr", "action", ["removeItem"], [], ["loc", [null, [92, 59], [92, 80]]]], "filtered", ["subexpr", "action", ["filtered"], [], ["loc", [null, [93, 57], [93, 76]]]]], 0, null, ["loc", [null, [85, 44], [96, 71]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 83,
                    "column": 36
                  },
                  "end": {
                    "line": 98,
                    "column": 36
                  }
                },
                "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "tabs.tabpanel", [["get", "parentCategory.id", ["loc", [null, [84, 57], [84, 74]]]]], [], 0, null, ["loc", [null, [84, 40], [97, 58]]]]],
              locals: ["parentCategory"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 16
                },
                "end": {
                  "line": 103,
                  "column": 16
                }
              },
              "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "fh-breadcrumb");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "hidden-print");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "slimScrollDiv");
              dom.setAttribute(el3, "style", "position: relative; overflow: hidden; width: auto; height: 100%;");
              var el4 = dom.createTextNode("\n                                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "full-height-scroll");
              dom.setAttribute(el4, "style", "overflow: hidden; width: auto; height: 100%;");
              var el5 = dom.createTextNode("\n                                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("ul");
              dom.setAttribute(el5, "class", "list-group elements-list");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                                    ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "full-height");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "slimScrollDiv");
              var el4 = dom.createTextNode("\n                                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "white-bg border-left");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                                ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1, 1, 1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "tabs.tablist", [], [], 0, null, ["loc", [null, [67, 40], [75, 57]]]], ["block", "each", [["get", "gridCategories", ["loc", [null, [83, 44], [83, 58]]]]], [], 1, null, ["loc", [null, [83, 36], [98, 45]]]]],
            locals: ["tabs"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 4
              },
              "end": {
                "line": 106,
                "column": 4
              }
            },
            "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ibox");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "ibox-content");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element2, 1, 1);
            morphs[1] = dom.createMorphAt(element2, 2, 2);
            return morphs;
          },
          statements: [["block", "if", [["get", "arrayTotal", ["loc", [null, [47, 22], [47, 32]]]]], [], 0, null, ["loc", [null, [47, 16], [60, 23]]]], ["block", "ivy-tabs", [], ["selection", ["subexpr", "@mut", [["get", "selection", ["loc", [null, [61, 38], [61, 47]]]]], [], []]], 1, null, ["loc", [null, [61, 16], [103, 29]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type", "multiple-nodes"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 107,
              "column": 0
            }
          },
          "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dropdown.trigger", [], ["eventType", "click", "onKeyDown", ["subexpr", "action", ["onKeyDown"], [], ["loc", [null, [9, 18], [9, 38]]]], "onClick", ["subexpr", "action", ["onClick"], [], ["loc", [null, [10, 16], [10, 34]]]], "tagName", "div", "class", "category-dropdown-trigger form-control d-flex justify-content-around"], 0, null, ["loc", [null, [7, 4], [43, 25]]]], ["block", "dropdown.content", [], ["class", "category-dropdown-list"], 1, null, ["loc", [null, [44, 4], [106, 25]]]]],
        locals: ["dropdown"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 107,
            "column": 19
          }
        },
        "moduleName": "retro-game-store/pods/components/category-dropdown/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "basic-dropdown", [], ["registerAPI", ["subexpr", "action", [["subexpr", "mut", [["get", "basicDropdown", ["loc", [null, [2, 29], [2, 42]]]]], [], ["loc", [null, [2, 24], [2, 43]]]]], [], ["loc", [null, [2, 16], [2, 44]]]], "matchTriggerWidth", false, "triggerComponent", ["subexpr", "@mut", [["get", "triggerComponent", ["loc", [null, [4, 21], [4, 37]]]]], [], []], "onClose", ["subexpr", "action", ["resetState"], [], ["loc", [null, [5, 12], [5, 33]]]], "renderInPlace", ["subexpr", "@mut", [["get", "renderInPlace", ["loc", [null, [6, 18], [6, 31]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [107, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});