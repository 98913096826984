define('retro-game-store/pods/affiliates/affiliate/subscription/renew-modal/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        title: "Subscription Plan",
        validations: {
            'model.subscriptionPlanId': {
                presence: true
            }
        },
        actions: {
            cancel: function cancel() {
                this.send('closeModal');
            },
            save: function save(callback) {
                var _this = this;

                this.get('model').set('subscriptionIsRenewing', true);
                var promise = this.get('model').save().then(function () {
                    _this.send('closeModal');
                });
                callback(promise);
            }
        }
    });
});