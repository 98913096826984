define('retro-game-store/pods/date-string/transform', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    exports['default'] = _emberData['default'].Transform.extend({
        deserialize: function deserialize(serialized) {
            return (0, _moment['default'])(serialized).format('MM/YYYY');
        },

        serialize: function serialize(deserialized) {
            return (0, _moment['default'])(deserialized, 'MM/YYYY').format();
        }
    });
});