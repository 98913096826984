define('retro-game-store/pods/pricing/index/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations) {
    var A = _ember['default'].A;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var mapBy = _Ember$computed.mapBy;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        limit: 10,
        serverQueryParams: ['storeId', 'categoryIds', 'productTypeIds', 'uncompetitiveOnly'],
        categoryFilters: A(),
        categoryIds: mapBy('categoryFilters', 'id'),
        productTypeFilters: A(),
        productTypeIds: mapBy('productTypeFilters', 'id'),
        storeId: alias('storeFilter.id'),
        uncompetitiveOnly: null,
        uncompetitiveOnlyFiltered: false,
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },

            filter: function filter(callback) {
                set(this, 'uncompetitiveOnlyFiltered', get(this, 'uncompetitiveOnly'));
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            }
        }
    });
});