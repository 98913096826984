define('retro-game-store/mixins/route-modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        //This is rendering the previous template as a background - IE. Show list page as background to the modal route
        renderTemplate: function renderTemplate() {
            var modalTemplate = this.get('modalTemplate');
            if (this.get('modalTemplate')) {
                var podLocationArray = modalTemplate.split('/'),
                    podName = undefined;
                podLocationArray.pop();
                podName = podLocationArray.join('/') + '/index';

                this.render(podName, { // the template to render, referenced by name
                    controller: podName, // the controller to use for this template, referenced by name
                    model: this.modelFor(podName) // the model to set on `options.controller`.
                });
            }
        },
        //Dont give a model, so you can load things in after the modal shows
        model: function model() {},
        afterModel: function afterModel(model, transition) {
            var name = this.get('modalTemplate');
            _ember['default'].run.next(this, function () {
                transition.send('openModal', name, model, name);
            });
        }
    });
});