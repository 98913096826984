define('retro-game-store/pods/components/sale-item-row/component', ['exports', 'ember', 'retro-game-store/constants/event-types', 'ember-awesome-macros/sum'], function (exports, _ember, _retroGameStoreConstantsEventTypes, _emberAwesomeMacrosSum) {
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['sale-item-row'],

        categories: service(),
        store: service(),

        tagName: 'li',
        item: alias('model'),
        tradeEventType: _retroGameStoreConstantsEventTypes.EVENT_TYPE_TRADE,
        miscEventType: _retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE,
        returnEventType: _retroGameStoreConstantsEventTypes.EVENT_TYPE_RETURN,
        saleitemIndex: (0, _emberAwesomeMacrosSum['default'])('index', 1),

        cogs: computed('item.{eventType,miscCostOfGoods,quantity}', 'item.inventoryItem.buyPrice', function () {
            var eventType = get(this, 'item.eventType');
            var miscCostOfGoods = get(this, 'item.miscCostOfGoods');
            var buyPrice = get(this, 'item.inventoryItem.buyPrice');
            var quantity = get(this, 'item.quantity');
            var cog = undefined;
            if (eventType === get(this, 'tradeEventType.type') || eventType === get(this, 'returnEventType.type')) {
                cog = '--';
            } else if (eventType === get(this, 'miscEventType.type')) {
                cog = miscCostOfGoods;
            } else {
                cog = buyPrice * quantity;
            }
            return cog;
        }),

        fullCategory: computed('model.productSku.product.category.@each', function () {
            var productType = get(this, 'store').peekRecord('productType', parseInt(get(this, 'model.productSku.product.category.productTypeId'), 10));
            var category = get(this, 'model.productSku.product.category');
            if (productType && category) {
                return get(productType, 'name') + ' / ' + get(category, 'name');
            } else {
                return '';
            }
        }),
        actions: {
            edit: function edit() {
                this.sendAction('edit', this.get('model'));
            },
            remove: function remove() {
                this.sendAction('remove', this.get('model'));
            }
        }
    });
});