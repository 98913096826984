define('retro-game-store/pods/components/loading-button/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        targetObject: _ember['default'].computed.alias('parentView.controller') ? _ember['default'].computed.alias('parentView.controller') : _ember['default'].computed.alias('parentView'),
        tagName: 'button',
        classNames: ['spinner-button', 'btn', 'btn-primary'],
        classNameBindings: ['isSpinning'],
        attributeBindings: ['disabled', 'title'],
        isSpinning: false,

        style: _ember['default'].computed('width', function () {
            var string = "width:" + this.get('width') + "px;";
            return _ember['default'].String.htmlSafe(string);
        }),

        click: function click(event) {
            function callbackHandler(promise) {
                self.set('promise', promise);
            }
            var self = this;
            event.preventDefault();
            if (!this.get('isSpinning')) {
                this.set('width', _ember['default'].$(this.get('element')).find('.fixed-width').width());
                this.set('isSpinning', true);
                var params = this.getWithDefault('params', []);
                var actionArguments = ['action', callbackHandler, params];

                this.sendAction.apply(this, actionArguments);
            }
        },

        handleActionPromise: _ember['default'].observer('promise', function () {
            var self = this;
            this.get('promise')['finally'](function () {
                if (!self.isDestroyed) {
                    self.set('isSpinning', false);
                }
            });
        })

    });
});