define('retro-game-store/initializers/session', ['exports'], function (exports) {
    exports['default'] = {
        name: 'session',
        after: 'ember-simple-auth',
        initialize: function initialize(application) {
            ['component', 'controller', 'model', 'route', 'view'].forEach(function (type) {
                application.inject(type, 'session', 'service:session');
            });
        }
    };
});