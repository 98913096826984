define('retro-game-store/pods/stores/store/route', ['exports', 'ember', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsTitleBar) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsTitleBar['default'], {
        model: function model(params) {
            return this.store.findRecord('store', params.store_id);
        },
        redirect: function redirect(model, transition) {
            if (transition.targetName === 'stores.store.index') {
                this.transitionTo('stores.store.details');
            }
        }
    });
});