define('retro-game-store/pods/percent/transform', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Transform.extend({
        deserialize: function deserialize(serialized) {
            //deal with floating point issue
            return Math.round(serialized * 100 * 1000) / 1000;
        },
        serialize: function serialize(deserialized) {
            return deserialized / 100;
        }
    });
});