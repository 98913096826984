define('retro-game-store/mixins/route-pagination', ['exports', 'ember', 'retro-game-store/mixins/query-params'], function (exports, _ember, _retroGameStoreMixinsQueryParams) {
    var get = _ember['default'].get;
    var merge = _ember['default'].merge;
    var Mixin = _ember['default'].Mixin;
    var next = _ember['default'].run.next;
    var set = _ember['default'].set;
    exports['default'] = Mixin.create(_retroGameStoreMixinsQueryParams['default'], {
        buildQueryParams: function buildQueryParams(controller, queryParamListName, offset, limit, routeParams) {
            var params = routeParams || {};
            var queryParams = this.getParamsObject(get(controller, queryParamListName), controller);
            params = merge(params, queryParams);
            params.offset = offset;
            params.limit = limit;
            return this.removeEmptyQueryParams(params);
        },
        pagingQuery: function pagingQuery(name, params, controllerName) {
            var controller = this.controllerFor(controllerName);
            var promise = this.store.query(name, params);

            return promise.then(function (models) {
                next(function () {
                    if (get(models, 'length') < get(controller, 'limit')) {
                        set(controller, 'hasMore', false);
                    } else {
                        set(controller, 'hasMore', true);
                    }
                    set(controller, 'pagesLoaded', 1);
                    set(controller, '_typeKey', models.type.modelName);
                });
                return models.toArray();
            });
        },
        pagingQueryWithMeta: function pagingQueryWithMeta(name, params, controllerName) {
            var controller = this.controllerFor(controllerName);
            var promise = this.store.query(name, params);

            return promise.then(function (models) {
                next(function () {
                    if (get(models, 'length') < get(controller, 'limit')) {
                        set(controller, 'hasMore', false);
                    } else {
                        set(controller, 'hasMore', true);
                    }
                    set(controller, 'pagesLoaded', 1);
                    set(controller, '_typeKey', models.type.modelName);
                });
                return {
                    models: models.toArray(),
                    meta: get(models, 'meta')
                };
            });
        }
    });
});