define('retro-game-store/pods/components/alert-component/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var equal = _ember['default'].computed.equal;
    exports['default'] = Component.extend({
        classNames: ['alert', 'alert-dismissible'],
        classNameBindings: ['isSuccess:alert-success', 'isWarning:alert-warning', 'isDanger:alert-danger'],
        type: 'success',
        isSuccess: equal('type', 'success'),
        isWarning: equal('type', 'warning'),
        isDanger: equal('type', 'danger')
    });
});