define('retro-game-store/pods/password/forgot/controller', ['exports', 'ember', 'retro-game-store/config/environment', 'ember-validations'], function (exports, _ember, _retroGameStoreConfigEnvironment, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        validations: {
            emailAddress: {
                email: true
            }
        },
        actions: {
            sendEmail: function sendEmail(callback) {
                var _this = this;

                var email = this.get('emailAddress');
                var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    _ember['default'].$.ajax(_retroGameStoreConfigEnvironment['default'].RESTAPI + '/api/password/forgot', {
                        data: JSON.stringify({
                            tenantId: _retroGameStoreConfigEnvironment['default'].affiliate,
                            email: email
                        }),
                        success: function success(response) {
                            resolve(response);
                        },
                        error: function error(reason) {
                            var response = _ember['default'].$.parseJSON(reason.responseText),
                                errors = [];
                            if (response.errors !== undefined) {
                                (function () {
                                    var jsonErrors = response.errors;
                                    _ember['default'].EnumerableUtils.forEach(_ember['default'].keys(jsonErrors), function (key) {
                                        errors.push(jsonErrors[key]);
                                    });
                                })();
                            }
                            reject(errors);
                        },
                        type: 'POST',
                        contentType: 'application/json; charset=utf-8',
                        dataType: 'json'
                    });
                });
                promise.then(function () {
                    _this.set('forgotPasswordSuccess', true);
                });
                callback(promise);
            }
        }
    });
});