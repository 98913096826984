define('retro-game-store/pods/invoices/new/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        validations: {},

        vendors: [],

        updateVendor: function updateVendor(vendor) {
            set(this, 'model.vendorId', get(vendor, 'id'));
            set(this, 'model.vendor', vendor);
        },

        actions: {
            getAllVendors: function getAllVendors() {
                this.set('vendors', this.store.findAll('vendor'));
            },

            createVendor: function createVendor(term) {
                var _this = this;

                this.store.createRecord('vendor', {
                    name: term
                }).save().then(function (vendor) {
                    _this.updateVendor(vendor);
                });
            },

            updateVendor: function updateVendor(vendor) {
                this.updateVendor(vendor);
            },

            save: function save(callback) {
                var _this2 = this;

                var promise = this.get('model').save().then(function (model) {
                    _this2.transitionToRoute('invoices.invoice.details', model);
                });
                callback(promise);
            }
        }
    });
});