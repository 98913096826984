define('retro-game-store/pods/inventory/summary/categories/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering) {
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var mapBy = _Ember$computed.mapBy;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var controller = _ember['default'].inject.controller;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], {
        summaryController: controller('inventory.summary'),
        storeFilter: reads('summaryController.storeFilter'),
        categoryFilters: reads('summaryController.categoryFilters'),
        categoryIds: mapBy('categoryFilters', 'id'),
        productTypeFilters: reads('summaryController.productTypeFilters'),
        productTypeIds: mapBy('productTypeFilters', 'id'),

        storeId: computed('storeFilter', function () {
            if (this.get('storeFilter')) {
                return this.get('storeFilter').get('id');
            }
        }),
        limit: 10,
        groupBy: 'platform',
        serverQueryParams: ['productTypeIds', 'categoryIds', 'storeId', 'groupBy'],
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(this.get('serverQueryParams'));
                callback(promise);
            },
            filter: function filter() {
                var _this = this;

                this.get('summaryController').set('isSpinning', true);
                var promise = this.filter(this.get('serverQueryParams'));
                promise.then(function () {
                    _this.get('summaryController').set('isSpinning', false);
                });
            },
            inventoryListWithFilter: function inventoryListWithFilter(id) {
                var productCategory = this.store.peekRecord('product-category', id);
                var json = productCategory.toJSON({ includeId: true });
                // json.selected = true;
                this.get('summaryController').set('categoryFilters', [json]);
                this.transitionToRoute('inventory.items');
            }
        }
    });
});