define('retro-game-store/pods/sales/new/route', ['exports', 'ember', 'retro-game-store/mixins/check-abilities'], function (exports, _ember, _retroGameStoreMixinsCheckAbilities) {
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsCheckAbilities['default'], {
        abilitiesRequired: ['sale.access'],

        model: function model(params, transition) {
            var qparams = get(transition, 'queryParams');
            return this.store.createRecord('sale', { params: qparams }).save();
        },
        afterModel: function afterModel(model) {
            this.replaceWith('sales.sale', model);
        }
    });
});