define('retro-game-store/pods/invoice/model', ['exports', 'ember', 'ember-data', 'retro-game-store/constants/order-statuses'], function (exports, _ember, _emberData, _retroGameStoreConstantsOrderStatuses) {
    var get = _ember['default'].get;
    var equal = _ember['default'].computed.equal;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        affiliateId: attr('number'),
        amount: attr('number', { defaultValue: 0 }),
        date: attr('date', { defaultValue: new Date() }),
        dateVoided: attr('date'),
        employeeId: attr('number'),
        invoiceCode: attr('string'),
        status: attr('string'),
        vendorId: attr('number'),

        //Relationships
        affiliate: belongsTo('affiliate'),
        employee: belongsTo('employee'),
        invoiceItems: hasMany('invoice-item'),
        vendor: belongsTo('vendor'),

        //Computeds
        isClosed: equal('status', get(_retroGameStoreConstantsOrderStatuses.ORDER_STATUS_CLOSED, 'label'))
    });
});