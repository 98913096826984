define('retro-game-store/pods/inventory/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            category: {
                embedded: 'always'
            },
            condition: {
                serialize: 'ids',
                deserialize: 'records'
            },
            productType: {
                serialize: 'ids',
                deserialize: 'records'
            },
            storeInfo: {
                embedded: 'always'
            }
        },
        keyForRelationship: function keyForRelationship(key) {
            if (key === 'storeInfo') {
                return 'storeInfo';
            }
            return key + 'Id';
        }
    });
});