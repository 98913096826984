define('retro-game-store/pods/components/customer-notification-row/component', ['exports', 'ember', 'ember-awesome-macros/tag'], function (exports, _ember, _emberAwesomeMacrosTag) {
    var _templateObject = _taggedTemplateLiteral(['', ' / ', ''], ['', ' / ', '']);

    function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

    var Component = _ember['default'].Component;
    var get = _ember['default'].get;
    exports['default'] = Component.extend({
        tagName: 'li',
        fullCategory: (0, _emberAwesomeMacrosTag['default'])(_templateObject, 'model.product.productType.name', 'model.product.category.name'),
        actions: {
            remove: function remove() {
                this.sendAction('remove', get(this, 'model'));
            }
        }
    });
});