define('retro-game-store/pods/date/transform', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {

    //This transforms the deserialized value of date into a moment.js object
    exports['default'] = _emberData['default'].Transform.extend({
        deserialize: function deserialize(serialized) {
            if (serialized) {
                var result = (0, _moment['default'])(serialized);
                if (_moment['default'].isMoment(result) && result.isValid()) {
                    return result;
                }
                return null;
            } else {
                return serialized;
            }
        },

        serialize: function serialize(deserialized) {
            if (_moment['default'].isMoment(deserialized)) {
                return deserialized.clone().toISOString();
            } else {
                return deserialized;
            }
        }
    });
});