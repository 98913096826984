define('retro-game-store/pods/categories/service', ['exports', 'ember'], function (exports, _ember) {
    var A = _ember['default'].A;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Service.extend({
        api: service(),
        store: service(),
        models: null,
        raw: null,
        loadModels: function loadModels() {
            var _this = this;

            return get(this, 'api').request('GET', '/productTypes').then(function (productTypes) {
                var models = A();
                productTypes.forEach(function (type) {
                    models.addObject(get(_this, 'store').push(get(_this, 'store').normalize('product-type', type)));
                });
                var raw = productTypes;
                setProperties(_this, { models: models, raw: raw });
            });
        }
    });
});