define('retro-game-store/mixins/activate-controller-via-route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    // Override this to prevent conflicts with your app
    isActive: 'isActive',
    controllerAvailable: false,
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      if (!controller.isGenerated) {
        this.set('controllerAvailable', true);
        controller.set(this.get('isActive'), true);
      }
    },
    activate: function activate() {
      this._activateController(true);
    },
    deactivate: function deactivate() {
      this._activateController(false);
    },
    _activateController: function _activateController(isActive) {
      if (this.get('controllerAvailable')) {
        this.controller.set(this.get('isActive'), isActive);
      }
    }
  });
});