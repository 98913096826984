define('retro-game-store/pods/sales/sale/edit-tax-modal/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        validations: {
            'model.taxPercent': {
                presence: true,
                numericality: {
                    messages: {
                        numericality: ' must be a number between 0 and 100'
                    },
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 100
                }
            }
        },
        closeModal: function closeModal() {
            this.get('model').rollbackAttributes();
            this.send('closeModal');
        },
        actions: {
            cancel: function cancel() {
                this.closeModal();
            },
            save: function save(callback) {
                var _this = this;

                var promise = this.get('model').save().then(function () {
                    _this.closeModal();
                })['catch'](function (err) {
                    console.log(err);
                });
                callback(promise);
            }
        }
    });
});