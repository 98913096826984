define("retro-game-store/pods/components/select-component/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "retro-game-store/pods/components/select-component/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["get", "select", ["loc", [null, [19, 12], [19, 18]]]]], [], ["loc", [null, [19, 4], [19, 20]]]]],
        locals: ["select"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "retro-game-store/pods/components/select-component/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "power-select", [], ["renderInPlace", ["subexpr", "@mut", [["get", "renderInPlace", ["loc", [null, [2, 18], [2, 31]]]]], [], []], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [3, 13], [3, 21]]]]], [], []], "selectedItemComponent", ["subexpr", "@mut", [["get", "selectedItemComponent", ["loc", [null, [4, 26], [4, 47]]]]], [], []], "search", ["subexpr", "@mut", [["get", "search", ["loc", [null, [5, 11], [5, 17]]]]], [], []], "allowClear", ["subexpr", "@mut", [["get", "allowClear", ["loc", [null, [6, 15], [6, 25]]]]], [], []], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [7, 16], [7, 27]]]]], [], []], "onchange", ["subexpr", "@mut", [["get", "onchange", ["loc", [null, [8, 13], [8, 21]]]]], [], []], "class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [9, 10], [9, 15]]]]], [], []], "options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [10, 12], [10, 19]]]]], [], []], "onfocus", ["subexpr", "action", ["didFocus"], [], ["loc", [null, [11, 12], [11, 31]]]], "onopen", ["subexpr", "action", ["didOpen"], [], ["loc", [null, [12, 11], [12, 29]]]], "onclose", ["subexpr", "action", ["didClose"], [], ["loc", [null, [13, 12], [13, 31]]]], "searchEnabled", ["subexpr", "@mut", [["get", "searchEnabled", ["loc", [null, [14, 18], [14, 31]]]]], [], []], "noMatchesMessage", ["subexpr", "@mut", [["get", "noMatchesMessage", ["loc", [null, [15, 21], [15, 37]]]]], [], []], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [16, 13], [16, 21]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [20, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});