define('retro-game-store/pods/components/category-dropdown/tooltip/component', ['exports', 'ember'], function (exports, _ember) {
    var set = _ember['default'].set;
    var scheduleOnce = _ember['default'].run.scheduleOnce;
    var ViewUtils = _ember['default'].ViewUtils;
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        canShowTooltip: false,
        initializeTooltip: function initializeTooltip() {
            var component = null;
            // Hack to get the component's container
            // element since this component uses `tagName: ''`
            // https://github.com/emberjs/rfcs/issues/168#issue-178381310
            if (ViewUtils && ViewUtils.getViewBounds) {
                component = ViewUtils.getViewBounds(this).parentElement;
            } else {
                component = this._renderNode.contextualElement;
            }
            set(this, 'canShowTooltip', $(component).innerWidth() < component.scrollWidth);
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);
            scheduleOnce('afterRender', this, 'initializeTooltip');
        }
    });
});