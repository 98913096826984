define('retro-game-store/pods/password/reset/route', ['exports', 'ember', 'retro-game-store/config/environment', 'retro-game-store/mixins/unauthenticated-route'], function (exports, _ember, _retroGameStoreConfigEnvironment, _retroGameStoreMixinsUnauthenticatedRoute) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsUnauthenticatedRoute['default'], {
        model: function model(params) {
            var token = params.code;
            this.set('token', token);
        },
        setupController: function setupController(controller, model) {
            var token = this.get('token');

            controller.set('model', model);
            controller.set('token', token);

            new _ember['default'].RSVP.Promise(function (resolve) {
                _ember['default'].$.ajax(_retroGameStoreConfigEnvironment['default'].RESTAPI + '/api/password/token/validate', {
                    data: JSON.stringify({
                        tenantId: _retroGameStoreConfigEnvironment['default'].affiliate,
                        token: token
                    }),
                    success: function success(response) {
                        controller.set('tokenError', false);
                        resolve(response);
                    },
                    error: function error() {
                        controller.set('tokenError', true);
                    },
                    type: 'POST',
                    contentType: 'application/json; charset=utf-8',
                    dataType: 'json'
                });
            });
        }
    });
});