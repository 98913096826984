define('retro-game-store/mixins/modal-active', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        modalActive: false,

        actions: {
            openModal: function openModal() {
                this.controller.set('modalActive', true);
                return true;
            },
            closeModal: function closeModal() {
                this.controller.set('modalActive', false);
                return true;
            }
        }
    });
});