define('retro-game-store/pods/login/controller', ['exports', 'ember', 'ember-validations', 'retro-game-store/config/environment'], function (exports, _ember, _emberValidations, _retroGameStoreConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        validations: {
            identification: {
                email: true
            },
            password: true
        },
        actions: {
            authenticate: function authenticate(callback) {
                var _this = this;

                var authenticator = 'custom-oauth2-authenticator:oauth2-password-grant';

                var _getProperties = this.getProperties('identification', 'password');

                var identification = _getProperties.identification;
                var password = _getProperties.password;

                var promise = this.get('session').authenticate(authenticator, identification, password, _retroGameStoreConfigEnvironment['default'].affiliate, 'read write')['catch'](function (error) {
                    _this.set('authError', error);
                });
                callback(promise);
            }
        }

    });
});