define('retro-game-store/pods/platform-total/model', ['exports', 'ember', 'ember-data', 'accounting/to-fixed'], function (exports, _ember, _emberData, _accountingToFixed) {
    var computed = _ember['default'].computed;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        costOfGoods: attr('number'),
        grossProfit: attr('number'),
        invoices: attr('number'),
        netCash: attr('number'),
        netCreditCard: attr('number'),
        netCustomerCredit: attr('number'),
        netExternal: attr('number'),
        platformInfo: belongsTo('product-category'),
        returnsOverview: attr('number'),
        salesOverview: attr('number'),
        salesTax: attr('number'),
        shippingPaid: attr('number'),
        shippingReceived: attr('number'),
        tradesCashOverview: attr('number'),
        tradesCreditOverview: attr('number'),
        unitsSold: attr('number'),
        trades: computed('tradesCashOverview', 'tradesCreditOverview', function () {
            var _getProperties = this.getProperties('tradesCashOverview', 'tradesCreditOverview');

            var tradesCashOverview = _getProperties.tradesCashOverview;
            var tradesCreditOverview = _getProperties.tradesCreditOverview;

            return (0, _accountingToFixed['default'])(tradesCashOverview + tradesCreditOverview, 2);
        }),
        shipping: computed('shippingPaid', 'shippingReceived', function () {
            var _getProperties2 = this.getProperties('shippingPaid', 'shippingReceived');

            var shippingPaid = _getProperties2.shippingPaid;
            var shippingReceived = _getProperties2.shippingReceived;

            return (0, _accountingToFixed['default'])(shippingPaid - shippingReceived, 2);
        }),
        profit: computed('shippingPaid', 'shippingReceived', function () {
            var _getProperties3 = this.getProperties('shippingPaid', 'shippingReceived');

            var shippingPaid = _getProperties3.shippingPaid;
            var shippingReceived = _getProperties3.shippingReceived;

            return (0, _accountingToFixed['default'])(shippingPaid - shippingReceived, 2);
        })
    });
});