define('retro-game-store/pods/product-sku/model', ['exports', 'retro-game-store/pods/product-sku-child/model', 'ember-data'], function (exports, _retroGameStorePodsProductSkuChildModel, _emberData) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;

    /*
    The ProductSkuChildModel does not contain the product param to fix a possible recursion issue with the productSku->product->productSkus relationship
    that causes productSku->product to be null. This was initially found only in saleItem models, not sure if it occurred elsewhere
     */
    exports['default'] = _retroGameStorePodsProductSkuChildModel['default'].extend({
        product: belongsTo('product'),
        productId: attr('number')
    });
});