define('retro-game-store/pods/employee/model', ['exports', 'ember-data', 'ember-awesome-macros/tag'], function (exports, _emberData, _emberAwesomeMacrosTag) {
    var _templateObject = _taggedTemplateLiteral(['', ' ', ''], ['', ' ', '']),
        _templateObject2 = _taggedTemplateLiteral(['', ', ', ''], ['', ', ', '']);

    function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        firstName: attr('string'),
        lastName: attr('string'),
        email: attr('string'),
        password: attr('string'),
        passwordConfirmation: attr('string'),
        isAdmin: attr('boolean'),
        stores: hasMany('employeeStore'),
        name: (0, _emberAwesomeMacrosTag['default'])(_templateObject, 'firstName', 'lastName'),
        nameByLast: (0, _emberAwesomeMacrosTag['default'])(_templateObject2, 'lastName', 'firstName')
    });
});