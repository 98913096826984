define('retro-game-store/pods/components/inventory-bar-graph/component', ['exports', 'ember', 'd3'], function (exports, _ember, _d3) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'svg',
        easing: 'quad',
        duration: 500,
        margin: {
            top: 0,
            right: 20,
            bottom: 30,
            left: 50
        },
        tip: _ember['default'].computed('type', 'tipFormat', function () {
            var graphType = this.get('type'),
                format = this.get('tipFormat');
            return _d3['default'].tip().attr('class', 'd3-tip').offset(function () {
                return [-10, this.getBBox().width / 2 - 5];
            }).direction('n').html(function (d) {
                return format(d[graphType]);
            });
        }),
        tipFormat: _ember['default'].computed('type', function () {
            var format = _d3['default'].format(",d");
            if (this.get('type') !== 'count') {
                format = _d3['default'].format("$,.2f");
            }
            return format;
        }),
        tickFormat: _ember['default'].computed('type', function () {
            var format = _d3['default'].format("s");
            if (this.get('type') !== 'count') {
                format = _d3['default'].format("$s");
            }
            return format;
        }),
        height: _ember['default'].computed('margin', function () {
            return 350 - this.get('margin').top - this.get('margin').bottom;
        }),
        width: _ember['default'].computed('margin', function () {
            return parseInt(_d3['default'].select(this.get('element').parentNode).style('width'), 10) - this.get('margin').left - this.get('margin').right;
        }),
        maxValue: _ember['default'].computed('data', 'type', function () {
            var type = this.get('type');
            return _d3['default'].max(this.get('data'), function (d) {
                return d[type];
            });
        }),
        xScale: _ember['default'].computed('data', 'width', 'maxValue', function () {
            var maxValue = this.get('maxValue');
            //By setting .nice() we are telling the domain to include 'round' values that will show up the axis
            return _d3['default'].scale.linear().domain([0, maxValue]).range([0, this.get('width')]).clamp(true).nice();
        }),
        yScale: _ember['default'].computed('data', 'height', function () {
            return _d3['default'].scale.ordinal().domain(this.get('data').map(function (d) {
                return d.name;
            })).rangeRoundBands([this.get('height'), 0], 0.5);
        }),
        xAxis: _ember['default'].computed('xScale', 'maxValue', 'height', 'tickFormat', function () {
            var height = this.get('height'),
                xScale = this.get('xScale'),
                format = this.get('tickFormat');
            return _d3['default'].svg.axis().scale(xScale).orient("bottom").ticks(4).innerTickSize([-height]).tickPadding([9]).outerTickSize(0).tickFormat(format);
        }),
        yAxis: _ember['default'].computed('yScale', function () {
            return _d3['default'].svg.axis().scale(this.get('yScale')).orient('left');
        }),
        svg: _ember['default'].computed('width', 'height', 'margin', function () {
            var margin = this.get('margin');
            return _d3['default'].select(this.get('element')).attr('width', this.get('width') + margin.left + margin.right).attr('height', this.get('height') + margin.top + margin.bottom).append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        }),
        draw: function draw() {
            var height = this.get('height'),
                easing = this.get('easing'),
                duration = this.get('duration'),
                data = this.get('data'),
                x = this.get('xScale'),
                y = this.get('yScale'),
                xAxis = this.get('xAxis'),
                yAxis = this.get('yAxis'),
                svg = this.get('svg'),
                graphType = this.get('type'),
                tip = this.get('tip');

            svg.append("g").attr("class", "x axis").attr("transform", "translate(0," + height + ")").call(xAxis);

            svg.append("g").attr("class", "y axis").call(yAxis).append("text").attr("transform", "rotate(-90)").attr("y", 6).attr("dy", ".71em");

            svg.selectAll('.bar').data(data).enter().append('rect').attr('class', 'bar').attr('x', 0).attr('width', 0).attr('y', function (d) {
                return y(d.name);
            }).attr('height', y.rangeBand()).on('mouseover', tip.show).on('mouseout', tip.hide);

            svg.selectAll('.bar').data(data).transition().attr('width', function (d) {
                return x(d[graphType]);
            }).ease(easing).duration(duration);

            svg.call(tip);
        },
        modifyGraph: _ember['default'].observer('type', 'data.[]', function () {
            //When the data fed into this graph changes, update the bars and axis with new data. Animate everything for a smooth transition
            var svg = this.get('svg'),
                data = this.get('data'),
                easing = this.get('easing'),
                duration = this.get('duration'),
                x = this.get('xScale'),
                xAxis = this.get('xAxis'),
                graphType = this.get('type'),
                tip = this.get('tip');

            svg.select(".x.axis").transition().duration(duration).ease(easing).call(xAxis);

            svg.selectAll('.bar').on('mouseover', tip.show).on('mouseout', tip.hide).data(data).transition().attr('width', function (d) {
                return x(d[graphType]);
            }).ease(easing).duration(duration);

            svg.call(tip);
        }),

        setup: (function () {
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                this.draw();
            });
        }).on('didInsertElement')
    });
});