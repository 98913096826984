define('retro-game-store/pods/sales/stashed/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar', 'retro-game-store/mixins/authenticated-route', 'retro-game-store/mixins/check-abilities'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar, _retroGameStoreMixinsAuthenticatedRoute, _retroGameStoreMixinsCheckAbilities) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsAuthenticatedRoute['default'], _retroGameStoreMixinsCheckAbilities['default'], _retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        abilitiesRequired: ['sale.access'],
        model: function model(params) {
            var controller = this.controllerFor('sales.stashed'),
                queryParams = undefined;
            queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQueryWithMeta('sale', queryParams, 'sales.stashed');
        }
    });
});