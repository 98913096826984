define("retro-game-store/pods/components/platforms-select/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "retro-game-store/pods/components/platforms-select/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["get", "platform", ["loc", [null, [12, 12], [12, 20]]]]], [], ["loc", [null, [12, 4], [12, 22]]]]],
        locals: ["platform"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 26
          }
        },
        "moduleName": "retro-game-store/pods/components/platforms-select/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "power-select-multiple", [], ["options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [2, 24], [2, 31]]]]], [], []], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [3, 25], [3, 33]]]]], [], []], "placeholder", "All Platforms", "searchField", "name", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "selected", ["loc", [null, [6, 38], [6, 46]]]]], [], ["loc", [null, [6, 33], [6, 47]]]]], [], ["loc", [null, [6, 25], [6, 48]]]], "onopen", ["subexpr", "action", ["opened"], [], ["loc", [null, [7, 23], [7, 40]]]], "onclose", ["subexpr", "action", ["closed"], [], ["loc", [null, [8, 24], [8, 41]]]], "renderInPlace", true, "allowClear", true], 0, null, ["loc", [null, [1, 0], [13, 26]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});