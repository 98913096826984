define('retro-game-store/pods/affiliates/affiliate/subscription/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        validations: {
            'model.maxStoreLimit': {
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0,
                    allowBlank: true
                }
            }
        },
        actions: {
            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            }
        }
    });
});