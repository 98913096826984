define('retro-game-store/pods/shipping/sale/shipments/route', ['exports', 'ember', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsTitleBar) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsTitleBar['default'], {
        model: function model() {
            var saleId = this.modelFor('shipping.sale').get('id');
            this.set('saleId', saleId);
            return this.store.query('shipment', { saleId: saleId }).then(function (shipments) {
                return shipments.toArray();
            });
        },
        afterModel: function afterModel(model, transition) {
            var sale = this.store.peekRecord('shipment-sale', this.get('saleId'));
            if (transition.targetName === 'shipping.sale.shipments.index') {
                if (sale.get('status') === 'Pending') {
                    this.transitionTo('shipping.sale.shipments.new');
                } else {
                    this.transitionTo('shipping.sale.shipments.shipment', model.get('firstObject').get('id'));
                }
            }
        }
    });
});