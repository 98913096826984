define('retro-game-store/pods/customers/customer/notifications/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination'], function (exports, _ember, _retroGameStoreMixinsRoutePagination) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsRoutePagination['default'], {
        // model () {
        //     let customerId = this.modelFor('customers.customer').get('id');
        //     return this.pagingQuery('customer-notification', {
        //         skip: 0,
        //         top: 20,
        //         customerId: customerId
        //     }, 'customers.customer.notifications');
        // }
    });
});