define('retro-game-store/pods/inventory/items/new/route', ['exports', 'ember', 'retro-game-store/mixins/title-bar', 'ember-data-route', 'retro-game-store/mixins/check-abilities'], function (exports, _ember, _retroGameStoreMixinsTitleBar, _emberDataRoute, _retroGameStoreMixinsCheckAbilities) {
    var get = _ember['default'].get;
    var Route = _ember['default'].Route;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Route.extend(_retroGameStoreMixinsTitleBar['default'], _emberDataRoute['default'], _retroGameStoreMixinsCheckAbilities['default'], {
        abilitiesRequired: ['inventory.new'],

        model: function model() {
            return this.store.createRecord('inventory', {
                affiliateId: get(this, 'session.user.affiliateId'),
                quantity: 1,
                storeInfo: get(this, 'session.user.storeInfo')
            });
        },

        setupController: function setupController(controller) {
            setProperties(controller, {
                product: null,
                selectedCondition: null
            });
            this._super.apply(this, arguments);
        },

        resetController: function resetController(controller) {
            setProperties(controller, {
                pricingData: null,
                selectedCondition: null
            });
            this._super.apply(this, arguments);
        }
    });
});