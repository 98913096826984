define('retro-game-store/pods/components/metis-menu/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        session: _ember['default'].inject.service(),
        menuInitialized: false,
        startMenu: function startMenu() {
            _ember['default'].$("#side-menu").metisMenu();
        },
        sessionUserChanged: _ember['default'].observer('session.user', function () {
            var _this = this;

            if (this.get('session').get('user')) {
                _ember['default'].run.next(function () {
                    _this.startMenu();
                });
            }
        }),
        didRender: function didRender() {
            // We need this in case the user refreshes the page. The session user has already been
            // established, so the observer above will not be fired.
            if (this.get('session').get('user')) {
                _ember['default'].run.next(this, function () {
                    this.startMenu();
                });
            }
        }
    });
});