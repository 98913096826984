define('retro-game-store/pods/inventory/history/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar) {
    var get = _ember['default'].get;
    var Route = _ember['default'].Route;
    var RSVP = _ember['default'].RSVP;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        queryParams: {
            pId: {
                refreshModel: true
            }
        },
        model: function model(params) {
            if (params && get(params, 'pId')) {
                var productId = get(params, 'pId');
                var storeId = get(this, 'session.user.storeInfo.id');
                set(this, 'productId', productId);
                return this.pagingQuery('inventory-history', { productId: productId, storeId: storeId }, 'inventory.history');
            } else {
                return [];
            }
        },
        afterModel: function afterModel() {
            var _this = this;

            var hash = {
                stores: this.store.findAll('store')
            };

            if (get(this, 'productId')) {
                hash.product = this.store.findRecord('product', get(this, 'productId'));
            }

            return RSVP.hash(hash).then(function (response) {
                setProperties(_this, {
                    stores: response.stores,
                    product: response.product || null
                });
            });
        },
        setupController: function setupController(controller) {
            setProperties(controller, {
                stores: get(this, 'stores'),
                storeSelected: get(this, 'session.user.storeInfo'),
                productSelected: get(this, 'product') || null
            });
            this._super.apply(this, arguments);
        },
        resetController: function resetController(controller) {
            setProperties(controller, {
                productSelected: null,
                storeSelected: null
            });
            this._super.apply(this, arguments);
        }
    });
});