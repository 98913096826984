define('retro-game-store/pods/inventory/items/index/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations', 'retro-game-store/mixins/save-blob'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations, _retroGameStoreMixinsSaveBlob) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var mapBy = _ember['default'].computed.mapBy;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var set = _ember['default'].set;
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], _retroGameStoreMixinsSaveBlob['default'], {
        api: service(),
        exportIsSpinning: false,
        storeId: computed('storeFilter', function () {
            if (get(this, 'storeFilter')) {
                return get(this, 'storeFilter.id');
            }
        }),
        limit: 10,
        existingInventoryOnly: true,
        categoryFilters: A(),
        categoryIds: mapBy('categoryFilters', 'id'),
        productTypeFilters: A(),
        productTypeIds: mapBy('productTypeFilters', 'id'),
        serverQueryParams: ['keywords', 'categoryIds', 'productTypeIds', 'storeId', 'groupBy', 'existingInventoryOnly'],
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },
            exportCsv: function exportCsv() {
                var _this = this;

                set(this, 'exportIsSpinning', true);

                var params = this.getParamsObject(get(this, 'serverQueryParams'));

                return this.get('api').request('GET', '/inventory-csv', this.removeEmptyQueryParams(params), true).then(function (result) {
                    _this.saveBlobAs(result, 'inventory.csv');
                })['finally'](function () {
                    set(_this, 'exportIsSpinning', false);
                });
            }
        }
    });
});