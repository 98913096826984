define('retro-game-store/pods/customers/customer/notifications/index/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination'], function (exports, _ember, _retroGameStoreMixinsRoutePagination) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsRoutePagination['default'], {
        model: function model() {
            var customerId = this.modelFor('customers.customer').get('id');
            this.set('customerId', customerId);
            return this.pagingQuery('customer-notification', {
                skip: 0,
                top: 20,
                customerId: customerId
            }, 'customers.customer.notifications.index');
        },
        setupController: function setupController(controller, model) {
            controller.set('customerId', this.get('customerId'));
            this._super(controller, model);
        }
    });
});