define('retro-game-store/mixins/wizard-modal', ['exports', 'ember'], function (exports, _ember) {
    var set = _ember['default'].set;
    exports['default'] = _ember['default'].Mixin.create({
        animation: 'toLeft',
        modifyValidations: _ember['default'].observer('step', 'stepValidations', 'steps', function () {
            var _this = this;

            this.get('steps').forEach(function (step) {
                if (_this.get('step') === step) {
                    _this.set('validations', _this.get('stepValidations')[step] || {});
                    set(_this, 'validators', _ember['default'].A());
                    _this.buildValidators();
                    _this.validators.forEach(function (validator) {
                        if (!validator.hasObserverFor('errors.[]')) {
                            validator.addObserver('errors.[]', this, function (sender) {
                                var errors = _ember['default'].A();
                                this.validators.forEach(function (validator) {
                                    if (validator.property === sender.property) {
                                        errors.addObjects(validator.errors);
                                    }
                                }, this);
                                _ember['default'].set(this, 'errors.' + sender.property, errors);
                            });
                        }
                    }, _this);
                }
            });
        }),
        stepForward: function stepForward() {
            this.set('animation', 'toLeft');
            var index = this.get('steps').indexOf(this.get('step'));
            this.set('step', this.get('steps')[index + 1]);
        },
        stepBackward: function stepBackward() {
            this.set('animation', 'toRight');
            var index = this.get('steps').indexOf(this.get('step'));
            this.set('step', this.get('steps')[index - 1]);
        },
        actions: {
            stepForward: function stepForward() {
                this.stepForward();
            },
            stepBackward: function stepBackward() {
                this.stepBackward();
            }
        }
    });
});