define('retro-game-store/pods/invoices/invoice/details/route', ['exports', 'ember', 'ember-data-route'], function (exports, _ember, _emberDataRoute) {
    exports['default'] = _ember['default'].Route.extend(_emberDataRoute['default'], {
        renderTemplate: function renderTemplate() {
            this._super.apply(this, arguments);
            if (this.modelFor('invoices/invoice').get('isClosed')) {
                this.render(this.routeName + '.invoice-closed', { // the template to render
                    into: 'invoices/invoice', // the template to render into
                    controller: this.controller // the controller to use for the template
                });
            }
            if (this.modelFor('invoices/invoice').get('dateVoided')) {
                this.render(this.routeName + '.invoice-voided', { // the template to render
                    into: 'invoices/invoice', // the template to render into
                    controller: this.controller // the controller to use for the template
                });
            }
        }
    });
});