define('retro-game-store/pods/customer/model', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    var hasMany = _emberData['default'].hasMany;
    exports['default'] = Model.extend({
        affiliateId: attr('number'),
        city: attr('string'),
        creditBalance: attr('number', { defaultValue: 0 }),
        email: attr('string'),
        firstName: attr('string'),
        lastName: attr('string'),
        license: attr('string'),
        mobile: attr('string'),
        state: attr('string'),
        street1: attr('string'),
        street2: attr('string'),
        zip: attr('string'),

        //Relationships
        customerNotifications: hasMany('customerNotification'),

        //Computeds
        name: computed('firstName', 'lastName', function () {
            return get(this, 'firstName') + ' ' + get(this, 'lastName');
        })
    });
});