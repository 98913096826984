define('retro-game-store/pods/inventory/model', ['exports', 'ember', 'ember-data', 'moment', 'retro-game-store/macros', 'ember-inflector', 'ember-awesome-macros/tag'], function (exports, _ember, _emberData, _moment, _retroGameStoreMacros, _emberInflector, _emberAwesomeMacrosTag) {
    var _templateObject = _taggedTemplateLiteral(['', ' / ', ''], ['', ' / ', '']);

    function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

    var inflector = _emberInflector['default'].inflector;
    inflector.irregular('inventory', 'inventory');

    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var get = _ember['default'].get;
    var observer = _ember['default'].observer;
    var set = _ember['default'].set;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    var propsAreEqual = _retroGameStoreMacros['default'].propsAreEqual;
    exports['default'] = Model.extend({
        affiliateId: attr('number'),
        buyPrice: attr('number'),
        comment: attr('string'),
        dateCreated: attr('date', { defaultValue: new Date() }),
        dateAdded: attr('date'),
        labelQueueOverride: attr('number'),
        name: attr('string'),
        productId: attr('number'),
        productSkuId: attr('number'),
        quantity: attr('number'),
        quantityAvailable: attr('number'),
        quantityReturnable: attr('number'),
        researchUrl: attr('string'),
        sellPrice: attr('number'),
        stockNumber: attr('string'),
        transferQuantity: attr('number', { defaultValue: 0 }),
        upc: attr('string'),

        //Relationships
        category: belongsTo('product-category'),
        condition: belongsTo('condition'),
        productType: belongsTo('product-type'),
        storeInfo: belongsTo('store'),

        //Computeds
        productName: alias('product.name'),
        storeName: alias('storeInfo.name'),
        maxTransferQuantityMet: propsAreEqual('quantity', 'transferQuantity', false),

        ageInDays: computed('dateCreated', function () {
            return (0, _moment['default'])(new Date()).diff(get(this, 'dateCreated').subtract(1, 'days'), 'days');
        }),

        fullCategory: (0, _emberAwesomeMacrosTag['default'])(_templateObject, 'productType.name', 'category.name'),

        transferQuantityObserver: observer('quantity', 'transferQuantity', function () {
            if (get(this, 'quantity') < get(this, 'transferQuantity')) {
                set(this, 'transferQuantity', get(this, 'quantity'));
            }
        })
    });
});