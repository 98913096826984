define('retro-game-store/pods/components/category-dropdown/category-list-item/component', ['exports', 'ember'], function (exports, _ember) {
    var alias = _ember['default'].computed.alias;
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['category-dropdown-list-items-item'],
        classNameBindings: ['selected'],
        selected: alias('category.selected'),
        click: function click() {
            this.toggleProperty('selected');
            this.sendAction('toggle', get(this, 'category'));
        }
    });
});