define('retro-game-store/pods/stores/new/route', ['exports', 'ember', 'retro-game-store/mixins/title-bar', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/check-abilities', 'ember-data-route'], function (exports, _ember, _retroGameStoreMixinsTitleBar, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsCheckAbilities, _emberDataRoute) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsTitleBar['default'], _retroGameStoreMixinsRoutePagination['default'], _emberDataRoute['default'], _retroGameStoreMixinsCheckAbilities['default'], {
        abilitiesRequired: ['store.create'],
        model: function model() {
            var store = this.store.createRecord('store');
            return store;
        },
        afterModel: function afterModel() {
            var _this = this;

            return this.pagingQuery('store-employee', { top: 50 }, 'stores.new').then(function (employees) {
                _this.set('employees', employees.toArray());
            });
        },
        setupController: function setupController(controller, model) {
            this.store.query('product-category', { type: 1 }).then(function (platforms) {
                controller.set('platforms', platforms);
            });
            controller.set('employees', this.get('employees'));
            this._super(controller, model);
        }
    });
});