define('retro-game-store/pods/sales/sale/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/scanner-detection', 'retro-game-store/mixins/modal-active'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsScannerDetection, _retroGameStoreMixinsModalActive) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    var next = _ember['default'].run.next;
    exports['default'] = Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsScannerDetection['default'], _retroGameStoreMixinsModalActive['default'], {
        print: service(),
        qz: service(),
        api: service(),
        categories: service(),
        i18n: service(),

        setupController: function setupController(controller, model, transition) {
            var _this = this;

            this._super.apply(this, arguments);

            if (get(controller, 'model.params.openWithReturnModal')) {
                transition.then(function () {
                    _this.openWithReturnModal();
                });
            }

            if (get(controller, 'model.params.openWithReturn')) {
                transition.then(function () {
                    _this.openWithReturn(get(controller, 'model.params.returnItemId'));
                });
            }

            //when entering the sale page, make sure no button (e.g. "+ New Sale")
            //is focused, so that scanner keypress events don't accidentally trigger
            //their activation, potentially causing a new sale page to be loaded
            if (document.activeElement) {
                next(function () {
                    return document.activeElement.blur();
                });
            }

            this.getSaleItems();
            controller.listenForScanner();
        },

        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                this.controller.ignoreScanner();
                get(this, 'api').clearErrors();
                controller.setProperties({
                    error: null,
                    saleItems: []
                });
            }
            this._super.apply(this, arguments);
        },

        openWithReturn: function openWithReturn(item) {
            var _this2 = this;

            var controller = this.controller;
            get(this, 'api').request('POST', '/returnSaleItems', {
                returnId: item,
                id: get(controller, 'model.id'),
                quantity: 1
            }).then(function (returnItem) {
                // Prevent searching for further sales
                setProperties(controller, {
                    inputDisabled: true,
                    buttonDisabled: true
                });
                //since this record was created by the back end, we now push it into the store and add it to the current list of sale items
                var returnItemAdded = controller.store.push(controller.store.normalize('saleItem', returnItem));
                get(controller, 'saleItems').addObject(returnItemAdded);
                _this2.refreshSale();
            });
        },

        openWithReturnModal: function openWithReturnModal() {
            var controller = this.controller;
            this.send('openModal', 'sales/sale/return-modal', null, 'sales/sale/return-modal');
            var transactionId = get(controller, 'model.params.transactionId');
            var modalController = this.controllerFor('sales/sale/return-modal');
            modalController.autoSearchSale(transactionId);
        },

        getSaleItems: function getSaleItems() {
            var controller = this.controller;
            var promise = this.store.query('saleItem', { id: get(controller, 'model.id') });
            set(controller, 'saleItemsPromise', promise);
            promise.then(function (items) {
                set(controller, 'saleItems', items.toArray());
            });
        },

        refreshSale: function refreshSale() {
            var controller = this.controller;
            get(controller, 'model').reload();
        },

        resetSale: function resetSale() {
            this.transitionTo('sales.new');
        },

        openModal: function openModal(location, model) {
            get(this, 'api').clearErrors();
            this.controller.ignoreScanner();
            this.send('openModal', location, model, location);
        },

        actions: {
            openCustomerModal: function openCustomerModal() {
                var _this3 = this;

                var location = 'sales/sale/add-customer-modal';
                this.pagingQuery('customer', {
                    top: 10
                }, location).then(function (models) {
                    _this3.openModal(location, models);
                });
            },

            getSaleItems: function getSaleItems() {
                this.getSaleItems();
            },

            openWithReturn: function openWithReturn(item) {
                this.openWithReturn(item);
            },

            openWithReturnModal: function openWithReturnModal() {
                this.openWithReturnModal();
            },

            refreshSale: function refreshSale() {
                this.refreshSale();
            },

            resetSale: function resetSale() {
                this.resetSale();
            },

            addInventoryToSale: function addInventoryToSale() {
                var _this4 = this;

                var location = 'sales/sale/add-inventory-modal';
                var controller = this.controllerFor(location);

                // Normally this is not necessary, but since we are not loading any models initially, we need to pass this manually so paging/filtering work
                controller.set('_typeKey', 'inventory');

                this.store.query('store', { skip: 0 }).then(function (stores) {
                    setProperties(controller, {
                        stores: stores,
                        storeFilter: get(_this4, 'session.user.storeInfo')
                    });
                });

                set(controller, 'productTypes', get(this, 'categories.raw'));

                this.openModal(location, null);
            },

            addTradeInToSale: function addTradeInToSale() {
                var location = 'sales/sale/trade-in-modal';
                var controller = this.controllerFor(location);
                controller.listenForScanner();

                // Normally this is not necessary, but since we are not loading any models initially, we need to pass this manually so paging/filtering work
                setProperties(controller, {
                    _typeKey: 'product',
                    productTypes: get(this, 'categories.raw')
                });

                this.openModal(location, null);
            },

            addReturnToSale: function addReturnToSale() {
                var location = 'sales/sale/return-modal';
                this.openModal(location, null);
            },

            editSaleItem: function editSaleItem(item) {
                var location = 'sales/sale/line-item-modal',
                    controller = this.controllerFor(location);
                setProperties(controller, {
                    originalQuantity: get(item, 'quantity'),
                    originalDiscountType: get(item, 'discountType'),
                    originalHasDiscount: get(item, 'hasDiscount')
                });
                this.openModal(location, item);
            },

            addPayment: function addPayment(sale) {
                var location = 'sales/sale/payment-modal',
                    controller = this.controllerFor(location);
                set(controller, 'creditCard', this.store.createRecord('credit-card'));
                this.openModal(location, sale);
            },

            payCustomer: function payCustomer(sale) {
                var location = 'sales/sale/pay-customer-modal';
                this.openModal(location, sale);
            },

            printReceipt: function printReceipt(sale, items) {
                var _this5 = this;

                var affiliateName = get(this, 'session.user.affiliate.name');
                var controller = this.controllerFor('sales/sale');
                controller.activatePrintButton();
                get(this, 'print').printReceipt(affiliateName, sale, items).then(function () {
                    controller.resetPrintButton();
                    set(controller, 'error', null);
                })['catch'](function () {
                    controller.resetPrintButton();
                    set(controller, 'error', get(_this5, 'qz.receiptPrinterIsConnected') ? get(_this5, 'i18n').t('sales.printError') : null);
                });
            },

            printAuthorizationReceipt: function printAuthorizationReceipt(sale) {
                var _this6 = this;

                var affiliateName = get(this, 'session.user.affiliate.name');
                var controller = this.controllerFor('sales/sale');
                get(this, 'print').printAuthorizationReceipt(affiliateName, sale)['catch'](function () {
                    set(controller, 'error', get(_this6, 'qz.receiptPrinterIsConnected') ? get(_this6, 'i18n').t('sales.printError') : null);
                });
            },

            editShipping: function editShipping(sale) {
                var location = 'sales/sale/edit-shipping-modal';
                this.openModal(location, sale);
            },

            editTax: function editTax(sale) {
                var location = 'sales/sale/edit-tax-modal';
                this.openModal(location, sale);
            },

            editDiscount: function editDiscount(sale) {
                var location = 'sales/sale/edit-discount-modal';
                this.openModal(location, sale);
            },

            addMiscItem: function addMiscItem(sale) {
                var location = 'sales/sale/add-misc-modal';
                this.openModal(location, sale);
            },

            addDescription: function addDescription(sale) {
                var location = 'sales/sale/stash-modal';
                this.openModal(location, sale);
            }
        }
    });
});