define('retro-game-store/pods/customers/new/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        validations: {
            'model.firstName': {
                presence: true
            },
            'model.lastName': {
                presence: true
            },
            'model.email': {
                presence: true
            },
            'model.mobile': {
                presence: true
            },
            'model.creditBalance': {
                numericality: {
                    greaterThanOrEqualTo: 0
                }
            }
        },
        affiliateId: _ember['default'].computed.alias('session.user.affiliateId'),
        actions: {
            save: function save(callback) {
                var _this = this;

                this.get('model').set('affiliateId', this.get('affiliateId'));
                var promise = this.get('model').save().then(function (model) {
                    _this.transitionToRoute('customers.customer.details', model.get('id'));
                });
                callback(promise);
            }
        }
    });
});