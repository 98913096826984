define('retro-game-store/pods/user/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            storeInfo: {
                serialize: 'records',
                deserialize: 'records'
            },
            affiliate: {
                serialize: 'records',
                deserialize: 'records'
            }
        },
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            payload.storeInfo = payload.store;
            delete payload.store;
            return this._super(store, primaryModelClass, payload, id, requestType);
        }
    });
});