define('retro-game-store/pods/customers/customer/trades/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/authenticated-route'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsAuthenticatedRoute) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var Route = _ember['default'].Route;
    exports['default'] = Route.extend(_retroGameStoreMixinsAuthenticatedRoute['default'], _retroGameStoreMixinsRoutePagination['default'], {
        model: function model(params) {
            var customerId = this.modelFor('customers.customer').get('id');
            var controller = this.controllerFor('customers.customer.trades');
            set(controller, 'customerId', customerId);
            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQueryWithMeta('sale-item-summary', queryParams, 'customers.customer.trades');
        },
        setupController: function setupController(controller, model) {
            set(controller, 'customerId', get(controller, 'customerId'));
            get(this, 'store').query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });
            this._super(controller, model);
        }
    });
});