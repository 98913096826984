define("retro-game-store/pods/components/color-table-money-cell/component", ["exports", "ember", "accounting/unformat", "accounting/to-fixed"], function (exports, _ember, _accountingUnformat, _accountingToFixed) {
    exports["default"] = _ember["default"].Component.extend({
        valueSign: _ember["default"].computed('value', function () {
            var value = (0, _accountingUnformat["default"])((0, _accountingToFixed["default"])(this.get('value'), 2));
            if (value > 0) {
                return 'positive';
            } else if (value < 0) {
                return 'negative';
            }
        }),
        classNameBindings: ['valueSign']
    });
});