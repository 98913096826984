define('retro-game-store/pods/sale-item/model', ['exports', 'ember', 'ember-data', 'ember-awesome-macros/equal', 'ember-awesome-macros/raw', 'ember-awesome-macros/not', 'ember-awesome-macros/and', 'retro-game-store/constants/event-types'], function (exports, _ember, _emberData, _emberAwesomeMacrosEqual, _emberAwesomeMacrosRaw, _emberAwesomeMacrosNot, _emberAwesomeMacrosAnd, _retroGameStoreConstantsEventTypes) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        returnItem: attr(),

        basePrice: attr('number'),
        cashPrice: attr('number'),
        conditionId: attr('number'),
        description: attr('string'),
        discount: attr('number'),
        discountPercent: attr('percent'),
        discountTotal: attr('number'),
        eventType: attr('string'),
        inventoryItemId: attr('number'),
        inventoryNotes: attr('string'),
        miscCostOfGoods: attr('number'),
        productSkuId: attr('number'),
        quantity: attr('number'),
        quantityAvailable: attr('number'),
        quantityReturned: attr('number'),
        quantityReturnable: attr('number'),
        returnItemId: attr('number'),
        sellPrice: attr('number'),
        subtotal: attr('number'),
        tax: attr('number'),
        taxable: attr('boolean'),
        tradePrice: attr('number'),
        transactionId: attr('number'),
        willShip: attr('boolean'),

        //Relationships
        condition: belongsTo('condition'),
        inventoryItem: belongsTo('inventory'),
        productSku: belongsTo('product-sku'),
        transaction: belongsTo('sale'),

        //Computeds
        eventTypeDisplay: computed('eventType', function () {
            var eventType = get(this, 'eventType');
            if (eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type')) {
                return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'label');
            } else {
                return eventType;
            }
        }),

        fullCategory: computed('isReturn', 'returnItem.eventType', 'productSku.product.{productType.name,category.name}', function () {
            var isMiscReturnItem = get(this, 'isReturn') && get(this, 'returnItem.eventType') === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type');
            if (isMiscReturnItem) {
                return 'in ' + get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'label');
            } else {
                return 'in ' + get(this, 'productSku.product.productType.name') + ' / ' + get(this, 'productSku.product.category.name');
            }
        }),

        discountType: computed('discount', 'discountPercent', function () {
            var cash = get(this, 'discount');
            var percent = get(this, 'discountPercent');
            return cash !== 0 && percent === 0 ? 'cash' : 'percent';
        }),

        isReturn: (0, _emberAwesomeMacrosEqual['default'])('eventType', (0, _emberAwesomeMacrosRaw['default'])(get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_RETURN, 'type'))),
        isSale: (0, _emberAwesomeMacrosEqual['default'])('eventType', (0, _emberAwesomeMacrosRaw['default'])(get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE, 'type'))),
        hasDiscount: (0, _emberAwesomeMacrosNot['default'])((0, _emberAwesomeMacrosAnd['default'])((0, _emberAwesomeMacrosEqual['default'])('discount', 0), (0, _emberAwesomeMacrosEqual['default'])('discountPercent', 0))),
        notTaxable: (0, _emberAwesomeMacrosNot['default'])('taxable')
    });
});