define('retro-game-store/pods/components/add-inventory-item/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    exports['default'] = Component.extend({
        classNames: ['add-inventory-item-component'],
        tagName: 'tr',

        quantity: computed('model.quantityAvailable', function () {
            return get(this, 'model.quantityAvailable') ? 1 : 0;
        }),

        actions: {
            addItem: function addItem() {
                set(this, 'model.quantity', get(this, 'quantity'));
                this.sendAction('addInventoryItem', get(this, 'model'));
            }
        }
    });
});