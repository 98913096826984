define('retro-game-store/pods/product-price/model', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        name: attr(),
        productId: attr(),
        quantity: attr(),
        sourceId: attr(),

        //Relationships
        affiliateId: belongsTo('affiliate'),
        category: belongsTo('product-category'),
        condition: belongsTo('condition'),
        productType: belongsTo('product-type'),

        //Computeds
        fullCategory: computed('productType.name', 'category.name', function () {
            return get(this, 'productType.name') + ' / ' + get(this, 'category.name');
        })
    });
});