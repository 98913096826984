define('retro-game-store/pods/stores/store/receipt-details/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var A = _ember['default'].A;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    var isEmpty = _ember['default'].isEmpty;
    var service = _ember['default'].inject.service;
    var computed = _ember['default'].computed;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        api: service(),
        i18n: service(),

        maxFileSize: 2097152,
        allowedFileExts: ['.jpg', '.jpeg', '.png', '.bmp'],
        fileExtMimes: {
            '.jpg': 'image/jpeg',
            '.jpeg': 'image/jpeg',
            '.png': 'image/png',
            '.bmp': 'image/bmp'
        },

        logoErrors: null,
        deleteCurrentLogo: false,
        selectedLogoFile: null,
        selectedLogoExt: null,

        currentLogo: computed('model.receiptLogo', 'selectedLogoFile', 'selectedLogoExt', 'fileExtMimes', function () {
            var data = get(this, 'selectedLogoFile') || get(this, 'model.receiptLogo.image');
            var ext = get(this, 'selectedLogoExt') || get(this, 'model.receiptLogo.fileExt');
            var mimes = get(this, 'fileExtMimes');
            return data ? 'data:' + mimes[ext] + ';base64,' + data : null;
        }),

        updateOrDeleteLogo: function updateOrDeleteLogo() {
            var _this = this;

            var deleteLogo = get(this, 'deleteCurrentLogo');
            var storeId = get(this, 'model.id');
            var selectedLogoFile = get(this, 'selectedLogoFile');
            var selectedLogoExt = get(this, 'selectedLogoExt');

            var deletePromise = RSVP.resolve();
            var updatePromise = RSVP.resolve();

            if (deleteLogo) {
                deletePromise = get(this, 'api').request('DELETE', '/store-logos/' + storeId);
            }

            if (selectedLogoFile) {
                updatePromise = deletePromise.then(function () {
                    return get(_this, 'api').request('PUT', '/store-logos/' + storeId, {
                        fileExt: selectedLogoExt,
                        image: selectedLogoFile
                    });
                });
            }

            return updatePromise;
        },

        actions: {
            save: function save(callback) {
                var _this2 = this;

                var promise = this.updateOrDeleteLogo().then(function () {
                    return get(_this2, 'model').save();
                }).then(function () {
                    return setProperties(_this2, {
                        deleteCurrentLogo: false,
                        selectedLogoFile: null,
                        selectedLogoExt: null
                    });
                });
                callback(promise);
            },

            onFileSelect: function onFileSelect(file) {
                var _this3 = this;

                set(this, 'logoErrors', null);

                if (file) {
                    var _ret = (function () {
                        var ext = ('.' + file.name.split('.').pop()).toLowerCase();
                        var errors = A();

                        if (!get(_this3, 'allowedFileExts').includes(ext)) {
                            errors.pushObject('stores.store.receiptDetails.logoErrors.fileType');
                        }

                        if (file.size > get(_this3, 'maxFileSize')) {
                            errors.pushObject('stores.store.receiptDetails.logoErrors.fileSize');
                        }

                        if (!isEmpty(errors)) {
                            set(_this3, 'logoErrors', errors);
                            return {
                                v: undefined
                            };
                        }

                        var reader = new FileReader();
                        reader.addEventListener('load', function () {
                            //backend needs to receive/store the file data w/o the data url declaration prefix
                            var data = reader.result.replace(/^data\:.*\/.*;base64\,/, '');
                            setProperties(_this3, {
                                selectedLogoFile: data,
                                selectedLogoExt: ext
                            });
                        });
                        reader.readAsDataURL(file);
                    })();

                    if (typeof _ret === 'object') return _ret.v;
                }
            },

            removeLogo: function removeLogo() {
                var hasLogo = !isEmpty(get(this, 'model.receiptLogo.id'));

                if (hasLogo) {
                    set(this, 'model.logo', null);
                }

                setProperties(this, {
                    deleteCurrentLogo: hasLogo,
                    selectedLogoFile: null,
                    selectedLogoExt: null,
                    logoErrors: null
                });
            }
        }
    });
});