define('retro-game-store/pods/components/category-dropdown/delete-items/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var reads = _ember['default'].computed.reads;
    var tryInvoke = _ember['default'].tryInvoke;
    exports['default'] = Component.extend({
        arrayTotal: reads('selectedItems.length'),
        actions: {
            clear: function clear() {
                tryInvoke(this.attrs, 'clear');
            }
        }
    });
});