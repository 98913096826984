define('retro-game-store/pods/sales/sale/line-item-modal/controller', ['exports', 'ember', 'ember-validations', 'ember-awesome-macros/sum', 'retro-game-store/constants/event-types'], function (exports, _ember, _emberValidations, _emberAwesomeMacrosSum, _retroGameStoreConstantsEventTypes) {
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var equal = _Ember$computed.equal;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var _get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        scanner: service(),

        saleController: controller('sales.sale'),
        saleModel: reads('saleController.model'),
        miscEvent: _retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE,
        tradeEvent: _retroGameStoreConstantsEventTypes.EVENT_TYPE_TRADE,
        saleEvent: _retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE,
        buyEvent: _retroGameStoreConstantsEventTypes.EVENT_TYPE_BUY,
        returnEvent: _retroGameStoreConstantsEventTypes.EVENT_TYPE_RETURN,
        itemNoTax: false,
        quantity: reads('model.quantity'),
        isTrade: equal('model.eventType', _get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRADE, 'type')),
        quantityReturnable: (0, _emberAwesomeMacrosSum['default'])('originalQuantity', 'model.returnItem.quantityReturnable'),
        originalQuantity: 0,
        originalDiscountType: 'percent',
        originalHasDiscount: false,

        validations: {
            'model.discountPercent': {
                numericality: {
                    messages: {
                        numericality: ' must be a number between 0 and 100'
                    },
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 100
                }
            },
            'model.basePrice': true,
            'model.description': {
                inline: (0, _emberValidations.validator)(function () {
                    if (_get(this, 'model.model') && _get(this, 'model.model.eventType') === _get(this, 'miscEvent.type') && !_get(this, 'model.model.description')) {
                        return " can't be blank";
                    }
                })
            },
            'model.discount': {
                inline: (0, _emberValidations.validator)(function () {
                    if (_get(this, 'model') && _get(this, 'model.basePrice')) {
                        if (parseFloat(_get(this, 'model.discount'), 10) > _get(this, 'model.basePrice')) {
                            return " can't be greater than the item price";
                        }
                    }
                })
            }
        },

        quantityAvailable: computed('isTrade', 'originalQuantity', 'model.quantityAvailable', function () {
            var isTrade = _get(this, 'isTrade');
            return isTrade ? undefined : _get(this, 'originalQuantity') + _get(this, 'model.quantityAvailable');
        }),

        basePrice: computed('model.id', 'saleController.tradeTypeIsCredit', {
            //We are making the negative number server is sending positive in the context of this modal
            get: function get() {
                if (_get(this, 'model')) {
                    if (_get(this, 'saleController.tradeTypeIsCredit')) {
                        return Math.abs(_get(this, 'model.tradePrice'));
                    } else {
                        return Math.abs(_get(this, 'model.cashPrice'));
                    }
                }
            },
            set: function set(key, value) {
                return value;
            }
        }),

        setBasePrice: function setBasePrice(price) {
            if (_get(this, 'saleController.tradeTypeIsCredit')) {
                set(this, 'model.tradePrice', price);
            } else {
                set(this, 'model.cashPrice', price);
            }
        },

        reset: function reset() {
            set(this, 'model', null);
        },

        closeModal: function closeModal() {
            this.send('closeModal');
            this.reset();
            _get(this, 'saleController').listenForScanner();
        },

        actions: {
            cancel: function cancel() {
                _get(this, 'model').rollbackAttributes();
                if (_get(this, 'originalHasDiscount') !== _get(this, 'model.hasDiscount')) {
                    set(this, 'model.hasDiscount', _get(this, 'originalHasDiscount'));
                }
                if (_get(this, 'originalDiscountType') !== _get(this, 'model.discountType')) {
                    set(this, 'model.discountType', _get(this, 'originalDiscountType'));
                }
                this.closeModal();
            },

            toggleItemNoTax: function toggleItemNoTax() {
                var notTaxable = _get(this, 'model.notTaxable');
                set(this, 'model.taxable', !notTaxable ? false : true);
            },

            updateDiscountType: function updateDiscountType() {
                set(this, 'model.discountPercent', 0);
                set(this, 'model.discount', 0);
            },

            save: function save(callback) {
                var _this = this;

                var applyDiscount = _get(this, 'model.hasDiscount');
                var discount = _get(this, 'model.discount');
                var discountPercent = _get(this, 'model.discountPercent');
                if (!applyDiscount) {
                    set(this, 'model.discountPercent', 0);
                    set(this, 'model.discount', 0);
                }
                if (applyDiscount && discountPercent === 0 && discount === 0) {
                    set(this, 'model.hasDiscount', false);
                }
                if (applyDiscount && discount) {
                    //convert cash discount back to negative on save
                    set(this, 'model.discount', -Math.abs(discount));
                }
                if (_get(this, 'basePrice') || _get(this, 'basePrice') === 0) {
                    var basePrice = _get(this, 'model.basePrice');
                    var absBasePrice = Math.abs(_get(this, 'model.basePrice'));
                    var saleType = _get(this, 'model.eventType');
                    if (saleType === _get(this, 'miscEvent.type')) {
                        this.setBasePrice(basePrice);
                    } else if (saleType === _get(this, 'saleEvent.type')) {
                        this.setBasePrice(absBasePrice);
                    } else {
                        // first check if number is negative...if not, convert back to negative number for server
                        this.setBasePrice(-absBasePrice);
                    }
                }
                var promise = _get(this, 'model').save().then(function () {
                    _this.closeModal();
                    _this.send('refreshSale');
                })['catch'](function (errors) {
                    set(_this, 'model.quantity', _get(_this, 'originalQuantity'));
                    _this.send('errors', errors);
                });
                callback(promise);
            }
        }
    });
});