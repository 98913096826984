define('retro-game-store/pods/components/input-validator/component', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var isEmpty = _ember['default'].isEmpty;
    var set = _ember['default'].set;
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['input-validator'],
        classNameBindings: ['formGroup:form-group'],
        formGroup: true,
        labelWidthClass: 'col-md-4',
        inputWidthClass: 'col-md-4',
        targetObject: (function () {
            if (get(this, 'target')) {
                return get(this, 'target');
            } else {
                return get(this, 'parentView');
            }
        }).property('parentView', 'target'),
        hasError: false,
        error: null,
        validationTarget: 'model',
        label: true,
        focusOut: function focusOut() {
            if (get(this, 'targetObject.model')) {
                var value = get(this, 'targetObject.model.' + get(this, 'name'));
                if (!value) {
                    return;
                }
            }
            return set(this, 'hasError', !isEmpty(get(this, 'error')));
        },
        fieldLabel: (function () {
            if (get(this, 'text')) {
                return get(this, 'text');
            } else {
                return get(this, 'nameString');
            }
        }).property('nameString', 'text'),
        nameString: (function () {
            return get(this, 'name').string ? get(this, 'name').string : get(this, 'name');
        }).property('name'),
        displayAllErrors: (function () {
            if (get(this, 'targetObject.showValidationFields') === true) {
                set(this, 'hasError', !isEmpty(get(this, 'error')));
            }
            return get(this, 'targetObject.showValidationFields');
        }).observes('targetObject.showValidationFields'),
        setInputId: (function () {
            var id = this.$('input').attr('id');
            this.$('label').attr('for', id);
            // this.set('inputId', this.$('input').attr('id'));
        }).on('didRender'),
        setupInputHelper: (function () {
            var name = get(this, 'nameString');
            if (get(this, 'validationTarget') === 'model') {
                name = get(this, 'validationTarget') + '.' + name;
            }
            _ember['default'].Binding.from("targetObject.errors." + name).to("error").connect(this);
        }).on('didInsertElement')
    });
});