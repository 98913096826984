define("retro-game-store/initializers/ember-i18n", ["exports", "retro-game-store/instance-initializers/ember-i18n"], function (exports, _retroGameStoreInstanceInitializersEmberI18n) {
  exports["default"] = {
    name: _retroGameStoreInstanceInitializersEmberI18n["default"].name,

    initialize: function initialize() {
      var application = arguments[1] || arguments[0]; // depending on Ember version
      if (application.instanceInitializer) {
        return;
      }

      _retroGameStoreInstanceInitializersEmberI18n["default"].initialize(application);
    }
  };
});