define("retro-game-store/utils/print-format-helpers", ["exports", "accounting/format-money"], function (exports, _accountingFormatMoney) {
    exports.getSplitLine = getSplitLine;
    exports.getItemLines = getItemLines;
    exports.getPaymentLines = getPaymentLines;
    exports.getTitleLine1 = getTitleLine1;
    exports.getTitleLine2 = getTitleLine2;
    exports.getPlatformLine = getPlatformLine;
    exports.getPlatformDots = getPlatformDots;
    exports.getPriceDots = getPriceDots;
    exports.getConditionDots = getConditionDots;

    var maxChars = 32;
    var wrapLengthReceipt = 42;
    var wrapLengthLabel = 30;
    var dotsPerChar = 13;

    function getSplitLine(leftAlign, rightAlign) {
        if (!rightAlign) {
            rightAlign = " ";
        }
        if (!leftAlign) {
            leftAlign = " ";
        }

        rightAlign = rightAlign.padStart(wrapLengthReceipt - leftAlign.length, ' ');
        return ['\x1B' + '\x61' + '\x30', leftAlign + rightAlign, '\x0A'];
    }

    function getItemLines(items) {
        var commands = [];
        items.forEach(function (item) {
            var name = item.Description.substring(0, maxChars);
            var price = (0, _accountingFormatMoney["default"])(item.Price - (item.Discount || 0));

            commands = commands.concat(getSplitLine(name, price));

            if (item.Discount && item.Discount !== 0) {
                var amount = (0, _accountingFormatMoney["default"])(item.Discount);
                var text = '    DISCOUNT ';
                if (item.DiscountPercent && item.DiscountPercent !== 0) {
                    text = text + '(' + Math.round(item.DiscountPercent) + '%) ';
                }

                commands = commands.concat(getSplitLine(text, amount));
            }

            if (item.Quantity > 1) {
                var dividedPrice = (0, _accountingFormatMoney["default"])(item.Price / item.Quantity);
                commands.push('\x1B' + '\x61' + '\x30', // left align
                '    ' + item.Quantity + ' @ ' + dividedPrice, '\x0A');
            }

            if (item.IsTradein) {
                commands.push('\x1B' + '\x61' + '\x30', // left align
                '    TRADE-IN', '\x0A');
            }

            if (item.ReturnNumber && item.ReturnNumber > 0) {
                commands.push('\x1B' + '\x61' + '\x30', // left align
                '    RETURN #' + item.ReturnNumber, '\x0A');
            }

            if (item.IsShipment) {
                commands.push('\x1B' + '\x61' + '\x30', // left align
                '    TO BE SHIPPED', '\x0A');
            }
        });

        return commands;
    }

    function getPaymentLines(payments) {
        var commands = [];
        payments.forEach(function (p) {
            var description = p.Description.padEnd(17, ' ');
            var amount = (0, _accountingFormatMoney["default"])(p.AmountApplied || p.AmountTendered || 0);

            commands = commands.concat(getSplitLine(description, amount));
        });

        return commands;
    }

    function getTitleLine1(title) {
        if (title.length <= wrapLengthLabel) {
            return title;
        }
        return title.substring(0, wrapLengthLabel);
    }

    function getTitleLine2(title, platform) {
        if (title.length <= wrapLengthLabel) {
            return '';
        }

        platform = platform.substring(0, wrapLengthLabel - 5);
        title = title.substring(wrapLengthLabel).trim();

        if (title.length + platform.length + 3 <= wrapLengthLabel) {
            return title;
        }

        title = title.substring(0, wrapLengthLabel - (platform.length + 5)).trim();
        title += '.. ';
        return title;
    }

    function getPlatformLine(platform) {
        platform = platform.substring(0, wrapLengthLabel - 5);
        return '[' + platform + ']';
    }

    function getPlatformDots(secondTitleLine) {
        return secondTitleLine.length * dotsPerChar + 14;
    }

    function getPriceDots(price) {
        if (price < 10) {
            return 300;
        } else if (price < 100) {
            return 280;
        } else if (price < 1000) {
            return 260;
        } else if (price < 10000) {
            return 230;
        } else {
            return 210;
        }
    }

    function getConditionDots(condition) {
        if (condition === 'NEW') {
            return 265;
        }
        return 179;
    }
});