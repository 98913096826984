define("retro-game-store/locales/en/translations", ["exports"], function (exports) {
    exports["default"] = {
        actions: {
            cancel: "Cancel",
            confirmation: 'Are you sure you want to {{actionType}} this item?',
            done: "Done",
            remove: "Remove",
            "return": "Return",
            returnItem: "Return Item",
            returnItems: "Return Items",
            viewSale: "View Sale",
            "void": "Void",
            voidInvoice: "Void Invoice"
        },
        inventoryItems: {
            one: "{{count}} Inventory item",
            other: '{{count}} Inventory items'
        },
        add: {
            label: 'Add'
        },
        added: {
            label: 'Added'
        },
        admin: {
            label: 'Admin'
        },
        back: {
            label: 'Back'
        },
        cancel: {
            label: 'Cancel'
        },
        close: {
            label: 'Close'
        },
        create: {
            label: 'Create'
        },
        email: {
            label: 'Email Address'
        },
        header: {
            cardReader: 'Card Reader',
            cashDrawer: 'Cash Drawer',
            help: 'Help',
            labelPrinter: 'Label Printer',
            logOut: 'Log out',
            needPrinting: {
                one: 'needs printing',
                other: 'need printing'
            },
            newSale: {
                label: 'New Sale'
            },
            receiptPrinter: 'Receipt Printer',
            reportIssue: 'Report Issue',
            printModal: {
                confirmSuccess: "Confirm Success",
                printLabels: "Print Labels",
                printNext: "Print Next",
                nextTwentyFive: "Next 25",
                nextOneHundred: "Next 100",
                itemsToBePrinted: "{{count}} Inventory items with labels to be printed",
                printedSuccessfully: "{{count}} printed successfully",
                calibrate: "Calibrate",
                calibrateMessage: "If needed, install inventory labels and perform calibration",
                clearQueue: "Clear Queue",
                calibrateError: "An error occurred while attempting to calibrate the printer.",
                printError: "An error occurred while attempting to print the labels."
            },
            settings: "Print Settings"
        },
        loading: {
            label: 'Loading...'
        },
        misc: {
            all: 'All',
            "for": 'for',
            "in": 'in',
            none: 'None'
        },
        save: {
            label: 'Save'
        },
        view: {
            label: 'View'
        },
        login: {
            backTo: 'Back To Login',
            label: 'Login',
            description: 'FulcrumOS supports buy/sell/trade operations in addition to sales management, in-depth reporting and customer inventory notifications',
            signIn: 'Sign in to FulcrumOS'
        },
        notApplicable: {
            label: 'N/A'
        },
        password: {
            label: 'Password',
            confirm: 'Confirm Password',
            forgot: 'Forgot Password',
            reset: {
                label: 'Reset Your Password',
                tokenInvalid: 'The reset password token is invalid'
            },
            sendForgotEmail: 'Send Email'
        },
        errors: {
            authentication: "Login failed.",
            inclusion: " is not included in the list",
            exclusion: " is reserved",
            invalid: " is invalid",
            confirmation: " doesn't match {{attribute}}",
            accepted: " must be accepted",
            empty: " can't be empty",
            blank: " can't be blank",
            present: " must be blank",
            tooLong: " is too long (maximum is {{count}} characters)",
            tooShort: " is too short (minimum is {{count}} characters)",
            wrongLength: " is the wrong length (should be {{count}} characters)",
            notANumber: " is not a number",
            notAnInteger: " must be an integer",
            greaterThan: " must be greater than {{count}}",
            greaterThanOrEqualTo: " must be greater than or equal to {{count}}",
            equalTo: " must be equal to {{count}}",
            lessThan: " must be less than {{count}}",
            lessThanOrEqualTo: " must be less than or equal to {{count}}",
            otherThan: " must be other than {{count}}",
            odd: " must be odd",
            even: " must be even"
        },
        faqs: {
            label: 'FAQs',
            contactInformation: 'Contact Information',
            haveAQuestion: 'Have a question?',
            cancel: {
                header: 'How do I cancel my subscription?',
                steps: '<ol><li>Go to the Subscription tab under Admin</li><li>Click on Subscription in the left menu (under General)</li><li>Click cancel renewal</li><li>Click \'OK\'</li></ol>'
            },
            renew: {
                header: 'How do I renew my subscription?',
                steps: '<ol><li>Contact your representative to get the Subscription plan ID</li><li>Go to the Subscription tab under Admin</li><li>Click on Subscription in the left menu (under General)</li><li>Click the renew button</li><li>Enter the plan ID and Click \'OK\'</li></ol>'
            },
            logo: {
                header: 'How do I show a logo on my receipt?',
                steps: '<ol><li>Download an image to the local computer. PNG and BMP are preferred. Ensure the picture is black and white only, no gray</li><li>Open the terminal app</li><li>Press F11</li><li>In the logo text field (2nd from top), paste the entire path to the image file, including the drive. Example: C:/SomeFolder/logoImage.png</li><li>Click "OK"</li></ol>'
            }
        },
        user: {
            profile: 'Profile',
            firstName: 'First Name',
            lastName: 'Last Name',
            store: {
                pickDefault: 'Select a default store'
            },
            "switch": 'Switch User'
        },
        affiliates: {
            label: "Affiliates",
            searchByKeyword: "Search by keyword",
            backTo: 'Back to Affiliates List',
            activeStores: 'stores active',
            unlimitedActiveStores: 'stores active (unlimited)',
            affiliate: {
                addPlatformSpecificTradeOffer: 'Add platform-specific trade % offer',
                companyName: "Company Name",
                contactFirstName: "First Name",
                contactLastName: "Last Name",
                contactEmail: "Email Address",
                maxStoreLimit: "Max Store Limit",
                maxStoreLimitInfo: "Specify the maximum number of stores this subscriber can manage. Leave blank to accommodate unlimited stores.",
                noOverridesFound: "No platform-specific overrides found. Add items using the + button below.",
                phoneNumber: "Phone Number",
                subscriptionPlanId: "Subscription Plan ID",
                subscriptionPlanIdInfo: "Specify the Plan ID of the monthly subscription that will be used for billing. See Stripe's dashboard for existing plans and plan details.",
                creditCard: {
                    number: "Credit Card Number",
                    numberPlaceholder: "Card saved on file",
                    expiration: "Expiration Date",
                    name: "Name on Card"
                },
                urlFragment: 'URL Fragment',
                urlFragmentInfo: "Specify a unique identifier for this company's POS endpoint. For example a value of 'my-company' would make the endpoint https://pos.retrogamestore.com/my-company.",
                cashDiscount: 'Additional Cash Discount',
                cashDiscountInfo: "A default value of additional cash discount. This is the recommended percentage less (than trade price) to offer in cash during the trade-in process.",
                tradeMargin: 'Global Trade % Offer',
                tradeMarginInfo: "The default percentage you're willing to offer in trade based on the items selling price. For example, 30% would offer $3 in trade for an item that will be sold at $10.",
                options: {
                    category: 'Category',
                    categorySelected: "Platform specific trade % offer already exists. Please select another item or edit the platform specific trade % offer directly",
                    tradePercentage: 'Trade Percentage',
                    cashDiscount: 'Cash Discount',
                    globalValues: 'Global Values',
                    platformSpecificTradeOffers: 'Platform-Specific Trade % Offers',
                    add: 'Add',
                    tradePercentOffers: 'Trade % Offers',
                    testConnectivity: 'Test Connectivity',
                    enableShipping: 'Enable Shipping',
                    enableExternalPayments: 'Enable External Payments',
                    enableStripe: 'Enable Credit Card Processing',
                    edit: 'Edit',
                    remove: 'Remove',
                    tradePercentageModalTitle: 'Platform Specific Trade % Offers',
                    selectAGateway: 'Please select a gateway'
                }
            }
        },
        sales: {
            addModal: {
                addMiscItem: 'Add Misc Item',
                age: 'Age',
                available: 'Available',
                cancel: 'Cancel',
                category: 'Category',
                name: 'Name',
                noInventoryFound: 'No inventory found. Please search by product',
                itemsFound: {
                    one: '{{count}} item found',
                    other: '{{count}} items found'
                },
                price: 'Price',
                quantity: 'Quantity',
                stockNumber: 'Stock Number',
                store: 'Store'
            },
            addOne: 'Add one',
            addPayment: 'Add Payment',
            cancelThisSale: 'Cancel this sale',
            costOfGoods: "COGS",
            customer: 'Customer',
            customerNum: 'Customer #{{id}}',
            deleteConfirm: 'The current sale will be canceled and a new sale initiated. Continue?',
            details: {
                discount: "Discount",
                edit: "Edit",
                editTaxPercent: 'Edit Tax %',
                label: 'Details',
                noTax: 'No Tax',
                purchases: "Purchases",
                retailValue: "Retail Value",
                returns: "Returns",
                shipping: "Shipping",
                tax: "Tax",
                total: "Total",
                trades: "Trades"
            },
            discountWithValue: 'Discount: ({{amount}})',
            discountModal: {
                additionalDiscount: "Additional Discount",
                editDiscount: "Edit Discount",
                lineItemDiscounts: "Line Item Discounts",
                purchases: "Purchases",
                specifyCash: "Specify $",
                specifyPercent: "Specify %",
                subtotal: "Subtotal"
            },
            item: 'Item',
            lineItemModal: {
                discount: 'Discount'
            },
            "new": {
                add: 'Add',
                "return": 'Return',
                trade: 'Trade'
            },
            noCustomerSelected: 'No Customer Selected.',
            noItems: 'No items have been added to this sale.',
            payCustomer: 'Pay Customer',
            platform: 'Platform',
            price: 'Price',
            printReceipt: 'Print Receipt',
            printError: "An error occurred while attempting to print the receipt.",
            quantity: 'Qty',
            returnModal: {
                available: "Available",
                name: "Name",
                noReturnItemsFound: "No items found",
                price: "Price",
                stockNumber: "Stock #",
                quantity: "Quantity"
            },
            saveForLater: 'Save for Later',
            shippedMessage: 'Shipped items need a customer selected',
            subtotal: 'Subtotal',
            willShip: 'Will Ship',
            sale: {
                label: 'Sale',
                tradeInModal: {
                    inventoryNotes: 'Inventory notes',
                    willSellFor: 'Will sell for'
                }
            }
        },
        stores: {
            label: 'Stores',
            allStores: 'All Stores',
            searchByKeyword: 'Search by keyword',
            backTo: 'Back to Store List',
            maxStoreLimitError: {
                'one': 'Your current subscription tier limits you to the management of a single store. Please contact your sales representative to upgrade your subscription',
                'other': 'Your current subscription tier limits you to the management of {{count}} stores. Please contact your sales representative to upgrade your subscription'
            },
            store: {
                address: 'Street Line 1',
                address2: 'Street Line 2',
                city: 'City',
                defaultShipping: 'Default Shipping',
                defaultTax: 'Default Tax (%)',
                details: 'Store Details',
                employees: 'Employees',
                name: 'Store Name',
                phoneNumber: 'Phone Number',
                state: 'State',
                zip: 'Zip',
                labels: {
                    boxAndMediaLabels: 'Box and Media Labels',
                    boxLabels: 'Box labels',
                    printBoxLabelsFor: 'Print box labels for',
                    printMediaLabelsFor: 'Print media labels for',
                    condition: 'Condition',
                    createNewLabelOptions: 'Create New Label Options',
                    doNotPrint: 'Do not print',
                    mediaLabels: 'Media labels',
                    print: 'Print',
                    productType: 'Product type',
                    instructions: 'Specify whether to print media and/or box labels when adding inventory.'
                },
                labelOptionsModal: {
                    condition: 'Condition',
                    boxLabels: 'Box Labels',
                    done: 'Done',
                    editLabelOption: 'Edit Label Option',
                    mediaLabels: 'Media Labels',
                    selectPrintingOptionsFor: 'Select printing options for'
                },
                receiptDetails: {
                    label: 'Receipt Details',
                    returnPolicy: 'Return Policy',
                    logo: 'Logo',
                    removeLogo: "Remove",
                    logoReqs: {
                        title: "Image requirements",
                        fileType: ".PNG, .JPG/.JPEG, or .BMP format only",
                        fileSize: "Maximum filesize of 2MB",
                        monochrome: "Use black and white images for best results"
                    },
                    logoErrors: {
                        fileType: "Logo is not the correct format (.PNG, .JPG/.JPEG, or .BMP only)",
                        fileSize: "Logo is too large (2MB max)"
                    },
                    logoInstructions: {
                        label: 'Add a logo',
                        stepOne: 'Download an image to the local computer. Note that only JPG and BMP images are supported',
                        stepOneA: 'If using a BMP, ensure the image is formatted as "Monochrome" (ie. black and white only, no gray) by using MS Paint or similar',
                        stepTwo: 'For best results, the image width should not exceed 500px in width',
                        stepThree: 'Open the terminal app',
                        stepFour: 'Press F11',
                        stepFive: "In the logo text field (2nd from top), paste the entire path to the image file, including the drive.<br/>Example: C:\\SomeFolder\\logoImage.jpg",
                        stepSix: 'Click "OK"'
                    }
                }
            }
        },
        customers: {
            label: 'Customers',
            backTo: 'Back to customers list',
            edit: {
                details: {
                    label: "Details"
                },
                notifications: {
                    label: "Notifications"
                },

                sales: {
                    allStores: "All Stores",
                    clerk: "Clerk",
                    date: "Date",
                    label: "Sales",
                    location: "Location",
                    noSalesFound: "No Sales Found",
                    saleAmount: "Sale Amount"
                },
                trades: {
                    allStores: "All Stores",
                    cash: "Cash",
                    credit: "Credit",
                    label: "Trades",
                    location: "Location",
                    net: "Net",
                    noTradesFound: "No Trades Found",
                    retail: "Retail",
                    title: "Title"
                }
            }
        },
        employees: {
            label: 'Employees',
            searchByKeyword: 'Search by keyword',
            backTo: 'Back to Employees List',
            employee: {
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email Address',
                password: 'Password',
                confirmPassword: 'Confirm Password',
                admin: "This employee has admin privileges",
                adminInfo: "Access to administrative features like stores, employees and invoices."
            }
        },
        inventory: {
            label: 'Inventory',
            backTo: 'Back to Inventory List',
            item: {
                foil: "Foil",
                comments: 'Comments',
                condition: 'Condition',
                cost: 'Cost',
                exportCsv: 'Export CSV',
                inventoryInfo: 'Inventory Info',
                label: 'Item',
                notFound: 'No inventory found.',
                price: 'Price',
                printLabels: 'Print Labels',
                product: 'Product',
                qty: 'Qty',
                quantity: 'Quantity',
                stockNumber: 'Stock #',
                store: 'Store',
                ageInDays: {
                    one: "{{count}} day",
                    other: '{{count}} days'
                },
                printModal: {
                    calibrate: 'Calibrate',
                    cancel: 'Cancel',
                    instructions: 'If needed, install correct labels and perform calibration',
                    printBoxLabel: 'Print Box Label',
                    printLabels: 'Print Labels',
                    printMediaLabel: 'Print Media Label',
                    quantity: 'Quantity',
                    calibrateError: "An error occurred while attempting to calibrate the printer.",
                    printError: "An error occurred while attempting to print the labels."
                },
                add: {
                    autoGenerated: 'Auto-generated',
                    currentlySellingFor: 'Currently selling for',
                    dateAdded: 'Date added',
                    inventoryNotes: 'Inventory notes',
                    quantityInStock: 'Quantity in stock',
                    quantityToAdd: 'Quantity to add',
                    researchPricing: 'Research Pricing',
                    selectProduct: 'Please select a product to add inventory.',
                    sellingFor: 'selling for',
                    store: 'Store',
                    stockNumber: 'Stock number',
                    unitPurchasePrice: 'Unit purchase price',
                    unitSalePrice: 'Unit sale price'
                }
            },
            summary: {
                categories: 'By Category',
                cost: 'Cost',
                count: 'Count',
                inventoryAgingBy: 'Inventory aging by',
                inventoryTotals: 'Inventory totals',
                name: 'Name',
                noInventoryFound: 'No inventory found.',
                price: 'Price',
                stores: 'By Store'
            },
            transfer: {
                category: 'Category',
                condition: 'Condition',
                enterSkuOrUpc: 'Enter SKU or UPC',
                findAndAdd: 'Find item and add to list',
                instructions: 'Scan SKU or UPC for items to be transferred',
                manual: 'or enter manually below',
                item: 'Item',
                itemAddedToList: 'Item has been added to the list.',
                itemsSelectedOne: '{{count}} item selected',
                itemsSelectedMore: '{{count}} items selected',
                itemZeroQuantity: 'The item has zero quantity.',
                label: 'Transfer',
                listEmpty: 'No items scanned',
                maxQuantityError: 'Maximum quantity for item has been reached.',
                noItemsFound: 'No items found.',
                platform: 'Platform',
                quantity: 'Quantity',
                skuOrUpc: 'SKU or UPC',
                stockNum: 'Stock #',
                transferComplete: 'Transfer complete.',
                transferError: 'An error occurred, transfer could not be completed.',
                transferFrom: 'Transfer from',
                transferTo: 'Transfer to'
            }
        },
        shipping: {
            label: 'Shipping',
            customer: {
                edit: 'Edit Customer'
            }
        },
        conditions: {
            singular: 'Condition',
            "new": 'New',
            complete: 'Complete',
            completeInBox: 'Complete In Box',
            loose: 'Loose',
            mediaOnly: 'Media Only'
        },
        dashboard: {
            cashTrades: "Trades (Cash)",
            category: "Category",
            cogs: "COGS",
            creditTrades: "Trades (Credit)",
            dateRangeError: "Please select a date range that is less than 1 month",
            grossProfit: "Gross Profit",
            invoices: "Invoices",
            label: 'Dashboard',
            netCash: "Net Cash",
            netCreditCard: "Net Credit Card",
            netCustomerCredit: "Net Customer Credit",
            netExternal: "Net External",
            platform: "Platform",
            returns: "Returns",
            salesHistory: "Sales History",
            sales: "Sales",
            salesTax: "Sales Tax",
            shippingReceived: "Shipping Received",
            shippingPaid: "Shipping Paid",
            store: "Store",
            unitsSold: "Units Sold",
            views: {
                overview: "Overview",
                activity: "Activity",
                paymentTypes: "Payment Types",
                taxAndShipping: "Tax & Shipping"
            }
        },
        invoices: {
            number: 'Invoice Number',
            item: {
                number: "Vendor's Item Number",
                upc: 'UPC',
                productName: 'Product Name',
                quantity: 'Quantity',
                unitCost: 'Unit Cost',
                sellPrice: 'Sell Price',
                store: 'Store',
                voided: "Voided",
                modal: {
                    selectProductCategory: 'Select a product category',
                    enterCustomCategory: '...or enter a name for a new custom category.'
                }
            }

        },
        pos: {
            label: 'POS',
            itemReport: {
                cash: "Cash",
                cost: "Cost",
                credit: "Credit",
                discount: "Discount",
                exportCsv: "Export CSV",
                filter: {
                    addCatFilter: "Add category filter",
                    atAGlance: "At a glance",
                    byCategory: "By Category",
                    byStore: "By Store",
                    removeCatFilter: "Remove category filter"
                },
                itemsSold: "Items Sold",
                itemsTraded: "Items Traded",
                location: "Location",
                miscellaneous: "Miscellaneous",
                net: "Net",
                noItemsSoldFound: 'No items sold found.',
                noItemsTradedFound: 'No items traded found.',
                retail: "Retail",
                sales: "Sales",
                sellPrice: "Sell Price",
                title: "Title"
            },
            completed: {
                allStores: 'All Stores',
                customer: 'Customer',
                endDate: 'End Date',
                noCompletedSalesFound: 'No completed sales found.',
                startDate: 'Start Date',
                saleId: 'Sale ID',
                location: 'Location',
                createdOn: 'Created On',
                createdBy: 'Created By',
                saleAmount: 'Sale Amount'
            },
            customer: {
                add: 'Add a customer'
            },
            saveForLater: 'This sale has been stashed. You can resume this sale later via the Stashed Sales menu item.',
            payment: 'Add payment',
            paymentForCustomer: 'Pay Customer',
            editShippingAmoount: 'Edit Shipping Amount',
            shippingAmount: "Shipping Amount",
            taxAmount: "Tax Amount (%)",
            inventory: {
                add: 'Add an item',
                applyDiscount: "Apply discount",
                costOfGoods: "Cost of Goods",
                description: 'Description',
                discountMoney: 'Discount ($)',
                discountPercent: 'Discount (%)',
                edit: 'Edit line item',
                noTax: "No Tax",
                price: 'Price',
                "return": 'Select item(s) to be returned',
                trade: 'Trade in an item',
                willShip: 'Ship to Customer',
                history: {
                    allStores: 'All Stores',
                    customerSale: 'Customer Sale',
                    inventoryAdded: 'Inventory Added',
                    invoice: 'Invoice',
                    priceChange: 'Price Change',
                    noInventoryHistoryFound: 'No inventory history found.',
                    date: 'Date',
                    activity: 'Activity',
                    sellPrice: 'Sell Price',
                    transfer: 'Transfer',
                    qty: 'Qty',
                    by: 'By',
                    transferTo: "Transfered to {{store}}",
                    transferFrom: "Transfered from {{store}}",
                    conditionChanged: "Condition changed to {{condition}}"
                }
            },
            saleDescription: 'Add an optional description',
            editTax: 'Tax(%)',
            paymentModal: {
                paymentTitle: 'Choose a Payment Option to Apply'
            },
            tradeModal: {
                addToSale: 'Add to sale',
                averageAskingPrice: 'Average Asking Price',
                averageCapture: 'Average Capture',
                averageSellingPrice: 'Average Selling Price',
                buyPrice: 'Buy Price',
                cashFor: 'CASH FOR',
                category: 'Category',
                currentlySellingFor: 'Currently selling for',
                lastSoldFor: 'Last sold for',
                noProductsFound: 'No products found.',
                offer: 'OFFER',
                platform: 'Platform',
                priceCharts: 'Price Charts',
                productDetails: 'Product Details',
                productHistory: 'Product History',
                productName: 'Product Name',
                quantity: 'Quantity',
                research: 'Research',
                researchPricing: 'Research pricing',
                select: 'Select',
                sellPrice: 'Sell Price',
                standardMargin: 'Standard Margin{{plus}}',
                totalUnitsSold: 'Total units sold',
                totalSaleAmount: 'Total sale amount',
                tradeFor: 'TRADE FOR',
                upcNotFound: 'UPC code not found',
                quantityInStock: 'Quantity in stock',
                pleaseFillWillSellFor: 'Please fill out "Will sell for" to see available options',
                viewInventoryHistory: 'View inventory history',
                competitorBuyPrice: "Competitor Buy Price",
                competitorSellPrice: "Competitor Sell Price",
                competitor: "Competitor"
            }
        },
        platforms: {
            label: 'Platforms'
        },
        pricing: {
            allItems: 'All Items',
            backTo: 'Back to Price Changes list',
            item: 'Item',
            console: 'Console',
            condition: 'Condition',
            quantity: 'Quantity',
            type: 'Type',
            uncompetitiveItems: 'Show only uncompetitively priced items',
            product: {
                competitor: 'Competitor',
                deselectAll: 'Deselect all',
                inventory: {
                    noItemsFound: 'No items found'
                },
                mustSelect: 'Must select at least one inventory item',
                ourPrice: 'Our price',
                price: 'Price',
                printBoxLabel: "Print Box Label",
                printMediaLabel: "Print Media Label",
                quantity: 'Quantity',
                research: 'Research',
                specify: 'Specify a new price',
                stockNumber: 'Stock number',
                selectAll: 'Select all'
            }
        },
        menu: {
            dashboard: 'Dashboard',
            store: {
                notSelected: 'Store Not Selected'
            },
            changeStore: 'Change Store',
            changeUser: 'Change User',
            logout: 'Logout',
            pos: {
                label: 'Sales',
                "new": 'New Sale',
                stashed: 'Stashed Sales',
                completed: 'Completed Sales',
                itemsSold: "Items Sold",
                itemsTraded: "Items Traded"
            },
            shipping: 'Shipping',
            inventory: {
                history: 'History',
                label: 'Inventory',
                summary: 'Summary',
                list: 'Items',
                transfer: 'Transfer'
            },
            customers: 'Customers',
            priceChanges: 'Price Changes',
            admin: {
                label: 'Admin',
                subscription: 'Subscription',
                stores: 'Stores',
                employees: 'Employees',
                affiliates: 'Affiliates',
                invoices: 'Invoices'
            }
        },
        searchByKeyword: 'Search by keyword',
        searchByProduct: 'Search for Product by Name, UPC, or SKU',
        searchBySaleId: 'Search by Sale Number',
        priceChanges: 'Price Changes',
        drawerCounts: 'Drawer Counts',
        subscription: 'Subscription',
        boxAndMediaLabels: {
            label: 'Box and Media Labels',
            explanation: 'By adding a platform to this list, both a box label and a media label will be printed for each inventory item in the platform when printing labels.'
        },
        printLabelsExplanation: {
            inBox: "By selecting this option, labels will be printed for price changes and intake of 'Complete in Box' inventory items",
            "new": "By selecting this option, labels will be printed for price changes and intake of 'New' inventory items",
            loose: "By selecting this option, labels will be printed for price changes and intake of 'Loose' inventory items"
        },
        backToList: 'Back to list',
        categoryDropdown: {
            selectCategories: "Select categories",
            selectCategory: "Select category",
            numSelectedItems: {
                one: "{{count}} category",
                other: "{{count}} categories"
            },
            noResults: "No categories available. Please adjust your search term."
        },
        researchPricing: {
            label: 'Research pricing',
            notFound: "Pricing research not available"
        },
        shipment: {
            printError: "An error occurred while attempting to print the label.",
            calibrateError: "An error occurred while attempting to calibrate the printer."
        },
        settings: {
            label: "Print Settings",
            connected: "Printer connected",
            disconnected: "Printer not connected",
            selectPrinter: "Select printer",
            loadingPrinters: "Loading printers...",
            noPrinterSelected: "No printer selected",
            noPrintersFound: "No printers found",
            refreshPrinters: "Refresh printers list",
            qzTray: {
                connecting: "Connecting to QZ Tray...",
                connected: "Connected to QZ Tray",
                disconnected: "Not connected to QZ Tray",
                connect: "Connect"
            },
            receiptPrinter: {
                label: "Receipt Printer",
                select: "Select your receipt printer",
                sampleSale: "Print sample sale receipt",
                sampleAuth: "Print sample card authorization receipt",
                openDrawer: "Open cash drawer"
            },
            labelPrinter: {
                label: "Label Printer",
                select: "Select your label printer",
                calibrateInventory: "Calibrate for inventory labels",
                calibrateShipping: "Calibrate for shipping labels",
                sampleMedia: "Print sample media label",
                sampleShipping: "Print sample shipping label",
                sampleBox: "Print sample box label"
            },
            instructions: {
                label: "Instructions",
                step1: {
                    label: "Step 1",
                    message: "Connect and set your receipt printer and label printer. Each can be installed using standard printer drivers recommended by the manufacturer."
                },
                step2: {
                    label: "Step 2",
                    message: "Download and install the latest <strong>v2.1</strong> version of QZ Tray at"
                },
                step3: {
                    label: "Step 3",
                    message: "Select your receipt printer and label printer."
                }
            }
        }
    };
});