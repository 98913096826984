define('retro-game-store/pods/components/percent-input/component', ['exports', 'ember', 'ember-inputmask/components/input-mask'], function (exports, _ember, _emberInputmaskComponentsInputMask) {

    /**
     * `{{currency}}` component
     *  Displays an input that masks currency
     */

    exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
        value: null,
        mask: 'percentage',
        setUnmaskedValue: _ember['default'].observer('value', function () {
            if (this.$().inputmask('unmaskedvalue')) {
                this.set('unmaskedValue', this.$().inputmask('unmaskedvalue'));
            } else {
                this.set('unmaskedValue', 0);
            }
        })
    });
});