define('retro-game-store/pods/sales/sale/add-misc-modal/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var reads = _ember['default'].computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        scanner: service(),
        api: service(),

        saleController: controller('sales.sale'),
        sale: reads('saleController.model'),
        saleItems: reads('saleController.saleItems'),
        quantity: 1,

        validations: {
            'description': {
                presence: true
            },
            'price': {
                presence: true
            },
            'miscCostOfGoods': {}
        },
        closeModal: function closeModal() {
            set(this, 'description', null);
            set(this, 'price', null);
            set(this, 'miscCostOfGoods', null);
            set(this, 'quantity', 1);
            get(this, 'saleController').listenForScanner();
            this.send('closeModal');
        },

        actions: {
            cancel: function cancel() {
                this.closeModal();
            },

            save: function save(callback) {
                var _this = this;

                var promise = get(this, 'api').request('POST', '/miscSaleItems', {
                    id: get(this, 'model.id'),
                    description: get(this, 'description'),
                    amount: get(this, 'price'),
                    costOfGoods: get(this, 'miscCostOfGoods'),
                    quantity: get(this, 'quantity')
                }).then(function (saleItem) {
                    var saleItemAdded = _this.store.push(_this.store.normalize('sale-item', saleItem));
                    get(_this, 'saleItems').addObject(saleItemAdded);
                    _this.send('refreshSale');
                    _this.closeModal();
                });
                callback(promise);
            }
        }
    });
});