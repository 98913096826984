define('retro-game-store/pods/inventory/items/item/details/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var mapBy = _ember['default'].computed.mapBy;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var service = _ember['default'].inject.service;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        api: service(),
        qz: service(),
        validations: {
            // 'model.sellPrice': {
            //     presence: true
            // }
        },

        selectedCondition: null,
        conditions: mapBy('productSkus', 'condition'),
        actions: {
            save: function save(callback) {
                var promise = get(this, 'model').save();
                callback(promise);
            },
            updateCondition: function updateCondition(condition) {
                var model = get(this, 'model');
                var product = get(this, 'productSkus').findBy('condition', condition);
                var productSkuId = get(product, 'id');
                set(this, 'selectedCondition', condition);
                set(model, 'productSkuId', productSkuId);
            }
        }
    });
});