define('retro-game-store/instance-initializers/terminal', ['exports'], function (exports) {
    exports.initialize = initialize;
    /**
     * @DEPRECATED
     *
     * This service is no longer in use (see `qz` and `print` services instead).
     * However, it will remain in the application for legacy concerns.
     */

    function initialize() /*instance*/{
        // let terminal = instance.lookup('service:terminal');
        // terminal.listen();
    }

    exports['default'] = {
        name: 'terminal',
        initialize: initialize
    };
});