define('retro-game-store/pods/affiliates/affiliate/route', ['exports', 'ember', 'retro-game-store/mixins/title-bar', 'retro-game-store/mixins/check-abilities'], function (exports, _ember, _retroGameStoreMixinsTitleBar, _retroGameStoreMixinsCheckAbilities) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsTitleBar['default'], _retroGameStoreMixinsCheckAbilities['default'], {
        modelForAbilities: true,
        abilitiesRequired: ['affiliate.view'],
        redirect: function redirect(model, transition) {
            if (transition.targetName === 'affiliates.affiliate.index') {
                this.transitionTo('affiliates.affiliate.general');
            }
        }
    });
});