define('retro-game-store/pods/components/inventory-counter/component', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var Component = _ember['default'].Component;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var observer = _ember['default'].observer;
    exports['default'] = Component.extend({
        quantityDidChange: observer('quantity', function () {
            var quantity = get(this, 'quantity');
            if (quantity <= 0 || quantity[0] === 0) {
                set(this, 'quantity', quantity ? Math.abs(parseInt(quantity)) : 0);
            }
        }),

        decreaseQuantityMax: computed('quantity', function () {
            return get(this, 'quantity') <= 1;
        }),

        increaseQuantityMax: computed('quantity', 'quantityAvailable', function () {
            var available = get(this, 'quantityAvailable');
            var quantity = get(this, 'quantity');
            return available === 0 || quantity >= available;
        }),

        actions: {
            increaseQuantity: function increaseQuantity() {
                var quantity = get(this, 'quantity') ? parseInt(get(this, 'quantity')) : 0;
                set(this, 'quantity', quantity + 1);
            },

            decreaseQuantity: function decreaseQuantity() {
                var quantity = get(this, 'quantity') ? parseInt(get(this, 'quantity')) : 0;
                set(this, 'quantity', quantity - 1);
            }
        }
    });
});