define('retro-game-store/pods/sales/completed/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar', 'retro-game-store/mixins/authenticated-route', 'retro-game-store/mixins/check-abilities'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar, _retroGameStoreMixinsAuthenticatedRoute, _retroGameStoreMixinsCheckAbilities) {
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    exports['default'] = Route.extend(_retroGameStoreMixinsAuthenticatedRoute['default'], _retroGameStoreMixinsCheckAbilities['default'], _retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        abilitiesRequired: ['sale.access'],
        model: function model(params) {
            var controller = this.controllerFor('sales.completed');
            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQueryWithMeta('sale', queryParams, 'sales.completed');
        },
        setupController: function setupController(controller) {
            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });
            this._super.apply(this, arguments);
        }
    });
});