define('retro-game-store/pods/inventory/items/item/route', ['exports', 'ember', 'retro-game-store/config/environment', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreConfigEnvironment, _retroGameStoreMixinsTitleBar) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsTitleBar['default'], {
        model: function model(params) {
            return this.store.find('inventory', params.item_id);
        },
        redirect: function redirect(model, transition) {
            if (transition.targetName === 'inventory.items.item.index') {
                this.transitionTo('inventory.items.item.details', _retroGameStoreConfigEnvironment['default']['affiliate']);
            }
        }
    });
});