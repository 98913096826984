define('retro-game-store/pods/components/label-option-row/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var empty = _Ember$computed.empty;
    var get = _ember['default'].get;
    var isEmpty = _ember['default'].isEmpty;
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'li',
        classNameBindings: ['isDeleted:is-deleted'],
        isDeleted: alias('model.isDeleted'),

        conditions: alias('model.conditions'),

        conditionsEmpty: empty('conditions'),

        all: (0, _emberI18n.translationMacro)('misc.all'),
        none: (0, _emberI18n.translationMacro)('misc.none'),

        buildLabelLines: function buildLabelLines(labelType) {
            if (get(this, 'conditionsEmpty')) {
                return [get(this, 'none')];
            }

            var kind = 'print' + labelType + 'Label';

            var conditions = get(this, 'model.conditions');
            var noPrintFound = conditions.findBy(kind, false);

            if (!noPrintFound) {
                return [get(this, 'all')];
            }

            var printConditions = conditions.filterBy(kind, true);
            if (isEmpty(printConditions)) {
                return [get(this, 'none')];
            }

            return printConditions.mapBy('condition.name');
        },

        boxLabelLines: computed('conditions.[]', 'conditions.@each.printBoxLabel', 'conditionsEmpty', function () {
            return this.buildLabelLines('Box');
        }),

        mediaLabelLines: computed('conditions.@each.printMediaLabel', 'conditionsEmpty', function () {
            return this.buildLabelLines('Media');
        }),

        actions: {
            edit: function edit() {
                this.sendAction('edit', this.get('model'));
            },
            'delete': function _delete() {
                this.sendAction('delete', this.get('model'));
            }
        }
    });
});