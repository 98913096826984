define('retro-game-store/mixins/popover-handler', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        destroyPopover: function destroyPopover() {
            if (this.get('popover')) {
                this.get('popover').popover('destroy');
                this.set('popover', null);
            }
        },

        cancel: function cancel() {
            this.destroyPopover();
            this.destroyOutsideClickListener();
        },

        listenForClicksOutside: function listenForClicksOutside() {
            var _this = this;

            var listener = $(document).mouseup(function (event) {
                var container = $(_this.get('element')),
                    describedById = $(_this.get('element')).find('a').attr('aria-describedby'),
                    popup = $('#' + describedById);
                if (!popup.is(event.target) && !container.is(event.target) && container.has(event.target).length === 0 && popup.has(event.target).length === 0 && _this.get('popover')) {
                    // if the target of the click isn't the container...... nor a descendant of the container
                    _this.cancel();
                }
            });
            this.set('listener', listener);
        },

        destroyOutsideClickListener: function destroyOutsideClickListener() {
            if (this.get('listener')) {
                this.get('listener').unbind();
            }
        },

        onDestroyElement: _ember['default'].on('willDestroyElement', function () {
            //make sure the bootstrap popover stuff is cleaned up when the
            //component is destroyed, in cases where it might stil be open
            //during an unexpected destroy (route change, ect)
            this.destroyPopover();
            this.destroyOutsideClickListener();
        }),
        actions: {
            cancel: function cancel() {
                this.cancel();
            }
        }
    });
});