define('retro-game-store/pods/components/platforms-select/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        selectedOptionsChanged: _ember['default'].observer('selected.[]', function () {
            this.set('selectedIds', this.get('selected').mapBy('id'));
        }),
        actions: {
            opened: function opened() {
                if (this.attrs['on-open']) {
                    this.attrs['on-open']();
                }
            },
            closed: function closed() {
                if (this.attrs['on-close']) {
                    this.attrs['on-close']();
                }
            }
        }
    });
});