define('retro-game-store/pods/product-sku/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            inventoryItems: {
                embedded: 'always'
            },
            product: {
                embedded: 'always'
            },
            condition: {
                embedded: 'always'
            }
        }
    });
});