define('retro-game-store/pods/stores/store/receipt-details/route', ['exports', 'ember', 'ember-data-route'], function (exports, _ember, _emberDataRoute) {
    var get = _ember['default'].get;
    var setProperties = _ember['default'].setProperties;
    var Route = _ember['default'].Route;
    exports['default'] = Route.extend(_emberDataRoute['default'], {
        resetController: function resetController(controller) {
            get(controller, 'model').rollbackAttributes();
            setProperties(controller, {
                selectedLogoFile: null,
                deleteCurrentLogo: false,
                logoErrors: null
            });
        }
    });
});