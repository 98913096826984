define("retro-game-store/constants/categories", ["exports"], function (exports) {
    exports["default"] = [{
        id: '1',
        name: "Favorites",
        productCategories: [{
            "id": 86,
            "name": "Nintendo Switch",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 18,
            "name": "Playstation 4",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 28,
            "name": "Xbox One",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }]
    }, {
        id: '2',
        name: "Video Games",
        importEndpoint: "http:\\stuff",
        pricingEndpoint: "http:\\otherstuff",
        researchEndpoint: "http:\\url\to\research\button",
        sourceId: "73853",
        productCategories: [{
            "id": 45,
            "name": "3DO",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 84,
            "name": "3DO",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 81,
            "name": "a cool platform",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 106,
            "name": "Ac Adapter Wall Charger DSi DSi XL Tomee Brand",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 51,
            "name": "Amiibo",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 75,
            "name": "Amiibo",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 39,
            "name": "Atari 2600",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 37,
            "name": "Atari 400",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 11,
            "name": "Atari 5200",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 24,
            "name": "Atari 7800",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 48,
            "name": "Atari Lynx",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 43,
            "name": "CD-i",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 40,
            "name": "Colecovision",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 35,
            "name": "Commodore 64",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 30,
            "name": "Disney Infinity",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 90,
            "name": "Famicom",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 112,
            "name": "fubar x",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 97,
            "name": "Game & Watch",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 103,
            "name": "game and watch",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 20,
            "name": "GameBoy",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 2,
            "name": "GameBoy Advance",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 4,
            "name": "GameBoy Color",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 7,
            "name": "Gamecube",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 36,
            "name": "Intellivision",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 13,
            "name": "Jaguar",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 96,
            "name": "JP Sega Dreamcast",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 94,
            "name": "JP Sega Saturn",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 16,
            "name": "Macintosh",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 113,
            "name": "Meowmix",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 108,
            "name": "Mini Arcade",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 104,
            "name": "misc accessory",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 12,
            "name": "N-Gage",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 41,
            "name": "Neo Geo",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 89,
            "name": "Neo Geo CD",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 8,
            "name": "Neo Geo Pocket Color",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 23,
            "name": "NES",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 17,
            "name": "Nintendo 3DS",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 29,
            "name": "Nintendo 64",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 33,
            "name": "Nintendo DS",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 86,
            "name": "Nintendo Switch",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 10,
            "name": "Odyssey 2",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 78,
            "name": "Other",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 109,
            "name": "PAL Gamecube",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 14,
            "name": "PAL Master System",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 85,
            "name": "PAL NES",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 92,
            "name": "PAL Nintendo 64",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 87,
            "name": "PAL Playstation 2",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 93,
            "name": "PAL Sega Dreamcast",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 95,
            "name": "PAL Sega Master System",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 107,
            "name": "PAL Sega Mega Drive",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 88,
            "name": "PAL Super Nintendo",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 52,
            "name": "PC Games",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 31,
            "name": "Playstation",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 46,
            "name": "Playstation 2",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 38,
            "name": "Playstation 3",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 18,
            "name": "Playstation 4",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 42,
            "name": "PlayStation Vita",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 19,
            "name": "PSP",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 22,
            "name": "Sega 32X",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 21,
            "name": "Sega CD",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 5,
            "name": "Sega Dreamcast",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 44,
            "name": "Sega Game Gear",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 47,
            "name": "Sega Genesis",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 50,
            "name": "Sega Master System",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 32,
            "name": "Sega Saturn",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 25,
            "name": "Skylanders",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 105,
            "name": "Snes Controller Tomee Brand",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 91,
            "name": "Super Famicom",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 49,
            "name": "Super Nintendo",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 80,
            "name": "test platform",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 79,
            "name": "Test Plct",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 110,
            "name": "thangs",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 111,
            "name": "things",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 3,
            "name": "TurboGrafx-16",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 82,
            "name": "Ultimate Platform",
            "categoryType": 1,
            "affiliateId": 1000,
            "stores": null
        }, {
            "id": 26,
            "name": "Vectrex",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 1,
            "name": "Vic-20",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 34,
            "name": "Virtual Boy",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 83,
            "name": "Wholesale",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 76,
            "name": "Wholesale Lots",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 27,
            "name": "Wii",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 9,
            "name": "Wii U",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 15,
            "name": "Xbox",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 6,
            "name": "Xbox 360",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }, {
            "id": 28,
            "name": "Xbox One",
            "categoryType": 1,
            "affiliateId": null,
            "stores": null
        }]
    }];
});