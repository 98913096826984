define('retro-game-store/pods/affiliates/affiliate/subscription/cancel-modal/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            cancel: function cancel() {
                this.send('closeModal');
            },
            save: function save() {
                var _this = this;

                this.get('model').set('subscriptionIsRenewing', false);
                this.get('model').save().then(function () {
                    _this.send('closeModal');
                    _this.set('isBusy', false);
                });
            }
        }
    });
});