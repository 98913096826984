define('retro-game-store/pods/components/max-currency-input/component', ['exports', 'ember', 'ember-inputmask/components/input-mask', 'accounting/unformat', 'accounting/to-fixed'], function (exports, _ember, _emberInputmaskComponentsInputMask, _accountingUnformat, _accountingToFixed) {

    /**
     * `{{currency}}` component
     *  Displays an input that masks currency
     */

    exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
        value: null,
        mask: 'currency',
        setUnmaskedValue: _ember['default'].observer('value', function () {
            var _this = this;

            _ember['default'].run.schedule('afterRender', function () {
                var value = (0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(_this.$().inputmask('unmaskedvalue'), 2)),
                    paymentNeeded = Math.abs((0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(_this.get('paymentNeeded'), 2))),
                    payment = (0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(_this.get('payment').get('amountApplied'), 2)),
                    maxReturnAvailable = _this.get('payment').get('remainingReturnAmount');
                if (maxReturnAvailable !== null) {
                    maxReturnAvailable = (0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(_this.get('payment').get('remainingReturnAmount'), 2));
                }
                if (value) {
                    if (maxReturnAvailable !== null && value > maxReturnAvailable) {
                        _this.set('unmaskedValue', maxReturnAvailable);
                    } else {
                        if (value > paymentNeeded + payment) {
                            var unmaskedValue = value - (value - (paymentNeeded + payment));
                            _this.set('unmaskedValue', (0, _accountingToFixed['default'])(unmaskedValue, 2));
                        } else {
                            _this.set('unmaskedValue', value);
                        }
                    }
                } else {
                    _this.set('unmaskedValue', 0);
                }
            });
        })
    });
});