define('retro-game-store/pods/components/data-table/cell/link-to-menu/component', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var tryInvoke = _ember['default'].tryInvoke;
    var alias = _ember['default'].computed.alias;
    exports['default'] = _ember['default'].Component.extend({
        menuOptions: alias('column.menuOptions'),

        actions: {
            menuAction: function menuAction(action) {
                tryInvoke(get(this, 'tableActions'), action, [get(this, 'row.content')]);
            },
            open: function open(dropdown) {
                if (this.closeTimer) {
                    _ember['default'].run.cancel(this.closeTimer);
                    this.closeTimer = null;
                } else {
                    dropdown.actions.open();
                }
            },
            close: function close(dropdown) {
                var _this = this;

                this.closeTimer = _ember['default'].run.later(function () {
                    _this.closeTimer = null;
                    dropdown.actions.close();
                }, 100);
            },
            prevent: function prevent(e) {
                return e.stopImmediatePropagation();
            }
        }
    });
});