define('retro-game-store/constants/event-types', ['exports'], function (exports) {
    var EVENT_TYPE_SALE = { id: 0, label: 'Sale', type: 'Sale' };
    exports.EVENT_TYPE_SALE = EVENT_TYPE_SALE;
    var EVENT_TYPE_RETURN = { id: 1, label: 'Return', type: 'Return' };
    exports.EVENT_TYPE_RETURN = EVENT_TYPE_RETURN;
    var EVENT_TYPE_TRADE = { id: 2, label: 'Trade', type: 'Trade' };
    exports.EVENT_TYPE_TRADE = EVENT_TYPE_TRADE;
    var EVENT_TYPE_BUY = { id: 3, label: 'Buy', type: 'Buy' };
    exports.EVENT_TYPE_BUY = EVENT_TYPE_BUY;
    var EVENT_TYPE_RESTOCK = { id: 4, label: 'Restock', type: 'Restock' };
    exports.EVENT_TYPE_RESTOCK = EVENT_TYPE_RESTOCK;
    var EVENT_TYPE_PRICE_CHANGE = { id: 5, label: 'Price Change', type: 'PriceChange' };
    exports.EVENT_TYPE_PRICE_CHANGE = EVENT_TYPE_PRICE_CHANGE;
    var EVENT_TYPE_MISCELLANEOUS_SALE = { id: 6, label: 'Miscellaneous', type: 'MiscellaneousSale' };
    exports.EVENT_TYPE_MISCELLANEOUS_SALE = EVENT_TYPE_MISCELLANEOUS_SALE;
    var EVENT_TYPE_TRANSFER_FROM = { id: 7, label: 'Transfer From', type: 'TransferFrom' };
    exports.EVENT_TYPE_TRANSFER_FROM = EVENT_TYPE_TRANSFER_FROM;
    var EVENT_TYPE_TRANSFER_TO = { id: 8, label: 'Transfer To', type: 'TransferTo' };
    exports.EVENT_TYPE_TRANSFER_TO = EVENT_TYPE_TRANSFER_TO;
    var EVENT_TYPE_CONDITION_CHANGED = { id: 9, label: 'Condition Changed', type: 'ConditionChanged' };

    exports.EVENT_TYPE_CONDITION_CHANGED = EVENT_TYPE_CONDITION_CHANGED;
    exports['default'] = [EVENT_TYPE_SALE, EVENT_TYPE_RETURN, EVENT_TYPE_TRADE, EVENT_TYPE_BUY, EVENT_TYPE_RESTOCK, EVENT_TYPE_PRICE_CHANGE, EVENT_TYPE_MISCELLANEOUS_SALE, EVENT_TYPE_TRANSFER_FROM, EVENT_TYPE_TRANSFER_TO, EVENT_TYPE_CONDITION_CHANGED];
});