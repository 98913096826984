define('retro-game-store/pods/sales/sale/edit-discount-modal/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var computed = _ember['default'].computed;
    var reads = _ember['default'].computed.reads;
    var Controller = _ember['default'].Controller;
    var controller = _ember['default'].inject.controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        saleController: controller('sales.sale'),
        saleModel: reads('saleController.model'),
        saleItems: reads('saleController.saleItems'),
        discountPercentType: reads('saleModel.isPercentDiscount'),

        validations: {
            'model.discountPercent': {
                presence: true,
                numericality: {
                    messages: {
                        numericality: ' must be a number between 0 and 100'
                    },
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 100
                }
            }
        },

        itemDiscountsTotal: computed('saleItems.@each.{basePrice,discountPercent,discount,quantity}', 'model.purchaseTotal', function () {
            var saleItems = get(this, 'saleItems');
            var discountTotal = 0;
            saleItems.forEach(function (item) {
                var itemPrice = Math.abs(get(item, 'basePrice'));
                var itemDiscountPercent = get(item, 'discountPercent') * 0.01;
                var itemDiscountCash = Math.abs(get(item, 'discount'));
                var quantity = get(item, 'quantity');

                var itemDiscountCashPercent = 0;
                if (itemDiscountCash !== 0 && itemDiscountPercent === 0) {
                    var convertedSaleDiscountCash = itemDiscountCash / itemPrice * 100;
                    itemDiscountCashPercent = convertedSaleDiscountCash * 0.01;
                }

                var saleDiscountTotal = 0;
                //get discount on item;
                var itemDiscount = itemDiscountPercent ? itemDiscountPercent : itemDiscountCashPercent;
                //set the discount total
                saleDiscountTotal = itemPrice * itemDiscount;
                //multiply discount by quantity of items if discount type is percent
                if (itemDiscountCash !== 0 && itemDiscountPercent !== 0) {
                    saleDiscountTotal = saleDiscountTotal * quantity;
                }
                saleDiscountTotal = Math.round(saleDiscountTotal * 1e2) / 1e2;
                //add totals discounts of line item
                discountTotal += saleDiscountTotal;
            });

            return discountTotal;
        }),

        discountSubtotal: computed('model.purchaseTotal', 'model.discountPercent', 'model.discount', 'itemDiscountsTotal', 'discountPercentType', function () {
            var purchaseTotal = get(this, 'model.purchaseTotal');
            var itemDiscountsTotal = get(this, 'itemDiscountsTotal');
            var isPercentType = get(this, 'discountPercentType');
            var saleDiscountPercent = Math.abs(get(this, 'model.discountPercent')) * 0.01;
            var saleDiscountCash = Math.abs(get(this, 'model.discount'));

            if (saleDiscountCash === 0 && saleDiscountPercent === 0) {
                return purchaseTotal - itemDiscountsTotal;
            } else {
                if (isPercentType) {
                    var newSaleTotal = purchaseTotal - itemDiscountsTotal;
                    var saleDiscountTotal = newSaleTotal * saleDiscountPercent;
                    saleDiscountTotal = Math.round(saleDiscountTotal * 1e2) / 1e2;
                    return newSaleTotal - saleDiscountTotal;
                } else {
                    return purchaseTotal - itemDiscountsTotal - saleDiscountCash;
                }
            }
        }),

        closeModal: function closeModal() {
            this.get('model').rollbackAttributes();
            if (get(this, 'discountPercentType') !== get(this, 'model.isPercentDiscount')) {
                set(this, 'discountPercentType', get(this, 'model.isPercentDiscount'));
            }
            this.send('closeModal');
        },

        actions: {
            cancel: function cancel() {
                this.closeModal();
            },
            updateDiscountType: function updateDiscountType() {
                var percentType = get(this, 'discountPercentType');
                set(this, 'discountPercentType', !percentType);
                set(this, 'model.discountPercent', 0);
                set(this, 'model.discount', 0);
            },
            save: function save(callback) {
                var _this = this;

                if (get(this, 'model.discount') !== 0) {
                    set(this, 'model.discount', -Math.abs(get(this, 'model.discount')));
                }
                var promise = this.get('model').save().then(function () {
                    _this.closeModal();
                    _this.send('refreshSale');
                    _this.send('getSaleItems');
                })['catch'](function (err) {
                    _this.get('model').rollbackAttributes();
                    console.log(err);
                });
                callback(promise);
            }
        }
    });
});