define('retro-game-store/pods/affiliates/index/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar', 'retro-game-store/mixins/check-abilities'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar, _retroGameStoreMixinsCheckAbilities) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], _retroGameStoreMixinsCheckAbilities['default'], {
        abilitiesRequired: ['affiliate.list'],
        model: function model(params) {
            var controller = this.controllerFor('affiliates.index'),
                queryParams = undefined;
            queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQuery('affiliate', queryParams, 'affiliates.index');
        }
    });
});