define('retro-game-store/pods/application/report-bug-modal/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        qz: _ember['default'].inject.service(),
        api: _ember['default'].inject.service(),
        validations: {
            'details': {
                presence: true
            }
        },
        closeModal: function closeModal() {
            this.setProperties({
                details: null,
                reportRecieved: false
            });
            this.send('closeModal');
        },
        actions: {
            cancel: function cancel() {
                this.closeModal();
            },
            save: function save(callback) {
                var _this = this;

                var data = {
                    details: this.get('details'),
                    pageUrl: window.location.href,
                    labelPrinterResponse: 'Status: ' + (this.get('qz.labelPrinterIsConnected') ? 'Connected' : 'Not connected'),
                    receiptPrinterResponse: 'Status: ' + (this.get('qz.receiptPrinterIsConnected') ? 'Connected' : 'Not connected')
                };
                var promise = this.get('api').request('POST', '/supportticket', data).then(function () {
                    _this.set('reportRecieved', true);
                });
                callback(promise);
            }
        }
    });
});