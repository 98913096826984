define('retro-game-store/pods/pricing-data/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            productType: {
                embedded: 'always'
            },
            condition: {
                embedded: 'always'
            },
            language: {
                embedded: 'always'
            }
        }
    });
});