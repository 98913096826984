define('retro-game-store/pods/components/select-component/component', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    exports['default'] = _ember['default'].Component.extend({
        renderInPlace: false,
        autofocus: false,
        selectedItemComponent: "",
        allowClear: false,
        classNames: ['select-component'],
        classNameBindings: ['itemSelected'],
        itemSelected: _ember['default'].computed('selected', function () {
            if (this.get('selected')) {
                return true;
            } else {
                return false;
            }
        }),
        modalVisibleChanged: _ember['default'].observer('modal.visible', function () {
            if (this.get('modal').get('visible')) {
                if (this.get('autofocus')) {
                    $(this.get('element')).find('.ember-power-select-trigger').focus();
                }
            }
        }),
        stopPropagation: function stopPropagation(event) {
            event.stopPropagation();
        },
        actions: {
            didFocus: function didFocus(select) {
                if (get(this, 'willFocus')) {
                    if (!select.isOpen) {
                        select.actions.open();
                    } else {
                        select.actions.close();
                    }
                    set(this, 'willFocus', false);
                }
            },
            didOpen: function didOpen() {
                _ember['default'].$(this.get('element')).on('keydown', this.stopPropagation);
            },
            didClose: function didClose() {
                _ember['default'].$(this.get('element')).off('keydown', this.stopPropagation);
            }
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);
            if (this.get('autofocus')) {
                set(this, 'willFocus', true);
                $(this.get('element')).find('.ember-power-select-trigger').focus();
            }
        }
    });
});