define('retro-game-store/pods/affiliates/affiliate/options/trade-percentage-modal/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var A = _ember['default'].A;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var controller = _ember['default'].inject.controller;
    var setProperties = _ember['default'].setProperties;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        affiliateController: controller('affiliates/affiliate'),
        affiliateOptionsController: controller('affiliates/affiliate/options'),
        selectedCategories: A([]),
        selectedProductTypes: A([]),
        categoryExists: false,
        validations: {
            'model.category': {
                presence: true
            },
            'model.tradePercentageAsPercent': {
                presence: true
            },
            'model.cashDiscountAsPercent': {
                presence: true
            }
        },
        closeModal: function closeModal() {
            setProperties(this, {
                selectedCategories: A([]),
                selectedProductTypes: A([]),
                originalCategory: null,
                originalProductType: null,
                categoryExists: false
            });
            this.send('closeModal');
        },
        rollbackModel: function rollbackModel() {
            if (!get(this, 'model.isNew')) {
                get(this, 'model').rollbackAttributes();
                if (get(this, 'originalProductType')) {
                    set(this, 'model.productType', get(this, 'originalProductType'));
                }
                if (get(this, 'originalCategory')) {
                    set(this, 'model.category', get(this, 'originalCategory'));
                }
            }
        },
        actions: {
            addCategoryItem: function addCategoryItem(items, item) {
                var model = get(this, 'model');
                var discounts = get(this, 'affiliateController.model.platformDiscounts');
                var existingItem = discounts.findBy('category.id', get(item, 'id'));
                if (existingItem && get(item, 'id') !== get(model, 'category.id')) {
                    set(this, 'categoryExists', true);
                    setProperties(this, {
                        selectedCategories: A([]),
                        selectedProductTypes: A([])
                    });
                } else if (!get(item, 'conditions')) {
                    setProperties(this, {
                        categoryExists: false,
                        originalCategory: get(this, 'model.category'),
                        originalProductType: get(this, 'model.productType')
                    });
                    setProperties(model, {
                        category: this.store.peekRecord('product-category', get(item, 'id')),
                        productType: this.store.peekRecord('product-type', get(item, 'productTypeId'))
                    });
                }
            },
            /* jshint ignore:start */
            addCategoryTypeItem: function addCategoryTypeItem(items, item) {
                var model = get(this, 'model');
                var itemId = get(item, 'id').toString();
                var discounts = get(this, 'affiliateController.model.platformDiscounts');
                var existingItem = discounts.findBy('productType.id', itemId);
                if (existingItem) {
                    if (get(item, 'id') != get(model, 'productType.id')) {
                        set(this, 'categoryExists', true);
                        setProperties(this, {
                            selectedCategories: A([]),
                            selectedProductTypes: A([])
                        });
                    }
                } else {
                    setProperties(this, {
                        categoryExists: false,
                        originalProductType: get(this, 'model.productType')
                    });
                    setProperties(model, {
                        category: null,
                        productType: this.store.peekRecord('product-type', get(item, 'id'))
                    });
                }
            },
            /* jshint ignore:end */
            cancel: function cancel() {
                this.rollbackModel();
                this.closeModal();
            },
            save: function save() {
                setProperties(this, {
                    'model.affiliateId': get(this, 'affiliateController.model.id'),
                    'model.categoryId': get(this, 'model.category.id'),
                    'model.productTypeId': get(this, 'model.productType.id')
                });
                get(this, 'affiliateController.model.platformDiscounts').pushObject(get(this, 'model'));

                this.closeModal();
            }
        }
    });
});