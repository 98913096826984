define('retro-game-store/macros/props-are-equal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = propsAreEqual;
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;

    function propsAreEqual(dependentKey1, dependentKey2) {
        var strictEquality = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

        return computed(dependentKey1, dependentKey2, function () {
            var value1 = get(this, dependentKey1);
            var value2 = get(this, dependentKey2);
            if (strictEquality) {
                return value1 === value2;
            } else {
                /* jshint ignore:start */
                return value1 == value2;
                /* jshint ignore:end */
            }
        });
    }
});