define('retro-game-store/pods/inventory/items/index/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar) {
    var A = _ember['default'].A;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        api: service(),
        categories: service(),

        model: function model(params) {
            var controller = this.controllerFor('inventory.items.index');
            var summaryController = this.controllerFor('inventory.summary');

            setProperties(controller, {
                storeFilter: get(summaryController, 'storeFilter'),
                categoryFilters: get(summaryController, 'categoryFilters') || A(),
                productTypeFilters: get(summaryController, 'productTypeFilters') || A()
            });

            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQuery('inventory', queryParams, 'inventory.items.index');
        },

        setupController: function setupController(controller) {
            set(controller, 'productTypes', get(this, 'categories.raw'));
            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });
            this._super.apply(this, arguments);
        },

        resetController: function resetController(controller) {
            set(controller, 'keywords', null);
            this._super.apply(this, arguments);
        }
    });
});