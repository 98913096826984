define('retro-game-store/pods/sales/sale/controller', ['exports', 'ember', 'retro-game-store/mixins/scanner-event-listener'], function (exports, _ember, _retroGameStoreMixinsScannerEventListener) {
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var equal = _Ember$computed.equal;
    var Controller = _ember['default'].Controller;
    var service = _ember['default'].inject.service;
    exports['default'] = Controller.extend(_retroGameStoreMixinsScannerEventListener['default'], {
        api: service(),
        qz: service(),
        print: service(),

        saleItems: [],
        pagingTarget: 'model.models',

        resetPrintButton: function resetPrintButton() {
            this.set('printSpinning', false);
            this.set('disabled', false);
        },
        activatePrintButton: function activatePrintButton() {
            this.set('printSpinning', true);
            this.set('disabled', true);
        },
        printButtonDisabled: computed('disabled', 'qz.receiptPrinterIsConnected', function () {
            if (this.get('qz.receiptPrinterIsConnected') && !this.get('disabled')) {
                return false;
            } else {
                return true;
            }
        }),
        tradeType: equal('model.tradeType', 'Credit'),
        tradeTypeIsCredit: alias('tradeType'),

        actions: {
            removeCustomer: function removeCustomer() {
                this.set('model.customerId', null);
                this.get('model').save();
            },
            removeSaleItem: function removeSaleItem(item) {
                var _this = this;

                item.destroyRecord().then(function () {
                    _this.send('refreshSale');
                    _this.get('saleItems').removeObject(item);
                });
            },
            cancelSale: function cancelSale() {
                var _this2 = this;

                this.get('model').destroyRecord().then(function () {
                    _this2.transitionToRoute('sales.new');
                });
            },
            scanSuccess: function scanSuccess(scan) {
                var _this3 = this;

                this.get('api').request('POST', '/saleItems', {
                    identificationNumber: scan,
                    id: this.get('model.id'),
                    storeId: this.get('session.user.storeInfo.id'),
                    quantity: 1
                }).then(function () {
                    _this3.send('getSaleItems');
                    _this3.send('refreshSale');
                });
            },
            printReceipt: function printReceipt() {
                var _this4 = this;

                var affiliateName = this.get('session.user.affiliate.name');
                this.activatePrintButton();
                this.get('print').printReceipt(affiliateName, this.get('model'), this.get('saleItems')).then(function () {
                    _this4.resetPrintButton();
                    _this4.set('error', null);
                })['catch'](function () {
                    _this4.resetPrintButton();
                    _this4.set('error', _this4.get('qz.receiptPrinterIsConnected') ? _this4.get('i18n').t('sales.printError') : null);
                });
            },
            printAuthorizationReceipt: function printAuthorizationReceipt() {
                var _this5 = this;

                var affiliateName = this.get('session.user.affiliate.name');
                this.get('print').printAuthorizationReceipt(affiliateName, this.get('model'))['catch'](function () {
                    _this5.set('error', _this5.get('qz.receiptPrinterIsConnected') ? _this5.get('i18n').t('sales.printError') : null);
                });
            },
            tradeTypeToggled: function tradeTypeToggled(hash) {
                var _this6 = this;

                if (hash.newValue !== this.get('model.tradeType')) {
                    this.get('api').request('POST', '/sales/setTradeType', {
                        id: this.get('model.id'),
                        type: hash.newValue
                    }).then(function () {
                        _this6.send('refreshSale');
                        _this6.send('getSaleItems');
                    });
                }
                return;
            }
        }
    });
});