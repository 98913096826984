define('retro-game-store/pods/pricing/product/inventory/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations', 'retro-game-store/constants/label-print-type', 'ember-awesome-macros/is-empty'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations, _retroGameStoreConstantsLabelPrintType, _emberAwesomeMacrosIsEmpty) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var reads = _ember['default'].computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        validations: {
            newPrice: {
                presence: true
            }
        },
        selections: [],
        inventoryRequiredError: false,
        limit: 10,
        api: service(),

        serverQueryParams: ['categoryIds', 'conditionId', 'productId', 'storeId', 'uncompetitiveOnly'],
        pricingController: controller('pricing.index'),
        pricingProductController: controller('pricing.product'),
        pricingProductModel: reads('pricingProductController.model'),
        categoryIds: reads('pricingProductModel.category.id'),
        conditionId: reads('pricingProductModel.condition.id'),
        productId: reads('pricingProductModel.productId'),
        storeId: reads('pricingController.storeId'),

        researchUrl: reads('model.firstObject.researchUrl'),

        labelPrintType: computed('printMediaLabel', 'printBoxLabel', function () {
            if (get(this, 'printMediaLabel') && !get(this, 'printBoxLabel')) {
                return get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_MEDIA_ONLY, 'id');
            } else if (!get(this, 'printMediaLabel') && get(this, 'printBoxLabel')) {
                return get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_BOX_ONLY, 'id');
            } else if (get(this, 'printMediaLabel') && get(this, 'printBoxLabel')) {
                return get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_ALL, 'id');
            } else {
                return get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_NONE, 'id');
            }
        }),

        inventoryIds: computed('selections.[]', function () {
            return get(this, 'selections').mapBy('inventoryId');
        }),
        modelIsEmpty: (0, _emberAwesomeMacrosIsEmpty['default'])('model'),

        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },
            selectAll: function selectAll() {
                this.set('selections', get(this, 'model'));
            },
            deselectAll: function deselectAll() {
                set(this, 'selections', A());
            },
            save: function save(callback) {
                var _this = this;

                var promise = null;
                if (get(this, 'inventoryIds').length > 0) {
                    set(this, 'inventoryRequiredError', false);
                    promise = get(this, 'api').request('POST', '/priceAdjustment', {
                        inventoryIds: get(this, 'inventoryIds'),
                        newPrice: get(this, 'newPrice'),
                        labelQueueOverride: get(this, 'labelPrintType')
                    }).then(function () {
                        _this.transitionToRoute('pricing');
                    });
                } else {
                    set(this, 'inventoryRequiredError', true);
                }

                callback(promise);
            }
        }
    });
});