define('retro-game-store/pods/dashboard/controller', ['exports', 'ember', 'ember-validations', 'moment'], function (exports, _ember, _emberValidations, _moment) {
    var A = _ember['default'].A;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        api: service(),
        storesController: controller('dashboard.stores'),
        platformController: controller('dashboard.platforms'),
        categoryFilters: A(),
        productTypeFilters: A(),
        storeId: computed('storeSelected', function () {
            if (!get(this, 'storeSelected')) {
                return get(this, 'session.user.storeInfo.id');
            } else {
                return get(this, 'storeSelected.id') || null;
            }
        }),
        timePeriod: [{ id: 'day', name: 'Daily' }, { id: 'month', name: 'Monthly' }, { id: 'year', name: 'Yearly' }, { id: 'custom', name: 'Custom' }],

        timePeriodSelected: { id: 'day', name: 'Daily' },
        dateShown: (0, _moment['default'])(),

        dateStart: (0, _moment['default'])().startOf('year'),
        dateEnd: (0, _moment['default'])(),

        dateShownFormatted: computed('dateShown', 'dateStart', 'dateEnd', 'timePeriodSelected', function () {
            var date = get(this, 'dateShown');
            var timePeriod = get(this, 'timePeriodSelected.id');
            var dateStart = get(this, 'dateStart');
            var dateEnd = get(this, 'dateEnd');

            switch (timePeriod) {
                case 'day':
                    return date.format('MM/DD/YYYY');
                case 'month':
                    return date.format('MM/YYYY');
                case 'year':
                    return date.format('YYYY');
                default:
                    return dateStart.format('MM/DD/YYYY') + ' - ' + dateEnd.format('MM/DD/YYYY');
            }
        }),

        startDate: computed('timePeriodSelected', 'dateShown', 'dateStart', function () {
            var timePeriod = get(this, 'timePeriodSelected.id');
            var date = get(this, timePeriod === 'custom' ? 'dateStart' : 'dateShown');
            return (0, _moment['default'])(date).startOf(timePeriod).toDate();
        }),

        endDate: computed('timePeriodSelected', 'dateShown', 'dateEnd', function () {
            var timePeriod = get(this, 'timePeriodSelected.id');
            var date = get(this, timePeriod === 'custom' ? 'dateEnd' : 'dateShown');
            return (0, _moment['default'])(date).endOf(timePeriod).toDate();
        }),

        storesActive: alias('storesController.isActive'),

        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },

            filter: function filter(callback) {
                set(this, 'resultsShownDate', get(this, 'dateShownFormatted'));
                if (get(this, 'storesActive')) {
                    get(this, 'storesController').send('filter');
                } else {
                    get(this, 'platformController').send('filter');
                }
                callback();
            },

            nextDate: function nextDate() {
                var timePeriod = get(this, 'timePeriodSelected.id');
                var date = get(this, 'dateShown');
                date.add(1, timePeriod);
                set(this, 'dateShown', date.clone());
            },

            previousDate: function previousDate() {
                var timePeriod = get(this, 'timePeriodSelected.id');
                var date = get(this, 'dateShown');
                date.subtract(1, timePeriod);
                set(this, 'dateShown', date.clone());
            }
        }
    });
});