define('retro-game-store/pods/components/bootstrap-link/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'li',
        childLinkViews: [],
        classNameBindings: ['active'],

        active: _ember['default'].computed('childLinkViews.@each.active', 'active-allowed', {
            get: function get() {
                if (this.get('active-allowed') !== false) {
                    return _ember['default'].A(this.get('childLinkViews')).isAny('active');
                } else {
                    return false;
                }
            },
            set: function set(key, value) {
                return value;
            }
        }),

        activeDidChange: _ember['default'].observer('active', function () {
            this.set('is-active', this.get('active'));
        }),

        didRender: function didRender() {
            _ember['default'].run.schedule('afterRender', this, function () {
                var _this = this;

                var childLinkElements = this.$('a.ember-view');

                var childLinkViews = childLinkElements.toArray().map(function (view) {
                    return _this._viewRegistry[view.id];
                });

                this.set('childLinkViews', childLinkViews);
            });
        }
    });
});