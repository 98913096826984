define('retro-game-store/pods/components/report-table-totals/component', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'tr',

        rowWidth: computed(function () {
            var width = get(this, 'col.width');
            return 'html-safe width: ' + width + ';';
        })
    });
});