define('retro-game-store/pods/invoice-item/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;

    /*
    If you are adding or modifying properties below be sure to check the serializer
    to make sure the correct properties are being sent in the save payload.
     */

    exports['default'] = Model.extend({
        buyPrice: attr('number'),
        dateVoided: attr('date'),
        invoiceId: attr('number'),
        itemCode: attr('string'),
        productSkuId: attr('number'),
        quantity: attr('number'),
        sellPrice: attr('number'),
        storeId: attr('number'),
        voidable: attr('boolean'),

        // part of model attributes received, but may be used when saving a new invoice-item
        category: attr(),
        categoryId: attr('number'),
        categoryName: attr('string'),
        productId: attr('number'),
        productName: attr('string'),
        upc: attr('string'),

        //Relationships
        productSku: belongsTo('product-sku'),
        storeInfo: belongsTo('store')
    });
});