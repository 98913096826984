define('retro-game-store/constants/label-print-type', ['exports'], function (exports) {
    var LABEL_PRINT_TYPE_NONE = { id: 0, label: 'None', type: 'None' };
    exports.LABEL_PRINT_TYPE_NONE = LABEL_PRINT_TYPE_NONE;
    var LABEL_PRINT_TYPE_MEDIA_ONLY = { id: 1, label: 'Media Only', type: 'MediaOnly' };
    exports.LABEL_PRINT_TYPE_MEDIA_ONLY = LABEL_PRINT_TYPE_MEDIA_ONLY;
    var LABEL_PRINT_TYPE_BOX_ONLY = { id: 2, label: 'Box Only', type: 'BoxOnly' };
    exports.LABEL_PRINT_TYPE_BOX_ONLY = LABEL_PRINT_TYPE_BOX_ONLY;
    var LABEL_PRINT_TYPE_ALL = { id: 3, label: 'All', type: 'All' };

    exports.LABEL_PRINT_TYPE_ALL = LABEL_PRINT_TYPE_ALL;
    exports['default'] = [LABEL_PRINT_TYPE_NONE, LABEL_PRINT_TYPE_MEDIA_ONLY, LABEL_PRINT_TYPE_BOX_ONLY, LABEL_PRINT_TYPE_ALL];
});