define('retro-game-store/pods/product-type/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        importEndpoint: attr('string'),
        name: attr('string'),
        pricingEndpoint: attr('string'),
        researchEndpoint: attr('string'),
        sourceId: attr('number'),

        //Relationships
        conditions: hasMany('condition'),
        productCategories: hasMany('product-category')
    });
});