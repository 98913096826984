define('retro-game-store/mixins/scanner-event-listener', ['exports', 'ember'], function (exports, _ember) {
    var assert = _ember['default'].assert;
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Mixin = _ember['default'].Mixin;
    var set = _ember['default'].set;
    exports['default'] = Mixin.create({
        api: service(),
        scanner: service(),
        ignoreScan: false,
        listenForScanner: function listenForScanner() {
            set(this, 'ignoreScan', false);
        },
        ignoreScanner: function ignoreScanner() {
            set(this, 'ignoreScan', true);
        },
        scanError: false,
        init: function init() {
            assert('Must supply action "scanSuccess" on controller if using scanner-event-listener', this.actions.scanSuccess !== undefined);
            get(this, 'scanner').on('success', this, function (number) {
                if (get(this, 'ignoreScan') === false) {
                    set(this, 'api.errors', null);
                    this.send('scanSuccess', number);
                }
            });
            get(this, 'scanner').on('error', this, function () {
                if (get(this, 'ignoreScan') === false) {
                    set(this, 'api.errors', {
                        'messages': ['There was an error scanning the item']
                    });
                }
            });
            this._super.apply(this, arguments);
        }
    });
});