define('retro-game-store/mixins/controller-filtering', ['exports', 'ember', 'retro-game-store/mixins/query-params'], function (exports, _ember, _retroGameStoreMixinsQueryParams) {
    var get = _ember['default'].get;
    var Mixin = _ember['default'].Mixin;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Mixin.create(_retroGameStoreMixinsQueryParams['default'], {
        filter: function filter(params) {
            var _this = this;

            var queryParams = this.getParamsObject(params);
            queryParams.offset = 0;
            queryParams.limit = get(this, 'limit');
            queryParams = this.removeEmptyQueryParams(queryParams);

            set(this, 'hasMore', true);
            set(this, 'isBusy', true);
            set(this, 'pagesLoaded', 1);

            var promise = this.store.query(get(this, 'typeKey'), queryParams);
            promise.then(function (response) {
                var newContent = response.toArray();
                set(_this, 'model', newContent);
                if (newContent.length < get(_this, 'limit')) {
                    set(_this, 'hasMore', false);
                }
                set(_this, 'isBusy', false);
            });
            return promise;
        },
        filterWithMeta: function filterWithMeta(params) {
            var _this2 = this;

            var queryParams = this.getParamsObject(params);
            queryParams.offset = 0;
            queryParams.limit = get(this, 'limit');
            queryParams = this.removeEmptyQueryParams(queryParams);

            set(this, 'hasMore', true);
            set(this, 'isBusy', true);
            set(this, 'pagesLoaded', 1);

            var promise = this.store.query(get(this, 'typeKey'), queryParams);
            promise.then(function (response) {
                var newContent = response.toArray();
                setProperties(_this2.model, {
                    models: response.toArray(),
                    meta: get(response, 'meta')
                });
                if (newContent.length < get(_this2, 'limit')) {
                    set(_this2, 'hasMore', false);
                }
                set(_this2, 'isBusy', false);
            });
            return promise;
        }
    });
});