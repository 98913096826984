define('retro-game-store/pods/invoices/invoice/items/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations) {
    var _Ember$computed = _ember['default'].computed;
    var reads = _Ember$computed.reads;
    var equal = _Ember$computed.equal;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        api: service(),

        invoiceController: controller('invoices/invoice'),
        isInvoiceClosed: reads('invoiceController.model.isClosed'),
        invocieStatus: reads('invoiceController.model.status'),
        isInvoiceVoided: equal('invocieStatus', 'Voided'),
        limit: 10,
        invoiceId: reads('invoiceController.model.id'),
        invoice: reads('invoiceController.model'),
        serverQueryParams: ['invoiceId'],

        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },
            processInvoice: function processInvoice(callback) {
                var _this = this;

                var promise = get(this, 'api').request('POST', '/processInvoice/' + get(this, 'invoiceController.model.id')).then(function () {
                    _this.transitionToRoute('invoices');
                });
                callback(promise);
            },
            voidItem: function voidItem(row) {
                var _this2 = this;

                return get(this, 'api').request('DELETE', '/invoiceItems/' + get(row, 'id')).then(function () {
                    get(_this2, 'invoice').reload();
                });
            }
        }
    });
});