define('retro-game-store/pods/inventory-history/model', ['exports', 'ember-data', 'ember', 'retro-game-store/constants/event-types'], function (exports, _emberData, _ember, _retroGameStoreConstantsEventTypes) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        date: attr('date'),
        employeeId: attr('number'),
        eventType: attr('string'),
        inventoryItemId: attr('number'),
        invoiceId: attr('number'),
        newConditionId: attr('number'),
        newPrice: attr('number'),
        quantityAdjusted: attr('number'),
        transactionId: attr('number'),

        //Relationships
        employee: belongsTo('employee'),
        inventoryItem: belongsTo('inventory'),
        invoice: belongsTo('invoice'),
        newCondition: belongsTo('condition'),
        transaction: belongsTo('sale'),

        //Computeds
        activity: computed('eventType', function () {
            var eventType = get(this, 'eventType');
            switch (eventType) {
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE, 'type'):
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRADE, 'type'):
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_RETURN, 'type'):
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type'):
                    return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE, 'type');
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_BUY, 'type'):
                    return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_BUY, 'type');
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_RESTOCK, 'type'):
                    return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_RESTOCK, 'type');
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_PRICE_CHANGE, 'type'):
                    return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_PRICE_CHANGE, 'type');
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRANSFER_FROM, 'type'):
                    return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRANSFER_FROM, 'type');
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRANSFER_TO, 'type'):
                    return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRANSFER_TO, 'type');
                case get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_CONDITION_CHANGED, 'type'):
                    return get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_CONDITION_CHANGED, 'type');
            }
        })
    });
});