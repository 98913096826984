define('retro-game-store/pods/customers/customer/notifications/create-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var mapBy = _Ember$computed.mapBy;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var controller = _ember['default'].inject.controller;
    var next = _ember['default'].run.next;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        customerController: controller('customers.customer'),
        customer: reads('customerController.model'),
        limit: 10,
        serverQueryParams: ['keywords', 'categoryIds', 'productTypeIds', 'storeId'],
        title: 'Add Notification',
        selectedCategories: A(),
        categoryIds: mapBy('selectedCategories', 'id'),
        selectedProductTypes: A(),
        productTypeIds: mapBy('selectedProductTypes', 'id'),
        storeId: computed('storeFilter', function () {
            if (get(this, 'storeFilter')) {
                return get(this, 'storeFilter.id');
            }
        }),
        cancel: function cancel() {
            var _this = this;

            var customerId = get(this, 'customer.id');
            next(this, function () {
                _this.replaceRoute('customers.customer.notifications', customerId);
            });
        },
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },
            cancel: function cancel() {
                this.cancel();
            },
            notify: function notify(inventory) {
                var _this2 = this;

                var notification = get(this, 'notification');
                setProperties(notification, {
                    customer: get(this, 'customer'),
                    product: get(inventory, 'product'),
                    storeInfo: get(inventory, 'storeInfo')
                });
                get(this, 'notification').save().then(function () {
                    _this2.cancel();
                });
            }
        }
    });
});