define('retro-game-store/pods/components/generic-modal/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        visible: false,
        show: (function () {
            var _this = this;

            this.$('.modal').modal().on('hidden.bs.modal', (function () {
                if (!_this.get('isDestroyed')) {
                    _this.set('visible', false);
                    _this.sendAction('cancel');
                }
            }).bind(this));
            this.$('.modal').modal().on('shown.bs.modal', function () {
                _this.set('visible', true);
                if (_this.get('focusInput')) {
                    _this.get('element').querySelector('input').focus();
                }
            });
        }).on('didInsertElement'),

        willDestroyElement: function willDestroyElement() {
            this.$('.modal').modal('hide');
            // this.sendAction('cancel');
        }
    });
});