define('retro-game-store/mixins/check-abilities', ['exports', 'ember', 'retro-game-store/config/environment', 'ember-can'], function (exports, _ember, _retroGameStoreConfigEnvironment, _emberCan) {
    exports['default'] = _ember['default'].Mixin.create(_emberCan.CanMixin, {
        modelForAbilities: false,
        _checkAbilities: function _checkAbilities(model) {
            var _this = this;

            var abilitiesRequired = this.get('abilitiesRequired');
            if (_ember['default'].isArray(abilitiesRequired)) {
                abilitiesRequired.forEach(function (abilityRequired) {
                    //if we can't preform this ability, forward to the 403 page
                    if (_this.cannot(abilityRequired, model)) {
                        _this.transitionTo('fourOhThree', _retroGameStoreConfigEnvironment['default']['affiliate']);
                    }
                });
            }
        },
        beforeModel: function beforeModel() {
            if (!this.get('modelForAbilities')) {
                this._checkAbilities();
            }
            return this._super.apply(this, arguments);
        },
        afterModel: function afterModel(model) {
            if (this.get('modelForAbilities')) {
                this._checkAbilities(model);
            }
            return this._super.apply(this, arguments);
        }
    });
});