define('retro-game-store/pods/shipping/sale/shipments/shipment/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        saleController: _ember['default'].inject.controller('shipping.sale'),
        sale: _ember['default'].computed.reads('saleController.model'),
        qz: _ember['default'].inject.service(),
        print: _ember['default'].inject.service(),
        i18n: _ember['default'].inject.service(),
        api: _ember['default'].inject.service(),
        resetPrintButtons: function resetPrintButtons() {
            this.set('printSpinning', false);
            this.set('disabled', false);
        },
        printButtonDisabled: _ember['default'].computed('disabled', 'qz.labelPrinterIsConnected', function () {
            if (this.get('qz.labelPrinterIsConnected') && !this.get('disabled')) {
                return false;
            } else {
                return true;
            }
        }),
        actions: {
            printLabel: function printLabel() {
                var _this = this;

                this.set('printSpinning', true);
                this.set('disabled', true);

                this.get('print').printShippingLabel(this.get('model').get('easyPostId')).then(function () {
                    _this.resetPrintButtons();
                    _this.set('error', null);
                })['catch'](function () {
                    _this.resetPrintButtons();
                    _this.set('error', _this.get('i18n').t('shipment.printError'));
                });
            },
            openLabelImage: function openLabelImage(shipmentId) {
                this.get('api').request('GET', '/easy-post/get-label-url/' + shipmentId).then(function (url) {
                    window.open(url);
                });
            }
        }
    });
});