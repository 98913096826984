define('retro-game-store/pods/stores/logo-instructions-modal/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        closeModal: function closeModal() {
            this.send('closeModal');
        },
        actions: {
            cancel: function cancel() {
                this.closeModal();
            }
        }
    });
});