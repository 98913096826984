define('retro-game-store/pods/can/service', ['exports', 'ember-can'], function (exports, _emberCan) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    exports['default'] = _emberCan.CanService.extend({
        parse: function parse(str) {
            var _str$split = str.split('.');

            var _str$split2 = _slicedToArray(_str$split, 2);

            var abilityName = _str$split2[0];
            var propertyName = _str$split2[1];

            return {
                propertyName: propertyName,
                abilityName: abilityName
            };
        }
    });
});