define('retro-game-store/mixins/scanner-detection', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        scanner: _ember['default'].inject.service(),
        setupController: function setupController() {
            _ember['default'].run.scheduleOnce('afterRender', this.get('scanner'), this.get('scanner').listen);
            this._super.apply(this, arguments);
        },
        resetController: function resetController() {
            this.get('scanner').ignore();
            this._super.apply(this, arguments);
        }
    });
});