define('retro-game-store/pods/affiliate/platform-discount/model', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
    var get = _ember['default'].get;
    var isEmpty = _ember['default'].isEmpty;
    var computed = _ember['default'].computed;
    exports['default'] = _emberDataModel['default'].extend({
        affiliateId: (0, _emberDataAttr['default'])('number'),
        cashDiscount: (0, _emberDataAttr['default'])('number'),
        tradePercentage: (0, _emberDataAttr['default'])('number'),

        //Relationships
        category: (0, _emberDataRelationships.belongsTo)('product-category'),
        productType: (0, _emberDataRelationships.belongsTo)('product-type'),

        //Computeds
        cashDiscountAsPercent: computed('cashDiscount', {
            get: function get() {
                return this.get('cashDiscount') ? this.get('cashDiscount') * 100 : null;
            },
            set: function set(key, value) {
                this.set('cashDiscount', value ? value / 100 : null);
                return value;
            }
        }),
        fullCategory: computed('category.name', 'productType.name', function () {
            if (!isEmpty(get(this, 'category.name'))) {
                return get(this, 'productType.name') + ' / ' + get(this, 'category.name');
            } else {
                return '' + get(this, 'productType.name');
            }
        }),
        tradePercentageAsPercent: computed('tradePercentage', {
            get: function get() {
                return this.get('tradePercentage') ? this.get('tradePercentage') * 100 : null;
            },
            set: function set(key, value) {
                this.set('tradePercentage', value ? value / 100 : null);
                return value;
            }
        })
    });
});