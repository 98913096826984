define('retro-game-store/pods/components/category-dropdown/pane/component', ['exports', 'ember', 'ember-awesome-macros/array/sort'], function (exports, _ember, _emberAwesomeMacrosArraySort) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var computed = _ember['default'].computed;
    var tryInvoke = _ember['default'].tryInvoke;
    var observer = _ember['default'].observer;
    exports['default'] = _ember['default'].Component.extend({
        categoryListItemComponent: 'category-dropdown/category-list-item',
        canAddParent: true,
        items: (0, _emberAwesomeMacrosArraySort['default'])('filteredItems', ['name:asc']),
        filteredItems: computed('parentCategory.productCategories.[]', 'value', function () {
            var categories = get(this, 'parentCategory.productCategories');
            var value = get(this, 'value');
            if (!value) {
                return categories;
            } else {
                return categories.filter(function (item) {
                    return get(item, 'name').toLowerCase().includes(value.toLowerCase());
                });
            }
        }),
        itemsDidChange: observer('items.[]', function () {
            tryInvoke(this.attrs, 'filtered', [get(this, 'items')]);
        }),
        classNames: ['category-dropdown-list-items'],
        actions: {
            addParentItem: function addParentItem(parentCategory) {
                set(this, 'parentAdded', true);
                tryInvoke(this, 'addParentItem', [parentCategory]);
            },
            addItem: function addItem(category) {
                tryInvoke(this, 'addItem', [category]);
            },
            toggleItem: function toggleItem(category) {
                if (get(category, 'selected')) {
                    tryInvoke(this, 'addItem', [category]);
                } else {
                    tryInvoke(this, 'removeItem', [category]);
                }
            }
        }
    });
});