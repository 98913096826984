define("retro-game-store/pods/components/add-inventory-item/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 0
          }
        },
        "moduleName": "retro-game-store/pods/components/add-inventory-item/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "col-md-3 col-xs-2");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "col-md-3 col-xs-2");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "col-md-2 col-xs-2");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "col-md-1 col-xs-2");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "col-md-1 col-xs-2");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "col-md-2 col-xs-2");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row pt-3");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12 col-md-8");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12 col-md-3 pl-0 pr-3");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-sm btn-default");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [10, 1]);
        var element2 = dom.childAt(element1, [3, 1]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [6]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [8]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[7] = dom.createAttrMorph(element2, 'disabled');
        morphs[8] = dom.createElementMorph(element2);
        morphs[9] = dom.createMorphAt(element2, 0, 0);
        return morphs;
      },
      statements: [["inline", "product-badge", [], ["name", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [2, 25], [2, 35]]]]], [], []], "category", ["subexpr", "@mut", [["get", "model.fullCategory", ["loc", [null, [2, 45], [2, 63]]]]], [], []], "tight", true], ["loc", [null, [2, 4], [2, 76]]]], ["content", "model.stockNumber", ["loc", [null, [6, 8], [6, 29]]]], ["content", "model.storeName", ["loc", [null, [9, 8], [9, 27]]]], ["inline", "t", ["inventory.item.ageInDays"], ["count", ["subexpr", "@mut", [["get", "model.ageInDays", ["loc", [null, [13, 41], [13, 56]]]]], [], []]], ["loc", [null, [13, 4], [13, 58]]]], ["inline", "format-money", [["get", "model.sellPrice", ["loc", [null, [16, 19], [16, 34]]]]], [], ["loc", [null, [16, 4], [16, 36]]]], ["content", "model.quantityAvailable", ["loc", [null, [19, 4], [19, 31]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "quantity", ["loc", [null, [24, 26], [24, 34]]]]], [], []], "type", "number", "disabled", ["subexpr", "eq", [["get", "model.quantityAvailable", ["loc", [null, [24, 62], [24, 85]]]], 0], [], ["loc", [null, [24, 58], [24, 88]]]], "class", "form-control", "size", "3", "min", "1", "max", ["subexpr", "@mut", [["get", "model.quantityAvailable", ["loc", [null, [24, 131], [24, 154]]]]], [], []]], ["loc", [null, [24, 12], [24, 156]]]], ["attribute", "disabled", ["subexpr", "eq", [["get", "model.quantityAvailable", ["loc", [null, [27, 65], [27, 88]]]], 0], [], ["loc", [null, [27, 60], [27, 92]]]]], ["element", "action", ["addItem"], [], ["loc", [null, [27, 93], [27, 113]]]], ["inline", "t", ["add.label"], [], ["loc", [null, [27, 114], [27, 131]]]]],
      locals: [],
      templates: []
    };
  })());
});