define('retro-game-store/pods/invoice/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            affiliate: {
                embedded: 'always'
            },
            employee: {
                embedded: 'always'
            },
            invoiceItems: {
                embedded: 'always'
            },
            vendor: {
                embedded: 'always'
            }
        }
    });
});