define('retro-game-store/pods/affiliates/new/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        creditCard: _ember['default'].computed.alias('model.creditCard'),
        validations: {
            'model.name': {
                presence: true
            },
            'model.contactFirstName': {
                presence: true
            },
            'model.contactLastName': {
                presence: true
            },
            'model.contactEmail': {
                email: true
            },
            'model.phoneNumber': {
                presence: true
            },
            'creditCard.number': {
                presence: true
            },
            'creditCard.expirationDate': {
                presence: true
            },
            'creditCard.nameOnCard': {
                presence: true
            },
            'model.subscriptionPlanId': {
                presence: true
            },
            'model.maxStoreLimit': {
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0,
                    allowBlank: true
                }
            }
        },
        actions: {
            save: function save(callback) {
                var _this = this;

                var promise = this.get('model').save().then(function () {
                    _this.transitionToRoute('affiliates.affiliate.general', _this.get('model').get('id'));
                });
                callback(promise);
            }
        }
    });
});