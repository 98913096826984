define('retro-game-store/pods/pricing/product/inventory/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        categories: service(),

        model: function model() {
            var pricingProductModel = this.modelFor('pricing.product');
            var pricingController = this.controllerFor('pricing.index');
            set(this, 'uncompetitiveOnly', get(pricingController, 'uncompetitiveOnlyFiltered'));
            var queryParams = {
                conditionId: get(pricingProductModel, 'condition.id'),
                productId: get(pricingProductModel, 'productId'),
                storeId: get(pricingController, 'storeId'),
                categoryIds: get(pricingProductModel, 'category.id'),
                uncompetitiveOnly: get(this, 'uncompetitiveOnly'),
                limit: 10,
                skip: 0
            };
            return this.pagingQuery('inventory-price', queryParams, 'pricing.product.inventory');
        },

        setupController: function setupController(controller) {
            var productTypes = get(this, 'categories.raw');
            var uncompetitiveOnly = get(this, 'uncompetitiveOnly');
            setProperties(controller, {
                productTypes: productTypes,
                uncompetitiveOnly: uncompetitiveOnly,
                printBoxLabel: true
            });

            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });

            this._super.apply(this, arguments);
        },

        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                setProperties(controller, {
                    newPrice: 0,
                    inventoryRequiredError: false,
                    selections: []
                });
            }
        }
    });
});