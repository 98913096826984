define('retro-game-store/pods/customer-notification/model', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        createdDate: _emberData['default'].attr('date'),
        notifiedDate: _emberData['default'].attr('date'),
        customer: _emberData['default'].belongsTo('customer'),
        product: _emberData['default'].belongsTo('product'),
        storeInfo: _emberData['default'].belongsTo('store'),
        createdDateMinNow: _ember['default'].computed('createdDate', function () {
            // This computed property takes care of server and browser being slightly out of sync.
            // We were seeing 'a few seconds from now', because the server was ahead of the browser slightly
            return this.get('createdDate').max(new Date());
        })
    });
});