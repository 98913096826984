define('retro-game-store/pods/sale/model', ['exports', 'ember-data', 'ember', 'ember-awesome-macros/bool', 'ember-awesome-macros/equal', 'ember-awesome-macros/not-equal', 'ember-awesome-macros/not', 'ember-awesome-macros/raw', 'ember-awesome-macros/and', 'retro-game-store/constants/order-statuses'], function (exports, _emberData, _ember, _emberAwesomeMacrosBool, _emberAwesomeMacrosEqual, _emberAwesomeMacrosNotEqual, _emberAwesomeMacrosNot, _emberAwesomeMacrosRaw, _emberAwesomeMacrosAnd, _retroGameStoreConstantsOrderStatuses) {
    var get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        customerId: attr('number'),
        date: attr('date'),
        description: attr('string'),
        discount: attr('number'),
        discountPercent: attr('percent'),
        discountTotal: attr('number'),
        processedById: attr('number'),
        purchaseTotal: attr('number'),
        remainingCreditReturnTotal: attr('number'),
        remainingExternalReturnTotal: attr('number'),
        returnTotal: attr('number'),
        returnTransactionId: attr('number'),
        sellTotal: attr('number'),
        shippingAmount: attr('number'),
        status: attr('string'),
        storeId: attr('number'),
        subtotal: attr('number'),
        taxAmount: attr('number'),
        taxPercent: attr('percent'),
        totalAmount: attr('number'),
        tradeTotal: attr('number'),
        tradeType: attr('string', { defaultValue: 'credit' }),

        //Relationships
        customer: belongsTo('customer'),
        payments: hasMany('sale-payment'),
        processedBy: belongsTo('employee'),
        returnTransaction: belongsTo('sale'),
        storeInfo: belongsTo('store'),

        //Computeds
        isClosed: (0, _emberAwesomeMacrosEqual['default'])('status', (0, _emberAwesomeMacrosRaw['default'])(get(_retroGameStoreConstantsOrderStatuses.ORDER_STATUS_CLOSED, 'label'))),
        isReturn: (0, _emberAwesomeMacrosBool['default'])('returnTransaction.id'),
        isPercentDiscount: (0, _emberAwesomeMacrosNot['default'])((0, _emberAwesomeMacrosAnd['default'])((0, _emberAwesomeMacrosNotEqual['default'])('discount', 0), (0, _emberAwesomeMacrosEqual['default'])('discountPercent', 0))),
        hasNoTax: (0, _emberAwesomeMacrosEqual['default'])('taxPercent', 0)
    });
});