define('retro-game-store/pods/sale-item-summary/model', ['exports', 'ember-data', 'ember', 'ember-inflector', 'retro-game-store/constants/event-types'], function (exports, _emberData, _ember, _emberInflector, _retroGameStoreConstantsEventTypes) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var isEmpty = _ember['default'].isEmpty;

    var inflector = _emberInflector['default'].inflector;
    inflector.uncountable('saleItemSummary', 'saleItemSummary');
    inflector.uncountable('sale-item-summary', 'sale-item-summary');

    exports['default'] = Model.extend({
        basePrice: attr('number'),
        cashPrice: attr('number'),
        costOfGoods: attr('number'),
        discount: attr('number'),
        eventType: attr('string'),
        name: attr('string'),
        quantity: attr('number'),
        quantityReturned: attr('number'),
        sellPrice: attr('number'),
        subtotal: attr('number'),
        tax: attr('number'),
        tradePrice: attr('number'),
        transactionId: attr('number'),

        //Relationships
        category: belongsTo('product-category'),
        productType: belongsTo('product-type'),
        storeInfo: belongsTo('store'),

        //Computeds
        fullCategory: computed('eventType', 'category.name', 'productType.name', function () {
            var i18n = get(this, 'i18n');
            var eventType = get(this, 'eventType');
            if (!isEmpty(get(this, 'category.name'))) {
                return get(this, 'productType.name') + ' / ' + get(this, 'category.name');
            } else if (eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type')) {
                return i18n.t('pos.itemReport.miscellaneous');
            } else {
                return '' + get(this, 'productType.name');
            }
        }),

        net: computed('eventType', 'basePrice', 'tradePrice', 'cashPrice', 'costOfGoods', 'sellPrice', function () {
            var eventType = get(this, 'eventType');
            if (eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE, 'type') || eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type')) {
                return get(this, 'basePrice') - get(this, 'costOfGoods') + get(this, 'discount');
            }
            if (eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRADE, 'type') || eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_BUY, 'type')) {
                return get(this, 'sellPrice') + get(this, 'cashPrice') + get(this, 'tradePrice');
            }
        }),

        itemPrice: computed('eventType', 'basePrice', 'sellPrice', function () {
            var eventType = get(this, 'eventType');
            if (eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_SALE, 'type') || eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_MISCELLANEOUS_SALE, 'type')) {
                return get(this, 'basePrice');
            }
            if (eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_TRADE, 'type') || eventType === get(_retroGameStoreConstantsEventTypes.EVENT_TYPE_BUY, 'type')) {
                return get(this, 'sellPrice');
            }
        })
    });
});