define('retro-game-store/pods/invoices/invoice/details/controller', ['exports', 'ember', 'ember-validations', 'ember-awesome-macros/array/filter-by', 'ember-awesome-macros/raw'], function (exports, _ember, _emberValidations, _emberAwesomeMacrosArrayFilterBy, _emberAwesomeMacrosRaw) {
    var _Ember$computed = _ember['default'].computed;
    var reads = _Ember$computed.reads;
    var equal = _Ember$computed.equal;
    var notEmpty = _Ember$computed.notEmpty;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        api: service(),

        invoiceController: controller('invoices/invoice'),
        isInvoiceClosed: reads('invoiceController.model.isClosed'),
        invocieStatus: reads('invoiceController.model.status'),
        isInvoiceVoided: equal('invocieStatus', 'Voided'),
        validations: {},

        vendors: [],

        invoiceItems: (0, _emberAwesomeMacrosArrayFilterBy['default'])('model.invoiceItems', (0, _emberAwesomeMacrosRaw['default'])('voidable'), false),
        hasUnvoidableItems: notEmpty('invoiceItems'),

        actions: {
            getAllVendors: function getAllVendors() {
                this.set('vendors', this.store.findAll('vendor'));
            },

            createVendor: function createVendor(term) {
                var vendor = this.store.createRecord('vendor', {
                    name: term
                });
                this.get('model').set('vendor', vendor);
            },

            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            },
            voidInvoice: function voidInvoice(model) {
                var _this = this;

                this.get('api').request('DELETE', '/invoices/' + get(model, 'id')).then(function () {
                    _this.transitionToRoute('invoices');
                });
            }
        }
    });
});