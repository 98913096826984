define('retro-game-store/pods/dashboard/stores/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/activate-controller-via-route'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsActivateControllerViaRoute) {
    var get = _ember['default'].get;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    exports['default'] = Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsActivateControllerViaRoute['default'], {
        model: function model(params) {
            var _this = this;

            var controller = this.controllerFor('dashboard.stores');
            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQueryWithMeta('store-total', queryParams, 'dashboard.stores').then(function (data) {
                set(data, 'meta.id', -1);
                if (_this.store.hasRecordForId('store-total', -1)) {
                    _this.store.peekRecord('store-total', -1).setProperties(get(data, 'meta'));
                } else {
                    var totals = _this.store.createRecord('store-total', get(data, 'meta'));
                    set(_this, 'totals', totals);
                }
                return data.models;
            });
        },
        setupController: function setupController(controller, model) {
            set(controller, 'totals', get(this, 'totals'));
            this._super(controller, model);
        }
    });
});