define('retro-game-store/pods/components/pikaday-moment/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Component.extend({
        dateValue: _ember['default'].computed('value', function () {
            var value = this.get('value');
            if (_moment['default'].isMoment(value)) {
                return value.toDate();
            } else if (value instanceof Date) {
                return value;
            }
        }),
        dateValueDidChange: _ember['default'].observer('dateValue', function () {
            this.set('value', (0, _moment['default'])(this.get('dateValue')));
        })
    });
});