define('retro-game-store/pods/sales/items-traded/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar', 'retro-game-store/mixins/authenticated-route', 'retro-game-store/mixins/check-abilities'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar, _retroGameStoreMixinsAuthenticatedRoute, _retroGameStoreMixinsCheckAbilities) {
    var Route = _ember['default'].Route;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Route.extend(_retroGameStoreMixinsAuthenticatedRoute['default'], _retroGameStoreMixinsCheckAbilities['default'], _retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        abilitiesRequired: ['sale.access'],

        model: function model(params) {
            var controller = this.controllerFor('sales.items-traded');
            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 20, params);
            return this.pagingQueryWithMeta('sale-item-summary', queryParams, 'sales.items-traded');
        },
        setupController: function setupController(controller, model) {
            setProperties(controller, {
                resultsShownDate: get(controller, 'dateShownFormatted'),
                nameFilterTypes: get(controller, 'nameFilterTypes'),
                costFilterTypes: get(controller, 'costFilterTypes'),
                nameFilterTypeSelected: get(controller, 'nameFilterTypes.firstObject'),
                costFilterTypeSelected: get(controller, 'costFilterTypes.firstObject')
            });

            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });
            this._super(controller, model);
        }
    });
});