define('retro-game-store/pods/sales/completed/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'ember-validations', 'retro-game-store/mixins/controller-filtering'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _emberValidations, _retroGameStoreMixinsControllerFiltering) {
    var alias = _ember['default'].computed.alias;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var Promise = _ember['default'].RSVP.Promise;
    var debounce = _ember['default'].run.debounce;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        limit: 10,
        serverQueryParams: ['status', 'startDate', 'endDate', 'storeId', 'customerId'],
        status: 'closed',
        storeId: alias('storeFilter.id'),
        customerId: alias('selectedCustomer.id'),
        pagingTarget: 'model.models',

        performCustomerSearch: function performCustomerSearch(term, resolve, reject) {
            var sorts = [{ name: 'lastName', direction: 'asc' }, { name: 'firstName', direction: 'asc' }, { name: 'city', direction: 'asc' }, { name: 'state', direction: 'asc' }];
            return this.store.query('customer', {
                keywords: term,
                sort: sorts
            }).then(function (result) {
                return resolve(result);
            }, reject);
        },

        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filterWithMeta(get(this, 'serverQueryParams'));
                callback(promise);
            },
            searchForCustomers: function searchForCustomers(term) {
                var _this = this;

                return new Promise(function (resolve, reject) {
                    debounce(_this, 'performCustomerSearch', term, resolve, reject, 500);
                });
            }
        }
    });
});