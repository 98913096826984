define('retro-game-store/pods/employees/employee/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _emberValidations['default'], {
        limit: 50,
        pagingTarget: 'stores',
        serverQueryParams: [],
        validations: {
            'model.firstName': {
                presence: true
            },
            'model.lastName': {
                presence: true
            },
            'model.email': {
                email: true
            },
            'model.password': {
                confirmation: {
                    message: ' must match Password'
                }
            }
        },
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore();
                callback(promise);
            },
            save: function save(callback) {
                var promise = this.get('model').save();
                callback(promise);
            }
        }
    });
});