define('retro-game-store/pods/application/settings-modal/controller', ['exports', 'ember', 'retro-game-store/constants/print-samples', 'retro-game-store/utils/print-commands'], function (exports, _ember, _retroGameStoreConstantsPrintSamples, _retroGameStoreUtilsPrintCommands) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var isEmpty = _ember['default'].isEmpty;
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = Controller.extend({
        session: service(),
        qz: service(),

        isLoadingPrinters: false,
        printers: [],
        findPrinterError: null,

        receiptPrinter: computed('printerOptions.[]', 'session.data.receiptPrinterId', function () {
            var printers = get(this, 'printerOptions');
            var id = get(this, 'session.data.receiptPrinterId');
            return !isEmpty(printers) && !isEmpty(id) && printers.findBy('id', id);
        }),

        labelPrinter: computed('printerOptions.[]', 'session.data.labelPrinterId', function () {
            var printers = get(this, 'printerOptions');
            var id = get(this, 'session.data.labelPrinterId');
            return !isEmpty(printers) && !isEmpty(id) && printers.findBy('id', id);
        }),

        printerOptions: computed('printers.[]', function () {
            return get(this, 'printers').map(function (printer) {
                return {
                    id: printer,
                    name: printer
                };
            });
        }),

        closeModal: function closeModal() {
            this.send('closeModal');
        },

        refreshPrinters: function refreshPrinters() {
            var _this = this;

            set(this, 'isLoadingPrinters', true);
            return get(this, 'qz').findPrinters().then(function (printers) {
                set(_this, 'printers', printers);
                set(_this, 'isLoadingPrinters', false);
            })['catch'](function () {
                set(_this, 'isLoadingPrinters', false);
            });
        },

        actions: {
            cancel: function cancel() {
                this.closeModal();
            },

            connectToQzTray: function connectToQzTray() {
                var _this2 = this;

                return get(this, 'qz').connect().then(function () {
                    return _this2.refreshPrinters();
                });
            },

            updatePrinter: function updatePrinter(type, option) {
                get(this, 'session').set('data.' + type + 'PrinterId', option ? option.id : null);
                get(this, 'qz').listenForPrinterStatusEvents();
            },

            refreshPrinters: function refreshPrinters() {
                return this.refreshPrinters();
            },

            printSampleSale: function printSampleSale() {
                var receiptLogo = get(this, 'session.user.storeInfo.receiptLogoImage');

                var _generateReceiptCommands = (0, _retroGameStoreUtilsPrintCommands.generateReceiptCommands)(_retroGameStoreConstantsPrintSamples.PRINT_SAMPLE_RECEIPT_SALE, receiptLogo);

                var _generateReceiptCommands2 = _slicedToArray(_generateReceiptCommands, 2);

                var commands = _generateReceiptCommands2[0];
                var configOverrides = _generateReceiptCommands2[1];

                return get(this, 'qz').printToReceiptPrinter(commands, configOverrides);
            },

            printSampleAuth: function printSampleAuth() {
                var _generateAuthReceiptCommands = (0, _retroGameStoreUtilsPrintCommands.generateAuthReceiptCommands)(_retroGameStoreConstantsPrintSamples.PRINT_SAMPLE_RECEIPT_AUTH);

                var _generateAuthReceiptCommands2 = _slicedToArray(_generateAuthReceiptCommands, 2);

                var commands = _generateAuthReceiptCommands2[0];
                var configOverrides = _generateAuthReceiptCommands2[1];

                return get(this, 'qz').printToReceiptPrinter(commands, configOverrides);
            },

            printSampleMedia: function printSampleMedia() {
                var _generateInventoryLabelCommands = (0, _retroGameStoreUtilsPrintCommands.generateInventoryLabelCommands)(_retroGameStoreConstantsPrintSamples.PRINT_SAMPLE_LABEL_MEDIA);

                var _generateInventoryLabelCommands2 = _slicedToArray(_generateInventoryLabelCommands, 2);

                var commands = _generateInventoryLabelCommands2[0];
                var configOverrides = _generateInventoryLabelCommands2[1];

                return get(this, 'qz').printToLabelPrinter(commands, configOverrides);
            },

            printSampleBox: function printSampleBox() {
                var _generateInventoryLabelCommands3 = (0, _retroGameStoreUtilsPrintCommands.generateInventoryLabelCommands)(_retroGameStoreConstantsPrintSamples.PRINT_SAMPLE_LABEL_BOX);

                var _generateInventoryLabelCommands32 = _slicedToArray(_generateInventoryLabelCommands3, 2);

                var commands = _generateInventoryLabelCommands32[0];
                var configOverrides = _generateInventoryLabelCommands32[1];

                return get(this, 'qz').printToLabelPrinter(commands, configOverrides);
            },

            printSampleShipping: function printSampleShipping() {
                var _generateShippingLabelCommands = (0, _retroGameStoreUtilsPrintCommands.generateShippingLabelCommands)(_retroGameStoreConstantsPrintSamples.PRINT_SAMPLE_LABEL_SHIPPING);

                var _generateShippingLabelCommands2 = _slicedToArray(_generateShippingLabelCommands, 2);

                var commands = _generateShippingLabelCommands2[0];
                var configOverrides = _generateShippingLabelCommands2[1];

                return get(this, 'qz').printToLabelPrinter(commands, configOverrides);
            },

            openCashDrawer: function openCashDrawer() {
                return get(this, 'qz').openCashDrawer();
            }
        }
    });
});