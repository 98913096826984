define('retro-game-store/pods/condition/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        productType: belongsTo('product-type'),
        productTypeId: attr('number'),
        sourceId: attr('number'),
        name: attr('string')
    });
});