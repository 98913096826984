define('retro-game-store/pods/label-option/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        storeId: attr(),

        category: belongsTo('product-category'),
        condition: belongsTo('condition'),
        productType: belongsTo('product-type'),
        storeInfo: belongsTo('store')
    });
});