define('retro-game-store/pods/employee-store/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        name: attr('string'),
        address: attr('string'),
        address2: attr('string'),
        city: attr('string'),
        state: attr('string'),
        zip: attr('string'),
        phoneNumber: attr('string'),
        affiliateId: attr('number'),
        defaultShipping: attr('number'),
        defaultTax: attr('number')
    });
});