define('retro-game-store/pods/inventory/items/item/details/print-label-modal/controller', ['exports', 'ember', 'retro-game-store/constants/label-print-type'], function (exports, _ember, _retroGameStoreConstantsLabelPrintType) {
    var A = _ember['default'].A;
    var computed = _ember['default'].computed;
    var reads = _ember['default'].computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Controller.extend({
        qz: service(),
        print: service(),
        api: service(),
        i18n: service(),

        batch: A([]),
        boxLabelsBatch: A([]),
        labels: A([]),
        quantity: 1,
        printBoxLabel: true,
        printMediaLabel: true,
        inventoryItemController: controller('inventory/items/item/details'),
        inventoryItem: reads('inventoryItemController.model'),
        disabled: false,
        printButtonDisabled: computed('disabled', 'quantity', 'printBoxLabel', 'printMediaLabel', function () {
            return get(this, 'disabled') || !parseInt(get(this, 'quantity'), 10) || !get(this, 'printBoxLabel') && !get(this, 'printMediaLabel');
        }),
        closeModal: function closeModal() {
            this.send('closeModal');
        },
        resetPrintButtons: function resetPrintButtons() {
            setProperties(this, {
                printSpinning: false,
                disabled: false
            });
        },
        actions: {
            cancel: function cancel() {
                setProperties(this, {
                    quantity: 1,
                    printBoxLabel: true,
                    printMediaLabel: true,
                    printSpinning: false,
                    disabled: false
                });
                this.closeModal();
            },
            printLabel: function printLabel() {
                var _this = this;

                setProperties(this, {
                    printSpinning: true,
                    disabled: true
                });
                var printMediaLabel = get(this, 'printMediaLabel');
                var printBoxLabel = get(this, 'printBoxLabel');
                var inventoryItem = get(this, 'inventoryItem').toJSON({ includeId: true });
                inventoryItem.category = {
                    name: get(this, 'inventoryItem.category.name')
                };
                inventoryItem.condition = {
                    name: get(this, 'inventoryItem.condition.name')
                };
                var printInventoryLabelList = [];

                for (var i = 0; i < get(this, 'quantity'); i++) {
                    if (printBoxLabel) {
                        inventoryItem.labelType = get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_BOX_ONLY, 'type');
                    }
                    if (printMediaLabel) {
                        inventoryItem.labelType = get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_MEDIA_ONLY, 'type');
                    }
                    if (printMediaLabel && printBoxLabel) {
                        inventoryItem.labelType = get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_ALL, 'type');
                    }
                    if (printBoxLabel || printMediaLabel) {
                        printInventoryLabelList.push(inventoryItem);
                    }
                }

                if (printInventoryLabelList.length > 0) {
                    this.get('print').printInventoryLabels(printInventoryLabelList).then(function () {
                        _this.resetPrintButtons();
                        set(_this, 'error', null);
                    })['catch'](function () {
                        _this.resetPrintButtons();
                        set(_this, 'error', _this.get('i18n').t('inventory.item.printModal.printError'));
                    });
                }
            }
        }
    });
});