define('retro-game-store/pods/debug/route', ['exports', 'ember', 'retro-game-store/mixins/authenticated-route', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsAuthenticatedRoute, _retroGameStoreMixinsTitleBar) {
  exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsAuthenticatedRoute['default'], _retroGameStoreMixinsTitleBar['default'], {
    terminal: _ember['default'].inject.service()
  });
});
/**
 * @DEPRECATED
 *
 * This page is no longer in use (see `application/settings-modal` instead).
 * However, it will remain in the application for legacy concerns.
 */