define('retro-game-store/components/x-toggle', ['exports', 'ember-cli-toggle/components/x-toggle/component', 'retro-game-store/config/environment'], function (exports, _emberCliToggleComponentsXToggleComponent, _retroGameStoreConfigEnvironment) {

  var config = _retroGameStoreConfigEnvironment['default']['ember-cli-toggle'] || {};

  exports['default'] = _emberCliToggleComponentsXToggleComponent['default'].extend({
    theme: config.defaultTheme || 'default',
    defaultOffLabel: config.defaultOffLabel || 'Off::off',
    defaultOnLabel: config.defaultOnLabel || 'On::on',
    showLabels: config.defaultShowLabels || false,
    size: config.defaultSize || 'medium'
  });
});