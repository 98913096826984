define('retro-game-store/pods/affiliates/affiliate/options/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        api: service(),
        categories: service(),
        tradePercentageModalController: controller('affiliates.affiliate.options.trade-percentage-modal'),
        tradePercentage: 1,
        validations: {
            'model.cashDiscount': {
                presence: true,
                numericality: {
                    messages: {
                        numericality: ' must be a number from 0 to 100'
                    },
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 100
                }
            },
            'model.tradePercentage': {
                presence: true,
                numericality: {
                    messages: {
                        numericality: ' must be a number from 0 to 100'
                    },
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 100
                }
            },
            'model.stripeAPIKey': {
                inline: (0, _emberValidations.validator)(function () {
                    if (!get(this, 'model.model.stripeAPIKey') && get(this, 'creditCardsEnabled') && get(this, 'gatewaySelected.id') === 'stripe') {
                        return " can't be blank";
                    }
                })
            },
            'model.authNetAPIKey': {
                inline: (0, _emberValidations.validator)(function () {
                    if (!get(this, 'model.model.authNetAPIKey') && get(this, 'creditCardsEnabled') && get(this, 'gatewaySelected.id') === 'auth') {
                        return " can't be blank";
                    }
                })
            },
            'model.authNetLogin': {
                inline: (0, _emberValidations.validator)(function () {
                    if (!get(this, 'model.model.authNetLogin') && get(this, 'creditCardsEnabled') && get(this, 'gatewaySelected.id') === 'auth') {
                        return " can't be blank";
                    }
                })
            },
            'model.easyPostAPIKey': {
                presence: {
                    'if': 'shippingEnabled'
                }
            },
            'gatewaySelected': {
                inline: (0, _emberValidations.validator)(function () {
                    if (get(this, 'creditCardsEnabled') && get(this, 'gatewaySelected.id') === 'default') {
                        return " must be selected if Enable Credit Card Processing checkbox is selected";
                    }
                })
            }
        },
        successIcon: 'fa fa-check',
        failureIcon: 'fa fa-exclamation-circle',
        gateway: [{ id: 'default', name: 'Select a gateway' }, { id: 'stripe', name: 'Use my Stripe account' }, { id: 'auth', name: 'Use my Authorize.net account' }],
        gatewaySelected: { id: 'default', name: 'Select a gateway' },
        creditCardsEnabled: false,
        shippingEnabled: false,
        actions: {
            save: function save(callback) {
                var _this = this;

                if (!get(this, 'shippingEnabled')) {
                    set(get(this, 'model'), 'easyPostAPIKey', null);
                }

                if (!get(this, 'creditCardsEnabled')) {
                    setProperties(get(this, 'model'), {
                        stripeAPIKey: null,
                        authNetAPIKey: null,
                        authNetLogin: null
                    });
                }

                if (get(this, 'gatewaySelected.id') === 'default' || get(this, 'creditCardsEnabled') === false) {
                    set(get(this, 'model'), 'creditCardApiType', 'None');
                } else if (get(this, 'gatewaySelected.id') === 'stripe') {
                    set(get(this, 'model'), 'creditCardApiType', 'Stripe');
                } else if (get(this, 'gatewaySelected.id') === 'auth') {
                    set(get(this, 'model'), 'creditCardApiType', 'AuthNet');
                }

                var promise = get(this, 'model').save().then(function () {
                    _this.store.unloadAll('affiliate/platform-discount');
                    get(_this, 'model').reload();
                });

                callback(promise);
            },
            testStripe: function testStripe(callback) {
                var _this2 = this;

                var promise = get(this, 'api').request('POST', '/stripe/validate-key/' + get(this, 'model.stripeAPIKey')).then(function () {
                    set(_this2, 'stripeTest', 'success');
                })['catch'](function () {
                    set(_this2, 'stripeTest', 'failure');
                });
                callback(promise);
            },
            testAuth: function testAuth(callback) {
                var _this3 = this;

                var promise = get(this, 'api').request('POST', '/authnet/validate-key/' + this.get('model').get('authNetAPIKey') + '/' + this.get('model').get('authNetLogin')).then(function () {
                    set(_this3, 'authTest', 'success');
                })['catch'](function () {
                    set(_this3, 'authTest', 'failure');
                });
                callback(promise);
            },
            testEasyPost: function testEasyPost(callback) {
                var _this4 = this;

                var promise = get(this, 'api').request('GET', '/easy-post/validate-key/' + get(this, 'model.easyPostAPIKey')).then(function () {
                    set(_this4, 'easyPostTest', 'success');
                })['catch'](function () {
                    set(_this4, 'easyPostTest', 'failure');
                });
                callback(promise);
            },
            addNewTradePercentage: function addNewTradePercentage() {
                set(get(this, 'tradePercentageModalController'), 'productTypes', get(this, 'categories.raw'));
                var location = 'affiliates/affiliate/options/trade-percentage-modal';
                var model = this.store.createRecord('affiliate/platform-discount');
                this.send('openModal', location, model, location);
            },
            editTradePercentages: function editTradePercentages(model) {
                var location = 'affiliates/affiliate/options/trade-percentage-modal';
                var controller = get(this, 'tradePercentageModalController');
                var category = get(model, 'category.content');
                var productType = get(model, 'productType.content');
                setProperties(controller, {
                    selectedCategories: category ? [category.toJSON({ includeId: true })] : [],
                    selectedProductTypes: category ? [] : [productType.toJSON({ includeId: true })],
                    productTypes: get(this, 'categories.raw')
                });
                this.send('openModal', location, model, location);
            },
            removeTradePercentage: function removeTradePercentage(model) {
                get(this, 'model.platformDiscounts').removeObject(model);
            }
        }
    });
});