define('retro-game-store/mixins/controller-pagination', ['exports', 'ember', 'retro-game-store/mixins/scroll-reset', 'retro-game-store/mixins/query-params'], function (exports, _ember, _retroGameStoreMixinsScrollReset, _retroGameStoreMixinsQueryParams) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var Mixin = _ember['default'].Mixin;
    var set = _ember['default'].set;
    exports['default'] = Mixin.create(_retroGameStoreMixinsScrollReset['default'], _retroGameStoreMixinsQueryParams['default'], {
        hasMore: true,
        isBusy: false,
        pagesLoaded: 1,
        limit: 10,
        offset: computed('pagingObject.[]', function () {
            return get(this, 'pagingObject.length');
        }),
        pagingTarget: 'model',
        typeKey: computed('pagingTarget', 'model', 'model.models', '_typeKey', function () {
            if (get(this, 'pagingTarget') === 'model' || get(this, 'pagingTarget') === 'model.models') {
                return this.get('_typeKey');
            }
            return get(this, 'model.' + get(this, 'pagingTarget') + '.content.type.modelName');
        }),
        pagingObject: computed('pagingTarget', 'model', 'model.models', function () {
            if (get(this, 'pagingTarget') === 'model') {
                return get(this, 'model');
            }
            if (get(this, 'pagingTarget') === 'model.models') {
                return get(this, 'model.models');
            }
            return get(this, 'pagingTarget');
        }),
        loadMore: function loadMore(params) {
            var _this = this;

            var queryParams = this.getParamsObject(params);
            queryParams.offset = get(this, 'offset');
            queryParams.limit = get(this, 'limit');
            queryParams = this.removeEmptyQueryParams(queryParams);
            if (get(this, 'typeKey')) {
                var promise = this.store.query(get(this, 'typeKey'), queryParams);
                promise.then(function (response) {
                    var newContent = response.toArray();
                    if (newContent.length < get(_this, 'limit')) {
                        set(_this, 'hasMore', false);
                    }
                    set(_this, 'pagesLoaded', get(_this, 'pagesLoaded') + 1);
                });
                return promise;
            }
            return null;
        },
        actions: {
            remove: function remove(model) {
                var _this2 = this;

                var pagingTarget = get(this, 'pagingTarget');
                model.destroyRecord().then(function () {
                    if (pagingTarget === 'model.models') {
                        get(_this2, 'model.models').removeObject(model);
                    } else {
                        get(_this2, 'content').removeObject(model);
                    }
                });
            }
        }
    });
});