define('retro-game-store/pods/affiliates/affiliate/options/route', ['exports', 'ember', 'ember-data-route'], function (exports, _ember, _emberDataRoute) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Route.extend(_emberDataRoute['default'], {
        api: service(),
        categories: service(),
        setupController: function setupController(controller, model) {
            var gatewaySelected = get(controller, 'gateway')[0];
            var type = get(model, 'creditCardApiType');

            if (type === 'Stripe') {
                gatewaySelected = get(controller, 'gateway')[1];
            } else if (type === 'AuthNet') {
                gatewaySelected = get(controller, 'gateway')[2];
            }

            setProperties(controller, {
                creditCardsEnabled: type === 'Stripe' || type === 'AuthNet',
                gatewaySelected: gatewaySelected,
                productTypes: get(this, 'categories.raw'),
                shippingEnabled: get(model, 'easyPostActive')
            });

            this._super(controller, model);
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                setProperties(controller, {
                    stripeTest: null,
                    easyPostTest: null,
                    shippingEnabled: false,
                    creditCardsEnabled: false,
                    gatewaySelected: get(controller, 'gateway')[0],
                    productTypes: null
                });
            }
        }
    });
});