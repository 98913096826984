define('retro-game-store/pods/application/print-labels-modal/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        applicationController: _ember['default'].inject.controller('application'),
        qz: _ember['default'].inject.service(),
        print: _ember['default'].inject.service(),
        api: _ember['default'].inject.service(),
        i18n: _ember['default'].inject.service(),
        batch: _ember['default'].A([]),
        boxLabelsBatch: _ember['default'].A([]),
        labels: _ember['default'].A([]),
        disabled: null,
        successfulLabels: _ember['default'].computed('labels.[]', function () {
            return this.get('labels').get('length');
        }),
        totalMinusComplete: _ember['default'].computed('totalLabels', 'labels.[]', function () {
            if (this.get('labels')) {
                return this.get('totalLabels') - this.get('labels').get('length');
            }
        }),
        closeModal: function closeModal() {
            this.send('closeModal');
            this.resetPrintButtons();
            this.setProperties({
                labels: [],
                batch: [],
                error: null
            });
            this.send('continuePolling');
        },
        resetPrintButtons: function resetPrintButtons() {
            this.set('button1Spinning', false);
            this.set('button25Spinning', false);
            this.set('button100Spinning', false);
            this.set('disabled', false);
        },
        addQuantityToList: function addQuantityToList(list, item) {
            for (var i = 0; i < item.quantity; i++) {
                list.push(item);
            }
        },
        clearQueue: function clearQueue() {
            this.set('disabled', true);
            this.get('api').request('POST', '/labelClear');
            this.get('applicationController').set('labelCount', this.get('totalMinusComplete'));
            this.set('disabled', false);
            this.send('closeModal');
        },
        actions: {
            cancel: function cancel() {
                this.closeModal();
            },
            print: function print(callback, amount) {
                var _this = this;

                this.set('button' + amount + 'Spinning', true);
                this.set('disabled', true);
                this.get('api').request('GET', '/inventoryLabels', {
                    skip: this.get('labels').get('length'),
                    top: amount
                }).then(function (items) {
                    var printInventoryLabelList = [];

                    items.forEach(function (item) {
                        _this.get('batch').pushObject(item);
                        _this.addQuantityToList(printInventoryLabelList, item);
                    });

                    _this.get('print').printInventoryLabels(printInventoryLabelList).then(function () {
                        _this.resetPrintButtons();
                        _this.get('batch').forEach(function (item) {
                            _this.get('labels').pushObject(item);
                        });
                        _this.set('batch', []);
                        _this.set('error', null);
                    })['catch'](function () {
                        _this.resetPrintButtons();
                        _this.set('batch', []);
                        _this.set('error', _this.get('i18n').t('header.printModal.printError'));
                    });
                });
            },
            save: function save(callback) {
                var _this2 = this;

                var promise = this.get('api').request('POST', '/inventoryLabels', {
                    labelIds: this.get('labels').mapBy('id')
                }).then(function () {
                    _this2.get('applicationController').set('labelCount', _this2.get('totalMinusComplete'));
                    _this2.closeModal();
                });

                callback(promise);
            },
            clearQueue: function clearQueue() {
                this.clearQueue();
            }
        }
    });
});