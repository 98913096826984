define('retro-game-store/pods/sale-payment/model', ['exports', 'ember', 'ember-data', 'moment'], function (exports, _ember, _emberData, _moment) {
    var computed = _ember['default'].computed;
    var _set = _ember['default'].set;
    var _get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        amountApplied: attr('number'),
        amountTendered: attr('number'),
        paymentType: attr('string'),
        accountNumber: attr('string'),
        nameOnCard: attr('string'),
        cardType: attr('string'),
        chargeId: attr('string'),
        cardExpirationDate: attr('date'),
        creditCardApiType: attr('string'),

        //Computeds
        expirationDateString: computed('cardExpirationDate', {
            get: function get() {
                if (this.get('cardExpirationDate')) {
                    return _get(this, 'cardExpirationDate').format('MM/YYYY');
                } else {
                    return '';
                }
            },
            set: function set(key, value) {
                _set(this, 'cardExpirationDate', (0, _moment['default'])(value, "MM/YYYY").toDate());
                return value;
            }
        }),
        remainingReturnAmount: attr('number', { defaultValue: null }),
        changeOwed: computed('amountApplied', 'amountTendered', function () {
            if (_get(this, 'amountApplied') && _get(this, 'amountTendered') && _get(this, 'amountTendered') >= _get(this, 'amountApplied')) {
                return _get(this, 'amountTendered') - _get(this, 'amountApplied');
            }
        }),
        returnPayment: belongsTo('sale-payment')
    });
});