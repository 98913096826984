define('retro-game-store/pods/shipping/sale/shipments/new/route', ['exports', 'ember', 'ember-data-route'], function (exports, _ember, _emberDataRoute) {
    exports['default'] = _ember['default'].Route.extend(_emberDataRoute['default'], {
        model: function model() {
            var shipment = this.store.createRecord('shipment');
            var sale = this.modelFor('shipping.sale');
            this.set('sale', sale);
            shipment.set('transactionId', sale);
            return shipment;
        },
        afterModel: function afterModel() {
            var _this = this;

            return this.store.query('shipment-sale-item', {
                saleId: this.get('sale').get('id')
            }).then(function (items) {
                _this.set('items', items);
            });
        },
        setupController: function setupController(controller) {
            controller.set('shipItems', this.get('items'));
            this._super.apply(this, arguments);
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.resetProperties();
            }
        },
        actions: {
            editCustomer: function editCustomer() {
                var location = 'shipping/sale/shipments/new/edit-customer-modal';
                var customer = this.modelFor('shipping.sale').get('customer').get('content');
                this.send('openModal', location, customer, location);
            }
        }
    });
});