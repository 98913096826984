define('retro-game-store/pods/application/change-user-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/config/environment'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerPagination['default'], {
        api: _ember['default'].inject.service(),
        applicationController: _ember['default'].inject.controller('application'),
        loginController: _ember['default'].inject.controller('login'),
        serverQueryParams: ['storeId'],
        storeId: _ember['default'].computed('session', function () {
            return this.get('session').get('user').get('storeInfo').get('id');
        }),
        limit: 20,
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(this.get('serverQueryParams'));
                callback(promise);
            },
            cancel: function cancel() {
                this.set('authError', false);
                this.send('closeModal');
            },
            changeUser: function changeUser(user) {
                var _this = this;

                var authenticator = 'custom-oauth2-authenticator:oauth2-password-grant';
                var identification = user.get('email');
                var password = user.get('password');
                if (password) {
                    this.get('session').authenticate(authenticator, identification, password, _retroGameStoreConfigEnvironment['default'].affiliate, 'read write').then(function () {
                        _this.send('closeModal');
                        window.location.reload();
                    })['catch'](function (error) {
                        //Get the current route name, so we can try to transition to it after we show the login page. This will ofcourse fail because we are not logged in
                        //but by it failing, it will go back to the login page with the session attempted transition fufilled, which means when we successfully login
                        //we will go back to that page
                        var routeName = _this.get('applicationController').get('currentRouteName');
                        _this.transitionToRoute('login');
                        _this.set('session.user', null);

                        // document.location.reload();
                        _this.get('loginController').setProperties({
                            identification: user.get('email'),
                            authError: error
                        });

                        _this.transitionToRoute(routeName);
                    });
                } else {
                    this.set('authError', {
                        error_description: 'Password is required'
                    });
                }
            }
        }
    });
});