define('retro-game-store/pods/dashboard/route', ['exports', 'ember', 'retro-game-store/mixins/authenticated-route', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar', 'retro-game-store/config/environment'], function (exports, _ember, _retroGameStoreMixinsAuthenticatedRoute, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar, _retroGameStoreConfigEnvironment) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var service = _ember['default'].inject.service;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsAuthenticatedRoute['default'], _retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        api: service(),
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);

            setProperties(controller, {
                resultsShownDate: get(controller, 'dateShownFormatted'),
                totals: get(this, 'totals')
            });

            get(this, 'api').request('GET', '/productTypes').then(function (productTypes) {
                set(controller, 'productTypes', productTypes);
            });

            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });

            setProperties(controller, {
                routeName: this.routeName
            });
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.setProperties({
                    dateShown: moment(),
                    timePeriodSelected: { id: 'day', name: 'Daily' },
                    viewSelected: {
                        id: 'overview',
                        name: 'Overview'
                    }
                });
            }
        },
        redirect: function redirect(model, transition) {
            if (transition.targetName === 'dashboard.index') {
                this.replaceWith('dashboard.stores', _retroGameStoreConfigEnvironment['default']['affiliate']);
            }
        }
    });
});