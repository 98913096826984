define('retro-game-store/pods/store-total/serializer', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
    exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            storeInfo: {
                embedded: 'always'
            }
        },
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (payload === null) {
                return { data: null };
            }

            var payloadWithRoot = {},
                isCollection = payload.length > 0,
                key = isCollection ? _emberInflector['default'].inflector.pluralize(primaryModelClass.modelName) : primaryModelClass.modelName;

            payloadWithRoot[key] = payload.items;
            payloadWithRoot['meta'] = payload.meta;
            return this._super(store, primaryModelClass, payloadWithRoot, id, requestType);
        }
    });
});