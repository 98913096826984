define('retro-game-store/pods/vendor-item/model', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        affiliate: attr(),
        category: attr(),
        categoryId: attr(),
        categoryStr: attr(),
        name: attr(),
        productType: attr(),
        productTypeId: attr(),
        productSkus: hasMany('product-sku'),
        sourceId: attr(),
        upc: attr()
    });
});