define('retro-game-store/pods/stores/index/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        model: function model(params) {
            var controller = this.controllerFor('stores.index');
            var queryParams = this.buildQueryParams(controller, 'serverQueryParams', 0, 10, params);
            return this.pagingQuery('store', queryParams, 'stores.index');
        },
        afterModel: function afterModel() {
            this._super.apply(this, arguments);
            return this.store.findRecord('user', 'me', { reload: true });
        }
    });
});