define('retro-game-store/pods/sales/sale/add-customer-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'retro-game-store/mixins/wizard-modal', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _retroGameStoreMixinsWizardModal, _emberValidations) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var Controller = _ember['default'].Controller;
    var observer = _ember['default'].observer;
    var _Ember$inject = _ember['default'].inject;
    var service = _Ember$inject.service;
    var controller = _Ember$inject.controller;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var reads = _Ember$computed.reads;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], _retroGameStoreMixinsWizardModal['default'], {
        scanner: service(),
        saleController: controller('sales.sale'),
        sale: reads('saleController.model'),
        serverQueryParams: ['keywords'],
        limit: 10,
        //Step array is required for wizard modal
        steps: ['add', 'create'],
        validations: {},
        stepValidations: {
            create: {
                'addedCustomer.firstName': {
                    presence: true
                },
                'addedCustomer.lastName': {
                    presence: true
                },
                'addedCustomer.email': {
                    presence: true
                },
                'addedCustomer.mobile': {
                    presence: true
                },
                'addedCustomer.license': {
                    length: {
                        maximum: 32,
                        allowBlank: true
                    }
                },
                'addedCustomer.creditBalance': {
                    numericality: {
                        greaterThanOrEqualTo: 0
                    }
                }
            }
        },
        step: 'add',
        closeModal: function closeModal() {
            this.resetModal();
            get(this, 'saleController').listenForScanner();
            this.send('closeModal');
        },
        resetModal: function resetModal() {
            set(this, 'step', get(this, 'steps')[0]);
        },
        affiliateId: alias('session.user.affiliateId'),
        stepChanged: observer('step', function () {
            var step = get(this, 'step');
            if (step === 'add') {
                if (get(this, 'addedCustomer')) {
                    get(this, 'addedCustomer').deleteRecord();
                    set(this, 'addedCustomer', null);
                }
            } else if (step === 'create') {
                this.set('addedCustomer', this.store.createRecord('customer', {
                    affiliateId: get(this, 'affiliateId')
                }));
            }
        }),
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },
            cancel: function cancel() {
                this.closeModal();
            },
            addCustomer: function addCustomer(customer) {
                var _this = this;

                get(this, 'sale').set('customerId', get(customer, 'id'));
                get(this, 'sale').save().then(function () {
                    _this.closeModal();
                });
            },
            createCustomer: function createCustomer(callback) {
                var _this2 = this;

                var promise = this.get('addedCustomer').save().then(function (customer) {
                    set(_this2, 'addedCustomer', null);
                    get(_this2, 'sale').set('customerId', get(customer, 'id'));
                    return get(_this2, 'sale').save().then(function () {
                        _this2.closeModal();
                    });
                });
                callback(promise);
            }
        }
    });
});