define('retro-game-store/pods/components/loading-link/component', ['exports', 'ember'], function (exports, _ember) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var tryInvoke = _ember['default'].tryInvoke;
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        tagName: 'a',
        classNames: ['loading-link'],
        classNameBindings: ['isLoading', 'disabled'],
        attributeBindings: ['disabled', 'title'],
        isLoading: false,
        text: null,
        title: null,
        disabled: false,

        click: function click(event) {
            var _this = this;

            event.preventDefault();
            if (!get(this, 'isLoading') && !get(this, 'disabled')) {
                set(this, 'isLoading', true);
                var result = tryInvoke(this.attrs, 'action');
                if (result) {
                    result['finally'](function () {
                        if (!_this.isDestroyed && !_this.isDestroying) {
                            set(_this, 'isLoading', false);
                        }
                    });
                }
            }
        }
    });
});