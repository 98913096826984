define('retro-game-store/pods/product-child/model', ['exports', 'ember-data', 'ember-awesome-macros/tag'], function (exports, _emberData, _emberAwesomeMacrosTag) {
    var _templateObject = _taggedTemplateLiteral(['', ' / ', ''], ['', ' / ', '']);

    function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    exports['default'] = Model.extend({
        affiliateId: attr('number'),
        cashDiscount: attr('number'),
        categoryId: attr('number'),
        categorySourceId: attr('number'),
        categoryStr: attr('string'),
        name: attr('string'),
        productTypeId: attr('number'),
        quantity: attr('number'),
        sourceId: attr('number'),
        totalSaleAmount: attr('number'),
        totalSaleCount: attr('number'),
        tradePercentage: attr('percent'),
        upc: attr('string'),

        //Relationships
        affiliate: belongsTo('affiliate'),
        category: belongsTo('product-category'),
        productSkus: hasMany('product-sku'),
        productType: belongsTo('product-type'),

        //computeds
        fullCategory: (0, _emberAwesomeMacrosTag['default'])(_templateObject, 'productType.name', 'category.name')
    });
});