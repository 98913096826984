define('retro-game-store/pods/sales/sale/add-inventory-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering', 'ember-validations'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering, _emberValidations) {
    var A = _ember['default'].A;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var mapBy = _Ember$computed.mapBy;
    var readOnly = _Ember$computed.readOnly;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var Promise = _ember['default'].RSVP.Promise;
    var debounce = _ember['default'].run.debounce;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        scanner: service(),
        api: service(),

        saleController: controller('sales.sale'),
        sale: reads('saleController.model'),
        saleItems: reads('saleController.saleItems'),
        serverQueryParams: ['productId', 'storeId', 'categoryIds', 'productTypeIds', 'availableInventoryOnly'],
        availableInventoryOnly: true,
        limit: 10,
        storeId: alias('storeFilter.id'),

        productTypeFilters: A(),
        categoryFilters: A(),
        productTypeIds: mapBy('productTypeFilters', 'id'),
        categoryIds: mapBy('categoryFilters', 'id'),

        productId: alias('productSelected.id'),
        numItemsFound: readOnly('model.length'),

        closeModal: function closeModal() {
            get(this, 'saleController').listenForScanner();
            set(this, 'selectedCategories', A());
            this.send('closeModal');
        },
        performSearch: function performSearch(term, resolve, reject) {
            return this.store.query('product', {
                keywords: term
            }).then(function (result) {
                resolve(result);
            }, reject);
        },
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter(callback) {
                var promise = this.filter(get(this, 'serverQueryParams'));
                callback(promise);
            },
            cancel: function cancel() {
                this.closeModal();
            },
            searchForProducts: function searchForProducts(term) {
                var _this = this;

                return new Promise(function (resolve, reject) {
                    debounce(_this, 'performSearch', term, resolve, reject, 500);
                });
            },
            addInventoryItem: function addInventoryItem(item) {
                var _this2 = this;

                get(this, 'api').request('POST', '/saleItems', {
                    inventoryId: get(item, 'id'),
                    stockNumber: get(item, 'stockNumber'),
                    quantity: get(item, 'quantity'),
                    id: get(this, 'sale.id')
                }).then(function (saleItem) {
                    var saleItemAdded = _this2.store.push(_this2.store.normalize('sale-item', saleItem));
                    get(_this2, 'saleItems').addObject(saleItemAdded);
                    get(_this2, 'sale').reload().then(function () {
                        item.reload();
                    });
                });
            }
        }
    });
});