define('retro-game-store/pods/customers/customer/notifications/create-modal/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/route-modal'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsRouteModal) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Route = _ember['default'].Route;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsRouteModal['default'], {
        categories: service(),
        modalTemplate: 'customers/customer/notifications/create-modal',
        setupController: function setupController(controller) {
            setProperties(controller, {
                loading: true,
                notification: this.store.createRecord('customer-notification'),
                productTypes: get(this, 'categories.raw'),
                storeFilter: get(this, 'session.user.storeInfo')
            });

            this.pagingQuery('store-inventory', { top: 10, storeId: get(this, 'session.user.storeInfo.id') }, 'customers/customer/notifications/create-modal').then(function (inventory) {
                setProperties(controller, {
                    model: inventory,
                    loading: false
                });
            });

            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });
        },
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                setProperties(controller, {
                    keywords: null,
                    categoryFilter: null,
                    storeFilter: null
                });
            }
        }
    });
});