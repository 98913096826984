define('retro-game-store/pods/application/adapter', ['exports', 'ember', 'ember-data', 'ember-web-api/adapters/web-api', 'ember-simple-auth/mixins/data-adapter-mixin', 'retro-game-store/config/environment'], function (exports, _ember, _emberData, _emberWebApiAdaptersWebApi, _emberSimpleAuthMixinsDataAdapterMixin, _retroGameStoreConfigEnvironment) {
    exports['default'] = _emberWebApiAdaptersWebApi['default'].extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
        authorizer: 'authorizer:oauth2',
        coalesceFindRequests: true,
        host: _retroGameStoreConfigEnvironment['default'].RESTAPI,
        parseErrorResponse: function parseErrorResponse(responseText) {
            var json = this._super(responseText),
                strippedErrors = {},
                jsonIsObject = json && typeof json === 'object';

            if (jsonIsObject && json.message) {
                delete json.message;
            }

            if (jsonIsObject) {
                Object.keys(json).forEach(function (key) {
                    var newKey = _ember['default'].String.camelize(key.substring(key.indexOf('.') + 1));
                    strippedErrors[newKey] = json[key];
                });

                json.errors = _emberData['default'].errorsHashToArray(strippedErrors.errors);
            }

            return json;
        },
        isInvalid: function isInvalid(status) {
            return status === 422 || status === 400;
        },
        query: function query(store, type, _query) {
            var url = this.buildURL(type.modelName, null, null, 'query', _query);

            if (this.sortQueryParams) {
                _query = this.sortQueryParams(_query);
            }

            //Convert front end paging conventions to ODATA
            if (_query.hasOwnProperty('offset')) {
                _query.skip = _query['offset'];
                delete _query['offset'];
            }
            if (_query.hasOwnProperty('limit')) {
                _query.top = _query['limit'];
                delete _query['limit'];
            }

            return this.ajax(url, 'GET', { data: _query });
        },
        handleResponse: function handleResponse(status) {
            if (status === 401) {
                if (this.get('session.isAuthenticated')) {
                    this.get('session').invalidate();
                    return true;
                } else {
                    window.location.replace('/' + _retroGameStoreConfigEnvironment['default']['affiliate'] + '/login');
                }
            } else {
                return this._super.apply(this, arguments);
            }
        },
        normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
            if (payload && typeof payload === 'object' && payload.errors && payload.errors.length > 0) {
                return payload.errors;
            } else {
                return [{
                    status: '' + status,
                    title: "The backend responded with an error",
                    detail: '' + payload
                }];
            }
        }
    });
});