define('retro-game-store/pods/inventory/items/item/history/route', ['exports', 'ember', 'retro-game-store/mixins/route-pagination', 'retro-game-store/mixins/title-bar'], function (exports, _ember, _retroGameStoreMixinsRoutePagination, _retroGameStoreMixinsTitleBar) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsRoutePagination['default'], _retroGameStoreMixinsTitleBar['default'], {
        model: function model() {
            var inventoryId = this.modelFor('inventory.items.item').get('id');
            this.set('inventoryId', inventoryId);
            return this.pagingQuery('inventory-history', {
                top: 10,
                inventoryItemId: inventoryId
            }, 'inventory.items.item.history');
        },
        setupController: function setupController(controller, model) {
            controller.set('inventoryItemId', this.get('inventoryId'));
            this._super(controller, model);
        }
    });
});