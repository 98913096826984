define('retro-game-store/pods/login/route', ['exports', 'ember', 'retro-game-store/mixins/unauthenticated-route'], function (exports, _ember, _retroGameStoreMixinsUnauthenticatedRoute) {
    exports['default'] = _ember['default'].Route.extend(_retroGameStoreMixinsUnauthenticatedRoute['default'], {
        title: 'Login',
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.setProperties({
                    authError: null
                });
            }
        }
    });
});