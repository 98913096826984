define('retro-game-store/pods/components/keyboard-list-navigation/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        active: true,
        tabindex: 0,
        attributeBindings: ['tabindex'],
        activeNumber: 0,
        selectFirstItem: false,
        listChanged: _ember['default'].observer('list', 'selectFirstItem', function () {
            if (this.get('selectFirstItem')) {
                this.set('activeNumber', 1);
            } else {
                this.set('activeNumber', 0);
            }
        }),
        activeItem: _ember['default'].computed('list', 'activeNumber', function () {
            if (this.get('activeNumber') !== 0 && this.get('list')) {
                return this.get('list').objectAt(this.get('activeNumber') - 1);
            }
        }),
        keyDown: function keyDown(event) {
            if (this.get('active')) {
                if (event.keyCode === 38) {
                    //On Up Arrow - Modify active
                    if (this.get('activeNumber') > 1) {
                        this.set('activeNumber', this.get('activeNumber') - 1);
                    }
                } else if (event.keyCode === 40) {
                    //On Down Arrow - Modify active
                    this.set('activeNumber', this.get('activeNumber') + 1);
                }
            }
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                if (_this.get('selectFirstItem')) {
                    _this.set('activeNumber', 1);
                }
            });
        }
    });
});