define('retro-game-store/pods/sales/sale/payment-modal/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-filtering', 'ember-validations', 'accounting/unformat', 'accounting/to-fixed'], function (exports, _ember, _retroGameStoreMixinsControllerFiltering, _emberValidations, _accountingUnformat, _accountingToFixed) {
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Controller.extend(_retroGameStoreMixinsControllerFiltering['default'], _emberValidations['default'], {
        scanner: _ember['default'].inject.service(),
        qz: _ember['default'].inject.service(),
        print: _ember['default'].inject.service(),
        api: _ember['default'].inject.service(),
        saleController: _ember['default'].inject.controller('sales.sale'),
        sale: _ember['default'].computed.reads('saleController.model'),
        validations: {},
        cashValidations: {
            'activePayment.amountApplied': {
                presence: true
            },
            'activePayment.amountTendered': {
                inline: (0, _emberValidations.validator)(function () {
                    var tendered = this.get('activePayment.amountTendered') ? parseFloat(this.get('activePayment.amountTendered')) : 0;
                    if (tendered < parseFloat(this.get('activePayment.amountApplied'))) {
                        return " must be greater than Amount Applied";
                    }
                })
            }
        },
        creditValidations: {
            'activePayment.amountApplied': {
                presence: true,
                numericality: { greaterThan: 0 }
            },
            'activePayment.accountNumber': {
                presence: true
            },
            'activePayment.nameOnCard': {
                presence: true
            },
            'activePayment.cardExpirationDate': {
                presence: true
            }
        },
        externalValidations: {
            'activePayment.amountApplied': {
                presence: true,
                numericality: { greaterThan: 0 }
            },
            'activePayment.accountNumber': {
                presence: true
            }
        },
        storeCreditValidations: {
            'activePayment.amountApplied': {
                presence: true,
                numericality: { greaterThan: 0 }
            }
        },
        activePayment: null,
        processPaymentDisabled: true,
        customerCreditPayments: _ember['default'].computed.filterBy('model.payments', 'paymentType', 'storeCredit'),
        customerCreditAmountApplied: _ember['default'].computed.mapBy('customerCreditPayments', 'amountApplied'),
        customerCreditAmounts: _ember['default'].computed.sum('customerCreditAmountApplied'),
        customerCreditDisabled: _ember['default'].computed('model.customer.creditBalance', 'customerCreditAmounts', function () {
            var balance = this.get('model.customer.creditBalance');
            if (balance > this.get('customerCreditAmounts')) {
                return null;
            } else {
                return true;
            }
        }),
        customerCreditToApply: _ember['default'].computed('model.customer.creditBalance', 'paymentNeeded', 'customerCreditAmounts', function () {
            var creditBalance = this.get('model.customer.creditBalance') - this.get('customerCreditAmounts');
            return Math.min(this.get('paymentNeeded'), creditBalance);
        }),
        customerCreditRemaining: _ember['default'].computed('model.customer.creditBalance', 'customerCreditAmounts', 'activePayment.paymentType', function () {
            if (this.get('activePayment.paymentType') === 'storeCredit') {
                return this.get('model.customer.creditBalance') - this.get('customerCreditAmounts');
            } else {
                return 0;
            }
        }),
        totalMinusCustomerCredit: function totalMinusCustomerCredit() {
            return this.get('model.totalAmount') - this.get('customerCreditApplied');
        },
        //When the active payment changes, we need to rebuild the validations so that we can validate each field
        activePaymentTypeChanged: _ember['default'].observer('activePayment.paymentType', function () {
            var type = this.get('activePayment.paymentType') ? this.get('activePayment.paymentType').toLowerCase() : '';
            this.set('validations', this.get(type + 'Validations'));
            this.rebuildValidations();
        }),
        // Calculate the payment needed by subtracting the total amount from all of the payments added
        paymentNeeded: _ember['default'].computed('model.totalAmount', 'totalPayments', function () {
            var totalPayments = this.get('totalPayments');
            return this.get('model').get('totalAmount') - totalPayments;
        }),
        totalPayments: _ember['default'].computed('model.payments.@each.amountApplied', 'activePayment.amountApplied', function () {
            var paymentsAdded = 0;
            this.get('model').get('payments').forEach(function (payment) {
                var amountApplied = payment.toJSON().amountApplied;
                paymentsAdded += amountApplied;
            });
            return (0, _accountingToFixed['default'])(paymentsAdded, 2);
        }),
        canProcessPayment: _ember['default'].observer('customerCreditRemaining', 'paymentNeeded', function () {
            //If customer credit remaining is greater than 0,
            //and payment needed is 0 (we have payments totaling up to what we needed)
            //then we can continue on and process the payment
            if (this.get('customerCreditRemaining') >= 0 && this.get('paymentNeeded') === 0) {
                this.set('processPaymentDisabled', false);
            } else {
                this.set('processPaymentDisabled', true);
            }
        }),
        rebuildValidations: function rebuildValidations() {
            this.validators = _ember['default'].A();
            this.buildValidators();
            this.validators.forEach(function (validator) {
                if (!validator.hasObserverFor('errors.[]')) {
                    validator.addObserver('errors.[]', this, function (sender) {
                        var errors = _ember['default'].A();
                        this.validators.forEach(function (validator) {
                            if (validator.property === sender.property) {
                                errors.addObjects(validator.errors);
                            }
                        }, this);
                        _ember['default'].set(this, 'errors.' + sender.property, errors);
                    });
                }
            }, this);
        },
        closeModal: function closeModal() {
            this.get('saleController').listenForScanner();
            this.set('applyCustomerCredit', false);
            this.get('creditCard').destroy();
            this.set('activePayment', null);
            this.set('error', null);
            this.get('api').set('errors', null);
            this.send('closeModal');
        },
        actions: {
            cancel: function cancel() {
                this.get('sale').set('payments', _ember['default'].A());
                this.closeModal();
            },
            addPayment: function addPayment(type) {
                if (type === 'storeCredit' && this.get('customerCreditDisabled')) {
                    return;
                }
                var paymentNeeded = (0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(this.get('paymentNeeded'), 2));
                var payment = this.store.createRecord('sale-payment', {
                    paymentType: type,
                    amountApplied: type === 'storeCredit' ? (0, _accountingUnformat['default'])((0, _accountingToFixed['default'])(this.get('customerCreditToApply'), 2)) : paymentNeeded,
                    creditCardApiType: get(this, 'session.user.affiliate.creditCardApiType')
                });
                this.get('model').get('payments').addObject(payment);
                this.set('activePayment', payment);
            },
            removePayment: function removePayment(payment) {
                this.get('model').get('payments').removeObject(payment);
                if (payment === this.get('activePayment')) {
                    this.set('activePayment', null);
                }
            },
            showPayment: function showPayment(payment) {
                this.set('activePayment', payment);
            },
            setAmountTendered: function setAmountTendered(amount) {
                this.set('activePayment.amountTendered', amount);
            },
            pay: function pay(callback) {
                var _this = this;

                var payments = this.get('model').get('payments').map(function (payment) {
                    return payment.toJSON();
                }),
                    openDrawer = false,
                    printAuthReceipt = false;
                var paymentNeeded = get(this, 'paymentNeeded');

                payments.forEach(function (payment) {
                    var amountApplied = payment.amountApplied;
                    var paymentType = payment.paymentType;

                    if (paymentNeeded > 0 && amountApplied === 0) {
                        payments.removeObject(payment);
                    }
                    if (paymentType.toLowerCase() === 'cash') {
                        openDrawer = true;
                    } else if (paymentType.toLowerCase() === 'credit') {
                        printAuthReceipt = true;
                    }
                });

                var promise = this.get('api').request('POST', '/processSale', {
                    id: this.get('sale').get('id'),
                    payments: payments
                }).then(function () {
                    _this.get('model').set('payments', _ember['default'].A());
                    _this.get('sale').reload().then(function () {
                        if (printAuthReceipt) {
                            _this.send('printAuthorizationReceipt', _this.get('sale'));
                        }
                        if (openDrawer) {
                            _this.get('qz').openCashDrawer();
                        }
                        _this.send('printReceipt', _this.get('sale'), _this.get('saleController').get('saleItems'));
                        _this.closeModal();
                        _this.send('resetSale');
                    });
                });

                callback(promise);
            }
        }
    });
});