define('retro-game-store/pods/shipping/sale/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return this.store.find('shipment-sale', params.sale_id);
        },
        redirect: function redirect(model, transition) {
            if (transition.targetName === 'shipping.sale.index') {
                this.transitionTo('shipping.sale.shipments', model.get('id'));
            }
        }
    });
});