define('retro-game-store/pods/employee/ability', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
    exports['default'] = _emberCan.Ability.extend({
        list: _ember['default'].computed('session.user.roles.[]', function () {
            var roles = this.get('session.user.roles');
            if (_ember['default'].isArray(roles) && roles.contains('AffiliateAdministrator')) {
                return true;
            } else {
                return false;
            }
        })
    });
});