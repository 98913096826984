define("retro-game-store/initializers/format-money", ["exports", "accounting/settings"], function (exports, _accountingSettings) {
    exports.initialize = initialize;

    function initialize() /* application */{
        _accountingSettings.currency.format = {
            pos: "%s %v",
            neg: "-%s %v",
            zero: "%s %v"
        };
    }

    exports["default"] = {
        name: 'format-money',
        initialize: initialize
    };
});