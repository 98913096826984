define('retro-game-store/pods/components/switch-to-user/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'li',
        showPassword: _ember['default'].computed('hover', 'user.password', function () {
            if (this.get('hover') || this.get('user').get('password')) {
                return true;
            } else {
                return false;
            }
        }),
        mouseEnter: function mouseEnter() {
            this.set('hover', true);
        },
        mouseLeave: function mouseLeave() {
            this.set('hover', false);
        },
        keyPress: function keyPress(e) {
            if (e.which === 13) {
                this.sendAction('changeUser', this.get('user'));
            }
        }
    });
});