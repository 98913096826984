define('retro-game-store/pods/components/category-dropdown/trigger/component', ['exports', 'ember', 'ember-basic-dropdown/components/basic-dropdown/trigger'], function (exports, _ember, _emberBasicDropdownComponentsBasicDropdownTrigger) {
    var get = _ember['default'].get;
    exports['default'] = _emberBasicDropdownComponentsBasicDropdownTrigger['default'].extend({
        actions: {
            handleKeyDown: function handleKeyDown(e) {
                var dropdown = get(this, 'dropdown');
                if (dropdown.disabled) {
                    return;
                }
                var onKeyDown = get(this, 'onKeyDown');
                if (onKeyDown && onKeyDown(dropdown, e) === false) {
                    return;
                }
                if (e.keyCode === 13) {
                    // Enter
                    dropdown.actions.toggle(e);
                } else if (e.keyCode === 27) {
                    dropdown.actions.close(e);
                }
            }
        }
    });
});