define('retro-game-store/pods/inventory/summary/stores/controller', ['exports', 'ember', 'retro-game-store/mixins/controller-pagination', 'retro-game-store/mixins/controller-filtering'], function (exports, _ember, _retroGameStoreMixinsControllerPagination, _retroGameStoreMixinsControllerFiltering) {
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var mapBy = _Ember$computed.mapBy;
    var reads = _Ember$computed.reads;
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var controller = _ember['default'].inject.controller;
    var set = _ember['default'].set;
    exports['default'] = Controller.extend(_retroGameStoreMixinsControllerPagination['default'], _retroGameStoreMixinsControllerFiltering['default'], {
        summaryController: controller('inventory.summary'),
        storeFilter: reads('summaryController.storeFilter'),
        categoryFilters: reads('summaryController.categoryFilters'),
        categoryIds: mapBy('categoryFilters', 'id'),
        productTypeFilters: reads('summaryController.productTypeFilters'),
        productTypeIds: mapBy('productTypeFilters', 'id'),

        storeId: computed('storeFilter', function () {
            if (get(this, 'storeFilter')) {
                return get(this, 'storeFilter.id');
            }
        }),
        groupBy: 'store',
        limit: 10,
        serverQueryParams: ['productTypeIds', 'categoryIds', 'storeId', 'groupBy'],
        actions: {
            fetchMore: function fetchMore(callback) {
                var promise = this.loadMore(get(this, 'serverQueryParams'));
                callback(promise);
            },
            filter: function filter() {
                var _this = this;

                set(this, 'summaryController.isSpinning', true);
                var promise = this.filter(get(this, 'serverQueryParams'));
                promise.then(function () {
                    set(_this, 'summaryController.isSpinning', false);
                });
            },
            inventoryListWithFilter: function inventoryListWithFilter(id) {
                var store = this.store.peekRecord('store', id);
                set(this, 'summaryController.storeFilter', store);
                this.transitionToRoute('inventory.items');
            }
        }
    });
});