define('retro-game-store/pods/scanner/service', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
        timeBeforeScanTest: 100, // Wait duration (ms) after keypress event to check if scanning is finished
        avgTimeByChar: 40, // Average time (ms) between 2 chars. Used to do difference between keyboard typing and scanning
        minLength: 6, // Minimum length for a scanning
        endChar: [9, 13], // Chars to remove and means end of scanning
        startChar: [], // Chars to remove and means start of scanning
        scanButtonKeyCode: 0, // Key code of the scanner hardware button (if the scanner button a acts as a key itself)
        scanButtonLongPressThreshold: 3, // How many times the hardware button should issue a pressed event before a barcode is read to detect a longpress,
        firstCharTime: 0,
        stringWriting: '',
        scanButtonCounter: 0,
        active: true,
        resetScannerDetection: function resetScannerDetection() {
            this.set('firstCharTime', 0);
            this.set('lastCharTime', 0);
            this.set('stringWriting', '');
            this.set('scanButtonCounter', 0);
        },
        scannerDetectionTest: function scannerDetectionTest(stringToTest) {
            // If string is given, test it
            if (stringToTest) {
                this.set('firstCharTime', 0);
                this.set('lastCharTime', 0);
                this.set('stringWriting', stringToTest);
            }

            if (!this.get('scanButtonCounter')) {
                this.set('scanButtonCounter', 1);
            }

            // If all condition are good (length, time...), call the callback and re-initialize the plugin for next scanning
            // Else, just re-initialize
            if (this.get('stringWriting').length >= this.get('minLength')) {
                if (this.get('lastCharTime') - this.get('firstCharTime') < this.get('stringWriting').length * this.get('avgTimeByChar')) {
                    if (this.get('scanButtonCounter') > this.get('scanButtonLongPressThreshold')) {
                        this.send('scanButtonLongPressed', this.get('scanButtonCounter'));
                    }
                    this.trigger('success', this.get('stringWriting'));
                } else {
                    this.trigger('error', this.get('stringWriting'));
                }
            }
            //reset for next scan
            this.resetScannerDetection();
        },
        keyDown: function keyDown(event) {
            var _this = this;

            // If it's just the button of the scanner, ignore it and wait for the real input
            if (event.which === this.get('scanButtonKeyCode')) {
                this.set('scanButtonCounter', this.get('scanButtonCounter') + 1);
            }
            if (this.get('firstCharTime') && this.get('endChar').indexOf(event.which) !== -1) {
                event.stopPropagation();
                event.preventDefault();
                this.set('callIsScanner', true);
            } else if (!this.get('firstCharTime') && this.get('startChar').indexOf(event.which) !== -1) {
                event.stopPropagation();
                event.preventDefault();
                this.set('callIsScanner', false);
            } else {
                this.set('stringWriting', this.get('stringWriting') + String.fromCharCode(event.which));
                this.set('callIsScanner', false);
            }

            if (!this.get('firstCharTime')) {
                this.set('firstCharTime', Date.now());
            }
            this.set('lastCharTime', Date.now());

            if (this.get('testTimer')) {
                clearTimeout(this.get('testTimer'));
            }
            if (this.get('callIsScanner')) {
                this.scannerDetectionTest();
                this.set('testTimer', null);
            } else {
                this.set('testTimer', setTimeout(function () {
                    _this.scannerDetectionTest();
                }, this.get('timeBeforeScanTest')));
            }
        },
        activeChanged: _ember['default'].observer('active', function () {
            if (this.get('active')) {
                this.listen();
            } else {
                this.ignore();
            }
        }),
        listen: function listen() {
            var _this2 = this;

            //make sure we're not parsing scanner input multiple times
            _ember['default'].$(document).unbind('keypress.scannerDetection');
            _ember['default'].$(document).bind('keypress.scannerDetection', function (event) {
                _this2.keyDown(event);
            });
        },
        ignore: function ignore() {
            _ember['default'].$(document).unbind('keypress.scannerDetection');
        }
    });
});