define('retro-game-store/utils/print-commands', ['exports', 'accounting/format-money', 'retro-game-store/utils/print-format-helpers'], function (exports, _accountingFormatMoney, _retroGameStoreUtilsPrintFormatHelpers) {
    exports.generateReceiptCommands = generateReceiptCommands;
    exports.generateAuthReceiptCommands = generateAuthReceiptCommands;
    exports.generateInventoryLabelCommands = generateInventoryLabelCommands;
    exports.generateShippingLabelCommands = generateShippingLabelCommands;
    exports.generateOpenCashDrawerCommands = generateOpenCashDrawerCommands;

    function generateReceiptCommands(receipt, logoImage) {
        var itemCommands = (0, _retroGameStoreUtilsPrintFormatHelpers.getItemLines)(receipt.Items);
        var paymentCommands = (0, _retroGameStoreUtilsPrintFormatHelpers.getPaymentLines)(receipt.Payments);
        var imageCommand = {
            type: 'raw',
            format: 'image',
            flavor: 'base64',
            data: logoImage,
            options: { language: "escp", dotDensity: 'double' }
        };
        var dotCommand = '\x1B' + '\x32'; //reset line spacing after image

        var commands = ['\x1B' + '\x40', // initialize job
        '\x1B' + '\x61' + '\x31'];

        // center align
        if (logoImage) {
            commands.push(imageCommand, dotCommand);
        }

        commands.push('\x0A', '\x0A', receipt.Shop.Name + '\x0A', receipt.Shop.Location + '\x0A', '\x0A', '------------------------------------------' + '\x0A');

        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Order: ' + receipt.OrderNumber, receipt.OrderDate));
        commands.push('\x1B' + '\x61' + '\x30', // left align
        'Store: ' + receipt.Shop.Id + '\x0A', 'Associate: ' + receipt.CashierName + '\x0A', '------------------------------------------' + '\x0A', '\x0A');
        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Item', 'Subtotal'));
        commands.push('\x0A');
        commands = commands.concat(itemCommands);

        if (receipt.Discount && receipt.Discount !== 0) {
            var discount = (0, _accountingFormatMoney['default'])(receipt.Discount * -1);
            var text = '                 DISCOUNT ';
            if (receipt.DiscountPercent && receipt.DiscountPercent !== 0) {
                text = text + '(' + Math.round(receipt.DiscountPercent) + '%) ';
            }

            commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)(text, discount));
        }

        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('                 Subtotal', (0, _accountingFormatMoney['default'])(receipt.Subtotal)));
        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('                 Tax', (0, _accountingFormatMoney['default'])(receipt.Tax)));

        if (receipt.Shipping && receipt.Shipping > 0) {
            var ship = (0, _accountingFormatMoney['default'])(receipt.Shipping);
            commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('                 Shipping', ship));
        }

        commands.push('\x0A');
        commands.push('\x1B' + '\x45' + '\x0D'); // bold on
        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('                 Total', (0, _accountingFormatMoney['default'])(receipt.Total)));
        commands.push('\x1B' + '\x45' + '\x0A', '\x0A'); // bold off

        commands = commands.concat(paymentCommands);
        if (receipt.Change && receipt.Change > 0) {
            var change = (0, _accountingFormatMoney['default'])(receipt.Change);
            commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('                  Change', change));
        }

        commands.push('\x0A', '\x1B' + '\x61' + '\x31', '------------------------------------------', '\x0A');

        if (receipt.CustomerNumber && receipt.CustomerNumber > 0) {
            var balance = (0, _accountingFormatMoney['default'])(receipt.CustomerBalance);
            commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Customer #' + receipt.CustomerNumber, 'Store Credit: ' + balance));
            commands.push('\x1B' + '\x61' + '\x30', // left align
            receipt.CustomerName, '\x0A', '\x1B' + '\x61' + '\x31', '------------------------------------------', '\x0A');
        }

        var cityStateZip = '';
        if (receipt.Shop.City) {
            cityStateZip += receipt.Shop.City + ', ';
        }
        if (receipt.Shop.State) {
            cityStateZip += receipt.Shop.State + ', ';
        }
        cityStateZip += receipt.Shop.Zip;

        commands.push('\x0A');
        commands.push('\x1B' + '\x61' + '\x31'); // center align
        if (receipt.Shop.Address1) {
            commands.push(receipt.Shop.Address1 + '\x0A');
        }
        if (receipt.Shop.Address2) {
            commands.push(receipt.Shop.Address2 + '\x0A');
        }
        if (cityStateZip && cityStateZip.length > 0 && cityStateZip !== 'undefined') {
            commands.push(cityStateZip + '\x0A');
        }
        if (receipt.Shop.Phone) {
            commands.push(receipt.Shop.Phone + '\x0A');
        }
        if (receipt.Shop.Website) {
            commands.push(receipt.Shop.Website + '\x0A');
        }
        commands.push('\x0A\x0A');

        commands.push('\x1d' + '\x6b' + '\x04' + receipt.OrderNumber + '\x00', // barcode code39
        '\x0A' + '* ' + receipt.OrderNumber + ' *', '\x0A');

        if (receipt.ReturnPolicy) {
            commands.push('\x0A\x0A' + receipt.ReturnPolicy + '\x0A');
        }

        commands.push('\x0A\x0A\x0A\x0A\x0A\x0A\x0A', //some space
        '\x1D' + '\x56' + '\x00' // paper cut
        );

        return [commands, {}];
    }

    function generateAuthReceiptCommands(authReceipt) {
        var commands = ['\x1B' + '\x40', // initialize job
        '\x0A', '\x1B' + '\x61' + '\x31', // center align
        authReceipt.Shop.Name + '\x0A', authReceipt.Shop.Location + '\x0A', '\x0A', '------------------------------------------' + '\x0A'];

        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Order: ' + authReceipt.OrderNumber, authReceipt.OrderDate));
        commands.push('\x1B' + '\x61' + '\x30', // left align
        'Store: ' + authReceipt.Shop.Id + '\x0A', 'Associate: ' + authReceipt.CashierName + '\x0A', '------------------------------------------' + '\x0A', '\x0A', '\x1B' + '\x61' + '\x31', // center align
        '\x1B' + '\x45' + '\x0D', // bold on
        'SALE:' + '\x0A', '\x1B' + '\x45' + '\x0A', //bold off
        '\x0A');

        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Card Type: ', authReceipt.CardType));
        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Account #: ', authReceipt.CardNumber));
        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Holder Name: ', authReceipt.CustomerName));
        commands.push('\x1B' + '\x45' + '\x0D'); // bold on
        commands = commands.concat((0, _retroGameStoreUtilsPrintFormatHelpers.getSplitLine)('Amount Tendered: ', (0, _accountingFormatMoney['default'])(authReceipt.Total)));
        commands.push('\x1B' + '\x45' + '\x0A'); // bold off

        commands.push('\x1B' + '\x45' + '\x0A', //bold off
        '\x1B' + '\x61' + '\x31', // center align
        '\x0A\x0A', 'I agree to pay the above total amount' + '\x0A', 'according to the card issuer agreement' + '\x0A', '\x0A\x0A\x0A\x0A', '\x1B' + '\x45' + '\x0D', // bold on
        '\x1B' + '\x61' + '\x30', // left align
        'Sign: ___________________________________' + '\x0A', '\x0A\x0A\x0A', '\x1B' + '\x61' + '\x31', // center align
        'MERCHANT COPY' + '\x0A', '\x1B' + '\x45' + '\x0A', //bold off
        '------------------------------------------', '\x0A\x0A');

        var cityStateZip = '';
        if (authReceipt.Shop.City) {
            cityStateZip += authReceipt.Shop.City + ', ';
        }
        if (authReceipt.Shop.State) {
            cityStateZip += authReceipt.Shop.State + ', ';
        }
        cityStateZip += authReceipt.Shop.Zip;

        commands.push('\x0A');
        commands.push('\x1B' + '\x61' + '\x31'); // center align
        if (authReceipt.Shop.Address1) {
            commands.push(authReceipt.Shop.Address1 + '\x0A');
        }
        if (authReceipt.Shop.Address2) {
            commands.push(authReceipt.Shop.Address2 + '\x0A');
        }
        if (cityStateZip && cityStateZip !== 'undefined') {
            commands.push(cityStateZip + '\x0A');
        }
        if (authReceipt.Shop.Phone) {
            commands.push(authReceipt.Shop.Phone + '\x0A');
        }
        if (authReceipt.Shop.Website) {
            commands.push(authReceipt.Shop.Website + '\x0A');
        }
        commands.push('\x0A\x0A');

        commands.push('\x0A\x0A\x0A\x0A\x0A', //some space
        '\x1D' + '\x56' + '\x00' // paper cut
        );

        return [commands, {}];
    }

    function generateInventoryLabelCommands(inventoryLabels) {
        var commands = ['^XA\n', //initialize format
        '^LH0,0\n', //no margins
        '^LL248\n', //print height 31mm
        '^PW416\n', //print width 52mm
        '^XZ\n', //end format
        '^XA\n' //initialize label
        ];

        inventoryLabels.forEach(function (label) {
            var title1 = (0, _retroGameStoreUtilsPrintFormatHelpers.getTitleLine1)(label.Name);
            var title2 = (0, _retroGameStoreUtilsPrintFormatHelpers.getTitleLine2)(label.Name, label.Category);
            var platform = (0, _retroGameStoreUtilsPrintFormatHelpers.getPlatformLine)(label.Category);
            var dots = (0, _retroGameStoreUtilsPrintFormatHelpers.getPlatformDots)(title2);
            label.Condition = label.Condition.toUpperCase();
            if (label.Condition !== 'NEW') {
                label.Condition = 'PRE-OWNED';
            }

            commands.push('^XA\n', //initialize label
            '^CFC,15\n', //set to font C, size 15
            '^FO15,20^FD' + title1 + '^FS\n', //print at 15x 20y
            '^FO16,20^FD' + title1 + '^FS\n', //1px offset for bold
            '^FO14,45^FD' + title2 + '^FS\n', '^FO15,45^FD' + title2 + '^FS\n', '^FO' + dots + ',45^FD' + platform + '^FS\n', '^CFA,10\n', //set to font A, size 10
            '^FO15,70^FD' + label.StockNumber + '^FS\n');

            if (label.IsBoxLabel) {
                var conditionDots = (0, _retroGameStoreUtilsPrintFormatHelpers.getConditionDots)(label.Condition);

                commands.push('^CFF,25\n', //set to font F, size 25
                '^FO' + conditionDots + ',80^FD* ' + label.Condition + ' *^FS\n', '^CFE,30\n', //set to font E, size 30
                '^FO17,140^FD' + (0, _accountingFormatMoney['default'])(label.Price) + '^FS\n', '^FO18,140^FD' + (0, _accountingFormatMoney['default'])(label.Price) + '^FS\n');
            } else {
                var priceDots = (0, _retroGameStoreUtilsPrintFormatHelpers.getPriceDots)(label.Price);

                commands.push('^CFF,25\n', //set to font F, size 25
                '^FO16,100^FD*' + label.Condition + '*^FS\n', '^FO' + priceDots + ',100^FD' + (0, _accountingFormatMoney['default'])(label.Price) + '^FS\n', '^FO' + (priceDots + 1) + ',100^FD' + (0, _accountingFormatMoney['default'])(label.Price) + '^FS\n', '^FO50,140\n', '^BY1,3\n', //barcode size 1, width = 3*height
                '^B3N,N,55,N,N\n', //barcode code39, no extras, height 55
                '^FD' + label.StockNumber + '^FS\n');
            }

            commands.push('^XZ\n'); //end of label
        });

        return [commands, {}];
    }

    function generateShippingLabelCommands(shippingLabels) {
        var commands = ['^XA\n', //initialize format
        '^LH0,0\n', //no margins
        '^LL1264\n', //print height 158mm
        '^PW840\n', //print width 105mm
        '^XZ\n', //end format
        '^XA\n' //initialize label
        ];

        shippingLabels.forEach(function (label) {
            var zplStr = atob(label); //decode base64
            commands.push(zplStr);
        });

        return [commands, {}];
    }

    function generateOpenCashDrawerCommands() {
        var commands = ['\x1B' + '\x40', '\x10' + '\x14' + '\x01' + '\x00' + '\x05'];
        return [commands, {}];
    }
});