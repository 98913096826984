define('retro-game-store/pods/components/nav-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    childLinkViews: [],
    classNameBindings: ['active'],
    active: _ember['default'].computed('childLinkViews.@each.active', function () {
      return _ember['default'].A(this.get('childLinkViews')).isAny('active');
    }),

    didRender: function didRender() {
      _ember['default'].run.schedule('afterRender', this, function () {
        var _this = this;

        var childLinkElements = this.$('li.ember-view');

        var childLinkViews = childLinkElements.toArray().map(function (view) {
          return _this._viewRegistry[view.id];
        });

        this.set('childLinkViews', childLinkViews);
      });
    }

    // active: Ember.computed('childViews.@each.active', function() {
    //     // let active = false;
    //     // this.get('childLinkViews').forEach((child) => {
    //     //     if (child.get('active')) {
    //     //         active = true;
    //     //     }
    //     // });
    //     // return active;
    //     return Ember.A(this.get('childViews')).isAny('active', 'active');
    // }),
    //
    // didRender: function() {
    //     Ember.run.schedule('afterRender', this, function() {
    //       var childLinkElements = this.$('li.ember-view');
    //
    //       var childLinkViews = childLinkElements.toArray().map(view =>
    //         this._viewRegistry[view.id]
    //       );
    //
    //       this.set('childLinkViews', childLinkViews);
    //     });
    // }
  });
});