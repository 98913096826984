define('retro-game-store/pods/inventory/summary/route', ['exports', 'ember', 'retro-game-store/config/environment'], function (exports, _ember, _retroGameStoreConfigEnvironment) {
    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = _ember['default'].Route.extend({
        api: service(),
        categories: service(),
        afterModel: function afterModel() {
            var _this = this;

            var promise = get(this, 'api').request('GET', '/inventorySummary/chart');
            promise.then(function (data) {
                set(_this, 'summaryGraphData', data);
            });
            return promise;
        },
        setupController: function setupController(controller) {
            this.store.query('store', { skip: 0 }).then(function (stores) {
                set(controller, 'stores', stores);
            });

            setProperties(controller, {
                productTypes: get(this, 'categories.raw'),
                summaryGraphData: get(this, 'summaryGraphData'),
                routeName: this.routeName
            });

            this._super.apply(this, arguments);
        },
        redirect: function redirect(model, transition) {
            if (transition.targetName === 'inventory.summary.index') {
                this.transitionTo('inventory.summary.stores', _retroGameStoreConfigEnvironment['default']['affiliate']);
            }
        }
    });
});