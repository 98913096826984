define('retro-game-store/pods/stores/store/labels/controller', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    var Controller = _ember['default'].Controller;
    var get = _ember['default'].get;
    var _Ember$inject = _ember['default'].inject;
    var controller = _Ember$inject.controller;
    var service = _Ember$inject.service;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    exports['default'] = Controller.extend(_emberValidations['default'], {
        api: service(),
        categories: service(),
        labelOptionModalController: controller('stores/store/labels/label-option-modal'),
        actions: {
            addStoreLabelOption: function addStoreLabelOption() {
                setProperties(get(this, 'labelOptionModalController'), {
                    productTypes: get(this, 'categories.raw'),
                    labelOptions: get(this, 'labelOptions')
                });
                var storeLabelOption = this.store.createRecord('store-label-option', { storeId: get(this, 'model.id') });
                this.send('openModal', 'stores/store/labels/label-option-modal', storeLabelOption, 'stores/store/labels/label-option-modal');
            },
            editLabelOption: function editLabelOption(storeLabelOption) {
                setProperties(get(this, 'labelOptionModalController'), {
                    productTypes: get(this, 'categories.raw'),
                    labelOptions: get(this, 'labelOptions'),
                    originalModel: storeLabelOption,
                    originalConditions: get(storeLabelOption, 'conditions').map(function (item) {
                        return _ember['default'].copy(item, true);
                    }) // keep original condition values in case we need to rollback when cancelling the edit modal
                });
                this.send('openModal', 'stores/store/labels/label-option-modal', storeLabelOption, 'stores/store/labels/label-option-modal');
            },
            deleteLabelOption: function deleteLabelOption(storeLabelOption) {
                this.store.deleteRecord(storeLabelOption);
            },
            save: function save(callback) {
                var labelOptions = [];
                get(this, 'labelOptions').forEach(function (option) {
                    if (!get(option, 'isDeleted')) {
                        (function () {
                            var opt = {
                                storeId: get(option, 'storeId'),
                                productTypeId: get(option, 'productTypeId'),
                                categoryId: get(option, 'categoryId')
                            };
                            var conditions = [];
                            get(option, 'conditions').forEach(function (condition) {
                                conditions.push({
                                    conditionId: get(condition, 'condition.id'),
                                    printBoxLabel: get(condition, 'printBoxLabel'),
                                    printMediaLabel: get(condition, 'printMediaLabel')
                                });
                            });
                            set(opt, 'conditions', conditions);
                            labelOptions.push(opt);
                        })();
                    }
                });

                var promise = get(this, 'api').request('POST', '/storeLabelOptions?storeId=' + get(this, 'model.id'), { labelOptions: labelOptions });

                callback(promise);
            }
        }
    });
});