define('retro-game-store/pods/components/bootstrap-popover/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['bootstrap-popover'],
        tagName: 'span',

        destroyPopover: function destroyPopover() {
            this.get('popover').popover('destroy');
            this.set('popover', null);
        },

        cancel: function cancel() {
            this.destroyPopover();
        },

        mouseLeave: function mouseLeave() {
            this.cancel();
        },

        mouseEnter: function mouseEnter() {
            var element, placement, target;
            element = this.get('element');
            placement = this.get('placement');
            target = this.get('target');

            this.set('popover', $(element).find(target).popover({
                container: 'body', // popover will be rendered inside body to prevent clipping by parent elements
                placement: placement || 'top auto',
                html: true,
                content: $(element).find('.popover').html()
            }));

            this.get('popover').popover('show');
        }
    });
});