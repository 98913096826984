define('retro-game-store/initializers/pikaday-i18n', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports.initialize = initialize;

    function initialize(application) {
        var i18n = _ember['default'].Object.extend({
            previousMonth: 'Previous Month',
            nextMonth: 'Next Month',
            months: _moment['default'].localeData()._months,
            weekdays: _moment['default'].localeData()._weekdays,
            weekdaysShort: _moment['default'].localeData()._weekdaysShort
        });

        application.register('pikaday-i18n:main', i18n, { singleton: true });
        application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
    }

    exports['default'] = {
        name: 'pikaday-i18n',
        after: 'i18n',
        initialize: initialize
    };
});