define('retro-game-store/pods/store-inventory/model', ['exports', 'ember', 'ember-data', 'ember-inflector'], function (exports, _ember, _emberData, _emberInflector) {
    var computed = _ember['default'].computed;
    var get = _ember['default'].get;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var Model = _emberData['default'].Model;

    var inflector = _emberInflector['default'].inflector;
    inflector.irregular('storeInventory', 'storeInventory');

    exports['default'] = Model.extend({
        affiliateId: attr('string'),
        count: attr('number'),
        product: belongsTo('product'),
        storeInfo: belongsTo('store'),

        countIsEmpty: computed('count', function () {
            return get(this, 'count') === 0;
        }),

        fullCategory: computed('product.productType.name', 'product.category.name', function () {
            return get(this, 'product.productType.name') + ' / ' + get(this, 'product.category.name');
        })
    });
});