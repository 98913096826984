define('retro-game-store/pods/shipment/model', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        easyPostId: _emberData['default'].attr('string'),
        shipmentItems: _emberData['default'].hasMany('shipment-item'),
        transactionId: _emberData['default'].belongsTo('shipment-sale'),
        originStore: _emberData['default'].belongsTo('store'),
        recipientName: _emberData['default'].attr('string'),
        carrier: _emberData['default'].attr('string'),
        service: _emberData['default'].attr('string'),
        trackingCode: _emberData['default'].attr('string'),
        city: _emberData['default'].attr('string'),
        state: _emberData['default'].attr('string'),
        street1: _emberData['default'].attr('string'),
        street2: _emberData['default'].attr('string'),
        zip: _emberData['default'].attr('string'),
        date: _emberData['default'].attr('date')
    });
});