define('retro-game-store/authenticators/custom-oauth2-authenticator', ['exports', 'ember', 'ember-simple-auth/authenticators/oauth2-password-grant', 'retro-game-store/config/environment'], function (exports, _ember, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _retroGameStoreConfigEnvironment) {
    exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
        serverTokenEndpoint: _retroGameStoreConfigEnvironment['default']['simple-auth-oauth2'].serverTokenEndpoint,
        // Refresh the token 30 to 40 seconds before expiration
        tokenRefreshOffset: _ember['default'].computed(function () {
            var min = 30;
            var max = 40;
            return (Math.floor(Math.random() * (max - min)) + min) * 1000;
        }).volatile(),
        _scheduleAccessTokenRefresh: function _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken) {
            var refreshAccessTokens = this.get('refreshAccessTokens');
            if (refreshAccessTokens) {
                var now = new Date().getTime();
                if (_ember['default'].isEmpty(expiresAt) && !_ember['default'].isEmpty(expiresIn)) {
                    expiresAt = new Date(now + expiresIn * 1000).getTime();
                }
                var offset = this.get('tokenRefreshOffset');
                if (!_ember['default'].isEmpty(refreshToken) && !_ember['default'].isEmpty(expiresAt) && expiresAt > now - offset) {
                    _ember['default'].run.cancel(this._refreshTokenTimeout);
                    delete this._refreshTokenTimeout;
                    if (!_ember['default'].testing) {
                        this._refreshTokenTimeout = _ember['default'].run.later(this, this._refreshAccessToken, expiresIn, refreshToken, expiresAt - now - offset);
                    }
                }
            }
        },
        authenticate: function authenticate(identification, password, tenant) {
            var _this = this;

            var scope = arguments.length <= 3 || arguments[3] === undefined ? [] : arguments[3];

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var data = { 'grant_type': 'password', username: identification, password: password, tenant_id: tenant };
                var serverTokenEndpoint = _this.get('serverTokenEndpoint');
                var scopesString = _ember['default'].makeArray(scope).join(' ');
                if (!_ember['default'].isEmpty(scopesString)) {
                    data.scope = scopesString;
                }
                data.client_id = 'ember';
                _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
                    _ember['default'].run(function () {
                        var expiresAt = _this._absolutizeExpirationTime(response['expires_in']);
                        _this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
                        if (!_ember['default'].isEmpty(expiresAt)) {
                            response = _ember['default'].merge(response, { 'expires_at': expiresAt });
                        }
                        _ember['default'].$('body').addClass('authenticated');
                        resolve(response);
                    });
                }, function (xhr) {
                    _ember['default'].run(null, reject, xhr.responseJSON || xhr.responseText);
                });
            });
        }
    });
});