define('retro-game-store/pods/components/infinite-scroll/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: ['hasMore:has-more:no-more'],
        tagName: 'div',
        listTagName: 'ul',
        action: 'fetchMore',
        isFetching: false,
        hasMore: true,
        content: null,
        showMoreText: 'More',
        pagesLoaded: 1,
        scrollElement: window,
        classNames: 'infinite-scroll responsive-table',

        shouldUnbindScroll: (function () {
            if (this.get('pagesLoaded') > 3) {
                this.unbindScroll();
            }
        }).observes('pagesLoaded'),

        shouldBindScroll: (function () {
            if (this.get('pagesLoaded') === 1) {
                //This means we are resetting the hasMore property, which means scroll is being reset
                this.bindScroll();
            }
        }).observes('pagesLoaded'),

        setup: (function () {
            this.bindScroll();
        }).on('didInsertElement'),

        teardown: (function () {
            this.unbindScroll();
        }).on('willDestroyElement'),

        bindScroll: function bindScroll() {
            $(this.get('scrollElement')).on('scroll.' + this.elementId, this.didScroll.bind(this));
        },

        unbindScroll: function unbindScroll() {
            $(this.get('scrollElement')).off('scroll.' + this.elementId);
        },

        didScroll: function didScroll() {
            if (!this.get('isFetching') && this.get('hasMore') && this.isNearBottom()) {
                this.loadMore();
            }
        },

        handleFetch: function handleFetch(promise) {
            var success = this.fetchDidSucceed.bind(this),
                failure = this.fetchDidFail.bind(this);

            promise.then(success, failure);
        },

        fetchDidSucceed: function fetchDidSucceed(response) {
            var content = this.get('content'),
                newContent = response.toArray();
            // newContent = response.getWithDefault('content', response);

            this.safeSet('isFetching', false);
            if (content) {
                content.addObjects(newContent);
            }
            if (this.get('pageLoaded')) {
                this.sendAction('pageLoaded');
            }
        },

        fetchDidFail: function fetchDidFail() {
            this.safeSet('isFetching', false);
        },

        isNearBottom: function isNearBottom() {
            var lastItem = $(this.get('element')).find('li').last(),
                itemTop,
                itemHeight,
                viewPortTop;

            if (lastItem.length !== 0) {
                itemTop = lastItem.offset().top;
                itemHeight = lastItem.outerHeight() + 20;
                viewPortTop = $(document).scrollTop();
                if (viewPortTop > itemTop + itemHeight - $(this.get('scrollElement')).height()) {
                    return true;
                }
            }
            return false;
        },

        loadMore: function loadMore() {
            this.safeSet('isFetching', true);
            this.sendAction('action', this.handleFetch.bind(this));
        },

        safeSet: function safeSet(key, value) {
            if (!this.isDestroyed && !this.isDestroying) {
                this.set(key, value);
            }
        },

        activeItemDidChange: _ember['default'].observer('activeItem', function () {
            if (this.get('activeItem') === this.get('content').get('lastObject') && !this.get('isFetching') && this.get('hasMore')) {
                this.loadMore();
            }
        }),
        actions: {
            showMore: function showMore() {
                if (!this.get('isFetching') && this.get('hasMore')) {
                    this.loadMore();
                }
            }
        }
    });
});