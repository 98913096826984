define("retro-game-store/pods/customers/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 32
              },
              "end": {
                "line": 24,
                "column": 32
              }
            },
            "moduleName": "retro-game-store/pods/customers/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "glyphicon glyphicon-search");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 24
            },
            "end": {
              "line": 26,
              "column": 24
            }
          },
          "moduleName": "retro-game-store/pods/customers/index/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-xs-8");
          var el2 = dom.createTextNode("\n                                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "flex-container flex-space flex-valign-center");
          var el3 = dom.createTextNode("\n                                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" Has open balance");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" Has open notifications");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-xs-offset-2 col-xs-2");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element6, [3, 1]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element6, [5, 1]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "keywords", ["loc", [null, [11, 54], [11, 62]]]]], [], []], "class", "form-control", "placeholder", ["subexpr", "t", ["searchByKeyword"], [], ["loc", [null, [11, 96], [11, 117]]]], "maxlength", "128"], ["loc", [null, [11, 40], [11, 135]]]], ["inline", "input", [], ["type", "checkbox", "name", "openBalance", "checked", ["subexpr", "@mut", [["get", "openBalance", ["loc", [null, [14, 98], [14, 109]]]]], [], []]], ["loc", [null, [14, 47], [14, 111]]]], ["inline", "input", [], ["type", "checkbox", "name", "openNotification", "checked", ["subexpr", "@mut", [["get", "openNotification", ["loc", [null, [17, 103], [17, 119]]]]], [], []]], ["loc", [null, [17, 47], [17, 121]]]], ["block", "loading-button", [], ["action", "formSubmit", "class", "search-button pull-right"], 0, null, ["loc", [null, [22, 32], [24, 51]]]]],
        locals: ["form"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 44
                },
                "end": {
                  "line": 67,
                  "column": 167
                }
              },
              "moduleName": "retro-game-store/pods/customers/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "item.customerNotifications.length", ["loc", [null, [67, 130], [67, 167]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 44
                },
                "end": {
                  "line": 72,
                  "column": 44
                }
              },
              "moduleName": "retro-game-store/pods/customers/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-edit");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 32
              },
              "end": {
                "line": 76,
                "column": 32
              }
            },
            "moduleName": "retro-game-store/pods/customers/index/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-4 col-md-6");
            var el3 = dom.createTextNode("\n                                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n                                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-xs-12 col-md-4");
            var el5 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-xs-12 col-md-4");
            var el5 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-xs-12 col-md-4");
            var el5 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-3 col-md-2 text-xs-right");
            var el3 = dom.createTextNode("\n                                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-3 col-sm-2 text-xs-center hidden-print");
            var el3 = dom.createTextNode("\n                                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-2 col-sm-3 col-md-2 text-xs-right hidden-print");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1, 1]);
            var element2 = dom.childAt(element1, [1, 1]);
            var element3 = dom.childAt(element1, [3, 1]);
            var element4 = dom.childAt(element1, [5, 1]);
            var element5 = dom.childAt(element0, [7]);
            var morphs = new Array(10);
            morphs[0] = dom.createAttrMorph(element2, 'title');
            morphs[1] = dom.createMorphAt(element2, 0, 0);
            morphs[2] = dom.createAttrMorph(element3, 'title');
            morphs[3] = dom.createMorphAt(element3, 0, 0);
            morphs[4] = dom.createAttrMorph(element4, 'title');
            morphs[5] = dom.createMorphAt(element4, 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
            morphs[7] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
            morphs[8] = dom.createMorphAt(element5, 1, 1);
            morphs[9] = dom.createMorphAt(element5, 3, 3);
            return morphs;
          },
          statements: [["attribute", "title", ["concat", [["get", "item.name", ["loc", [null, [53, 67], [53, 76]]]]]]], ["content", "item.name", ["loc", [null, [53, 80], [53, 93]]]], ["attribute", "title", ["concat", [["get", "item.email", ["loc", [null, [56, 67], [56, 77]]]]]]], ["content", "item.email", ["loc", [null, [56, 81], [56, 95]]]], ["attribute", "title", ["concat", [["get", "item.mobile", ["loc", [null, [59, 67], [59, 78]]]]]]], ["content", "item.mobile", ["loc", [null, [59, 82], [59, 97]]]], ["inline", "format-money", [["get", "item.creditBalance", ["loc", [null, [64, 59], [64, 77]]]]], ["symbol", ["subexpr", "@mut", [["get", "selectedCurrency", ["loc", [null, [64, 85], [64, 101]]]]], [], []], "currency", "USD", "format", "%s %v"], ["loc", [null, [64, 44], [64, 133]]]], ["block", "link-to", ["customers.customer.notifications", ["get", "item.id", ["loc", [null, [67, 90], [67, 97]]]]], ["class", "btn btn-sm btn-warning"], 0, null, ["loc", [null, [67, 44], [67, 179]]]], ["block", "link-to", ["customers.customer.details", ["get", "item.id", ["loc", [null, [70, 84], [70, 91]]]]], ["class", "btn btn-sm btn-default"], 1, null, ["loc", [null, [70, 44], [72, 56]]]], ["inline", "confirm-action-btn", [], ["disabled", ["subexpr", "if", [["get", "item.creditBalance", ["loc", [null, [73, 78], [73, 96]]]], true], [], ["loc", [null, [73, 74], [73, 102]]]], "confirmAction", "remove", "type", "delete", "model", ["subexpr", "@mut", [["get", "item", ["loc", [null, [73, 146], [73, 150]]]]], [], []], "class", "remove", "title", ["subexpr", "t", ["actions.remove"], [], ["loc", [null, [73, 172], [73, 192]]]], "placement", "auto left"], ["loc", [null, [73, 44], [73, 217]]]]],
          locals: ["item"],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 32
              },
              "end": {
                "line": 80,
                "column": 32
              }
            },
            "moduleName": "retro-game-store/pods/customers/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("No customers found.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 28
            },
            "end": {
              "line": 81,
              "column": 28
            }
          },
          "moduleName": "retro-game-store/pods/customers/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "header");
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-4 col-md-2");
          var el3 = dom.createTextNode("Name");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-3 col-md-2 hidden-xs hidden-sm");
          var el3 = dom.createTextNode("Email");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-3 col-md-2 hidden-xs hidden-sm");
          var el3 = dom.createTextNode("Mobile");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-3 col-md-2 text-xs-right");
          var el3 = dom.createTextNode("Balance");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-3 col-sm-2 text-xs-center hidden-print");
          var el3 = dom.createTextNode("Notifications");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-2 hidden-print");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "model", ["loc", [null, [48, 40], [48, 45]]]]], [], 0, 1, ["loc", [null, [48, 32], [80, 41]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "retro-game-store/pods/customers/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-xs-12");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row hidden-print");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ibox");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "ibox-content");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "row");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ibox");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "ibox-content");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "row");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "col-xs-12");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1, 1, 1, 1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element7, [3, 1, 1, 1, 1, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "form-validator", [], ["submit", "filter", "errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [7, 65], [7, 71]]]]], [], []]], 0, null, ["loc", [null, [7, 24], [26, 43]]]], ["block", "infinite-scroll", [], ["content", ["subexpr", "@mut", [["get", "model", ["loc", [null, [39, 55], [39, 60]]]]], [], []], "hasMore", ["subexpr", "@mut", [["get", "hasMore", ["loc", [null, [39, 69], [39, 76]]]]], [], []], "pagesLoaded", ["subexpr", "@mut", [["get", "pagesLoaded", ["loc", [null, [39, 89], [39, 100]]]]], [], []]], 1, null, ["loc", [null, [39, 28], [81, 48]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});