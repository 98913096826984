define('retro-game-store/pods/inventory/items/item/details/route', ['exports', 'ember', 'ember-data-route'], function (exports, _ember, _emberDataRoute) {
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var setProperties = _ember['default'].setProperties;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_emberDataRoute['default'], {
        api: service(),

        afterModel: function afterModel(model) {
            var _this = this;

            return get(this, 'api').request('GET', '/productskus', { productId: get(model, 'productId') }).then(function (products) {
                set(_this, 'productSkus', products);
            });
        },
        setupController: function setupController(controller, model) {
            setProperties(controller, {
                productSkus: get(this, 'productSkus'),
                selectedCondition: get(model, 'condition')
            });
            this._super(controller, model);
        },
        actions: {
            openPrintLabelInventoryModal: function openPrintLabelInventoryModal() {
                var location = 'inventory/items/item/details/print-label-modal';
                this.send('openModal', location, [], location);
            }
        }
    });
});