define('retro-game-store/pods/inventory-price/model', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
    exports['default'] = _emberDataModel['default'].extend({
        category: (0, _emberDataAttr['default'])(),
        competitorPrice: (0, _emberDataAttr['default'])(),
        name: (0, _emberDataAttr['default'])(),
        productType: (0, _emberDataAttr['default'])(),
        researchUrl: (0, _emberDataAttr['default'])(),
        stockNumber: (0, _emberDataAttr['default'])(),
        storeInfo: (0, _emberDataAttr['default'])(),

        affiliateId: (0, _emberDataAttr['default'])('number'),
        conditionId: (0, _emberDataAttr['default'])('number'),
        inventoryId: (0, _emberDataAttr['default'])('number'),
        productId: (0, _emberDataAttr['default'])('number'),
        productSkuId: (0, _emberDataAttr['default'])('number'),
        quantity: (0, _emberDataAttr['default'])('number'),
        sellPrice: (0, _emberDataAttr['default'])('number')
    });
});