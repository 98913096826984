define('retro-game-store/pods/password/reset/controller', ['exports', 'ember', 'retro-game-store/config/environment', 'ember-validations'], function (exports, _ember, _retroGameStoreConfigEnvironment, _emberValidations) {
    exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
        api: _ember['default'].inject.service('api'),
        validations: {
            password: {
                confirmation: true,
                presence: true
            },
            passwordConfirmation: {
                presence: true
            }
        },
        actions: {
            reset: function reset(callback) {
                var _this = this;

                var promise = this.get('api').request('POST', '/password/reset', {
                    password: this.get('password'),
                    confirmPassword: this.get('passwordConfirmation'),
                    tenantId: _retroGameStoreConfigEnvironment['default'].affiliate,
                    token: this.get('token')
                }).then(function () {
                    _this.transitionToRoute('login', _retroGameStoreConfigEnvironment['default'].affiliate);
                });

                callback(promise);
            }
        }

    });
});