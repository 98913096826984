define('retro-game-store/router', ['exports', 'ember', 'retro-game-store/config/environment'], function (exports, _ember, _retroGameStoreConfigEnvironment) {

    var Router = _ember['default'].Router.extend({
        location: _retroGameStoreConfigEnvironment['default'].locationType
    });

    Router.map(function () {
        this.route('affiliate', {
            path: '/:affiliate_tenant'
        }, function () {
            this.route('login', {
                resetNamespace: true
            });
            this.route('password', {
                resetNamespace: true
            }, function () {
                this.route('forgot');
                this.route('reset', {
                    path: '/reset/:code'
                });
            });
            this.route('profile', {
                resetNamespace: true
            });
            this.route('affiliates', {
                resetNamespace: true
            }, function () {
                this.route('affiliate', {
                    path: '/:affiliate_id'
                }, function () {
                    this.route('general');
                    this.route('options');
                    this.route('subscription');
                });
                this.route('new');
            });
            this.route('dashboard', {
                resetNamespace: true
            }, function () {
                this.route('stores');
                this.route('platforms');
            });
            this.route('stores', {
                resetNamespace: true
            }, function () {
                this.route('new');
                this.route('store', {
                    path: '/:store_id'
                }, function () {
                    this.route('details');
                    this.route('employees');
                    this.route('receipt-details');
                    this.route('labels');
                });
            });
            this.route('employees', {
                resetNamespace: true
            }, function () {
                this.route('employee', {
                    path: '/:employee_id'
                });
                this.route('new');
            });
            this.route('customers', {
                resetNamespace: true
            }, function () {
                this.route('new');
                this.route('customer', {
                    path: '/:customer_id'
                }, function () {
                    this.route('details');
                    this.route('notifications', function () {
                        this.route('create-modal', {
                            path: '/create'
                        });
                    });
                    this.route('sales');
                    this.route('trades');
                });
            });
            this.route('inventory', {
                resetNamespace: true
            }, function () {
                this.route('summary', function () {
                    this.route('stores');
                    this.route('categories');
                });
                this.route('items', {
                    path: '/'
                }, function () {
                    this.route('new');
                    this.route('item', {
                        path: '/:item_id'
                    }, function () {
                        this.route('details');
                        this.route('history');
                    });
                });
                this.route('history');
                this.route('transfer');
            });
            this.route('sales', {
                resetNamespace: true
            }, function () {
                this.route('new');
                this.route('sale', {
                    path: '/:sale_id'
                }, function () {});
                this.route('completed');
                this.route('stashed');
                this.route('items-sold');
                this.route('items-traded');
            });
            this.route('pricing', {
                resetNamespace: true
            }, function () {
                this.route('product', {
                    path: '/:product_price_id'
                }, function () {
                    this.route('inventory');
                });
            });
            this.route('fourOhThree', {
                resetNamespace: true,
                path: '/403'
            });
            this.route('shipping', {
                resetNamespace: true
            }, function () {
                this.route('sale', {
                    path: '/:sale_id'
                }, function () {
                    this.route('shipments', function () {
                        this.route('new');
                        this.route('shipment', {
                            path: '/:shipment_id'
                        });
                    });
                });
            });
            this.route('invoices', {
                resetNamespace: true
            }, function () {
                this.route('new');
                this.route('invoice', {
                    path: '/:invoice_id'
                }, function () {
                    this.route('details');
                    this.route('items');
                });
            });
            this.route('help', {
                resetNamespace: true
            });
            this.route('fourOhFour', {
                resetNamespace: true,
                path: '/*path'
            });
        });
        this.route('unauthorized');
    });

    exports['default'] = Router;
});