define('retro-game-store/pods/print/service', ['exports', 'ember', 'accounting/to-fixed', 'moment', 'retro-game-store/constants/label-print-type', 'retro-game-store/utils/print-commands'], function (exports, _ember, _accountingToFixed, _moment, _retroGameStoreConstantsLabelPrintType, _retroGameStoreUtilsPrintCommands) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var get = _ember['default'].get;
    var service = _ember['default'].inject.service;
    var Service = _ember['default'].Service;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = Service.extend({
        qz: service(),
        api: service(),
        session: service(),

        printInventoryLabels: function printInventoryLabels(items) {
            var labels = [];

            items.forEach(function (item) {
                if (item.labelType === get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_MEDIA_ONLY, 'type')) {
                    labels.push({
                        Name: item.name,
                        Condition: item.condition.name,
                        Category: item.category.name,
                        StockNumber: item.stockNumber,
                        Price: item.sellPrice,
                        IsBoxLabel: false
                    });
                }
                if (item.labelType === get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_BOX_ONLY, 'type')) {
                    labels.push({
                        Name: item.name,
                        Condition: item.condition.name,
                        Category: item.category.name,
                        StockNumber: item.stockNumber,
                        Price: item.sellPrice,
                        IsBoxLabel: true
                    });
                }
                if (item.labelType === get(_retroGameStoreConstantsLabelPrintType.LABEL_PRINT_TYPE_ALL, 'type')) {
                    labels.push({
                        Name: item.name,
                        Condition: item.condition.name,
                        Category: item.category.name,
                        StockNumber: item.stockNumber,
                        Price: item.sellPrice,
                        IsBoxLabel: false
                    });
                    labels.push({
                        Name: item.name,
                        Condition: item.condition.name,
                        Category: item.category.name,
                        StockNumber: item.stockNumber,
                        Price: item.sellPrice,
                        IsBoxLabel: true
                    });
                }
            });

            var _generateInventoryLabelCommands = (0, _retroGameStoreUtilsPrintCommands.generateInventoryLabelCommands)(labels);

            var _generateInventoryLabelCommands2 = _slicedToArray(_generateInventoryLabelCommands, 2);

            var commands = _generateInventoryLabelCommands2[0];
            var configOverrides = _generateInventoryLabelCommands2[1];

            return get(this, 'qz').printToLabelPrinter(commands, configOverrides);
        },

        printShippingLabel: function printShippingLabel(shipmentId) {
            var _this = this;

            return get(this, 'api').request('GET', '/easy-post/get-label-image/' + shipmentId).then(function (image) {
                var shippingLabels = [image];

                var _generateShippingLabelCommands = (0, _retroGameStoreUtilsPrintCommands.generateShippingLabelCommands)(shippingLabels);

                var _generateShippingLabelCommands2 = _slicedToArray(_generateShippingLabelCommands, 2);

                var commands = _generateShippingLabelCommands2[0];
                var configOverrides = _generateShippingLabelCommands2[1];

                return get(_this, 'qz').printToLabelPrinter(commands, configOverrides);
            });
        },

        printAuthorizationReceipt: function printAuthorizationReceipt(affiliateName, sale) {
            var _this2 = this;

            var creditPayments = get(sale, 'payments').filterBy('paymentType', "Credit");
            var promises = [];

            creditPayments.forEach(function (creditPayment) {
                var authorizationReceipt = {
                    OrderNumber: get(sale, 'id'),
                    OrderDate: (0, _moment['default'])(get(sale, 'date')).format('MM/DD/YYYY hh:mm:ss A'),
                    CashierName: get(sale, 'processedBy.name'),
                    Total: get(creditPayment, 'amountApplied'),
                    CardNumber: "************" + get(creditPayment, 'accountNumber'),
                    CustomerName: get(creditPayment, 'nameOnCard'),
                    CardType: get(creditPayment, 'cardType'),
                    Shop: {
                        Name: affiliateName,
                        Location: get(sale, 'storeInfo.name'),
                        Address1: get(sale, 'storeInfo.address'),
                        Address2: get(sale, 'storeInfo.address2'),
                        City: get(sale, 'storeInfo.city'),
                        State: get(sale, 'storeInfo.state'),
                        Zip: get(sale, 'storeInfo.zip'),
                        Phone: get(sale, 'storeInfo.phoneNumber'),
                        Id: get(sale, 'storeInfo.id')
                    }
                };

                var _generateAuthReceiptCommands = (0, _retroGameStoreUtilsPrintCommands.generateAuthReceiptCommands)(authorizationReceipt);

                var _generateAuthReceiptCommands2 = _slicedToArray(_generateAuthReceiptCommands, 2);

                var commands = _generateAuthReceiptCommands2[0];
                var configOverrides = _generateAuthReceiptCommands2[1];

                promises.push(get(_this2, 'qz').printToReceiptPrinter(commands, configOverrides));
            });

            return RSVP.all(promises);
        },

        printReceipt: function printReceipt(affiliateName, sale, items) {
            var saleItems = [];
            var payments = [];
            var changeTotal = 0;

            items.forEach(function (item) {
                saleItems.push({
                    Description: get(item, 'description'),
                    Price: get(item, 'subtotal'),
                    Quantity: get(item, 'quantity'),
                    Discount: get(item, 'discount'),
                    DiscountPercent: get(item, 'discountPercent'),
                    IsShipment: get(item, 'willShip'),
                    IsTradeIn: get(item, 'eventType') === 'Trade' || get(item, 'eventType') === 'Buy',
                    ReturnNumber: get(item, 'returnItemId') ? get(item, 'returnItemId') : '0'
                });
            });

            get(sale, 'payments').forEach(function (payment) {
                var accountStr = get(payment, 'accountNumber') == null ? "" : " **" + get(payment, 'accountNumber');
                payments.push({
                    Description: get(payment, 'paymentType') + accountStr,
                    AmountApplied: get(payment, 'amountApplied'),
                    AmountTendered: get(payment, 'amountTendered')
                });
                changeTotal += get(payment, 'changeOwed') ? get(payment, 'changeOwed') : 0;
            });

            var receipt = {
                OrderNumber: get(sale, 'id'),
                OrderDate: (0, _moment['default'])(get(sale, 'date')).format('MM/DD/YYYY hh:mm:ss A'),
                CashierName: get(sale, 'processedBy.name'),
                Total: get(sale, 'totalAmount'),
                Subtotal: get(sale, 'subtotal'),
                Tax: get(sale, 'taxAmount'),
                Shipping: get(sale, 'shippingAmount'),
                Discount: get(sale, 'discount'),
                DiscountPercent: get(sale, 'discountPercent'),
                ReturnPolicy: get(sale, 'storeInfo.returnPolicy'),
                Change: (0, _accountingToFixed['default'])(changeTotal, 2),
                Shop: {
                    Name: affiliateName,
                    Location: get(sale, 'storeInfo.name'),
                    Address1: get(sale, 'storeInfo.address'),
                    Address2: get(sale, 'storeInfo.address2'),
                    City: get(sale, 'storeInfo.city'),
                    State: get(sale, 'storeInfo.state'),
                    Zip: get(sale, 'storeInfo.zip'),
                    Phone: get(sale, 'storeInfo.phoneNumber'),
                    Id: get(sale, 'storeInfo.id')
                },
                Items: saleItems,
                Payments: payments
            };

            if (get(sale, 'customer.id')) {
                receipt.CustomerName = get(sale, 'customer.name');
                receipt.CustomerNumber = get(sale, 'customer.id');
                receipt.CustomerBalance = get(sale, 'customer.creditBalance');
            }

            var receiptLogo = get(this, 'session.user.storeInfo.receiptLogoImage');

            var _generateReceiptCommands = (0, _retroGameStoreUtilsPrintCommands.generateReceiptCommands)(receipt, receiptLogo);

            var _generateReceiptCommands2 = _slicedToArray(_generateReceiptCommands, 2);

            var commands = _generateReceiptCommands2[0];
            var configOverrides = _generateReceiptCommands2[1];

            return get(this, 'qz').printToReceiptPrinter(commands, configOverrides);
        }
    });
});