define('retro-game-store/pods/sale-item/serializer', ['exports', 'ember-data', 'retro-game-store/pods/application/serializer'], function (exports, _emberData, _retroGameStorePodsApplicationSerializer) {
    exports['default'] = _retroGameStorePodsApplicationSerializer['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
        attrs: {
            condition: {
                serialize: false,
                deserialize: 'records'
            },
            inventoryItem: {
                serialize: false,
                deserialize: 'records'
            },
            productSku: {
                serialize: false,
                deserialize: 'records'
            },
            transaction: {
                serialize: false,
                deserialize: 'records'
            }
        }
    });
});