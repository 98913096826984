define('retro-game-store/pods/affiliate/ability', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
    exports['default'] = _emberCan.Ability.extend({
        list: _ember['default'].computed('session.user.roles.[]', function () {
            var roles = this.get('session.user.roles');
            if (_ember['default'].isArray(roles) && roles.contains('SystemAdministrator')) {
                return true;
            } else {
                return false;
            }
        }),
        viewAccountSettings: _ember['default'].computed('session.user.roles.[]', function () {
            var roles = this.get('session.user.roles');
            if (_ember['default'].isArray(roles) && (roles.contains('SystemAdministrator') || roles.contains('AffiliateAdministrator'))) {
                return true;
            } else {
                return false;
            }
        }),
        view: _ember['default'].computed('session.user', 'model.id', function () {
            var roles = this.get('session.user.roles');
            if (_ember['default'].isArray(roles)) {
                if (roles.contains('SystemAdministrator')) {
                    return true;
                } else if (roles.contains('AffiliateAdministrator')) {
                    if (this.get('model').get('id') === this.get('session').get('user').get('affiliateId').toString()) {
                        return true;
                    }
                }
            }
            return false;
        }),
        editMaxStores: _ember['default'].computed('session.user.roles.[]', function () {
            var roles = this.get('session.user.roles');
            if (_ember['default'].isArray(roles) && roles.contains('SystemAdministrator')) {
                return true;
            } else {
                return false;
            }
        })
    });
});