define('retro-game-store/pods/components/category-dropdown-single/component', ['exports', 'ember', 'retro-game-store/pods/components/category-dropdown/component', 'retro-game-store/pods/components/category-dropdown/template', 'ember-i18n'], function (exports, _ember, _retroGameStorePodsComponentsCategoryDropdownComponent, _retroGameStorePodsComponentsCategoryDropdownTemplate, _emberI18n) {
    var set = _ember['default'].set;
    var get = _ember['default'].get;
    var setProperties = _ember['default'].setProperties;
    var computed = _ember['default'].computed;

    var ENTER_KEYCODE = 13;

    exports['default'] = _retroGameStorePodsComponentsCategoryDropdownComponent['default'].extend({
        canAddParent: false,
        canSelectMultiple: false,
        allowClear: true,
        selectedItems: null,
        selectedItemComponent: "category-dropdown-single/selected-item",
        deleteComponent: "category-dropdown-single/delete-item",
        placeholder: (0, _emberI18n.translationMacro)('categoryDropdown.selectCategory'),
        layout: _retroGameStorePodsComponentsCategoryDropdownTemplate['default'],
        placeholderText: computed('placeholder', 'selectedItems', function () {
            if (!get(this, 'selectedItems') && get(this, 'placeholder')) {
                return get(this, 'placeholder');
            } else {
                return '';
            }
        }),
        hasFocused: function hasFocused() {
            var textField = this.$('input');
            if (document.activeElement !== textField[0]) {
                textField.focus();
            }
        },
        addItem: function addItem(item) {
            this.removeSelectedItem();
            var selectionId = get(this, 'selection');
            var parentCategory = get(this, 'categories').findBy('id', selectionId);
            get(parentCategory, 'productCategories').removeObject(item);
            setProperties(this, {
                selectedItems: { item: item, selectionId: selectionId },
                value: null
            });
            get(this, 'basicDropdown').actions.close();
        },
        removeSelectedItem: function removeSelectedItem() {
            var selectedItems = get(this, 'selectedItems');
            if (selectedItems) {
                var category = get(selectedItems, 'item');
                var selectionId = get(selectedItems, 'selectionId');
                var parentCategory = get(this, 'categories').findBy('id', selectionId);
                get(parentCategory, 'productCategories').pushObject(category);
                set(this, 'selectedItems', null);
                get(this, 'basicDropdown').actions.close();
            }
        },
        actions: {
            onKeyDown: function onKeyDown(select, event) {
                if (event.keyCode === ENTER_KEYCODE && get(this, 'filteredItems.length') === 1) {
                    this.addItem(get(this, 'filteredItems.firstObject'));
                    get(this, 'basicDropdown').actions.close();
                }
            },
            addItem: function addItem(item) {
                this.addItem(item);
            },
            clear: function clear() {
                this.removeSelectedItem();
            }
        }
    });
});